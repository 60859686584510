import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import State from '@wfp-root-app/store/state';
import { ActionCreators, SecurityProps } from './security';
import Manager from '../managers/manager';
import { ActionCreators as ManagerActionCreators } from '../managers/managers';
import ChangePasswordModal from './ChangePasswordModal';

interface Props {
    security: SecurityProps;
    manager: Manager;
    getSecurityData: () => void;
    changePassword: (oldPass: string, newPass: string) => void;
}

class ForceResetPasswordPage extends React.Component<Props, any> {
    constructor(props, context) {
        super(props, context);

        this.state = {
            otpSecret: null,
            showOtpSecret: false,
            changePassword: false,
        };
    }

    changePassword(oldPass, newPass) {
        this.props.changePassword(oldPass, newPass);
        this.setState({ changePassword: false });
    }

    componentDidMount() {
        this.props.getSecurityData();
    }

    render() {
        return (
            <main>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h5>Your password is outdated!</h5>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '20px' }}>
                        <div className="col-md-12">
                            Your password is outdated. For security reasons please change your password.
                        </div>
                    </div>
                    {this.state.changePassword && (
                        <ChangePasswordModal
                            onClose={() => this.setState({ changePassword: false })}
                            onConfirm={this.changePassword.bind(this)}
                        />
                    )}

                    <div className="form-group pv2">
                        <button
                            className="wfp-btn--primary"
                            onClick={() => this.setState({ changePassword: true })}
                            type="button"
                        >
                            Change Password
                        </button>
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        security: state.security,
        manager: state.auth.manager,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getSecurityData: bindActionCreators(ActionCreators.getSecurityData, dispatch),
        changePassword: bindActionCreators(ManagerActionCreators.changePassword, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForceResetPasswordPage as any);
