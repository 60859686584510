import { connect } from 'react-redux';
import { ActivityLogsList } from '../activityLogs/ActivityLogsList';
import { bindActionCreators } from 'redux';
import { ActionCreators as ManagerActionCreators } from '../managers/managers';
import { ActionCreators as VendorActionCreators } from '../vendors/vendors';
import { ManagerPermission } from '../permission-profiles/permission';
import { ActionCreators as PartnerActionCreators } from '../partners/partnersActions';
import State from '@wfp-root-app/store/state';

function mapDispatchToProps(dispatch: any) {
    return {
        loadActivityLogs: (page, limit, managerId) =>
            dispatch(ManagerActionCreators.loadActivityLogs(managerId, page, limit)),
        loadManagersNickNames: bindActionCreators(ManagerActionCreators.loadManagersNickNames, dispatch),
        loadVendorsNickNames: bindActionCreators(VendorActionCreators.loadVendorsNickNames, dispatch),
        loadVendorUsersNames: bindActionCreators(VendorActionCreators.loadVendorUsersNames, dispatch),
        loadPartnersNames: bindActionCreators(PartnerActionCreators.loadPartnersNames, dispatch),
        loadPartnerUserNames: bindActionCreators(PartnerActionCreators.loadPartnerUsersName, dispatch),
        isManager: true,
    };
}

function mapStateToProps(state: State) {
    return {
        activityLogs: state.managers.activityLogs,
        managersNickNames: state.managers.managerNickNameList,
        vendorsNickNames: state.vendors.nickNameList,
        partnersNickNames: state.partners.nameList,
        partnerUsersNickNames: state.partners.partnerUsersNamesList,
        auth: state.auth,
        url: state.auth.manager ? `/admin/${state.auth.manager.id}/activity/export-activity-log` : `/login`,
        downloadRestriction: [ManagerPermission.userActivityDownload],
        vendorUsersNames: state.vendors.usersNames,
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(ActivityLogsList) as any;
