import React, { useEffect, useMemo, useState } from 'react';

import { ReadonlyCard, TokenCard } from './Cards';
import { TokensInfo } from './model';
import { SortActiveTokens } from './TokenClusterUtils';
import { PredefinedCurrency } from '../app/appConfig';
import { currencyFormatter } from '../utils/utils';
import { SerializedBalanceEntry } from '../vendors/model';

const spacing: React.CSSProperties = { width: 20 };
const flexRow: React.CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
};

interface Props {
    balanceToMap: Array<SerializedBalanceEntry>;
    beneficiaryId: string;
    currency: PredefinedCurrency;
    getClusterInfoFunction: (beneficiaryId: string) => Promise<any>;
    title?: string;
    value?: string;
    timezone: string;
}

export const BalanceEntriesComponent: React.FC<Props> = ({
    balanceToMap,
    beneficiaryId,
    currency,
    getClusterInfoFunction,
    title,
    value,
    timezone,
}: Props) => {
    const [clustersInfo, setClustersInfo] = useState([]);

    useEffect(() => {
        const fetchData = async () => await getClusterInfoFunction(beneficiaryId);
        fetchData().then(
            (res) => setClustersInfo(res),
            () => setClustersInfo([])
        );
    }, [beneficiaryId]);

    const clusterNames = useMemo(() => clustersInfo.map((cluster) => cluster.category), [clustersInfo]);

    const clusterTokens = useMemo(
        () =>
            clustersInfo.map((clusterInfo: TokensInfo) => {
                if (clusterInfo.tokens.length > 0) {
                    return new SortActiveTokens().select(clusterInfo.tokens).slice(0, 3);
                } else {
                    return [];
                }
            }),
        [clustersInfo]
    );

    if (balanceToMap) {
        const balanceCards = balanceToMap.map((balance, index) => {
            const image = 'thematic-resourcing-pos'; // balance.category === 'FOOD' ? 'thematic-food-pos' : 'thematic-resourcing-pos'
            return (
                <ReadonlyCard
                    image={image}
                    key={index}
                    title={title || balance.category}
                    value={value || currencyFormatter(currency).format(balance.value)}
                />
            );
        });
        const balanceCardsWithSpacing = [];
        balanceCards.forEach((card, index) => {
            if (index !== 0) {
                balanceCardsWithSpacing.push(<div key={'spacing-' + index} style={spacing} />);
            }
            balanceCardsWithSpacing.push(card);
        });

        const tokenCards = clusterNames.map((name, idx) => (
            <TokenCard
                currency={currency}
                data={clusterTokens[idx]}
                key={idx}
                timezone={timezone}
                title={`${clusterNames[idx]} TOKENS`}
            />
        ));
        const tokenCardsWithSpacing = [];
        tokenCards.forEach((card, index) => {
            if (index !== 0) {
                tokenCardsWithSpacing.push(<div key={'spacing-' + index} style={spacing} />);
            }
            tokenCardsWithSpacing.push(card);
        });

        return (
            <>
                <div style={flexRow}>{balanceCardsWithSpacing}</div>
                <div style={flexRow}>{tokenCardsWithSpacing}</div>
            </>
        );
    } else {
        return null;
    }
};
