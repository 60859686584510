export enum ManagerTab {
    GeneralInformation = 'General Information',
    DownloadUser = 'Download User',
    ActivityLogs = 'Activity Logs',
    PermissionsFiles = 'Permissions Files',
}

export type ManagerTabUrl = 'information' | 'activity' | 'download' | 'permissions';

export const MANAGER_URL_TABS_MAP = {
    information: ManagerTab.GeneralInformation,
    activity: ManagerTab.ActivityLogs,
    download: ManagerTab.DownloadUser,
    permissions: ManagerTab.PermissionsFiles,
} as const;
