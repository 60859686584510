import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

const name = 'routerHelper';
const initialState = createInitialState();
const routerHelperSlice = createSlice({
    name,
    initialState,
    reducers: {
        makeRedirect: {
            reducer: (state, action: PayloadAction<{ url: string; navigateState: any }>) => {
                state.redirectUrl = action.payload.url;
                state.navigateState = action.payload.navigateState;
            },
            prepare: (url: string, navigateState?: any) => {
                return { payload: { url, navigateState } };
            },
        },
        clearRedirect: (state) => {
            state.redirectUrl = null;
            state.navigateState = null;
        },
    },
});

export interface RouterHelperState {
    redirectUrl: string | null;
    navigateState: any;
}

function createInitialState(): RouterHelperState {
    return {
        redirectUrl: null,
        navigateState: null,
    };
}

export const routerHelperActions = { ...routerHelperSlice.actions };
export const routerHelperReducer = routerHelperSlice.reducer;

const routerHelper = <T extends { routerHelper: RouterHelperState }>(state: T) => state.routerHelper;

export const selectRouterHelper = createSelector([routerHelper], (state) => state);
