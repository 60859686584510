import React from 'react';
import { ManagerPermission } from './permission-profiles/permission';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { AppContainer } from './app/AppContainer';
import { HomePage } from './home/HomePage';
import LoginPage from './login/LoginPage';
import ErrorPage from './app/ErrorPage';
import ResetOtpPage from './login/ResetOtpPage';
import ForceResetPasswordPage from './security/ForceResetPasswordPage';
import TaskAuthorizationsPage from './authorization/TaskAuthorizationsPage';
import { AuthorizationTaskSubPage } from './authorization/AuthorizationTaskSubPage';
import ManagersPage from './managers/ManagersPage';
import ManagerExportPage from './managers/ManagerExportPage';
import { AddManagerPage } from './managers/AddManagerPage';
import ManagerEditPage from './managers/ManagerEditPage';
import { ManagerTabRenderer } from './managers/tabs';
import ActivityLogsDetailsView from './activityLogs/details/ActivityLogsDetailsView';
import { ManagerLogExport } from './activityLogs/MenagerActivityLogExport';
import DocumentsPage from './documents/DocumentsPage';
import { UploadDocumentsForm } from './documents/UploadDocumentsForm';
import ExportedFromStatistics from './statistics/export/ExportedFromStatistics';
import AsyncTaskDetailsView from './utils/AsyncTaskDetailsView';
import ManagersMainPage from './managers/ManagersMainPage';
import { ManagerPersonalTabRenderer } from './managers/personal/ManagerPersonalTabRenderer';
import { BENEFICIARY_SUPPORT_PANEL_URL } from './partners/utils/constants';
import PartnersPage from './partners/PartnersPage';
import PartnerAddPage from './partners/PartnerAddPage';
import PartnerEditPage from './partners/PartnerEditPage';
import { BeneficiarySupportPanelTabRenderer } from './partners/tabs';
import EntitlementsPage from './entitlements/EntitlementsPage';
import { FileUploadContainer } from './beneficiaries/fileUploadPages/FileUploadContainer';
import DeduplicatedEntitlementExportPage from './entitlements/DeduplicatedEntitlementExportPage';
import TaxIdMappingExportPage from './entitlements/TaxIdMappingExportPage';
import EditBeneficiaryPage from './beneficiaries/edit/EditBeneficiaryPage';
import { EditBeneficiaryTabRenderer } from './beneficiaries/edit/EditBeneficiaryTabRenderer';
import { AssistanceContainer } from './entitlements/AssistanceContainer';
import EntitlementExportPage from './entitlements/EntitlementExportPage';
import { ProtectedRoute } from '@wfp-common/auth/ProtectedRoute';
import { PermissionChecker } from '@wfp-common/auth/PermissionChecker';

export const sessionAutoExpirationMessage = 'The previous session time has auto-expired.';

export default () => {
    return (
        <Route element={<Outlet />}>
            <Route
                element={
                    <ProtectedRoute>
                        <AppContainer />
                    </ProtectedRoute>
                }
            >
                <Route index element={<HomePage />} />
                <Route path={'home'} element={<Navigate to={'/'} />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="set-otp" element={<ResetOtpPage />} />

                <Route path="change-password" element={<ForceResetPasswordPage />} />

                <Route
                    path={'adjustments/*'}
                    element={
                        <PermissionChecker requiredPermissions={[ManagerPermission.adjustmentsView]}>
                            <TaskAuthorizationsPage />
                        </PermissionChecker>
                    }
                >
                    <Route path=":taskType" element={<AuthorizationTaskSubPage />} />
                    <Route element={<ErrorPage />} path="*" />
                </Route>

                <Route path={'admin'} element={<Outlet />}>
                    <Route
                        index
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.managersManagement]}>
                                <ManagersPage />
                            </PermissionChecker>
                        }
                    />

                    <Route
                        path={'download'}
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.userDownload]}>
                                <ManagerExportPage />
                            </PermissionChecker>
                        }
                    />
                    <Route path={'new'} element={<AddManagerPage />} />

                    <Route
                        path={':managerId'}
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.managersManagement]}>
                                <ManagerEditPage />
                            </PermissionChecker>
                        }
                    >
                        <Route path={':managerTab'} element={<ManagerTabRenderer />} />
                        <Route
                            path={':managerTab/export-activity-log'}
                            element={
                                <PermissionChecker requiredPermissions={[ManagerPermission.userActivityDownload]}>
                                    <ManagerLogExport />
                                </PermissionChecker>
                            }
                        />
                        <Route path={':managerTab/:logId'} element={<ActivityLogsDetailsView />} />
                    </Route>
                </Route>

                <Route path={'documents'} element={<DocumentsPage />} />
                <Route
                    path={'documents/create'}
                    element={
                        <PermissionChecker requiredPermissions={[ManagerPermission.documentManagement]}>
                            <UploadDocumentsForm />
                        </PermissionChecker>
                    }
                />

                <Route
                    path={'entitlements'}
                    element={
                        <PermissionChecker requiredPermissions={[ManagerPermission.beneficiariesRead]}>
                            <Outlet />
                        </PermissionChecker>
                    }
                >
                    <Route index element={<EntitlementsPage />} />
                    <Route path={'import'} element={<FileUploadContainer />} />

                    <Route
                        path={'download'}
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.beneficiariesDownload]}>
                                <AssistanceContainer />
                            </PermissionChecker>
                        }
                    />

                    <Route
                        path={'download-mapping'}
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.beneficiariesDownload]}>
                                <TaxIdMappingExportPage />
                            </PermissionChecker>
                        }
                    />

                    <Route path={'download/beneficiary'} element={<EditBeneficiaryPage />}>
                        <Route path={':beneficiaryTab'} element={<EditBeneficiaryTabRenderer />} />
                    </Route>

                    <Route
                        path="download/deduplicated-entitlements/file"
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.beneficiariesDownload]}>
                                <DeduplicatedEntitlementExportPage />
                            </PermissionChecker>
                        }
                    />

                    <Route
                        path="download/entitlements/file"
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.beneficiariesDownload]}>
                                <EntitlementExportPage />
                            </PermissionChecker>
                        }
                    />

                    <Route
                        path={'download'}
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.beneficiariesDownload]}>
                                <DeduplicatedEntitlementExportPage />
                            </PermissionChecker>
                        }
                    />

                    <Route path={'beneficiary'} element={<EditBeneficiaryPage />}>
                        <Route path={':beneficiaryTab'} element={<EditBeneficiaryTabRenderer />} />
                    </Route>
                </Route>

                <Route
                    path={BENEFICIARY_SUPPORT_PANEL_URL}
                    element={
                        <PermissionChecker requiredPermissions={[ManagerPermission.partnersRead]}>
                            <Outlet />
                        </PermissionChecker>
                    }
                >
                    <Route index element={<PartnersPage />} />

                    <Route
                        path={'new'}
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.partnerPark]}>
                                <PartnerAddPage />
                            </PermissionChecker>
                        }
                    />

                    <Route path={':partnerId'} element={<PartnerEditPage />}>
                        <Route path={':baneSupportTab'} element={<BeneficiarySupportPanelTabRenderer />}>
                            <Route path={':supportTabSubView'} />
                        </Route>
                    </Route>
                </Route>

                <Route
                    path={'exported-from-statistics'}
                    element={
                        <PermissionChecker requiredPermissions={[ManagerPermission.beneficiariesDownload]}>
                            <ExportedFromStatistics />
                        </PermissionChecker>
                    }
                />

                <Route path={'tasks/:type/:taskId'} element={<AsyncTaskDetailsView />} />

                <Route path={'personal'} element={<ManagersMainPage />}>
                    <Route path={':personalTab'} element={<ManagerPersonalTabRenderer />} />
                </Route>

                <Route element={<ErrorPage />} path="*" />
            </Route>
        </Route>
    );
};
