import Action from '../action';
import { Filter } from './FilterTypes';

export class FiltersState {
    relatedTo: string;
    selectedFilters: Filter[];

    constructor(selectedFilters: Filter[], relatedTo: string) {
        this.selectedFilters = selectedFilters;
        this.relatedTo = relatedTo;
    }
}

export enum FiltersTypes {
    beneficiaries = 'beneficiaries',
    transactions = 'transactions',
    activityLogs = 'activity-logs',
    summaryStatistics = 'summary-statistics',
    beneficiaryZeroing = 'beneficiary-zeroing',
    entitlements = 'entitlements',
    deduplicatedEntitlements = 'deduplicatedEntitlements',
}

export const ActionTypes = {
    saveFilters: 'FiltersStore.saveFilters',
};

export const FiltersCreators = {
    saveFilters(filters: Filter[], relatedTo: string) {
        return (dispatch) => {
            return dispatch({
                type: ActionTypes.saveFilters,
                payload: {
                    relatedTo: relatedTo,
                    filters: filters,
                },
            });
        };
    },
};

export function filtersReducer(state: FiltersState[] = [], action: Action): FiltersState[] {
    switch (action.type) {
        case ActionTypes.saveFilters:
            if (state && !state.find((x) => x.relatedTo === action.payload.relatedTo)) {
                return state.concat(new FiltersState(action.payload.filters, action.payload.relatedTo));
            }

            if (state.length < 1) {
                return [new FiltersState(action.payload.filters, action.payload.relatedTo)];
            }
            const index = state.findIndex((x) => x.relatedTo === action.payload.relatedTo);
            if (!~index) {
                return state.concat([new FiltersState(action.payload.filters, action.payload.relatedTo)]);
            }

            state.splice(index, 1, new FiltersState(action.payload.filters, action.payload.relatedTo));
            return state;
    }
    return state;
}
