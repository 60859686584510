import { useDispatch } from 'react-redux';
import { updateSectionOrder } from '../../apiClient';
import { errorAction } from '../../utils/notifications';
import { FileDocument } from './types';
import { Dispatch, SetStateAction } from 'react';

interface Args {
    documents: [string, FileDocument[]][];
    setDocuments: Dispatch<SetStateAction<[string, FileDocument[]][]>>;
    getDocuments(): Promise<void>;
}

export function useDocumentSectionReorder({ documents, setDocuments, getDocuments }: Args) {
    const dispatch = useDispatch();
    const saveSectionOrder = async () => {
        try {
            await updateSectionOrder(
                documents?.map(([section], index) => ({
                    name: section,
                    order: index + 1,
                }))
            );
        } catch (error) {
            await getDocuments();
            await dispatch(errorAction('Could not reorder document sections'));
        }
    };

    const reorderSections = (selected: string, target: string) => {
        const isNotString = typeof selected !== 'string' || typeof target !== 'string';
        if (selected === target || isNotString) return;
        const currentIndex = documents.findIndex(([section]) => selected === section);
        const targetIndex = documents.findIndex(([section]) => target === section);
        const _documents = [...documents];
        const [element] = _documents.splice(currentIndex, 1);
        _documents.splice(targetIndex, 0, element);
        setDocuments(_documents);
    };

    return { saveSectionOrder, reorderSections };
}
