import React from 'react';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthConfig, AuthConfigUser } from '@wfp-common/models';

interface AuthContextType extends Pick<AuthConfig, 'isPathOnProtectedLocation' | 'appHomePath' | 'appEntryPath'> {
    loggedUser: AuthConfigUser | null;
    isUserLogged: boolean;
}

const AuthContext = createContext<AuthContextType>(null);

interface AuthContextProviderProps {
    authConfig: () => AuthConfig;
}

export const AuthContextProvider = (props: PropsWithChildren<AuthContextProviderProps>) => {
    const authConfig = props.authConfig();

    useEffect(() => {
        authConfig.initApp();
    }, []);

    const value: AuthContextType = useMemo(
        () => ({
            loggedUser: authConfig.getUser(),
            isUserLogged: authConfig.isUserLogged && authConfig.isAppReadyToUse,
            appEntryPath: authConfig.appEntryPath,
            appHomePath: authConfig.appHomePath,
            isPathOnProtectedLocation: authConfig.isPathOnProtectedLocation,
        }),
        [authConfig]
    );

    if (!authConfig.getApiToken() && authConfig.isPathOnProtectedLocation) {
        return <Navigate to={authConfig.appEntryPath} />;
    }

    return <AuthContext.Provider value={value} {...props} />;
};

export const useAuthContext = () => {
    const context = useContext<AuthContextType>(AuthContext);

    if (!context) {
        throw new Error('You need to wrap AuthContextProvider');
    }

    return context;
};
