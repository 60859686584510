import Action from '../action';
import {
    changePaymentRequestStatus,
    getPossibleTargetStatuses,
    loadPaymentRequest,
    loadPaymentRequests,
} from '../apiClient';
import { Filter } from '../utils/FilterTypes';
import { PagedState } from '../utils/paging';
import NamedId from '../utils/NamedId';
import { FileTaskReference } from '../utils/fileTaskReference';
import { errorAction } from '../utils/notifications';
import StatusTransitionReference from '../utils/statusTransitionReference';
import { handleSapPaymentRequestsError } from '../utils/handleSapPaymentRequestsError';
import { handlePaymentRequestStatusChangeError } from '../utils/handlePaymentRequestStausChangeError';
import { AsyncTask } from '../utils/asyncTasks';
import { AppConfigActionTypes } from '../app/appConfig';

export enum SapPaymentRequestStatus {
    Exists = 'Exists',
    Run = 'Run',
    Completed = 'Completed',
    Unknown = 'Unknown',
    Failed = 'Failed',
}

export enum PaymentRequestStatus {
    NotGenerated = 'Not Generated',
    GenerationParkedProcessing = 'Processing Park Generation',
    GenerationParked = 'Generation Parked',
    RejectionProcessing = 'Processing Reject Generation',
    GenerationPostProcessing = 'Processing Generation',
    Generated = 'Generated',
    CancellationParked = 'Cancellation Parked',
    CancellationProcessing = 'Processing Post Cancellation',
    Cancelled = 'Cancelled',
    IssuePaymentPark = 'Issue Payment Park',
    IssuePaymentPost = 'Issue Payment Post',
    PaidParked = 'Paid Parked',
    PaidPostProcessing = 'Processing Post Paid',
    Paid = 'Paid',
}

export enum PaymentRequestAction {
    ParkGeneration = 'Park Generation',
    PostGeneration = 'Post Generation',
    RejectGeneration = 'Reject Generation',
    UnParkGeneration = 'Un-Park Generation',
    ParkCancellation = 'Park Cancellation',
    IssuePaymentPark = 'Issue Payment Park',
    IssuePaymentPost = 'Issue Payment Post',
    ParkPaid = 'Park Paid',
    PostPaid = 'Post Paid',
    PostCancellation = 'Post Cancellation',
    RejectCancellation = 'Reject Cancellation',
    UnParkCancellation = 'Un-Park Cancellation',
    Empty = '',
}

export const SapPaymentRequestMappedStatuses: {
    [sapPaymentRequestStatus in SapPaymentRequestStatus]: string;
} = {
    [SapPaymentRequestStatus.Exists]: 'Payment Proposal',
    [SapPaymentRequestStatus.Run]: 'Payment Run',
    [SapPaymentRequestStatus.Completed]: 'Payment Completed',
    [SapPaymentRequestStatus.Unknown]: 'Unknown',
    [SapPaymentRequestStatus.Failed]: 'Failed',
};

export const PaymentRequestStatusesThatBlockTxModification = [
    PaymentRequestStatus.GenerationParked,
    PaymentRequestStatus.Generated,
    PaymentRequestStatus.CancellationParked,
];

export interface PaymentRequestsBalance {
    credits: number;
    debits: number;
}

export interface SapPaymentRequestProposal {
    poItem: string;
    poNumber: string;
    availableAmount: string;
    requestedAmount: string;
    originalAmount: string;
    terminationDate: string;
}

export class SapPaymentRequest implements SapPaymentRequestProposal {
    poItem: string;
    poNumber: string;
    availableAmount: string;
    requestedAmount: string;
    originalAmount: string;
    terminationDate: string;
    recordId?: string;
    status?: SapPaymentRequestStatus;
    lastStatusUpdate: string;
    runDate?: string;
}

export function isZeroAndPaidPayment(payment: PaymentRequest) {
    return (
        Number(payment.currentPeriod.credits) === 0 &&
        Number(payment.currentPeriod.debits) === 0 &&
        Number(payment.previousPeriods.credits) === 0 &&
        Number(payment.previousPeriods.debits) === 0 &&
        payment.status === PaymentRequestStatus.Paid
    );
}

export class PaymentRequest {
    id?: string;
    vendor: NamedId;
    periodStart: string;
    periodEnd: string;
    paymentNumber?: string;
    currentPeriod: PaymentRequestsBalance;
    previousPeriods: PaymentRequestsBalance;
    outstandingAmount: number;
    bankFeeAmount: number;
    payableAmount: number;
    paymentDiscount: string;
    status: PaymentRequestStatus;
    statusTransitions: StatusTransitionReference<PaymentRequestStatus>[];
    excelFileTask?: FileTaskReference;
    pdfFileTask?: FileTaskReference;
    createdAt: string;
    relatedPayments?: PaymentRequest[];
    isParkGeneratingAllowed: boolean;
    category: string;
    sendSapPaymentAttempts: AsyncTask[];
    sapPaymentRequests: SapPaymentRequest[];
}

export interface PaymentStatusTransition {
    action: PaymentRequestAction;
    from: PaymentRequestStatus;
    to: PaymentRequestStatus;
}

export interface PaymentPendingAction {
    [endDate: string]: number;
}

export class PaymentRequestState {
    request: PaymentRequest;
    requestPossibleStatuses: Array<PaymentStatusTransition>;

    constructor(request: PaymentRequest = null, possibleStatuses: Array<PaymentStatusTransition> = []) {
        this.request = request;
        this.requestPossibleStatuses = possibleStatuses;
    }
}

export class PaymentRequestsState {
    constructor(
        public requests: PagedState<PaymentRequest> = new PagedState<PaymentRequest>(),
        public periodEnd: Date = new Date(),
        public pendingActionState: PagedState<PaymentPendingAction> = new PagedState<PaymentPendingAction>(),
        public activeEntitlement: string = ''
    ) {}
}

const ActionTypes = {
    dataLoaded: 'PaymentRequestsPage.dataLoaded',
    paymentRequestLoaded: 'PaymentRequestsPage.paymentRequestLoaded',
    pendingLoaded: 'PaymentRequestsPage.pendingLoaded',
    statusesLoaded: 'PaymentRequestsPage.statusesLoaded',
    activeEntitlementSelected: 'PaymentRequestsPage.activeEntitlementSelected',
};

export const ActionCreators = {
    loadPaymentRequests(page, limit = 1000, periodEnd: Date, category: string, filters: Array<Filter> = []) {
        return (dispatch) => {
            return loadPaymentRequests(periodEnd, category, filters, page, limit)
                .then((res) => res || new PagedState<PaymentRequest>().configureFor204Response(limit, filters))
                .then((requests) =>
                    dispatch({
                        type: ActionTypes.dataLoaded,
                        payload: { requests, periodEnd },
                    })
                );
        };
    },

    loadPaymentRequest(paymentRequestId) {
        return (dispatch) => {
            let paymentResponse;
            return loadPaymentRequest(paymentRequestId)
                .then((response) => {
                    paymentResponse = response;
                    return dispatch({
                        type: ActionTypes.paymentRequestLoaded,
                        payload: response,
                    });
                })
                .then(() =>
                    dispatch(ActionCreators.dispatchFailedSapPaymentRequestAction(paymentResponse.sapPaymentRequests))
                );
        };
    },

    loadTargetStatuses(paymentRequestId) {
        return (dispatch) => {
            return getPossibleTargetStatuses(paymentRequestId)
                .then((response) => {
                    const targetStatuses = response;
                    return dispatch({
                        type: ActionTypes.statusesLoaded,
                        payload: targetStatuses.possibleStatuses,
                    });
                })
                .catch(() =>
                    errorAction({
                        message: 'Failed to load possible statuses for payment requests',
                    })
                );
        };
    },

    dispatchFailedSapPaymentRequestAction(sapPaymentRequests: Array<SapPaymentRequest>) {
        return (dispatch) => {
            if (
                sapPaymentRequests &&
                sapPaymentRequests.length > 0 &&
                sapPaymentRequests.find((sapPaymentRequest: SapPaymentRequest) =>
                    [SapPaymentRequestStatus.Failed, SapPaymentRequestStatus.Unknown].includes(sapPaymentRequest.status)
                )
            ) {
                dispatch(errorAction('WINGS document chain has not concluded successfully. Contact system admin'));
            }
        };
    },

    handlePoDetailsFetchingError(err) {
        return (dispatch) => handleSapPaymentRequestsError(err, dispatch);
    },

    changePaymentRequestStatus(
        paymentRequestId: string,
        targetStatus: PaymentRequestStatus,
        routeOverride: string = null,
        paymentSapDetails?: Array<SapPaymentRequest>
    ) {
        return async (dispatch) => {
            try {
                const response = await changePaymentRequestStatus(
                    paymentRequestId,
                    targetStatus,
                    routeOverride,
                    paymentSapDetails
                );
                dispatch({
                    type: ActionTypes.paymentRequestLoaded,
                    payload: response,
                });
            } catch (err) {
                handlePaymentRequestStatusChangeError(err, dispatch);
            }
            dispatch(ActionCreators.loadTargetStatuses(paymentRequestId));
            dispatch(ActionCreators.loadPaymentRequest(paymentRequestId));
        };
    },

    selectActiveEntitlement(activeEntitlement: string) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.activeEntitlementSelected,
                payload: activeEntitlement,
            });
    },
};

export function paymentRequestsReducer(
    state: PaymentRequestsState = new PaymentRequestsState(),
    action: Action
): PaymentRequestsState {
    switch (action.type) {
        case ActionTypes.dataLoaded:
            return new PaymentRequestsState(
                action.payload.requests,
                action.payload.periodEnd,
                state.pendingActionState,
                state.activeEntitlement
            );

        case ActionTypes.pendingLoaded:
            return new PaymentRequestsState(state.requests, state.periodEnd, action.payload, state.activeEntitlement);

        case ActionTypes.paymentRequestLoaded:
            const payload: PaymentRequest = action.payload;
            const requests: PagedState<PaymentRequest> = state.requests;

            const existingPaymentRequest = requests.items.find((pr) => pr.vendor && pr.vendor.id === payload.vendor.id);
            if (!existingPaymentRequest) {
                return state;
            }

            Object.assign(existingPaymentRequest, payload);

            const pagedStateCopy = new PagedState<PaymentRequest>();
            pagedStateCopy.items = [...requests.items];
            pagedStateCopy.paging = requests.paging;
            pagedStateCopy.filters = requests.filters;

            return new PaymentRequestsState(
                pagedStateCopy,
                state.periodEnd,
                state.pendingActionState,
                state.activeEntitlement
            );

        case ActionTypes.activeEntitlementSelected:
            return new PaymentRequestsState(state.requests, state.periodEnd, state.pendingActionState, action.payload);

        case AppConfigActionTypes.loadAppConfig:
            return new PaymentRequestsState(
                state.requests,
                state.periodEnd,
                state.pendingActionState,
                action.payload.entitlementsConfig.categories[0]
            );
    }
    return state;
}

export function paymentRequestReducer(state: PaymentRequestState = new PaymentRequestState(), action: Action) {
    switch (action.type) {
        case ActionTypes.paymentRequestLoaded:
            return new PaymentRequestState(action.payload, state.requestPossibleStatuses);
        case ActionTypes.statusesLoaded:
            return new PaymentRequestState(state.request, action.payload);
    }
    return state;
}
