import React from 'react';
import LoginPage from './LoginPage';
import { bindActionCreators } from 'redux';
import { ActionCreators } from './auth';
import { connect } from 'react-redux';
import { windowStorage, WindowStorageToken } from '../windowStorage';
import { useEffect, useState } from 'react';
import { checkTokens } from '../apiClient';
import styled from 'styled-components';
import { Loader } from '../utils/Loader';

function ResetOtpPage(props) {
    if (windowStorage.getItem(WindowStorageToken.managerApiToken)) {
        // eslint-disable-next-line react/prop-types
        props.logout(window.location.href);
    }

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        checkTokens()
            .then(() => setError(''))
            .catch(async (error) => {
                if (error.status === 404) {
                    setError('Reset OTP token url is expired.');
                } else {
                    setError('Reset OTP token url is invalid.');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        );
    }

    if (error) {
        return <div className="alert alert-danger">{error}</div>;
    }

    return <LoginPage otpReset={true} />;
}

function mapDispatchToProps(dispatch: any) {
    return {
        logout: bindActionCreators(ActionCreators.logout, dispatch),
    };
}

const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 22px;
`;

export default connect(null, mapDispatchToProps, null)(ResetOtpPage);
