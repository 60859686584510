import React from 'react';
import { Link, Navigate, Outlet, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ExternalValidationError } from '../utils/inputs';
import { ActionCreators as authActionCreators, AuthState, hasFeatureAccess } from '../login/auth';
import { SideMenu } from '../utils/SideMenu';
import { ActionCreators } from './managers';
import { PagedState } from '../utils/paging';
import { ManagerPermission } from '../permission-profiles/permission';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';
import State from '@wfp-root-app/store/state';

enum ManagerTabs {
    PasswordAndSecurity = 'Password & Security',
    DetailsAndPreferences = 'Personal Details & Preferences',
    ActivityLogs = 'Activity Logs',
}

const urlPathForTab = {
    [ManagerTabs.PasswordAndSecurity]: 'security',
    [ManagerTabs.DetailsAndPreferences]: 'details',
    [ManagerTabs.ActivityLogs]: 'activity-log',
};

interface Props {
    redirectToPage: (url: string) => void;
    validationError?: ExternalValidationError;
    auth: AuthState;
    getManagers: () => void;
    reloadUserData: () => void;
    params: any;
}

interface ManagerState {
    activeTab: ManagerTabs;
    activityLogs: PagedState<any>;
}

export enum ManagerTabType {
    SECURITY = 'security',
    DETAILS = 'details',
    ACTIVITY_LOG = 'activity-log',
}

class ManagersMainPage extends React.Component<Props, ManagerState> {
    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        this.props.reloadUserData();
        this.setState({
            activeTab: ManagerTabs.DetailsAndPreferences,
        });
    }

    renderSidemenu() {
        const subtabs = [ManagerTabs.DetailsAndPreferences];

        const { auth } = this.props;
        subtabs.push(ManagerTabs.PasswordAndSecurity);
        if (hasFeatureAccess(auth, ManagerPermission.userActivityView)) {
            subtabs.push(ManagerTabs.ActivityLogs);
        }
        const activeTab = this.activeMenuTab();

        return (
            <div>
                <SideMenu
                    activeTab={activeTab}
                    onSelectTab={(tab) => {
                        const url = urlPathForTab[tab];
                        this.props.redirectToPage(`/personal/${url}`);
                    }}
                    tabs={subtabs}
                />
            </div>
        );
    }

    activeMenuTab(): ManagerTabs {
        const tab = this.props.params.personalTab;

        const activeTab = Object.entries(urlPathForTab).find(([, v]) => v === tab);

        if (!activeTab) return;

        return activeTab[0] as ManagerTabs;
    }

    renderBreadcrumbs() {
        return (
            <div className="row col-sm-12">
                <nav className="wfp-breadcrumbs col-sm-6">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">{`Personal`}</span>
                        </li>
                    </ol>
                </nav>
            </div>
        );
    }

    render() {
        return (
            <main className={'row'}>
                {this.renderBreadcrumbs()}
                <div className="col-sm-3">{this.renderSidemenu()}</div>
                <div className="col-sm-9" style={{ marginBottom: 150 }}>
                    <Outlet />
                </div>
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        auth: state.auth,
        validationError: state.managers.validationError,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        redirectToPage: (url) => dispatch(routerHelperActions.makeRedirect(url)),
        getManagers: bindActionCreators(ActionCreators.getManagers, dispatch),
        reloadUserData: bindActionCreators(authActionCreators.reloadUserData, dispatch),
    };
}

const withHooks = (Component: any) => {
    return (props: any) => {
        const params = useParams();

        if (!Boolean(params.personalTab)) {
            return <Navigate to={`/personal/${ManagerTabType.DETAILS}`} />;
        }

        return <Component {...props} params={params} />;
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ManagersMainPage)) as any;
