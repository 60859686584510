import React from 'react';
import { displayTime } from '../utils/utils';
import {
    ActionCreators,
    AsyncTask,
    AsyncTaskRawStatus,
    AsyncTaskStatus,
    AsyncTasksTypes,
    finishedTasksStatuses,
    isBlockchainTransactionExecutor,
    isFinancialProcessor,
    isParkingSes,
    isParkingSesAlignment,
    isPendingStart,
    isValidator,
} from '../utils/asyncTasks';
import Manager from '../managers/manager';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { scaleFloatToInteger } from '../utils/customMath';
import _ from 'lodash';
import moment from 'moment';
import { AsyncTasksAccordion } from '../asyncTasks/accordion/AsyncTasksAccordion';
import styled from 'styled-components';
import { ChevronDown } from '../icons/ChevronDown.svg';
import { ChevronUp } from '../icons/ChevronUp.svg';
import { warningAction } from '../utils/notifications';
import classNames from 'classnames';
import State from '@wfp-root-app/store/state';
import { NavigateHook, useNavigate } from '@wfp-common/hooks/useNavigate';

interface Props {
    cancelTask: (type: string, id: string, isSubtask: boolean) => void;
    manager: Manager;
    data: Array<AsyncTask>;
    canAuthorizeTask?: boolean;
    canCancelSubtask?: boolean;
    authorizedManagerId: string;
    authorize: (type: string, id: string) => Promise<void>;
    canDownload: boolean;
    downloadFile: (type: string, id: string) => string;
    showWarning: (warning: string) => void;
    navigate: NavigateHook;
}

export const DownloadButton = styled.button`
    margin-bottom: 5px;
    font-size: 0.9rem;
    min-width: 70%;
`;

export const StyledTh = styled.th<{ width: string }>`
    width: ${(props) => props.width};
`;

class AsyncTasksListView extends React.Component<Props, any> {
    constructor(props) {
        super(props);
    }

    getNameTagPostfix(asyncTask: AsyncTask, index = 1): string {
        let nameTag = `Subimport #${index}`;

        if (isValidator(asyncTask)) {
            nameTag = 'Integrity validation';
        } else if (isBlockchainTransactionExecutor(asyncTask)) {
            nameTag = 'Blockchain transactions';
        }

        return nameTag;
    }

    renderDownloadButton = (row: AsyncTask, child?: boolean) => {
        if (!this.props.canDownload) return <td>-</td>;

        const isImportEntitlementsPage = window.location.pathname.includes('entitlements/import');
        if (isImportEntitlementsPage) {
            const transactionResult = row.children.find(
                (child) =>
                    child.type === AsyncTasksTypes.UPLOAD_REPORT &&
                    (child.fileName?.includes('transaction_result') ||
                        child.fileName?.includes('removeAssistance') ||
                        child.fileName?.includes('removeDeduplicatedEntitlements'))
            );

            const validationResult = row.children.find(
                (child) => child.type === AsyncTasksTypes.UPLOAD_REPORT && child.fileName?.includes('validation_result')
            );

            const deduplicationResult = row.children.find(
                (child) =>
                    child.type === AsyncTasksTypes.UPLOAD_REPORT && child.fileName?.includes('deduplication_result')
            );

            const notFinishedChildren = row.children.filter((child) => !child.finishedAt);

            return (
                <td>
                    {!child && notFinishedChildren.length === 0 && (
                        <>
                            {deduplicationResult && (
                                <DownloadButton
                                    className="wfp-btn"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        this.props.downloadFile(deduplicationResult.type, deduplicationResult.id);
                                    }}
                                    title={'This file contains all deduplications done within uploaded file.'}
                                >
                                    UAOP
                                </DownloadButton>
                            )}
                            {transactionResult && (
                                <DownloadButton
                                    className="wfp-btn"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        this.props.downloadFile(transactionResult.type, transactionResult.id);
                                    }}
                                    title={
                                        'This file contains status of executed transactions per row from imported file.'
                                    }
                                >
                                    Transaction
                                </DownloadButton>
                            )}
                            {validationResult && (
                                <DownloadButton
                                    className="wfp-btn"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        this.props.downloadFile(validationResult.type, validationResult.id);
                                    }}
                                    title={'This file contains results of data validation.'}
                                >
                                    Validation
                                </DownloadButton>
                            )}
                        </>
                    )}
                </td>
            );
        }

        return (
            <td>
                {!child && row.fileName && (
                    <a
                        onClick={(event) => {
                            event.stopPropagation();
                            this.props.downloadFile(row.type, row.id);
                        }}
                    >
                        Download
                    </a>
                )}
            </td>
        );
    };

    getRowStatus = (manualParkedPO: boolean, row: AsyncTask) => {
        if (isFinancialProcessor(row) && row.status === AsyncTaskStatus.failed && manualParkedPO) {
            return AsyncTaskStatus.overridden;
        } else {
            const isRowWithRetries = row.retriesCount > 1;
            let rowStatus = isRowWithRetries
                ? `${row.status === AsyncTaskStatus.created ? 'To be retried' : row.status} (${
                      row.retriesCount
                  } attempt)`
                : row.status;

            rowStatus +=
                row.progress &&
                (row.type !== AsyncTasksTypes.IMPORT_BENEFICIARIES || row.children.length === 0) &&
                row.rawStatus === AsyncTaskRawStatus.inProgress
                    ? ` (${scaleFloatToInteger(parseFloat(row.progress), 2)}%)`
                    : '';

            return rowStatus;
        }
    };

    renderRow(
        row: AsyncTask,
        child?: boolean,
        index?: number,
        toggleAction?: { isHidden: boolean; toggle: () => void }
    ) {
        if (row) {
            let manualParkedPO = false;
            if (row.parentId) {
                const sapPOToAmount = this.props.data.find((task) => task.id === row.parentId).sapPoToAmount;
                manualParkedPO = sapPOToAmount ? !!sapPOToAmount.authorizedByManagerId : false;
            }

            const rowStatus = this.getRowStatus(manualParkedPO, row);

            return (
                <tr
                    className={child ? 'child' : 'parent'}
                    data-parent={row.parentId}
                    key={row.id}
                    onClick={this._onRowChosen.bind(this, row)}
                >
                    <td>{this.renderNameCell(row, child, index)}</td>
                    <td>{displayTime(row.createdAt)}</td>
                    <td>{displayTime(row.startedAt)}</td>
                    <td>{displayTime(row.finishedAt)}</td>
                    <td>{rowStatus}</td>

                    {this.renderDownloadButton(row, child)}
                    {toggleAction?.toggle ? (
                        <td
                            onClick={(event) => {
                                event.stopPropagation();
                                toggleAction.toggle();
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <a style={{ textDecoration: 'none', width: '100%', height: '100%', borderBottom: 'none' }}>
                                {toggleAction.isHidden ? <ChevronDown /> : <ChevronUp />}
                            </a>
                        </td>
                    ) : (
                        <td />
                    )}
                </tr>
            );
        }
    }

    sortAsyncTaskChild(childrenExcludingUploadReports: AsyncTask[]) {
        const validator = childrenExcludingUploadReports.find((task) => isValidator(task));
        const financials = childrenExcludingUploadReports.filter((task) => isFinancialProcessor(task));
        const financialSummarise = financials[0];
        if (financialSummarise) {
            financialSummarise.retriesCount = financials.length;
        }
        const sesParkings = childrenExcludingUploadReports.filter((task) => isParkingSes(task));
        const sesParkingSummarise = sesParkings[0];
        if (sesParkingSummarise) {
            sesParkingSummarise.retriesCount = sesParkings.length;
        }
        const sesAlignmentParkings = childrenExcludingUploadReports.filter((task) => isParkingSesAlignment(task));
        const sesAlignmentParkingSummarise = sesAlignmentParkings[0];
        if (sesAlignmentParkingSummarise) {
            sesAlignmentParkingSummarise.retriesCount = sesAlignmentParkings.length;
        }
        const allOtherTasks = childrenExcludingUploadReports.filter(
            (task) =>
                !isFinancialProcessor(task) && !isValidator(task) && !isParkingSes(task) && !isParkingSesAlignment(task)
        );
        const sortedAllOtherChildren = allOtherTasks.sort((a, b) => {
            if (a.scheduledFor && b.scheduledFor) {
                return new Date(a.scheduledFor).getTime() - new Date(b.scheduledFor).getTime();
            }
            if (a?.additionalInfo?.validFrom && b?.additionalInfo?.validFrom) {
                return (
                    moment(a.additionalInfo.validFrom, 'YYYYMMDD').toDate().getTime() -
                    moment(b.additionalInfo.validFrom, 'YYYYMMDD').toDate().getTime()
                );
            }
            return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        });
        const tasksToDisplay = [validator, financialSummarise, sesParkingSummarise, ...sortedAllOtherChildren];
        const unfinishedChildrenCount = tasksToDisplay.filter(
            (task) => task && !finishedTasksStatuses.includes(task.status)
        ).length;
        if (
            unfinishedChildrenCount === 0 &&
            sesAlignmentParkingSummarise &&
            (sortedAllOtherChildren.length > 0 || sesAlignmentParkingSummarise.status !== AsyncTaskStatus.created)
        ) {
            tasksToDisplay.push(sesAlignmentParkingSummarise);
        }
        return _.compact(tasksToDisplay);
    }

    render() {
        const rows = [];

        for (const row of this.props.data) {
            const temporary = [];
            temporary.push(row);
            if (row.children && row.children.length > 0) {
                const childrenExcludingUploadReports = row.children.filter(
                    (children) =>
                        ![AsyncTasksTypes.UPLOAD_REPORT, AsyncTasksTypes.SCOPE_UPLOAD_REPORT].includes(children.type)
                );
                const sortedChildren = this.sortAsyncTaskChild(childrenExcludingUploadReports);
                const processingChildTask = sortedChildren.find(
                    (children) => children.startedAt && !children.finishedAt
                );
                const notStartedChildTask = sortedChildren.find((children) => isPendingStart(children));
                if (
                    (isPendingStart(row) || notStartedChildTask) &&
                    !processingChildTask &&
                    this.props?.manager?.agency !== 'ALL'
                ) {
                    this.props.showWarning(
                        'Your request has been received and scheduled. There are other tasks ahead of your request. Your request will execute once the other tasks are complete. Please be patient. You will receive an email notification once your file has finished processing.'
                    );
                }
                let index = 1;
                for (const childTask of sortedChildren) {
                    temporary.push(childTask);
                    if (
                        !(
                            isValidator(childTask) ||
                            isParkingSes(childTask) ||
                            isFinancialProcessor(childTask) ||
                            isParkingSesAlignment(childTask)
                        )
                    ) {
                        index++;
                    }
                }
                rows.push(temporary);
            }
        }

        return (
            <main>
                <table className=" mt4 table-hover async-tasks-table" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>Task</th>
                            <StyledTh width="20%">Created</StyledTh>
                            <StyledTh width="20%">Started</StyledTh>
                            <StyledTh width="20%">Finished</StyledTh>
                            <th>Status</th>
                            <StyledTh width="18%">Results</StyledTh>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row) => {
                            return (
                                <AsyncTasksAccordion
                                    key={row[0].id}
                                    mainTask={row[0]}
                                    otherTasks={row.slice(1)}
                                    renderRow={this.renderRow.bind(this)}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </main>
        );
    }

    camelCaseToDash(myStr) {
        return _.kebabCase(myStr);
    }

    getRedirectUrlForRow(row: AsyncTask): string {
        let url = `/tasks/${row.type}/${row.id}`;
        if (isFinancialProcessor(row) || isParkingSes(row) || isParkingSesAlignment(row)) {
            const dashedTaskType = this.camelCaseToDash(this.getNameTagPostfix(row));
            url = `/subtasks/${row.parentId}/${dashedTaskType}`;
        }

        return url;
    }

    _onRowChosen(row: AsyncTask) {
        const redirectUrl = this.getRedirectUrlForRow(row);
        this.props.navigate(redirectUrl);
    }

    private renderNameCell(asyncTask: AsyncTask, isChild: boolean, index: number) {
        if (isChild) {
            return <>{this.getNameTagPostfix(asyncTask, index)}</>;
        } else {
            const fileName = asyncTask.fileName;
            const interventionName = asyncTask.interventionName;

            return (
                <div>
                    <div className="wfp-form--group ">{interventionName}</div>
                    <div className="wfp-form--group ">{fileName}</div>
                </div>
            );
        }
    }
}

function mapStateToProps(state: State) {
    return {
        manager: state.auth.manager,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        cancelTask: bindActionCreators(ActionCreators.cancelTask, dispatch),
        showWarning: (warning) => dispatch(warningAction(warning)),
    };
}

const withHooks = (Component: any) => {
    return (props: any) => {
        const navigate = useNavigate();

        return <Component navigate={navigate} {...props} />;
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AsyncTasksListView));
