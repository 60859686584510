import { TokenContractWithBalance } from './model';

export class SortActiveTokens {
    select(clusters: Array<TokenContractWithBalance>): Array<TokenContractWithBalance> {
        const now = new Date();
        return clusters
            .filter(
                (c: TokenContractWithBalance) =>
                    new Date(c.validUntil).getTime() > now.getTime() &&
                    new Date(c.validFrom).getTime() < now.getTime() &&
                    Number(c.balance) > 0
            )
            .sort(
                (a: TokenContractWithBalance, b: TokenContractWithBalance) =>
                    new Date(a.validUntil).getTime() - new Date(b.validUntil).getTime()
            );
    }
}

export class SortExpiredTokens {
    select(clusters: Array<TokenContractWithBalance>): Array<TokenContractWithBalance> {
        const now = new Date();
        return clusters
            .filter(
                (c: TokenContractWithBalance) =>
                    new Date(c.validUntil).getTime() < now.getTime() && Number(c.balance) > 0
            )
            .sort(
                (a: TokenContractWithBalance, b: TokenContractWithBalance) =>
                    new Date(a.validUntil).getTime() - new Date(b.validUntil).getTime()
            );
    }
}

export class SortFutureTokens {
    select(clusters: Array<TokenContractWithBalance>): Array<TokenContractWithBalance> {
        const now = new Date();
        return clusters
            .filter(
                (c: TokenContractWithBalance) =>
                    new Date(c.validFrom).getTime() > now.getTime() && Number(c.balance) > 0
            )
            .sort(
                (a: TokenContractWithBalance, b: TokenContractWithBalance) =>
                    new Date(a.validUntil).getTime() - new Date(b.validUntil).getTime()
            );
    }
}
