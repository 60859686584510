import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import State from '@wfp-root-app/store/state';

import { TokenContractWithBalance, TokensInfo } from './model';
import { SortActiveTokens, SortExpiredTokens, SortFutureTokens } from './TokenClusterUtils';
import { getClustersInfo } from '../apiClient';
import { PredefinedCurrency } from '../app/appConfig';
import { RowMenu } from '../utils/RowMenu';
import { currencyFormatter, displayMomentAsDay } from '../utils/utils';
import { InformationDialog } from '../utils/Dialogs';

interface Props {
    beneficiaryId: string;
    currency: PredefinedCurrency;
    timezone: string;
}

const BeneficiariesTokens: React.FC<Props> = ({ beneficiaryId, currency, timezone }) => {
    const [selectedTab, setSelectedTab] = useState<string>();
    const [clustersInfo, setClustersInfo] = useState<Array<TokensInfo>>();
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [tokenHashForDialog, setTokenHashForDialog] = useState<string>();

    const categoryNames = useMemo(() => {
        if (clustersInfo) {
            return clustersInfo.map((cluster: TokensInfo) => cluster.category);
        } else {
            return [null];
        }
    }, [clustersInfo]);

    const activeTokens = useMemo(() => {
        if (clustersInfo) {
            return clustersInfo.map((cluster: TokensInfo) => new SortActiveTokens().select(cluster.tokens));
        } else {
            return [null];
        }
    }, [clustersInfo]);

    const expiredTokens = useMemo(() => {
        if (clustersInfo) {
            return clustersInfo.map((cluster: TokensInfo) => new SortExpiredTokens().select(cluster.tokens));
        } else {
            return [null];
        }
    }, [clustersInfo]);

    const futureTokens = useMemo(() => {
        if (clustersInfo) {
            return clustersInfo.map((cluster: TokensInfo) => new SortFutureTokens().select(cluster.tokens));
        } else {
            return [null];
        }
    }, [clustersInfo]);

    const activeIdx = useMemo(
        () => categoryNames.findIndex((el: string) => el === selectedTab),
        [categoryNames, selectedTab]
    );

    const dataForActiveTokenTable = useMemo(() => activeTokens[activeIdx], [activeTokens, activeIdx]);

    const dataForExpiredTokenTable = useMemo(() => expiredTokens[activeIdx], [expiredTokens, activeIdx]);

    const dataForFutureTokenTable = useMemo(() => futureTokens[activeIdx], [futureTokens, activeIdx]);

    const showActiveTable = useMemo(
        () => !!(activeTokens[activeIdx] && activeTokens[activeIdx].length),
        [activeTokens, activeIdx]
    );

    const showExpiredTable = useMemo(
        () => !!(expiredTokens[activeIdx] && expiredTokens[activeIdx].length),
        [expiredTokens, activeIdx]
    );

    const showFutureTable = useMemo(
        () => !!(futureTokens[activeIdx] && futureTokens[activeIdx].length),
        [futureTokens, activeIdx]
    );

    const onClose = () => {
        setShowDialog(false);
        setTokenHashForDialog(null);
    };

    const showAdditionalMessage = !showActiveTable && !showExpiredTable;

    useEffect(() => {
        const fetchData = async () => await getClustersInfo(beneficiaryId);
        fetchData().then(
            (res) => setClustersInfo(res),
            () => setClustersInfo([])
        );
    }, []);

    useEffect(() => {
        setSelectedTab(categoryNames[0]);
    }, [clustersInfo]);

    return (
        <div className="mt-3">
            {showDialog && <InformationDialog message={tokenHashForDialog} onClose={onClose} title="Copied" />}
            {clustersInfo && (
                <RowMenu
                    activeTab={selectedTab}
                    onSelectTab={(tab: string) => setSelectedTab(tab)}
                    tabs={categoryNames}
                    wrapElements={true}
                />
            )}
            {showFutureTable && (
                <>
                    <div className="mt2">Future Tokens</div>
                    <table className="wfp-table--striped mt2 table-hover">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Balance</th>
                                <th>Valid from</th>
                                <th>Valid until</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataForFutureTokenTable.map((el: TokenContractWithBalance, idx) => (
                                <tr key={el.address}>
                                    <td>{idx + 1}</td>
                                    <td>{currencyFormatter(currency).format(Number(el.balance))}</td>
                                    <td>{displayMomentAsDay(moment(el.validFrom).tz(timezone))}</td>
                                    <td>{displayMomentAsDay(moment(el.validUntil).tz(timezone))}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
            {showActiveTable && (
                <>
                    <div className="mt2">Active Tokens</div>
                    <table className="wfp-table--striped mt2 table-hover">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Balance</th>
                                <th>Valid from</th>
                                <th>Valid until</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataForActiveTokenTable.map((el: TokenContractWithBalance, idx) => (
                                <tr key={el.address}>
                                    <td>{idx + 1}</td>
                                    <td>{currencyFormatter(currency).format(Number(el.balance))}</td>
                                    <td>{displayMomentAsDay(moment(el.validFrom).tz(timezone))}</td>
                                    <td>{displayMomentAsDay(moment(el.validUntil).tz(timezone))}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
            {showExpiredTable && (
                <>
                    <div className="mt4">Expired Tokens</div>
                    <table className="wfp-table--striped mt2 table-hover">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Balance</th>
                                <th>Valid from</th>
                                <th>Valid until</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataForExpiredTokenTable.map((el: TokenContractWithBalance, idx) => (
                                <tr key={el.address}>
                                    <td>{idx + 1}</td>
                                    <td>{currencyFormatter(currency).format(Number(el.balance))}</td>
                                    <td>{displayMomentAsDay(moment(el.validFrom).tz(timezone))}</td>
                                    <td>{displayMomentAsDay(moment(el.validUntil).tz(timezone))}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
            {showAdditionalMessage && (
                <div className="mt2">
                    <small>
                        <i>No tokens to show</i>
                    </small>
                </div>
            )}
        </div>
    );
};

function mapStateToProps(state: State) {
    return {
        beneficiaryId: state.editBeneficiary.beneficiary.id,
        currency: state.appConfig.entitlementCurrencyConfig,
        timezone: state.appConfig.timeZone,
    };
}

export default connect(mapStateToProps, null, null)(BeneficiariesTokens);
