import { ActionCreators } from '../login/auth';
import { errorAction } from './notifications';
import { fieldValidationAction } from './inputs';
import { Middleware } from 'redux';

function isErrorHandledByMiddleware(err) {
    const handledStatuses = [401, 403, 409];
    return handledStatuses.includes(err.status);
}

export function throwErrorIfNotHandled(unhandledErrorMessage: string) {
    return function (err) {
        if (isErrorHandledByMiddleware(err)) {
            throw err;
        } else {
            throw new Error(unhandledErrorMessage);
        }
    };
}

function errorHandler(err, dispatch) {
    if (err.status === 403) {
        dispatch(ActionCreators.logout());
        dispatch(
            errorAction(
                'Your current session does not include a permission for this action, please relog and try again.'
            )
        );
        return true;
    }

    if (err.status === 401) {
        dispatch(ActionCreators.logout());
        dispatch(errorAction('Your session has expired.'));
        return true;
    }

    if (err.status === 409) {
        err.json().then(({ errors }) => errors.forEach((error) => dispatch(fieldValidationAction(error))));
        return true;
    }

    const timeout = setTimeout(() => dispatch(errorAction(err), 50));
    err.preventDefault = () => clearTimeout(timeout);
    return false;
}
function errorMiddleware(store) {
    return function (next) {
        return function (action) {
            return Promise.resolve(next(action)).catch((err) => {
                if (!errorHandler(err, store.dispatch)) {
                    throw err;
                }
            });
        };
    };
}
export default errorMiddleware as Middleware;
