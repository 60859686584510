import { useSearchParams as useRouterSearchParams } from 'react-router-dom';

interface SearchParamsHook {
    redirectUrl: string;
}

export const useSearchParams = (): SearchParamsHook => {
    const [searchParams] = useRouterSearchParams();

    return {
        redirectUrl: searchParams.get('redirectUrl'),
    };
};
