import React from 'react';
import { FC } from 'react';
import { ChangePasswordChecker } from './ChangePasswordChecker';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '@wfp-common/auth/AuthContext';

interface ProtectedRouteProps {
    requiredPermissions?: any[];
    additionalPermission?: boolean;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = (props) => {
    const { isUserLogged, appHomePath, isPathOnProtectedLocation } = useAuthContext();

    if (isUserLogged && !isPathOnProtectedLocation) {
        return <Navigate to={appHomePath} />;
    }

    return <ChangePasswordChecker>{props.children}</ChangePasswordChecker>;
};

ProtectedRoute.defaultProps = {
    requiredPermissions: [],
    additionalPermission: false,
};
