import { DateFilterRange, DateFilterLimit } from './DateFilter';

export type FilterType = 'TEXT' | 'SELECT' | 'DATE' | 'HIDDEN' | 'MULTISELECT' | 'NUMBER';
export class FilterTypes {
    static text: FilterType = 'TEXT';
    static select: FilterType = 'SELECT';
    static multiselect: FilterType = 'MULTISELECT';
    static date: FilterType = 'DATE';
    static static: FilterType = 'HIDDEN';
    static number: FilterType = 'NUMBER';
}

export interface Filter {
    label?: string;
    name: string;
    type: FilterType;
    isSelected: boolean;
    value: any;
    dateRange?: any;
    dateLimit?: DateFilterLimit;
    onlyOneOption?: any;
}

export class StaticFilter implements Filter {
    type = FilterTypes.static;
    isSelected = true;

    constructor(
        public name: string,
        public value: string
    ) {}
}

class FilterImpl<T> implements Filter {
    isSelected: boolean;

    constructor(
        public type: FilterType,
        public name: string,
        public value: T,
        public label: string = null,
        isSelected = false
    ) {
        this.isSelected = isSelected || false;
    }
}

export class TextFilter extends FilterImpl<string> {
    constructor(
        label: string,
        name: string,
        value: string,
        public maxLength: number = 255
    ) {
        super(FilterTypes.text, name, value, label);
    }
}

export class NumberFilter extends FilterImpl<number> {
    constructor(label: string, name: string, value: number) {
        super(FilterTypes.number, name, value, label);
    }
}

export class SelectOption {
    constructor(
        public label: string,
        public value: any
    ) {}
}

export class SelectFilter extends FilterImpl<any> {
    constructor(
        label: string,
        name: string,
        value: any,
        public options: SelectOption[]
    ) {
        super(FilterTypes.select, name, value, label);
    }
}

export class MultiSelectFilter extends FilterImpl<any> {
    constructor(
        label: string,
        name: string,
        value: any,
        public options?: SelectOption[],
        public onlyOneOption?: any,
        isSelected = false
    ) {
        super(FilterTypes.multiselect, name, value, label, isSelected);
        this.onlyOneOption = onlyOneOption;
    }
}

export class DateSelectFilter extends FilterImpl<string> {
    constructor(
        public label: string,
        public name: string,
        public value: string,
        public options: SelectOption[],
        public dateRange?: DateFilterRange,
        public dateLimit?: DateFilterLimit,
        public isSelected = false
    ) {
        super(FilterTypes.date, name, value, label, isSelected);
        this.dateRange = dateRange;
        this.dateLimit = dateLimit;
    }
}
