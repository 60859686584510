import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators, AssistanceListType } from './assistance';
import EntitlementsPage from './EntitlementsPage';
import DeduplicatedEntitlementsPage from './DeduplicatedEntitlementsPage';
import State from '@wfp-root-app/store/state';
import { RowMenu } from '../utils/RowMenu';
import TaxIdMappingExportPage from './TaxIdMappingExportPage';
import { DeduplicationStatistics } from '../statistics/deduplicationStatistics/DeduplicationStatistics';
import { selectAuth } from '@wfp-root-app/store/store';

const AssistanceContainer: React.FC = () => {
    const activeTab = useSelector((state: State) => state.assistance.activeTab);
    const manager = useSelector((state: State) => state.auth.manager);
    const dispatch = useDispatch();
    const auth = useSelector(selectAuth);

    const onSelectTab = (tab: AssistanceListType) => {
        dispatch(ActionCreators.changeTab(tab));
    };

    const renderAssistanceList = () => {
        switch (activeTab) {
            case AssistanceListType.AssistanceProvided:
                return <EntitlementsPage />;
            case AssistanceListType.AssistanceDeduplicated:
                return <DeduplicatedEntitlementsPage />;
            case AssistanceListType.CaseloadOverlap:
                return <DeduplicationStatistics auth={auth} />;
        }

        if (activeTab === AssistanceListType.TaxIdMapping && manager?.agency !== 'ALL') {
            return <TaxIdMappingExportPage />;
        }
    };
    const renderTabs = () => {
        if (manager?.agency !== 'ALL') {
            return [
                AssistanceListType.AssistanceProvided,
                AssistanceListType.AssistanceDeduplicated,
                AssistanceListType.TaxIdMapping,
                AssistanceListType.CaseloadOverlap,
            ];
        }

        return [
            AssistanceListType.AssistanceProvided,
            AssistanceListType.AssistanceDeduplicated,
            AssistanceListType.CaseloadOverlap,
        ];
    };

    return (
        <>
            <RowMenu activeTab={activeTab} onSelectTab={onSelectTab} tabs={renderTabs()} />
            {renderAssistanceList()}
        </>
    );
};

AssistanceContainer.displayName = 'AssistanceContainer';

export { AssistanceContainer };
