import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '@wfp-common/auth/AuthContext';
import { useMatchPath } from '@wfp-common/hooks/useMatchPath';

export const ChangePasswordChecker: FC = (props) => {
    const authCtx = useAuthContext();
    const matchChangePassword = useMatchPath('/change-password');

    if (authCtx.isUserLogged && authCtx?.loggedUser?.askForNewPassword && !matchChangePassword) {
        return <Navigate to={'/change-password'} />;
    }

    return <>{props.children}</>;
};
