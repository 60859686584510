import React from 'react';
import { FC } from 'react';
import { StepperProvider } from './StepperContext';
import { StepperDialog } from './StepperDialog';

interface StepperRootProps {
    onClose?: () => void;
}

const StepperRoot: FC<StepperRootProps> = (props: StepperRootProps) => {
    return (
        <StepperProvider>
            <StepperDialog onClose={props.onClose} />
        </StepperProvider>
    );
};

StepperRoot.displayName = 'StepperRoot';

export { StepperRoot };
