import React from 'react';
import { Modal } from 'react-bootstrap';
import { Form, ValidationContext } from '../utils/inputs';
import ResetPasswordForm from './ResetPasswordForm';

export type ChangePasswordModalProps = {
    onClose: () => void;
    onConfirm: (oldPassword: string, newPassword: string) => void;
};

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ onConfirm, onClose }) => {
    const validationContext = new ValidationContext();

    return (
        <div>
            <div className="fade modal-backdrop in" />
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>Change password</Modal.Title>
                </Modal.Header>
                <Form
                    className={'mv1 wfp-form'}
                    context={validationContext}
                    onSubmit={(event) => {
                        event.preventDefault();
                    }}
                >
                    <ResetPasswordForm onClose={onClose} onSubmit={onConfirm} ownPassword={true} />
                </Form>
            </Modal.Dialog>
        </div>
    );
};

export default ChangePasswordModal;
