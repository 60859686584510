import React from 'react';

export const ChevronUp = () => {
    return (
        <svg height="14px" version="1.1" viewBox="0 0 24 14" width="24px">
            <title>chevron--up</title>
            <desc>Created with Sketch.</desc>
            <defs/>
            <g fill="none" fillRule="evenodd" id="chevron--up" stroke="none" strokeWidth="1">
                <polygon
                    fill="#000000"
                    fillRule="nonzero"
                    id="chevron"
                    points="5.99408113 1.44972267 0.726461685 6.99971556 -5.79924997e-13 6.31479463 5.99350948 2.93098879e-14 11.996192 6.31451019 11.2702706 7"
                />
            </g>
        </svg>
    );
};
