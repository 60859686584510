import React from 'react';
import { useDispatch } from 'react-redux';

import { ActionCreators } from './partnersActions';
import { defaultPartnerSortOptions, BeneficiarySupportOrganizationGeneralInfo } from './partners';
import PermissionsFiles from '../permissions-files/PermissionsFiles';
import { ManagerPermission } from '../permission-profiles/permission';

interface Props {
    partner: BeneficiarySupportOrganizationGeneralInfo;
}

const PartnerPermissionsFiles: React.FC<Props> = ({ partner }) => {
    const dispatch = useDispatch();

    const uploadPermissionsFile = (userId: string, file: File) => {
        return dispatch(ActionCreators.uploadPermissionsFile(userId, file));
    };

    const loadPartners = () => {
        dispatch(ActionCreators.loadPartners(defaultPartnerSortOptions));
    };

    return (
        <PermissionsFiles
            loadUsers={loadPartners}
            permissionsFiles={partner.permissionFiles}
            requiredPermission={[ManagerPermission.partnerPost, ManagerPermission.partnerPark]}
            uploadPermissionsFile={uploadPermissionsFile}
            userId={partner.id}
        />
    );
};

PartnerPermissionsFiles.displayName = 'PartnerPermissionsFiles';

export { PartnerPermissionsFiles };
