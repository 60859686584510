import { useNavigate as useRouterNavigate } from 'react-router-dom';

export type NavigateHook = (url: string, state?: any) => void;

export const useNavigate = (): NavigateHook => {
    const routerNavigate = useRouterNavigate();
    const navigate = (url: string, state?: any) => {
        routerNavigate(url, { state });
    };

    return navigate;
};
