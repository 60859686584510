import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ErrorPage from '../../app/ErrorPage';
import BeneficiaryActivitiesPage from '../BeneficiaryActivitiesPage';
import EntitlementsView from '../EntitlementsView';
import DeduplicatedEntitlementsView from '../DeduplicatedEntitlementsView';
import BeneficiariesTokens from '../BeneficiariesTokens';
import { useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';

enum EditBeneficiaryTab {
    MAIN = 'main',
    ENTITLEMENTS = 'entitlements',
    DEDUPLICATED_ENTITLEMENTS = 'deduplicated-entitlements',
    TOKENS = 'tokens',
}

export const EditBeneficiaryTabRenderer = () => {
    const params = useParams();
    const location = useLocation();

    const { appConfig } = useSelector((state: State) => state);

    switch (params.beneficiaryTab as EditBeneficiaryTab) {
        case EditBeneficiaryTab.MAIN:
            return <BeneficiaryActivitiesPage location={{ state: location.state }} />;
        case EditBeneficiaryTab.ENTITLEMENTS:
            return <EntitlementsView location={{ state: location.state }} />;
        case EditBeneficiaryTab.DEDUPLICATED_ENTITLEMENTS:
            return <DeduplicatedEntitlementsView location={{ state: location.state }} />;
        case EditBeneficiaryTab.TOKENS:
            return (
                <BeneficiariesTokens
                    beneficiaryId={location.state.beneficiaryId}
                    currency={appConfig.entitlementCurrencyConfig}
                    timezone={appConfig.timeZone}
                />
            );
        default:
            return <ErrorPage />;
    }
};
