import React from 'react';
import { Modal } from 'react-bootstrap';

import { UploadStatuses, UploadStatusWidget } from '../../utils/import';
import { UploadTaskState } from '../beneficiaries';
import { getAsyncTask } from '../../apiClient';
import { AsyncTask, AsyncTasksTypes } from '../../utils/asyncTasks';
import styled from 'styled-components';
import { connect } from 'react-redux';
import State from '@wfp-root-app/store/state';

export interface DryRunDialogProps {
    title: string;
    asyncTask?: AsyncTask;
    taskStateError: string;
    type?: string;
    categories: string[];

    onCancel: () => void;
    onConfirm: (dryRunAsyncTaskId: string, additionalData?: Record<string, any>) => void;
    downloadFile?: (type: string, id: string) => void;
}

export interface DryRunDialogState {
    taskState: UploadTaskState;
    refreshHandle?: number;
    asyncTask?: AsyncTask;
    overlapPeriod: string;
    selectedFlag: UaopFlags;
    selectedCategories: string[];
}

const Paragraph = styled.p`
    font-weight: lighter;
    font-size: 14px;
    font-style: italic;
`;

const CheckBoxContainer = styled.div<{ marginTop?: string }>`
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop || '25px'};
`;

const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1050;
    background-color: rgba(0, 0, 0, 0.4);
`;

export enum UaopFlags {
    IncrementalDeltaAmountYesOption = 'IncrementalDeltaAmountYesOption',
    IncrementalDeltaAmountNoOption = 'IncrementalDeltaAmountNoOption',
    IncrementalShiftPeriod = 'IncrementalShiftPeriod',
    IncrementalAnyAmount = 'IncrementalAnyAmount',
    TotalDeltaAmount = 'TotalDeltaAmount',
    TotalDeltaAmountWithShift = 'TotalDeltaAmountWithShift',
    TotalAnyAmount = 'TotalAnyAmount',
    NoneHistorical = 'NoneHistorical',
    NoneIntendedAssistanceOverlap = 'NoneIntendedAssistanceOverlap',
}

// const resetFlags: Omit<DryRunDialogState, 'asyncTask' | 'refreshHandle' | 'taskState'> = {
//     validateCategoryInPeriod: false,
//     deduplicateAnyEntitlement: false,
//     deduplicateWithStrategy: false,
//     overlapPeriod: 'none',
//     strategyToUse: DeduplicationStrategy.first,
// };

class DryRunDialog extends React.Component<DryRunDialogProps, DryRunDialogState> {
    constructor(props: DryRunDialogProps) {
        super(props);

        this.state = {
            taskState: new UploadTaskState(UploadStatuses.pending),
            asyncTask: null,
            overlapPeriod: 'none',
            selectedFlag: UaopFlags.IncrementalDeltaAmountYesOption,
            selectedCategories: [],
        };
    }

    componentDidMount() {
        this.setState({
            refreshHandle: window.setInterval(this.loadTaskState, 2000),
        });
    }

    componentWillUnmount() {
        if (this.state.refreshHandle) {
            clearInterval(this.state.refreshHandle);
        }
    }

    render() {
        const { title, onCancel, onConfirm, downloadFile, type } = this.props;
        const { taskState } = this.state;
        const isEntitlementsImport = type === AsyncTasksTypes.IMPORT_BENEFICIARIES;

        return (
            <div>
                <ModalBackground />
                <Modal.Dialog dialogClassName="modal-large" style={{ overflow: 'auto', marginTop: '100px' }}>
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UploadStatusWidget
                            downloadFile={downloadFile}
                            importError={taskState.error}
                            importStatus={taskState.status}
                            importTask={taskState.task}
                        />
                        {isEntitlementsImport && this.renderDeduplicationOptions()}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="wfp-btn" onClick={onCancel} style={{ marginRight: 10 }} type="button">
                            Cancel
                        </button>
                        <button
                            className="wfp-btn--primary"
                            disabled={this.shouldRunButtonBeDisabled(taskState)}
                            onClick={() =>
                                onConfirm(taskState.task.id, {
                                    overlapPeriod: this.state.overlapPeriod,
                                    uaopFlag: this.state.selectedFlag,
                                    categories: this.state.selectedCategories,
                                })
                            }
                            type="button"
                        >
                            Run import
                        </button>
                    </Modal.Footer>
                </Modal.Dialog>
            </div>
        );
    }

    shouldRunButtonBeDisabled(taskState: UploadTaskState): boolean {
        if (taskState.status !== UploadStatuses.finished) {
            return true;
        }
        if (!taskState.task) {
            return true;
        } else if (taskState.error) {
            return true;
        } else if (this.state.asyncTask && this.state.asyncTask.errors && this.state.asyncTask.errors.length > 0) {
            return true;
        }
        return false;
    }

    renderDeduplicationOptions = () => {
        const categories = this.props.categories;

        return (
            <>
                <h6
                    style={{
                        marginTop: '32px',
                    }}
                >
                    UAOP Categories (select at least one)
                </h6>

                <CheckBoxContainer marginTop="0px">
                    {categories.map((category) => (
                        <>
                            <label>
                                <input
                                    checked={this.state.selectedCategories.findIndex((c) => c === category) > -1}
                                    onChange={() => {
                                        const categoryIndex = this.state.selectedCategories.findIndex(
                                            (c) => c === category
                                        );

                                        if (categoryIndex === -1) {
                                            this.setState({
                                                selectedCategories: [...this.state.selectedCategories, category],
                                            });
                                        } else {
                                            const newCategories = this.state.selectedCategories.filter(
                                                (sc) => sc !== category
                                            );

                                            this.setState({
                                                selectedCategories: [...newCategories],
                                            });
                                        }
                                    }}
                                    type="checkbox"
                                />{' '}
                                {category}
                            </label>
                        </>
                    ))}
                </CheckBoxContainer>
                <h6
                    style={{
                        marginTop: '32px',
                    }}
                >
                    UAOP Options (select one)
                </h6>
                <CheckBoxContainer marginTop="0px">
                    <label>
                        <input
                            checked={this.state.selectedFlag === UaopFlags.IncrementalDeltaAmountYesOption}
                            name="validate-category"
                            onChange={() => {
                                this.setState({
                                    selectedFlag: UaopFlags.IncrementalDeltaAmountYesOption,
                                });
                            }}
                            type="checkbox"
                        />{' '}
                        Incremental: Delta Amount (Recommended) - Yes option
                    </label>
                    <Paragraph>
                        Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the
                        indicated assistance period in my file, only the difference in amount is loaded.
                    </Paragraph>
                </CheckBoxContainer>
                <CheckBoxContainer>
                    <label>
                        <input
                            checked={this.state.selectedFlag === UaopFlags.IncrementalDeltaAmountNoOption}
                            name="validate-category"
                            onChange={() => {
                                this.setState({
                                    selectedFlag: UaopFlags.IncrementalDeltaAmountNoOption,
                                });
                            }}
                            type="checkbox"
                        />{' '}
                        Incremental: Delta Amount (Recommended) - No option
                    </label>
                    <Paragraph>
                        Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the
                        indicated assistance period in my file, only the difference in amount is loaded.
                    </Paragraph>
                </CheckBoxContainer>
                <CheckBoxContainer>
                    <label>
                        <input
                            checked={this.state.selectedFlag === UaopFlags.IncrementalShiftPeriod}
                            name="validate-category"
                            onChange={() => {
                                this.setState({
                                    selectedFlag: UaopFlags.IncrementalShiftPeriod,
                                });
                            }}
                            type="checkbox"
                        />{' '}
                        Incremental: Shift Period
                    </label>
                    <Paragraph>
                        Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the
                        indicated assistance period in my file, only the difference in amount is loaded.
                    </Paragraph>
                </CheckBoxContainer>
                <CheckBoxContainer>
                    <label>
                        <input
                            checked={this.state.selectedFlag === UaopFlags.IncrementalAnyAmount}
                            name="validate-category"
                            onChange={() => {
                                this.setState({
                                    selectedFlag: UaopFlags.IncrementalAnyAmount,
                                });
                            }}
                            type="checkbox"
                        />{' '}
                        Incremental: Any Amount
                    </label>
                    <label style={{ fontSize: '0.9rem' }}>
                        Only assist accounts that have not been assisted for at least [1,2 or 3] month(s) before the
                        start date(s) listed in the file:{' '}
                        <select
                            id="period"
                            name="period"
                            onChange={(event) => {
                                this.setState({
                                    overlapPeriod: event.target.value,
                                });
                            }}
                            value={this.state.overlapPeriod || 'none'}
                        >
                            <option value="none">Not selected</option>
                            <option value="1">1 month</option>
                            <option value="2">2 months</option>
                            <option value="3">3 months</option>
                        </select>
                    </label>
                    <Paragraph>
                        Select this option if you want to deduplicate the data you are loading against existing
                        assistance already registered on the system <u>if they overlap in time.</u> If such potential
                        duplicates are identified, BB will skip loading the affected Tax IDs, and load the accounts for
                        the unaffected Tax IDs. BB will then provide you with a report to show which Tax IDs it skipped
                        due to deduplication, and the details of the existing registered assistance on the system
                        against which it deduplicated.
                    </Paragraph>
                </CheckBoxContainer>
                <CheckBoxContainer>
                    <label>
                        <input
                            checked={this.state.selectedFlag === UaopFlags.TotalAnyAmount}
                            name="validate-category"
                            onChange={() => {
                                this.setState({
                                    selectedFlag: UaopFlags.TotalAnyAmount,
                                });
                            }}
                            type="checkbox"
                        />{' '}
                        Total: Any Amount
                    </label>
                    <Paragraph>
                        Select this option if you want to deduplicate the data you are loading against any existing
                        assistance already registered on the system <u>even if they do not overlap in time.</u> If such
                        potential duplicates are identified, BB will skip loading the affected Tax IDs, and load the
                        accounts for the unaffected Tax IDs. BB will then provide you with a report to show which Tax
                        IDs it skipped due to deduplication, and the details of the existing registered assistance on
                        the system against which it deduplicated.
                    </Paragraph>
                </CheckBoxContainer>
                <CheckBoxContainer>
                    <label>
                        <input
                            checked={this.state.selectedFlag === UaopFlags.TotalDeltaAmount}
                            name="validate-category"
                            onChange={() => {
                                this.setState({
                                    selectedFlag: UaopFlags.TotalDeltaAmount,
                                });
                            }}
                            type="checkbox"
                        />{' '}
                        Total: Delta Amount
                    </label>
                    <Paragraph>
                        Select this option if you want to deduplicate the data you are loading against any existing
                        assistance already registered on the system <u>even if they do not overlap in time.</u> If such
                        potential duplicates are identified, BB will skip loading the affected Tax IDs, and load the
                        accounts for the unaffected Tax IDs. BB will then provide you with a report to show which Tax
                        IDs it skipped due to deduplication, and the details of the existing registered assistance on
                        the system against which it deduplicated.
                    </Paragraph>
                </CheckBoxContainer>
                <CheckBoxContainer>
                    <label>
                        <input
                            checked={this.state.selectedFlag === UaopFlags.NoneHistorical}
                            name="validate-category"
                            onChange={() => {
                                this.setState({
                                    selectedFlag: UaopFlags.NoneHistorical,
                                });
                            }}
                            type="checkbox"
                        />{' '}
                        None (Historical)
                    </label>
                    <Paragraph>
                        Select this option if you are loading historical data about assistance that has already been
                        distributed or if for whatever reason you do not want to deduplicate the data you are loading
                        against other assistance already registered on the system. When you load your data with this
                        flag selected, future assistance loaded to the system can still be deduplicated against it.
                    </Paragraph>
                </CheckBoxContainer>
                <CheckBoxContainer>
                    <label>
                        <input
                            checked={this.state.selectedFlag === UaopFlags.NoneIntendedAssistanceOverlap}
                            name="validate-category"
                            onChange={() => {
                                this.setState({
                                    selectedFlag: UaopFlags.NoneIntendedAssistanceOverlap,
                                });
                            }}
                            type="checkbox"
                        />{' '}
                        None (Intended Assistance Overlap)
                    </label>
                    <Paragraph>
                        Note: for this UAOP option, the assistance period must be the same for all the rows in your
                        file. <br /> Do not prevent unintended assistance overlap prevention. I’m loading data about
                        assistance that has not yet been made available to households so that it is visible on the
                        system and UAOP can be applied to future (not yet distributed) assistance against it. However, I
                        want my assistance to be added in full to any other assistance that may already exist on the
                        system. <br />
                        Note: for this UAOP option, the assistance period must be the same for all the rows in your
                        file.
                    </Paragraph>
                </CheckBoxContainer>
            </>
        );
    };

    private loadTaskState = async () => {
        if (this.props.taskStateError) {
            this.setState({
                taskState: new UploadTaskState(UploadStatuses.finished, null, this.props.taskStateError),
            });
        } else if (this.props.asyncTask) {
            const response = await getAsyncTask(this.props.asyncTask.type, this.props.asyncTask.id);
            const taskStatus =
                response.task.finishedAt && response.task.children && response.task.children.length > 0
                    ? UploadStatuses.finished
                    : UploadStatuses.pending;

            const taskState = new UploadTaskState(taskStatus, response.task);
            this.setState({
                taskState,
                asyncTask: response.task,
            });
            if (taskStatus !== UploadStatuses.pending) {
                clearInterval(this.state.refreshHandle);
                this.setState({
                    refreshHandle: undefined,
                });
            } else {
                this.setState({ taskState });
            }
        }
    };
}

function mapStateToProps(state: State) {
    return {
        categories: state.appConfig.entitlementsConfig.categories,
    };
}

export default connect(mapStateToProps)(DryRunDialog);
