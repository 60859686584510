import React from 'react';
import { HooksActionCreators } from './partnersActions';
import { useDispatch } from 'react-redux';
import BreadCrumbs from '../utils/BreadCrumbs';
import { PartnerForm } from './PartnerForm';
import { useCallback, useMemo } from 'react';
import { BENEFICIARY_SUPPORT_PANEL_URL } from './utils/constants';

const inputs = [
    { name: 'name', label: 'Beneficiary Support name', isFocused: true },
    { name: 'sublocation', label: 'Sublocation' },
    { name: 'city', label: 'City' },
    { name: 'country', label: 'Country' },
    { name: 'phoneNumber', label: 'Phone number' },
    { name: 'email', label: 'Email', type: 'email' },
];

export default function PartnerAddPage() {
    const dispatch = useDispatch();
    const createPartner = useMemo(() => HooksActionCreators.createPartner(dispatch), []);
    const handleSubmit = useCallback((formData) => {
        createPartner(formData).catch((e) => e);
    }, []);
    return (
        <>
            <BreadCrumbs path={`${BENEFICIARY_SUPPORT_PANEL_URL}/new`} />
            <h3>New Beneficiary Support</h3>
            <PartnerForm fields={inputs} onSubmit={handleSubmit} />
        </>
    );
}
