import NamedId from '../utils/NamedId';
import PermissionProfile from '../permission-profiles/profile';
import { ManagerPermission } from '../permission-profiles/permission';

export enum ManagerStatus {
    parked = 'parked',
    active = 'active',
    blocked = 'blocked',
    cancelled = 'cancelled',
    expired = 'expired',
}

export default class Manager {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    createdAt?: string;
    createdByManager: NamedId;
    authorizedByManager?: NamedId;
    authorizedAt?: string;
    askForNewPassword?: boolean;
    expirationDate: string;
    otpEnabled?: boolean;
    isPasswordBlocked: boolean;
    startDate?: string;
    profile?: PermissionProfile;
    agency: string;
}

export function hasVendorsFeature(manager: Manager) {
    return manager.profile?.permissions.includes(ManagerPermission.vendorsRead);
}

export function hasPartnersFeature(manager: Manager) {
    return manager.profile?.permissions.includes(ManagerPermission.partnersRead);
}

export function hasProductsFeature(manager: Manager) {
    return manager.profile?.permissions.includes(ManagerPermission.productsRead);
}

export function hasBeneficiariesFeature(manager: Manager) {
    return manager.profile?.permissions.includes(ManagerPermission.beneficiariesRead);
}

export function hasTransactionsFeature(manager: Manager) {
    return manager.profile?.permissions.includes(ManagerPermission.transactionRequestRead);
}

export function hasEntitlementsFeature(manager: Manager) {
    return manager.profile?.permissions.includes(ManagerPermission.beneficiariesRead);
}

export function hasPaymentRequestsFeature(manager: Manager) {
    return manager.profile?.permissions.includes(ManagerPermission.paymentsRead);
}

export function hasManagersManagementFeature(manager: Manager) {
    return manager.profile?.permissions.includes(ManagerPermission.managersManagement);
}

export function hasAdjustmentsViewFeature(manager: Manager) {
    return manager.profile?.permissions.includes(ManagerPermission.adjustmentsView);
}

export function hasOversightFeature(manager: Manager) {
    return manager.profile?.permissions.includes(ManagerPermission.operationActivityView);
}

export function hasDownloadFeature(manager: Manager, type: string) {
    if (type.includes('beneficiary')) {
        return manager.profile?.permissions.includes(ManagerPermission.beneficiariesDownload);
    } else if (type.includes('vendor')) {
        return manager.profile?.permissions.includes(ManagerPermission.vendorsDownload);
    } else if (type.includes('transaction')) {
        return manager.profile?.permissions.includes(ManagerPermission.transactionsDownload);
    } else if (type.includes('report')) {
        return manager.profile?.permissions.includes(ManagerPermission.beneficiariesDownload);
    } else if (type.includes('scope')) {
        return manager.profile?.permissions.includes(ManagerPermission.beneficiariesDownload);
    } else if (type.includes('alternative')) {
        return manager.profile?.permissions.includes(ManagerPermission.beneficiariesDownload);
    } else if (type.includes('family-tax-id')) {
        return manager.profile?.permissions.includes(ManagerPermission.beneficiariesDownload);
    } else if (type === 'export-beneficiaries-from-stats') {
        return manager.profile?.permissions.includes(ManagerPermission.beneficiariesDownload);
    } else if (type === 'upload-remove-entitlements') {
        return manager.profile?.permissions.includes(ManagerPermission.beneficiariesDownload);
    }
    return false;
}

export function hasAddAdditionalFileFeature(manager: Manager, type: string) {
    if (type === 'beneficiary-import-requests') {
        return (
            manager.profile?.permissions.includes(ManagerPermission.beneficiariesEntitlementPark) ||
            manager.profile?.permissions.includes(ManagerPermission.beneficiariesEntitlementVerify)
        );
    } else if (type === 'beneficiary-block-requests') {
        return (
            manager.profile?.permissions.includes(ManagerPermission.beneficiariesMassBlockPark) ||
            manager.profile?.permissions.includes(ManagerPermission.beneficiariesMassBlockPost)
        );
    } else if (type === 'beneficiary-unblock-requests') {
        return (
            manager.profile?.permissions.includes(ManagerPermission.beneficiariesMassUnblockPark) ||
            manager.profile?.permissions.includes(ManagerPermission.beneficiariesMassUnblockPost)
        );
    } else if (type === 'beneficiary-zeroing') {
        return (
            manager.profile?.permissions.includes(ManagerPermission.beneficiariesMassZeroPark) ||
            manager.profile?.permissions.includes(ManagerPermission.beneficiariesMassZeroPost)
        );
    } else if (type === 'beneficiary-zeroing-block') {
        return (
            manager.profile?.permissions.includes(ManagerPermission.beneficiariesMassBlockPark) ||
            manager.profile?.permissions.includes(ManagerPermission.beneficiariesMassBlockPost)
        );
    } else if (type === 'alternative-collector-import-requests') {
        return (
            manager.profile?.permissions.includes(ManagerPermission.alternativeCollectorsImportPark) ||
            manager.profile?.permissions.includes(ManagerPermission.alternativeCollectorsImportPost)
        );
    } else if (type === 'remove-alternative-collector-requests') {
        return (
            manager.profile?.permissions.includes(ManagerPermission.removeAlternativeCollectorsImportPark) ||
            manager.profile?.permissions.includes(ManagerPermission.removeAlternativeCollectorsImportPost)
        );
    }
    return false;
}

export function hasDownloadAdditionalFileFeature(manager: Manager) {
    return manager.profile?.permissions.includes(ManagerPermission.beneficiariesRead);
}
