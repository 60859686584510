import React, { FormEvent } from 'react';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import { CustomDateFilters, DateFilter, DateFilterRange, dateFilterRangeForDateFilter } from '../DateFilter';
import { DateSelectFilter } from '../FilterTypes';
import { isTimeDiffMoreThanYear } from '../utils';

interface Props {
    filter: DateSelectFilter;
    filterChanged: (filter: DateSelectFilter, event: FormEvent<HTMLInputElement>) => void;
    withIsSelected: boolean;
    startDate?: string;
    endDate?: string;
    withTimeRangeLimit?: boolean;
    minDate?: Date;
    maxDate?: Date;
}

interface State {
    ranges: any;
    startDate: moment.Moment;
    endDate: moment.Moment;
    changeDateRange: boolean;
}

export default class DateFilterField extends React.Component<Props, State> {
    UNSAFE_componentWillMount() {
        let ranges = {};

        if (Array.isArray(this.props.filter.options)) {
            this.props.filter.options.forEach((option) => {
                if (option.value && option.value !== CustomDateFilters.custom) {
                    const range = dateFilterRangeForDateFilter(option.value);
                    ranges[option.label] = [range.startDate, range.endDate];
                }
            });
        } else {
            ranges = {
                Today: [moment(), moment()],
                Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [
                    moment().subtract(1, 'month').startOf('month'),
                    moment().subtract(1, 'month').endOf('month'),
                ],
                'This Year': [moment().startOf('year'), moment().endOf('year')],
                'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
            };
        }
        const startDate = this.props.startDate ? moment(this.props.startDate) : moment().subtract(29, 'days');
        const endDate = this.props.endDate ? moment(this.props.endDate) : moment();
        this.setState({
            ranges,
            startDate: startDate.startOf('day'),
            endDate: endDate.endOf('day'),
            changeDateRange: false,
        });
        const filter = this.props.filter;
        filter.value = CustomDateFilters.custom;
        const filterRange: DateFilterRange = {
            startDate: startDate.startOf('day').toDate(),
            endDate: endDate.endOf('day').toDate(),
        };
        filter.dateRange = filterRange;
    }

    updateFilterInfo(event, dateFilter: DateFilter, filterRange?: DateFilterRange) {
        const filter = this.props.filter;
        filter.value = dateFilter;
        filter.dateRange = filterRange;
        filter.isSelected = true;
        this.props.filterChanged(filter, event);
    }

    checkboxTick(checkboxEvent) {
        const filter = this.props.filter;
        filter.isSelected = checkboxEvent.target.checked;
        const startDate = this.state.startDate.toDate();
        const endDate = this.state.endDate.toDate();
        const filterRange: DateFilterRange = {
            startDate: startDate,
            endDate: endDate,
        };
        filter.dateRange = filterRange;
        this.props.filterChanged(filter, checkboxEvent);
    }

    checkbox(isExceedingTimeRange = false) {
        return (
            <input
                checked={this.props.filter.isSelected && !isExceedingTimeRange}
                disabled={isExceedingTimeRange}
                name={'date_check_box'}
                onChange={this.checkboxTick.bind(this)}
                style={{ marginBottom: 10 }}
                title={isExceedingTimeRange ? 'Maximum date range is one year' : ''}
                type="checkbox"
            />
        );
    }

    dateRangeChange(event) {
        this.setState({
            startDate: moment(new Date(event.selection.startDate)).startOf('day'),
            endDate: moment(new Date(event.selection.endDate)).endOf('day'),
        });
    }

    applyChangedDate() {
        const startDate = this.state.startDate;
        const endDate = this.state.endDate;

        const filterRange: DateFilterRange = {
            startDate: startDate.startOf('day').toDate(),
            endDate: endDate.endOf('day').toDate(),
        };
        this.updateFilterInfo(event, CustomDateFilters.custom, filterRange);
        this.setState({ changeDateRange: false });
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.startDate && nextProps.endDate) {
            this.setState({
                startDate: moment(new Date(nextProps.startDate)).startOf('day'),
                endDate: moment(new Date(nextProps.endDate)).endOf('day'),
            });
        }
    }

    render() {
        const start = this.state.startDate;
        const end = this.state.endDate;
        let label = start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD');
        if (start.format('YYYY-MM-DD') === end.format('YYYY-MM-DD')) {
            label = start.format('YYYY-MM-DD');
        }
        const selectionRange = {
            startDate: start,
            endDate: end,
            key: 'selection',
        };
        const filter = this.props.filter;
        const isExceedingTimeRange: boolean =
            this.props.withTimeRangeLimit &&
            isTimeDiffMoreThanYear(selectionRange.startDate.toDate(), selectionRange.endDate.toDate());

        const maxDate = this.props.maxDate ? { maxDate: this.props.maxDate } : null;
        const minDate = this.props.minDate ? { minDate: this.props.minDate } : null;
        const dateBounds = { ...maxDate, ...minDate };
        return (
            <div key={filter.name}>
                <label htmlFor="selection">{filter.label}:</label>
                {this.props.withIsSelected && this.checkbox(isExceedingTimeRange)}
                <input
                    className={'replaced-form-input'}
                    onClick={() =>
                        this.setState({
                            changeDateRange: !this.state.changeDateRange,
                        })
                    }
                    readOnly={true}
                    type={'text'}
                    value={label}
                />
                {this.state.changeDateRange && (
                    <div
                        className="dateFilterWithApply"
                        style={{
                            position: 'absolute',
                            zIndex: 2,
                            backgroundColor: 'white',
                        }}
                    >
                        <DateRangePicker
                            {...dateBounds}
                            onChange={this.dateRangeChange.bind(this)}
                            ranges={[selectionRange]}
                        />
                        <div className="wfp-form--actions text-center">
                            <button
                                className="wfp-btn ml3"
                                onClick={() => this.setState({ changeDateRange: false })}
                                style={{ margin: 5 }}
                                type="button"
                            >
                                Cancel
                            </button>
                            <button
                                className="wfp-btn--primary"
                                disabled={isExceedingTimeRange}
                                onClick={this.applyChangedDate.bind(this)}
                                style={{ margin: 5 }}
                                title={isExceedingTimeRange ? 'Maximum date range is one year' : ''}
                                type="submit"
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
