import { showLoading, hideLoading } from 'react-redux-loading-bar';

export default function ({ dispatch }) {
    return function (next) {
        return function (action) {
            if (typeof action === 'function') {
                const maybePromise = next(action);
                if (maybePromise && typeof maybePromise.then === 'function') {
                    dispatch(showLoading());

                    return maybePromise
                        .then((result) => {
                            dispatch(hideLoading());
                            return result;
                        })
                        .catch((err) => {
                            dispatch(hideLoading());
                            throw err;
                        });
                }

                return maybePromise;
            } else {
                next(action);
            }
        };
    };
}
