import 'bootstrap/dist/css/bootstrap.css';
import 'wfp-ui/dist/css/bootstrap-theme.css';
import 'wfp-ui/dist/css/wfpui.css';
import 'wfp-ui/dist/assets/fonts/aleo/aleo.css';
import 'wfp-ui/dist/assets/fonts/lato/lato.css';
import './styles/daterangepicker.scss';
import './styles/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { useRootAuthConfig } from './app/auth';
import { AuthContextProvider } from './common/auth/AuthContext';
import { store } from '@wfp-root-app/store/store';

ReactDOM.render(
    <Provider store={store as any}>
        <BrowserRouter>
            <AuthContextProvider authConfig={useRootAuthConfig}>
                <App />
            </AuthContextProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
