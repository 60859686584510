import React from 'react';
import { bindActionCreators } from 'redux';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { connect } from 'react-redux';

import { EditBeneficiaryCreators } from './edit/editBeneficiaryActionCreator';
import { Beneficiary, BeneficiaryStatuses } from './beneficiaries';
import { ReadonlyCard } from './Cards';
import { displayTime } from '../utils/utils';
import { InformationDialog } from '../utils/Dialogs';
import hasConflictingBeneficiaryUpdates from '../utils/hasConflictingBeneficiaryUpdates';
import { EntityType } from '../authorization/taskAuthorizations';
import SourceReasonDialog from './edit/SourceReasonDialog';
import { BalanceEntriesComponent } from './balanceEntries';
import { EntitlementCategoriesConfig, EntitlementCurrencyConfig } from '../app/appConfig';
import _ from 'lodash';
import { getClustersInfo } from '../apiClient';
import { MultiSelectFilter, SelectFilter, SelectOption } from '../utils/FilterTypes';
import { ExpirationTokenType } from '../transactions/transactions';
import { handleZeroCategoryChange } from './helpers/utils';
import { countries } from '../utils/countries';
import { ManagerPermission } from '../permission-profiles/permission';
import State from '@wfp-root-app/store/state';

export const AllCategoryOption = 'ALL';

export interface ZeroingOption {
    category: string[];
    availabilityType: string;
}

const flexRow: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
};

interface Props {
    auth: AuthState;
    beneficiaryId: string;
    beneficiary?: Beneficiary;
    updateBeneficiaryStatus: (
        beneficiaryId: string,
        status: BeneficiaryStatuses,
        reason: string,
        source: string
    ) => void;
    zeroBeneficiary: (beneficiaryId: string, reason: string, source: string, zeroingOption: ZeroingOption) => void;
    acStatusChange: (id: string, reason: string, source: string, newStatus: string) => void;
    categories: EntitlementCategoriesConfig;
    currency: EntitlementCurrencyConfig;
    shouldShowAccountType: boolean;
    timezone: string;
}

interface BeneficiaryActivitiesPageState {
    isBalanceZeroingDialogVisible: boolean;
    isZeroingUpdateWarningVisible?: boolean;
    isACStatusChangeWarningVisible: boolean;
    isACStatusChangeDialogVisible: boolean;
    zeroingOption?: ZeroingOption;
}

class BeneficiaryActivitiesPage extends React.Component<Props, BeneficiaryActivitiesPageState> {
    constructor(props) {
        super(props);
        this.state = {
            isBalanceZeroingDialogVisible: false,
            isZeroingUpdateWarningVisible: false,
            isACStatusChangeWarningVisible: false,
            isACStatusChangeDialogVisible: false,
        };
    }

    sectionHeader(title: string) {
        return <h6>{title}</h6>;
    }

    renderZeroingUpdateWarningDialog() {
        return (
            <InformationDialog
                message="Beneficiary balance cannot be currently zeroed because another update is waiting to be posted."
                onClose={() => this.setState({ isZeroingUpdateWarningVisible: false })}
                title="Pending updates"
            />
        );
    }

    renderACStatusChangeWarningDialog() {
        return (
            <InformationDialog
                message="AC status change cannot be currently changed because another update is waiting to be posted"
                onClose={() => this.setState({ isACStatusChangeWarningVisible: false })}
                title="Pending updates"
            />
        );
    }

    onZeroBeneficiaryBalance(zeroingOption: ZeroingOption) {
        const conflictingUpdates = [
            EntityType.beneficiarySingleBlock,
            EntityType.beneficiarySingleUnblock,
            EntityType.beneficiaryZeroingBlock,
            EntityType.beneficiaryZeroing,
        ];
        const isPendingZeroingUpdateWarningVisible = hasConflictingBeneficiaryUpdates(
            this.props.beneficiary,
            conflictingUpdates
        );
        if (isPendingZeroingUpdateWarningVisible) {
            this.setState({ isZeroingUpdateWarningVisible: true });
        } else {
            this.setState({ isBalanceZeroingDialogVisible: true, zeroingOption: zeroingOption });
        }
    }

    renderZeroBeneficiaryBalance() {
        return (
            <div className={'wfp-form--actions text-center'}>
                <button className="wfp-btn--primary" onClick={this.onZeroBeneficiaryBalance.bind(this)} type="button">
                    Zero
                </button>
            </div>
        );
    }

    renderBalance() {
        const { beneficiary, beneficiaryId, timezone } = this.props;
        if (!beneficiary || !beneficiary.balance) {
            return;
        } else {
            return (
                <BalanceEntriesComponent
                    balanceToMap={beneficiary.balance}
                    beneficiaryId={beneficiaryId}
                    currency={this.props.currency}
                    getClusterInfoFunction={getClustersInfo}
                    timezone={timezone}
                />
            );
        }
    }

    onConfirmBeneficiaryZeroing(reason: string, source: string, zeroingOption: ZeroingOption) {
        const { zeroBeneficiary, beneficiary } = this.props;
        zeroBeneficiary(beneficiary.id, reason, source, zeroingOption);
        this.setState({ isBalanceZeroingDialogVisible: false });
    }

    onConfirmACStatusChange(reason: string, source: string) {
        const { acStatusChange, beneficiary } = this.props;

        acStatusChange(
            beneficiary.id,
            reason,
            source,
            beneficiary.alternativeCollectorStatus === 'active' ? 'blocked' : 'active'
        );
        this.setState({ isACStatusChangeDialogVisible: false });
    }

    renderZeroConfirmationDialog() {
        const { categories } = this.props;
        const extendedCategory = categories.categories.concat([AllCategoryOption]);
        const extendedCategoryOptions = extendedCategory.map((s) => {
            return new SelectOption(s.toString(), s);
        });
        const tokenAvabilityOptions = Object.values(ExpirationTokenType).map((s) => {
            return new SelectOption(_.capitalize(s.toString()), s);
        });
        const defaultFilters = [
            new MultiSelectFilter(
                'Category',
                'category',
                [extendedCategoryOptions[0]],
                extendedCategoryOptions,
                AllCategoryOption
            ),
            new SelectFilter('Type', 'type', tokenAvabilityOptions[0], tokenAvabilityOptions),
        ];

        return (
            <SourceReasonDialog
                categories={this.props.categories}
                confirmTitle="Park"
                onClose={() => this.setState({ isBalanceZeroingDialogVisible: false })}
                onConfirm={this.onConfirmBeneficiaryZeroing.bind(this)}
                onFilterChange={handleZeroCategoryChange}
                selectedOptions={defaultFilters}
                title="Balance zeroing"
            />
        );
    }

    renderACStatusChangeConfirmationDialog(currentACStatus) {
        const newStatus = currentACStatus === 'active' ? 'blocked' : 'active';
        return (
            <SourceReasonDialog
                confirmTitle="Park"
                onClose={() => this.setState({ isACStatusChangeDialogVisible: false })}
                onConfirm={this.onConfirmACStatusChange.bind(this)}
                title={`AC status change - ${currentACStatus} to ${newStatus}`}
            />
        );
    }

    render() {
        const { beneficiary, auth } = this.props;
        const hasBalanceZeroingPermission = hasFeatureAccess(auth, ManagerPermission.beneficiariesSingleZeroPark);
        const {
            isBalanceZeroingDialogVisible,
            isZeroingUpdateWarningVisible,
            isACStatusChangeDialogVisible,
            isACStatusChangeWarningVisible,
        } = this.state;
        const displayedBeneficiary = beneficiary || new Beneficiary();
        return (
            <div>
                {isBalanceZeroingDialogVisible && this.renderZeroConfirmationDialog()}
                {isZeroingUpdateWarningVisible && this.renderZeroingUpdateWarningDialog()}
                {isACStatusChangeDialogVisible &&
                    this.renderACStatusChangeConfirmationDialog(beneficiary.alternativeCollectorStatus)}
                {isACStatusChangeWarningVisible && this.renderACStatusChangeWarningDialog()}
                {this.sectionHeader('Balance')}

                <div>{this.renderBalance()}</div>

                {hasBalanceZeroingPermission && this.renderZeroBeneficiaryBalance()}

                {this.sectionHeader('Activities')}
                <div style={flexRow}>
                    <ReadonlyCard title="Created" value={displayTime(displayedBeneficiary.createdAt)} />
                </div>
            </div>
        );
    }
}

interface OwnProps {
    location: { state: { beneficiaryId: string } };
}

function mapDispatchToProps(dispatch: any) {
    return {
        updateBeneficiaryStatus: bindActionCreators(EditBeneficiaryCreators.updateBeneficiaryStatus, dispatch),
        zeroBeneficiary: bindActionCreators(EditBeneficiaryCreators.zeroBeneficiary, dispatch),
        acStatusChange: bindActionCreators(EditBeneficiaryCreators.acStatusChange, dispatch),
    };
}

function mapStateToProps(state: State, ownProps: OwnProps) {
    return {
        auth: state.auth,
        beneficiary: state.editBeneficiary.beneficiary,
        beneficiaryId: ownProps.location.state.beneficiaryId,
        categories: state.appConfig.entitlementsConfig,
        currency: state.appConfig.entitlementCurrencyConfig,
        shouldShowAccountType: state.appConfig.country === countries.BANGLADESH,
        timezone: state.appConfig.timeZone,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryActivitiesPage as any);
