import React, { useState } from 'react';
import { AsyncTask } from '../../utils/asyncTasks';

export type AsyncTasksAccordionProps = {
    mainTask: AsyncTask;
    otherTasks: AsyncTask[];
    renderRow: (
        row: AsyncTask,
        child?: boolean,
        index?: number,
        toggleAction?: { isHidden: boolean; toggle: () => void }
    ) => JSX.Element;
};

export const AsyncTasksAccordion = ({ mainTask, otherTasks, renderRow }: AsyncTasksAccordionProps) => {
    const [isHidden, setIsHidden] = useState(true);

    return (
        <>
            {renderRow(mainTask, false, 0, { toggle: () => setIsHidden(!isHidden), isHidden })}
            {!isHidden &&
                otherTasks.length > 0 &&
                otherTasks.map((otherTask, index) => {
                    return renderRow(otherTask, true, index);
                })}
        </>
    );
};
