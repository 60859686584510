import React from 'react';

export function ChevronDown() {
    return (
        <svg height="14px" version="1.1" viewBox="0 0 24 14" width="24px">
            <title>chevron--down</title>
            <desc>Created with Sketch.</desc>
            <defs/>
            <g fill="none" fillRule="evenodd" id="chevron--down" stroke="none" strokeWidth="1">
                <polygon
                    fill="#000000"
                    fillRule="nonzero"
                    id="chevron"
                    points="5.99408113 1.44972267 0.726461685 6.99971556 -5.79924997e-13 6.31479463 5.99350948 -2.74780199e-14 11.996192 6.31451019 11.2702706 7"
                    transform="translate(5.998096, 3.500000) rotate(180.000000) translate(-5.998096, -3.500000) "
                />
            </g>
        </svg>
    );
}
