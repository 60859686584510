import React from 'react';
import { FC, useEffect } from 'react';
import State from '@wfp-root-app/store/state';
import {
    ActionCreators,
    EntityType,
    EntityUpdate,
    EntityUpdateRequest,
    ListedEntitiesType,
    ProductUpdateRequest,
    UpdateStateListType,
} from './taskAuthorizations';
import EntityUpdatesAuthorizationList, { UpdateRowConfiguration } from './EntityUpdatesAuthorizationList';
import { hasFeatureAccess } from '../login/auth';
import { RowMenu, RowMenuProps } from '../utils/RowMenu';
import { useDispatch, useSelector } from 'react-redux';
import { AuthorizationTab } from './TaskAuthorizationsPage';
import { ManagerPermission } from '../permission-profiles/permission';
import { useLocation } from '@wfp-common/hooks/useLocation';

function AuthorizationRowMenu(props: RowMenuProps<UpdateStateListType>) {
    return RowMenu(props);
}

const PageLimit = 15;

interface EntityUpdateAuthorizationPageProps {
    tab: AuthorizationTab;
}

export const EntityUpdateAuthorizationPage: FC<EntityUpdateAuthorizationPageProps> = (props) => {
    const location = useLocation();
    const requestsTabs = [UpdateStateListType.Requested, UpdateStateListType.Posted, UpdateStateListType.Rejected];
    const dispatch = useDispatch();
    const { auth } = useSelector((state: State) => state);
    const { activeListType, entityUpdateRequests } = useSelector((state: State) => state.taskAuthorizations);
    const categories = useSelector((state: State) => state.appConfig.entitlementsConfig);

    const authorizationTypeToListedEntitiesType = {
        [AuthorizationTab.ManagerDetails]: EntityType.managerDetails,
        [AuthorizationTab.PartnersDetails]: EntityType.partnerDetails,
        [AuthorizationTab.PartnersUsersDetails]: EntityType.partnerUserDetails,
    };

    useEffect(() => {
        changeListType(UpdateStateListType.Requested);
        const listedEntitiesType = authorizationTypeToListedEntitiesType[props.tab];
        loadEntityUpdateRequests(listedEntitiesType, activeListType, 1);

        return () => {
            clearUpdateRequests();
        };
    }, [location.pathname]);

    const changeRequestsTab = (activeListType: UpdateStateListType) => {
        const listedEntitiesType = authorizationTypeToListedEntitiesType[props.tab];
        changeListType(activeListType);

        loadEntityUpdateRequests(listedEntitiesType, activeListType, 1);
    };

    const changeListType = (newListType: UpdateStateListType) => {
        dispatch(ActionCreators.changeListType(newListType));
    };

    const loadEntityUpdateRequests = (
        listedEntitiesType: ListedEntitiesType,
        activeListTypeTab: UpdateStateListType,
        page: number
    ) => {
        const d = ActionCreators.loadEntityUpdateRequests(listedEntitiesType, activeListTypeTab, page, PageLimit);
        d(dispatch);
    };

    const clearUpdateRequests = () => {
        const action = ActionCreators.clearUpdateRequests();
        action(dispatch);
    };

    const configurationForUpdateRequest = (entityUpdateRequest: EntityUpdateRequest): UpdateRowConfiguration => {
        if (entityUpdateRequest.authorizedAt || entityUpdateRequest.deletedAt) {
            return new UpdateRowConfiguration(true, null);
        }
        const isCreator = entityUpdateRequest.createdByManager
            ? entityUpdateRequest.createdByManager.id === auth.manager.id
            : false;
        const isOwnAffected =
            entityUpdateRequest.entityType === EntityType.managerDetails &&
            entityUpdateRequest.entityId === auth.manager.id;
        const hasNotPermission =
            entityUpdateRequest.updates.find((v) => v.path === 'otpEnabled') &&
            !hasFeatureAccess(auth, ManagerPermission.otpStatusChangePost);
        if (auth.manager.id !== '1' && (isOwnAffected || isCreator || hasNotPermission)) {
            let comment;
            if (isCreator) {
                comment = 'You cannot post an update that you parked yourself';
            } else if (isOwnAffected) {
                comment = 'You cannot post an update that is related to you';
            } else if (hasNotPermission) {
                comment = 'You cannot post an update without related permission';
            }
            return new UpdateRowConfiguration(isCreator || isOwnAffected || hasNotPermission, comment);
        }
        if (
            entityUpdateRequest.updates.map((u) => u.path).includes('expirationDate') &&
            entityUpdateRequest.entityId === auth.manager.id
        ) {
            //todo to remove check user id
            const comment = "You cannot post this update, because it includes your account's expiration date change";
            return new UpdateRowConfiguration(isCreator, comment);
        }
        switch (entityUpdateRequest.entityType) {
            case EntityType.managerDetails: {
                const readonly = !hasFeatureAccess(auth, ManagerPermission.managersManagementPost);
                const comment = readonly ? 'No permission to post user details' : null;
                return new UpdateRowConfiguration(readonly, comment);
            }
            case EntityType.partnerDetails: {
                const readonly = !hasFeatureAccess(auth, ManagerPermission.partnerPost);
                const comment = readonly ? 'No permission to post partner details' : null;
                return new UpdateRowConfiguration(readonly, comment);
            }
            case EntityType.partnerUserDetails: {
                const readonly = !hasFeatureAccess(auth, ManagerPermission.partnerUserPost);
                const comment = readonly ? 'No permission to post partner user details' : null;
                return new UpdateRowConfiguration(readonly, comment);
            }
            default:
                return new UpdateRowConfiguration(false, null);
        }
    };

    const renderEntityUpdateAuthorization = () => {
        const listedEntitiesType = authorizationTypeToListedEntitiesType[props.tab];

        if (!auth.manager) {
            return null;
        }

        function confirmEntityUpdateRequest(entityUpdateRequest: EntityUpdateRequest | ProductUpdateRequest) {
            const d = ActionCreators.confirmEntityUpdateRequest(entityUpdateRequest);
            d(dispatch);
        }

        const editEntityUpdateRequest = (id: string, newUpdates: EntityUpdate[]) => {
            const d = ActionCreators.editEntityUpdateRequest(id, newUpdates);
            d(dispatch);
        };

        const parkUpdate = (entityUpdateRequest: EntityUpdateRequest) => {
            const d = ActionCreators.parkEntityUpdateRequest(entityUpdateRequest, auth.manager);
            d(dispatch);
        };

        const rejectEntityUpdateRequest = (
            entityUpdateRequest: EntityUpdateRequest | ProductUpdateRequest,
            comment: string
        ) => {
            const d = ActionCreators.rejectEntityUpdateRequest(entityUpdateRequest, comment);
            d(dispatch);
        };

        return (
            <EntityUpdatesAuthorizationList
                categories={categories.categories}
                configurationForUpdate={configurationForUpdateRequest}
                entityUpdateRequests={entityUpdateRequests}
                listType={activeListType}
                managerId={auth.manager.id}
                onConfirmUpdateRequest={confirmEntityUpdateRequest}
                onEditUpdateRequest={editEntityUpdateRequest}
                onPageChanged={(page) => loadEntityUpdateRequests(listedEntitiesType, activeListType, page)}
                onParkUpdateRequest={parkUpdate}
                onRejectUpdateRequest={rejectEntityUpdateRequest}
            />
        );
    };

    return (
        <div>
            <AuthorizationRowMenu activeTab={activeListType} onSelectTab={changeRequestsTab} tabs={requestsTabs} />

            {[
                AuthorizationTab.ManagerDetails,
                AuthorizationTab.PartnersDetails,
                AuthorizationTab.PartnersUsersDetails,
            ].includes(props.tab) && renderEntityUpdateAuthorization()}
        </div>
    );
};
