import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Switch from 'rc-switch';

import State from '@wfp-root-app/store/state';
import { ActionCreators, ExtendedManager } from './managers';
import { ExternalValidationError } from '../utils/inputs';
import { AuthState, hasFeatureAccess } from '../login/auth';
import ManagerPersonalDetails from './ManagerPersonalDetails';
import { InformationDialog } from '../utils/Dialogs';
import { EntityType } from '../authorization/taskAuthorizations';
import { ManagerPermission } from '../permission-profiles/permission';

interface Props {
    editPersonalData: (params: any) => void;
    validationError?: ExternalValidationError;
    manager: ExtendedManager;
    auth: AuthState;
}

interface PersonalDataState {
    isEditEnabled: boolean;
    hasPendingDetailsUpdate: boolean;
}

class ManagerEditPersonalPage extends React.Component<Props, PersonalDataState> {
    constructor(props) {
        super(props);
        this.state = {
            isEditEnabled: false,
            hasPendingDetailsUpdate: false,
        };
        this.toggleEdit = this.toggleEdit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.manager !== this.props.manager) {
            this.setState({ isEditEnabled: false });
        }
    }

    toggleEdit(checked) {
        const { manager } = this.props;
        let hasPendingDetailsUpdate = false;
        if (manager) {
            const hasManagerDetailsEntity = manager.pendingEntityUpdates.filter(
                (update) => update.entityType === EntityType.managerDetails
            );
            hasPendingDetailsUpdate = hasManagerDetailsEntity.length !== 0;
        }
        if (hasPendingDetailsUpdate) {
            this.setState({ hasPendingDetailsUpdate });
        } else {
            this.setState({ isEditEnabled: checked });
        }
    }

    renderPendingUpdatesDialog() {
        return (
            <InformationDialog
                message="Personal details cannot be currently edited because another edit is waiting to be posted"
                onClose={() => this.setState({ hasPendingDetailsUpdate: false })}
                title="Pending updates"
            />
        );
    }

    hasWritePermission() {
        return hasFeatureAccess(this.props.auth, ManagerPermission.managersManagementPark);
    }

    render() {
        const { isEditEnabled, hasPendingDetailsUpdate } = this.state;
        const isEditSwitchVisible = this.hasWritePermission();
        return (
            <main>
                <h3>
                    {this.props.manager
                        ? `Personal Details of User "${this.props.manager.firstName} ${this.props.manager.lastName}"`
                        : ''}
                </h3>
                <div className={'row'}>
                    {isEditSwitchVisible && <label className="col-sm-4 ta-right">Toggle Edit</label>}
                    {isEditSwitchVisible && (
                        <div className="col-sm-8 pv1">
                            {<Switch checked={this.state.isEditEnabled} onClick={this.toggleEdit} />}
                        </div>
                    )}
                </div>
                {hasPendingDetailsUpdate && this.renderPendingUpdatesDialog()}
                <ManagerPersonalDetails
                    editDisabled={!isEditEnabled}
                    manager={this.props.manager}
                    onSaved={this.props.editPersonalData}
                    validationError={this.props.validationError}
                />
            </main>
        );
    }
}

function mapStateToProps(state: State, ownProps) {
    return {
        validationError: state.managers.validationError,
        manager: state.auth.manager,
        auth: state.auth,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        editPersonalData: bindActionCreators(ActionCreators.editPersonalData, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerEditPersonalPage);
