import React from 'react';
import { ActionCreators as AsyncTaskActionCreators, AsyncTasksTypes, HookActionCreators } from '../utils/asyncTasks';
import { useCallback } from 'react';
import { useActionCreator } from '../utils/useActionCreator';

interface OwnProps {
    asyncTaskId: string;
    fileName?: string;
    open?: boolean;
    hasAccess?: boolean;
}

type Props = OwnProps;

function DownloadPermissionsButton({ asyncTaskId, fileName, open, hasAccess }: Props) {
    const openFile = useActionCreator(HookActionCreators.openFile, true);
    const downloadFile = useActionCreator(AsyncTaskActionCreators.downloadFile);
    const handleClick = useCallback(() => {
        if (open) {
            openFile(AsyncTasksTypes.UserPermissionsFileUpload, asyncTaskId);
        } else {
            downloadFile(AsyncTasksTypes.UserPermissionsFileUpload, asyncTaskId);
        }
    }, [asyncTaskId, open]);
    const renderLink = typeof hasAccess !== 'boolean' || hasAccess;
    return (
        <>
            {renderLink ? (
                <a onClick={handleClick} rel="noopener noreferrer nofollow" target={open ? '_blank' : ''}>
                    {fileName || 'Download'}
                </a>
            ) : (
                fileName
            )}
        </>
    );
}

export { DownloadPermissionsButton };
