import React from 'react';
import { TableRow } from './table-head.styled';
import { ChangeSortOptions, useSort } from '../../hooks/useSort';
import { useMultiSort } from '../../hooks/useMultiSort';
import { omit } from '../../utils';

interface Props {
    changeSortOptions?: ChangeSortOptions;
}

interface TableHeadCompoundComponent extends React.FC<Props> {
    DisabledColumn: React.FC<any>;
    MultiSort: React.FC<Props>;
    Cell: React.FC<{ columnName: string; colSpan?: number }>;
}

function TableHead({ children, changeSortOptions }) {
    const render = useSort(changeSortOptions);
    return (
        <thead>
            <TableRow>{render(children)}</TableRow>
        </thead>
    );
}

function MultiSortTableHead({ children, changeSortOptions }) {
    const render = useMultiSort(changeSortOptions);
    return (
        <thead>
            <TableRow>{render(children)}</TableRow>
        </thead>
    );
}

const memo = (React.memo(TableHead) as unknown) as TableHeadCompoundComponent;
const MultiSort = (React.memo(MultiSortTableHead) as unknown) as React.FC<Props>;
memo.DisabledColumn = DisabledColumn as React.FC;
memo.DisabledColumn.displayName = 'DisabledColumn';
memo.MultiSort = MultiSort;
memo.MultiSort.displayName = 'MultiSortTableHead';
memo.Cell = Cell as React.FC<{ columnName: string }>;
export { memo as SortableTableHead };

function DisabledColumn({ children, ...props }) {
    return <th {...props}>{children}</th>;
}

function Cell({ children, ...props }) {
    return <th {...omit(props, 'columnName')}>{children}</th>;
}
