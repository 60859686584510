import React, { FC } from 'react';
import { useNavigate } from '@wfp-common/hooks/useNavigate';

import { displayTime, wfpFormat } from '../utils/utils';
import { createActivityLogMessage } from './createActivityLogMessage';
import { ActivityLog, ActivityType, ActivityTypes, unRedirectableLogs } from './activityLogs';
import { ManagerNickName } from '../managers/managers';
import { VendorNickName, VendorUserName } from '../vendors/vendors';
import { BeneficiarySupportOrganizationName, BeneficiarySupportUserName } from '../partners/partners';
import { useManagerParams } from '../managers/hooks/useManagerParams';

const flex: React.CSSProperties = {
    display: 'flex',
};
const CommentCellHeader: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
};

const CommentCellContainer: React.CSSProperties = {
    display: 'flex',
    marginBottom: 10,
    flexDirection: 'column',
    padding: 10,
};

function getStyle(type: string) {
    let style = 'shadowed-container';
    if (!unRedirectableLogs.includes(type as ActivityType)) {
        style += ' cursor-pointer';
    }
    return style;
}

function logTitle(log: ActivityLog<any>) {
    const activity = ActivityTypes.displayName(log.activity);
    const withoutHeader = [
        ActivityType.otpSecretChange,
        ActivityType.passwordReset,
        ActivityType.passwordChange,
        ActivityType.otpSecretReset,
        ActivityType.documentSectionCreate,
        ActivityType.documentSectionUpdate,
        ActivityType.documentSectionReorder,
        ActivityType.documentUpload,
        ActivityType.documentDownload,
        ActivityType.documentDelete,
    ].map((v) => v.toString());
    if (withoutHeader.includes(log.activity)) {
        return `${wfpFormat(activity)}`;
    }
    if (log.cancelledByManagerId) {
        return `[CANCEL] ${wfpFormat(activity)}`;
    } else if (log.postedByManagerId) {
        return `[POST] ${wfpFormat(activity)}`;
    } else if (log.parkedByManagerId) {
        return `[PARK] ${wfpFormat(activity)}`;
    }
    return wfpFormat(activity);
}

interface ActivityLogCellProps {
    log: ActivityLog<any>;
    managersNickName: ManagerNickName[];
    vendorsNickNames: VendorNickName[];
    vendorUsersNames: VendorUserName[];
    partnersNames: BeneficiarySupportOrganizationName[];
    partnerUsersNames: BeneficiarySupportUserName[];
}

const ActivityLogCell: FC<ActivityLogCellProps> = ({
    log,
    managersNickName,
    vendorsNickNames,
    vendorUsersNames,
    partnerUsersNames,
    partnersNames,
}) => {
    const navigate = useNavigate();
    const { managerId } = useManagerParams();

    const redirectToDetails = (activityLogId: string, type: string) => {
        if (!unRedirectableLogs.includes(type as ActivityType)) {
            navigate(`/admin/${managerId}/activity/${activityLogId}`);
        }
    };

    return (
        <div
            className={getStyle(log.activity)}
            key={log.id}
            onClick={() => redirectToDetails(log.id, log.activity)}
            style={{ ...CommentCellContainer }}
        >
            <div style={CommentCellHeader}>
                <div style={flex}>
                    <label>{logTitle(log)}</label>
                </div>
                <div style={flex}>
                    <p>{displayTime(log.created_at)}</p>
                </div>
            </div>
            <div style={flex}>
                <p style={{ whiteSpace: 'pre-line' }}>
                    {createActivityLogMessage(
                        log,
                        managersNickName,
                        vendorsNickNames,
                        vendorUsersNames,
                        partnersNames,
                        partnerUsersNames
                    )}
                </p>
            </div>
        </div>
    );
};

export default ActivityLogCell;
