import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, Outlet, Navigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { NavigateHook, useNavigate } from '@wfp-common/hooks/useNavigate';
import State from '@wfp-root-app/store/state';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

import { ActionCreators as VendorActionCreators } from '../vendors/vendors';
import {
    ActionCreators as PartnerActionCreators,
    BeneficiarySupportOrganization,
    BeneficiarySupportOrganizationName,
    BeneficiarySupportUserName,
} from './partners';
import { ActionCreators } from './partnersActions';
import { AuthState } from '../login/auth';
import { ActionCreators as ManagerActionCreators, ManagerNickName } from '../managers/managers';
import { InformationDialog } from '../utils/Dialogs';
import { ExternalValidationError } from '../utils/inputs';
import { PagedState } from '../utils/paging';
import { AuthorizationSideMenu, AuthorizationSideMenuProps } from '../utils/SideMenu';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { BENEFICIARY_SUPPORT_PANEL_URL } from './utils/constants';
import { BeneficiarySupportPanelTab } from './tabs/BeneficiarySupportPanelTabRenderer';

enum PartnerTab {
    GeneralInformation = 'General Information',
    PartnerPanel = 'Beneficiary Support Users',
    PermissionFiles = 'Permission Files',
}

const urlPathForTab = {
    [PartnerTab.GeneralInformation]: 'general',
    [PartnerTab.PartnerPanel]: 'panel',
    [PartnerTab.PermissionFiles]: 'permission-files',
};

function EditPartnerSideMenu(props: AuthorizationSideMenuProps<any>) {
    return AuthorizationSideMenu(props);
}

interface Props {
    loadPartner: (partnerId: string, withPartnerUsers?: boolean) => void;
    savePartner: (partner: any) => void;
    cancelPark: (id: string) => void;
    redirectToPage: (url: string) => void;
    partnerId: string;
    routes: { path: string }[];
    loadManagersNickNames: () => void;
    loadPartnersNames: (sortOptions: SORT_OPTIONS) => void;
    loadPartnerUsersNames: (sortOptions: SORT_OPTIONS) => void;

    validationError?: ExternalValidationError;
    partner: BeneficiarySupportOrganization;
    auth: AuthState;
    managerNickNameList: Array<ManagerNickName>;
    partnerUsersList: Array<any>;
    authorizePartner: () => (vendorId: string) => (dispatch: any) => Promise<void>;
    partnersNames: BeneficiarySupportOrganizationName[];
    partnerUsersNames: BeneficiarySupportUserName[];
    params: { partnerId: string; supportTab: string };
    navigate: NavigateHook;
}

interface PartnerEditPageState {
    editDisabled: boolean;
    hasPendingDetailsUpdate: boolean;
    activeTab: { text: PartnerTab; counter: string | null };
    searchValue: string;
    activityLogs: PagedState<any>;
}

class PartnerEditPage extends React.Component<Props, PartnerEditPageState> {
    constructor(props) {
        super(props);
        this.state = this.createDefaultState();
    }

    UNSAFE_componentWillMount() {
        const partnerId = this.props.partnerId;
        if ((!this.props.partner || !this.props.partner.id) && !this.isCreatePartnerPage()) {
            this.props.loadPartner(partnerId, false);
        }
    }

    componentDidMount(): void {
        if (!this.isCreatePartnerPage()) {
            this.props.loadPartner(this.props.partnerId, false);
        }
    }

    isCreatePartnerPage() {
        return this.props.partnerId === 'new';
    }

    renderPendingUpdatesDialog() {
        return (
            <InformationDialog
                message="Partner details cannot be currently edited because another edit is waiting to be posted"
                onClose={() => this.setState({ hasPendingDetailsUpdate: false })}
                title="Pending updates"
            />
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.partnerId !== this.props.partnerId) {
            this.setState(this.createDefaultState());
        }
    }

    createDefaultState() {
        const editDisabled = !this.isCreatePartnerPage();
        return {
            searchValue: '',
            activeTab: { text: PartnerTab.GeneralInformation, counter: null },
            entitlements: [],
            editDisabled: editDisabled,
            hasPendingDetailsUpdate: false,
            vendorGeneralInfo: null,
            vendorPaymentInfo: null,
            activityLogs: new PagedState<any>(),
        };
    }

    renderSidemenu() {
        const activeTab = this.activeMenuTab();
        const subtabs = [
            { text: PartnerTab.GeneralInformation, counter: 0 },
            { text: 'Beneficiary Support Users', counter: Number(this.props.partner?.requestCount || 0) },
            { text: PartnerTab.PermissionFiles, counter: 0 },
        ];

        return (
            <div>
                <EditPartnerSideMenu
                    activeTab={activeTab}
                    onSelectTab={(activeTab) => {
                        this.handleTabChange(activeTab);
                    }}
                    tabs={subtabs}
                />
            </div>
        );
    }

    handleTabChange(activeTab) {
        let path = '';
        this.setState({ activeTab: activeTab });

        switch (activeTab) {
            case PartnerTab.GeneralInformation:
                path = 'general';
                break;
            case PartnerTab.PartnerPanel:
                path = 'panel';
                break;
            case PartnerTab.PermissionFiles:
                path = 'permission-files';
                break;
        }

        this.props.navigate(`${BENEFICIARY_SUPPORT_PANEL_URL}/${this.props.partnerId}/${path}`);
    }

    renderBreadcrumbs() {
        const { partner } = this.props as any;
        return (
            <div className="row col-sm-12">
                <nav className="wfp-breadcrumbs col-sm-6">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to={BENEFICIARY_SUPPORT_PANEL_URL}>
                                <span>Beneficiary Support Panel</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">{`Edit ${
                                partner.id ? '"' + partner.id + '"' : ''
                            }`}</span>
                        </li>
                    </ol>
                </nav>
            </div>
        );
    }

    activeMenuTab(): PartnerTab {
        const supportTab = this.props.params.supportTab;
        const tab = Object.entries(urlPathForTab).find(([, v]) => v === supportTab);

        if (!tab) return;

        return tab[0] as PartnerTab;
    }

    render() {
        const { hasPendingDetailsUpdate } = this.state;
        const isEditPartner = !this.isCreatePartnerPage();

        return (
            <main>
                {this.renderBreadcrumbs()}
                {hasPendingDetailsUpdate && this.renderPendingUpdatesDialog()}
                <div className={'row'}>
                    {isEditPartner && (
                        <div className="col-sm-3">
                            <h5 style={{ marginTop: 0, marginBottom: 0 }}>
                                Beneficiary Support: {this.props.partner.name}
                            </h5>
                            {this.renderSidemenu()}
                        </div>
                    )}
                    <div className="col-sm-9" style={{ marginBottom: 150 }}>
                        <Outlet />
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        validationError: state.vendors.validationError,
        auth: state.auth,
        managerNickNameList: state.managers.managerNickNameList,
        partnerUsersList: state.partnerUsers.list,
        partnersNames: state.partners.nameList,
        partnerUsersNames: state.partners.partnerUsersNamesList,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        loadPartner: bindActionCreators(ActionCreators.loadPartner, dispatch),
        savePartner: bindActionCreators(PartnerActionCreators.savePartner, dispatch),
        requestPasswordReset: bindActionCreators(VendorActionCreators.requestPasswordReset, dispatch),
        authorizePartner: bindActionCreators(ActionCreators.authorizePartner, dispatch),
        loadManagersNickNames: bindActionCreators(ManagerActionCreators.loadManagersNickNames, dispatch),
        cancelPark: bindActionCreators(VendorActionCreators.cancelPark, dispatch),
        loadPartnersNames: bindActionCreators(ActionCreators.loadPartnersNames, dispatch),
        loadPartnerUsersNames: bindActionCreators(ActionCreators.loadPartnerUsersName, dispatch),
        redirectToPage: (url) => dispatch(routerHelperActions.makeRedirect(url)),
    };
}

const withHooks = (Component: any) => {
    return (props: any) => {
        const params = useParams();
        const { partners } = useSelector((state: State) => state);
        const navigate = useNavigate();

        const partner =
            partners.list.find((v) => v.id.toString() === params.partnerId) || new BeneficiarySupportOrganization();
        const partnerId = params.partnerId;

        if (!Boolean(params.baneSupportTab)) {
            return <Navigate to={BeneficiarySupportPanelTab.GENERAL} />;
        }

        return (
            <Component
                {...props}
                navigate={navigate}
                params={{ partnerId: params.partnerId, supportTab: params.baneSupportTab }}
                partner={partner}
                partnerId={partnerId}
            />
        );
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(PartnerEditPage));
