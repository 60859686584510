import {
    createTransactionRequest,
    exportTransactions,
    loadTransactionChain,
    loadTransactionDetails,
    loadTransactions,
} from '../apiClient';
import Action from '../action';
import { PagedState } from '../utils/paging';
import { TransactionRequestParams } from '../apiClient';
import { Filter } from '../utils/FilterTypes';
import { PaymentRequestStatus } from '../paymentRequests/paymentRequests';
import StatusTransitionReference from '../utils/statusTransitionReference';
import { CreateTransactionRequestBody } from './adjustments/adjustments';
import { closeNotificationAction, errorAction, successAction } from '../utils/notifications';
import NamedId from '../utils/NamedId';
import BigNumber from '../utils/bigNumber';
import _ from 'lodash';
import { SORT_OPTIONS } from '../utils/hooks/useSort';

export type TransactionStatus =
    | 'failed'
    | 'unknown'
    | 'verifying'
    | 'to reverse'
    | 'verification conflict'
    | 'outstanding'
    | 'adjusted'
    | 'adjustment'
    | 'paid';

export class TransactionStatuses {
    static failed: TransactionStatus = 'failed';
    static unknown: TransactionStatus = 'unknown';
    static verifying: TransactionStatus = 'verifying';
    static toReverse: TransactionStatus = 'to reverse';
    static verificationConflict: TransactionStatus = 'verification conflict';
    static outstanding: TransactionStatus = 'outstanding';
    static adjusted: TransactionStatus = 'adjusted';
    static adjustment: TransactionStatus = 'adjustment';
    static adjustableStatuses = [
        TransactionStatuses.toReverse,
        TransactionStatuses.outstanding,
        TransactionStatuses.adjustment,
    ];

    static allStatuses(): Array<TransactionStatus> {
        return [
            TransactionStatuses.failed,
            TransactionStatuses.unknown,
            TransactionStatuses.verifying,
            TransactionStatuses.toReverse,
            TransactionStatuses.verificationConflict,
            TransactionStatuses.outstanding,
            TransactionStatuses.adjusted,
            TransactionStatuses.adjustment,
        ];
    }

    static mapVendorNickNameToSender(transaction): string {
        if (transaction.vendorNickName && transaction.vendorId === transaction.senderId) {
            return transaction.vendorNickName;
        } else {
            return transaction.senderId;
        }
    }

    static mapVendorNickNameToReceiver(transaction): string {
        if (transaction.vendorNickName && transaction.vendorId === transaction.receiverId) {
            return transaction.vendorNickName;
        } else {
            return transaction.receiverId;
        }
    }

    static displayNameForStatus(status: TransactionStatus): string {
        switch (status) {
            case TransactionStatuses.unknown:
                return 'Pending';
            case TransactionStatuses.failed:
                return 'Failed';
            case TransactionStatuses.verifying:
                return 'Verifying';
            case TransactionStatuses.verificationConflict:
                return 'Verification Conflict';
            case TransactionStatuses.toReverse:
                return 'To Reverse';
            case TransactionStatuses.outstanding:
                return 'Original';
            case TransactionStatuses.adjusted:
                return 'Adjusted';
            case TransactionStatuses.adjustment:
                return 'Adjustment';
        }
        return status;
    }
}

export enum ExpirationTokenType {
    all = 'all',
    inactive = 'inactive',
    active = 'active',
    expired = 'expired',
    future = 'future',
}

export class ExpirationTokenTypes {
    static all(): ExpirationTokenType[] {
        return [ExpirationTokenType.all, ExpirationTokenType.inactive, ExpirationTokenType.active];
    }

    static displayName(type: ExpirationTokenType): string {
        const allName = 'Active and Inactive';
        switch (type) {
            case ExpirationTokenType.all:
                return allName;
            case ExpirationTokenType.active:
                return _.capitalize(ExpirationTokenType.active);
            case ExpirationTokenType.inactive:
                return _.capitalize(ExpirationTokenType.inactive);
            case ExpirationTokenType.expired:
                return _.capitalize(ExpirationTokenType.expired);
            case ExpirationTokenType.future:
                return _.capitalize(ExpirationTokenType.future);
            default:
                return allName;
        }
    }
}

export enum TransactionType {
    addVendorBalance = '12',
    reclaimBalance = '21',
    topup = '13',
    unload = '31',
    reverse = '23',
    spendBalance = '32',
}

export enum txNamesByType {
    topup = 'Load',
    unload = 'Unload',
    reverse = 'Reverse',
    spendBalance = 'Spend',
    addVendorBalance = 'Direct',
    reclaimBalance = 'Reclaim',
}

export class TransactionTypes {
    static contributingToVendorPayment = [TransactionType.spendBalance, TransactionType.reverse];

    static all(): TransactionType[] {
        return [
            TransactionType.topup,
            TransactionType.spendBalance,
            TransactionType.unload,
            TransactionType.reverse,
            TransactionType.reclaimBalance,
            TransactionType.addVendorBalance,
        ];
    }

    static displayName(type: TransactionType): string {
        switch (type) {
            case TransactionType.topup:
                return txNamesByType.topup;
            case TransactionType.unload:
                return txNamesByType.unload;
            case TransactionType.reverse:
                return txNamesByType.reverse;
            case TransactionType.spendBalance:
                return txNamesByType.spendBalance;
            case TransactionType.addVendorBalance:
                return txNamesByType.addVendorBalance;
            case TransactionType.reclaimBalance:
                return txNamesByType.reclaimBalance;
            default:
                return type;
        }
    }
}

export enum PaymentStatus {
    None = 'None',
    Excluded = 'Excluded',
    TBD = 'TBD',
    PendingReversal = 'Pending Reversal',
    Paid = 'Paid',
    Included = 'Included',
    Outstanding = 'Outstanding',
}

export class PaymantStatuses {
    static all(): PaymentStatus[] {
        return [
            PaymentStatus.None,
            PaymentStatus.Excluded,
            PaymentStatus.TBD,
            PaymentStatus.PendingReversal,
            PaymentStatus.Paid,
            PaymentStatus.Included,
            PaymentStatus.Outstanding,
        ];
    }
}

export enum TransactionVoidType {
    isVoided = 'is voided',
    voiding = 'voiding',
    failed = 'failed',
}

export interface Transaction {
    agency: string;
    id: number;
    category: string;
    amount: BigNumber;
    currency: string;
    blockchainHash: string;
    beneficiaryId?: string;
    vendorId?: string;
    vendorNickName?: string;
    stno?: string;
    personIrisId?: string;
    irisProvider?: string;
    irisConsumer?: string;
    itn?: string;
    outlet?: string;
    createdAt: Date;

    status: TransactionStatus;
    displayNameForTransactionStatus: string;
    displayNameForFinancialStatus: string;
    displayNameForAdjustmentType: string;
    statusTransitions: StatusTransitionReference<TransactionStatus>[];

    senderId: string;
    receiverId: string;
    type: TransactionType;
    pendingTransactionRequestId: string;
    originalTransactionId?: number;
    originalTransactionTime?: Date;
    source?: string;
    reason?: string;
    paymentRequest?: {
        id: string;
        status: PaymentRequestStatus;
    };
    createdByManager?: NamedId;
    authorizedByManager?: NamedId;
    alternativeCollectorId?: string;
    expirationTokenType?: ExpirationTokenType;
    voidType?: TransactionVoidType;
}

export interface TransactionChain {
    items: Transaction[];
    amount: BigNumber;
    currency: string;
}

export const TransactionsActionTypes = {
    dataLoaded: 'TransactionsPage.dataLoaded',
    detailsLoaded: 'TransactionsPage.detailsLoaded',
    beneficiaryTransactionsLoaded: 'BeneficiaryTransactionsPage.dataLoaded',
    editBeneficiaryAdjustmentTransactionsLoaded: 'EditBeneficiaryTransactions.adjustmentTransactionsDataLoaded',
    editBeneficiaryExpendituresTransactionsLoaded: 'EditBeneficiaryTransactions.expendituresTransactionsDataLoaded',
    transactionChainLoaded: 'Transactions.transactionChainLoaded',
    transactionAdjusted: 'Transactions.transactionAdjusted',
};

export const ActionCreators = {
    loadTransactions(
        timezone,
        page,
        limit = 25,
        filters: Array<Filter> = [],
        customParams: TransactionRequestParams = {},
        sortOptions: SORT_OPTIONS
    ) {
        return (dispatch) => {
            return loadTransactions(timezone, filters, page, limit, customParams, sortOptions).then((transactions) => {
                const obj = {
                    type: TransactionsActionTypes.dataLoaded,
                    payload: Object.assign(transactions, { filters }),
                };
                dispatch(obj);
                dispatch(closeNotificationAction);
            });
        };
    },

    loadTransactionChain(transactionId: string) {
        return (dispatch) => {
            return loadTransactionChain(transactionId).then((transactionChain) => {
                dispatch({
                    type: TransactionsActionTypes.transactionChainLoaded,
                    payload: transactionChain,
                });
            });
        };
    },

    adjustTransaction(body: CreateTransactionRequestBody, originalTransactionId: string) {
        return async (dispatch) => {
            const response = await createTransactionRequest(body);
            dispatch({
                type: TransactionsActionTypes.transactionAdjusted,
                payload: response,
            });
            dispatch(ActionCreators.loadTransactionChain(originalTransactionId));
            dispatch(successAction('Adjustment has been successfully parked.'));
        };
    },

    loadEditBeneficiaryAdjustmentTransactions(
        timezone: string,
        beneficiaryId: string,
        page,
        limit = 25,
        filters: Array<Filter> = [],
        sortOptions: SORT_OPTIONS
    ) {
        return (dispatch) => {
            return loadTransactions(
                timezone,
                filters,
                page,
                limit,
                {
                    beneficiaryId,
                    tab: 'adjustments',
                },
                sortOptions
            ).then((transactions) =>
                dispatch({
                    type: TransactionsActionTypes.editBeneficiaryAdjustmentTransactionsLoaded,
                    payload: Object.assign(transactions, { filters }),
                })
            );
        };
    },

    loadEditBeneficiaryExpendituresTransactions(
        timezone,
        beneficiaryId: string,
        page,
        limit = 25,
        filters: Array<Filter> = [],
        sortOptions: SORT_OPTIONS
    ) {
        return (dispatch) => {
            return loadTransactions(
                timezone,
                filters,
                page,
                limit,
                {
                    beneficiaryId,
                    tab: 'expenditures',
                },
                sortOptions
            ).then((transactions) =>
                dispatch({
                    type: TransactionsActionTypes.editBeneficiaryExpendituresTransactionsLoaded,
                    payload: Object.assign(transactions, { filters }),
                })
            );
        };
    },

    exportTransactions(
        timezone,
        columns: any[],
        filters: Array<Filter> = [],
        customParams: TransactionRequestParams = {}
    ) {
        return (dispatch) =>
            exportTransactions(timezone, columns, filters, customParams)
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                .then(() => {})
                .catch((err) => {
                    if (err.status === 422) {
                        dispatch(errorAction('Too wide transaction date range'));
                    } else if (err.status === 423) {
                        dispatch(
                            errorAction(
                                `Export task limit exceeded. Please wait until one of the scheduled exports finished`
                            )
                        );
                    }
                });
    },
    setTransactionCount(count: number) {
        return (dispatch) => {
            dispatch({
                type: 'customTxCount',
                payload: count,
            });
        };
    },
};
export const HooksActionCreators = {
    loadTransactionDetails(dispatch) {
        return (transactionId: string) => {
            loadTransactionDetails(transactionId)
                .then((details) => {
                    dispatch({
                        type: TransactionsActionTypes.detailsLoaded,
                        payload: details,
                    });
                })
                .catch(() => {
                    dispatch(errorAction('Could not load transaction details'));
                });
        };
    },
};

export function transactionsReducer(state: PagedState<Transaction> = new PagedState<Transaction>(), action: Action) {
    switch (action.type) {
        case TransactionsActionTypes.dataLoaded:
            return action.payload;
        case 'customTxCount':
    }
    return state;
}

export function transactionDetailsReducer(state: TransactionDetails[] = [], action: Action) {
    switch (action.type) {
        case TransactionsActionTypes.detailsLoaded:
            return action.payload.data;
        default:
            return state;
    }
}

export interface TransactionDetails {
    individualId?: string;
    alternativeCollectorId?: string;
    branchCode?: string;
    oid?: string;
    deviceId?: string;
    products?: string;
    authenticationMethod?: string;
    id?: string;
}

export function transactionChainReducer(state: TransactionChain[] = null, action: Action) {
    switch (action.type) {
        case TransactionsActionTypes.transactionChainLoaded:
            return action.payload;
    }
    return state;
}
