import React from 'react';
import { ChangeEvent, RefObject } from 'react';
import _ from 'lodash';
import Action from '../action';
import moment from 'moment';
import { Moment } from 'moment';
import Select from 'react-select';
import { SelectOption } from './FilterTypes';

const namedFunction = (fun: Function, name: string): Function => {
    Object.defineProperty(fun, 'name', { value: name, configurable: true });
    return fun;
};

const Validators = {
    external: (error: ExternalValidationError) => {
        const fun = (currentValue) => currentValue.toString() !== error.value.toString();
        return namedFunction(fun, _.camelCase(error.type));
    },
    required(value: any) {
        if (value === 0) {
            return true;
        }
        if (Array.isArray(value) && value.length === 0) {
            return false;
        }
        return !!value;
    },
    min(minValue: number) {
        return namedFunction(function (value: string) {
            return parseFloat(value) >= minValue;
        }, 'min');
    },
    max(maxValue: number) {
        return namedFunction(function (value: string) {
            return parseFloat(value) <= maxValue;
        }, 'max');
    },
    email(value: any) {
        return /\S+@\S+\.\S+/.test(value);
    },
    number(value: any) {
        if ((value && value.length === 0) || !value) {
            return true;
        }
        if (value < 0) {
            return false;
        }
        return value && !isNaN(value);
    },
    // maxLength(maxLength: number) {
    //     return namedFunction(function (value: string) {
    //         if (value && value.length > maxLength) {
    //             return false;
    //         }
    //         return true;
    //     }, 'maxLength');
    // },

    onlyOption(onlyOption: string) {
        return namedFunction(function (options?: any[]) {
            const onlyValues = options ? options.map((option) => option.value) : [];
            return !(onlyValues.includes(onlyOption) && onlyValues.length > 1);
        }, 'onlyOption');
    },
};

const DefaultMessages = {
    [Validators.required.name]: 'This field is required.',
    [Validators.email.name]: 'Please provide a valid e-mail address.',
    [Validators.number.name]: 'Please provide a valid number.',
    [Validators.external.name]: 'This value should be different',
    [Validators.min(0).name]: 'This value is too small',
    [Validators.max(0).name]: 'This value is too big',
    // [Validators.maxLength(2550).name]: 'This value is too long',
    [Validators.onlyOption('').name]: 'Values cannot occur together',

    uniqueViolation: 'This value is already used.',
};

export class ValidationContext {
    _inputs: any = {};
    isRegistered = false;

    isValid() {
        let result = true;
        // don't short circuit to ensure all validators are fired
        for (const name of Object.keys(this._inputs)) {
            result = this._inputs[name].isValid() && result;
        }
        return result;
    }

    register(input: Input) {
        this.isRegistered = true;
        this._inputs[input.props.name] = input;
    }

    unregister() {
        this.isRegistered = false;
    }

    pushError(externalError: ExternalValidationError) {
        const inputWithError = this._inputs[externalError.path];

        if (inputWithError) {
            inputWithError.pushError(externalError);
        }
    }

    setSubmitted() {
        for (const name of Object.keys(this._inputs)) {
            this._inputs[name].setSubmitted();
        }
    }
}

interface InputProps {
    id?: string;
    type?: string;
    name: string;
    required?: boolean;
    value?: any;
    min?: number;
    max?: number;
    step?: number | 'any';
    accept?: string;
    className?: string;
    onChange?: (event: any) => void;
    errorMessages?: any;
    hideErrorBox?: boolean;
    context?: ValidationContext;
    style?: React.CSSProperties;
    editDisabled?: boolean;
    isFocused?: boolean;
    placeholder?: string;
    checked?: boolean;
    disabled?: boolean;
    maxLength?: number;
    isFilter?: boolean;
    isModal?: boolean;
    errorMessage?: string;
    options?: SelectOption[];
    onlyOption?: string;
    inputStyle?: React.CSSProperties;
    minLength?: number;
}

class InputState {
    dirty = false;
    value: any;
}

export class Input extends React.Component<InputProps, InputState> {
    static defaultProps = {
        type: 'text',
        errorMessages: {},
    };

    formRef: RefObject<HTMLInputElement>;

    _validators: Array<Function> = [];

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = new InputState();
    }

    componentWillUnmount(): void {
        if (this.props.context) {
            this.props.context.unregister();
        }
    }

    UNSAFE_componentWillReceiveProps(props: InputProps) {
        this.setState({ value: props.value });
        this._validators = [];

        if (props.context) {
            props.context.register(this);
        }

        // const maxLength = this.props.maxLength || 255;

        // this._validators.push(Validators.maxLength(maxLength));

        if (props.type === 'number') {
            this._validators.push(Validators.number);
        }
        if (props.min !== undefined) {
            this._validators.push(Validators.min(props.min));
        }
        if (props.max !== undefined) {
            this._validators.push(Validators.max(props.max));
        }
        if (props.type === 'email') {
            this._validators.push(Validators.email);
        }

        if (props.required) {
            this._validators.push(Validators.required);
        }

        if (props.onlyOption !== undefined) {
            this._validators.push(Validators.onlyOption(props.onlyOption));
        }
    }

    pushError(error: ExternalValidationError) {
        this._validators.push(Validators.external(error));
    }

    isValid() {
        this.setState({ dirty: true }); // reflect validation state in UI
        if (this.formRef.current) {
            const isHTMLNativeCheckValid = this.formRef.current.reportValidity();
            if (!isHTMLNativeCheckValid) {
                return isHTMLNativeCheckValid;
            }
        }
        return this._validators.every((v) => v(this.state.value));
    }

    setSubmitted() {
        this.setState({ dirty: false }); // clear validation state
    }

    _handleChange(event: ChangeEvent<any>) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ dirty: true, value });
        this.props.onChange(event);
    }

    _handleDateChange(date: Moment) {
        const newDate = moment(date).format('YYYY-MM-DD');
        this.setState({ dirty: true, value: newDate });
        this.props.onChange(date);
    }

    renderWarningBox = (validationMessage: string) => {
        if (!this.props.hideErrorBox) {
            return (
                <span
                    className="error"
                    style={
                        this.props.isFilter
                            ? {
                                  position: 'absolute',
                                  right: 15,
                                  bottom: 20,
                              }
                            : this.props.isModal
                            ? {
                                  fontSize: 12,
                                  position: 'absolute',
                                  left: 20,
                                  top: 40,
                              }
                            : {}
                    }
                >
                    {validationMessage}
                </span>
            );
        }
    };

    renderSelectComponent(multi: boolean, isValid: boolean, validationClassName: string, validationMessage: string) {
        return (
            <Select
                className={this.props.className + ' ' + validationClassName}
                isMulti={multi}
                name={this.props.name}
                onChange={(e) => this.props.onChange(e)}
                options={this.props.options}
                searchable={false}
                title={this.state.dirty && !isValid ? validationMessage : ''}
                value={this.props.value}
            />
        );
    }

    renderInputField(
        inputType: string,
        isValid: boolean,
        validationClassName: string,
        validationMessage: string,
        isFocused: boolean,
        placeholder: string
    ) {
        switch (inputType) {
            case 'multiSelect':
                return this.renderSelectComponent(true, isValid, validationClassName, validationMessage);
            case 'select':
                return this.renderSelectComponent(false, isValid, validationClassName, validationMessage);
            case 'textarea':
                return (
                    <textarea
                        // accept={this.props.accept}
                        autoFocus={isFocused}
                        // checked={this.props.checked}
                        className={this.props.className + ' ' + validationClassName}
                        disabled={this.props.editDisabled || this.props.disabled}
                        id={this.props.id}
                        // max={this.props.max}
                        // min={this.props.min}
                        minLength={this.props.minLength}
                        name={this.props.name}
                        onChange={this._handleChange.bind(this)}
                        placeholder={placeholder}
                        // ref={this.formRef}
                        required={this.props.required}
                        // step={this.props.step}
                        style={this.props.inputStyle}
                        title={this.state.dirty && !isValid ? validationMessage : ''}
                        // type={this.props.type}
                        value={this.props.value}
                    />
                );
            default:
                return (
                    <input
                        accept={this.props.accept}
                        autoFocus={isFocused}
                        checked={this.props.checked}
                        className={this.props.className + ' ' + validationClassName}
                        disabled={this.props.editDisabled || this.props.disabled}
                        id={this.props.id}
                        max={this.props.max}
                        min={this.props.min}
                        minLength={this.props.minLength}
                        name={this.props.name}
                        onChange={this._handleChange.bind(this)}
                        placeholder={placeholder}
                        ref={this.formRef}
                        required={this.props.required}
                        step={this.props.step}
                        style={this.props.inputStyle}
                        title={this.state.dirty && !isValid ? validationMessage : ''}
                        type={this.props.type}
                        value={this.props.value}
                    />
                );
        }
    }

    render() {
        const failingValidators = this._validators.filter((v) => !v(this.state.value));
        const isValid = !failingValidators.length;
        const isFocused = this.props.isFocused || false;
        const placeholder = this.props.placeholder || '';
        let validationClassName = '';
        let validationMessage = '';

        if (this.state.dirty) {
            validationClassName += isValid ? 'valid' : 'invalid';
            validationMessage =
                failingValidators[0] &&
                (this.props.errorMessages[failingValidators[0].name] || DefaultMessages[failingValidators[0].name]);
        }
        return (
            <div style={this.props.style}>
                {this.renderInputField(
                    this.props.type,
                    isValid,
                    validationClassName,
                    validationMessage,
                    isFocused,
                    placeholder
                )}
                {this.state.dirty && !isValid
                    ? this.renderWarningBox(validationMessage)
                    : this.props.errorMessage
                    ? this.renderWarningBox(this.props.errorMessage)
                    : null}
            </div>
        );
    }
}

interface FormProps {
    className?: string;
    onSubmit: (event: Event) => void;
    context?: ValidationContext;
}

export class Form extends React.Component<FormProps, any> {
    _onSubmit(event: Event) {
        if (!this.props.context || this.props.context.isValid()) {
            this.props.onSubmit(event);

            if (this.props.context) {
                this.props.context.setSubmitted();
            }
        }
        event.preventDefault();
    }

    render() {
        return (
            <form className={this.props.className} onSubmit={this._onSubmit.bind(this)}>
                {this.props.children}
            </form>
        );
    }
}

interface LinkWithValidationContextProps {
    className?: string;
    onClick: (event) => void;
    context: ValidationContext;
}

export class LinkWithValidationContext extends React.Component<LinkWithValidationContextProps, any> {
    _onClick(event: Event) {
        if (!this.props.context.isRegistered) {
            this.props.onClick(event);
        } else {
            if (this.props.context.isValid()) {
                this.props.onClick(event);
                this.props.context.setSubmitted();
            }
        }
        event.preventDefault();
    }

    render() {
        return (
            <a className={this.props.className} onClick={this._onClick.bind(this)}>
                {this.props.children}
            </a>
        );
    }
}

export const FieldValidationActionType = 'Global.fieldValidation';

export function fieldValidationAction(error): Action {
    return {
        type: FieldValidationActionType,
        payload: error,
    };
}

export interface ExternalValidationError {
    path: string;
    type: string;
    value: any;
}
