import { errorAction } from './notifications';
import { throwErrorIfNotHandled } from './errorMiddleware';

export function handlePaymentRequestStatusChangeError(err, dispatch) {
    err.json()
        .then(({ errors }) => {
            if (errors) {
                dispatch(errorAction(`Failed to change payment status - ${errors}`));
            } else {
                dispatch(errorAction(`Failed to change payment status`));
            }
        })
        .catch((err) => {
            throwErrorIfNotHandled(`Failed to change payment status`)(err);
        });
}
