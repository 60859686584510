export const EditActionTypes = {
    editBeneficiaryLoadSuccess: 'editBeneficiaryLoadSuccess',
    cleanBeneficiaryData: 'EditBeneficiary.cleanBeneficiaryData',
    addComment: 'EditBeneficiary.addComment',
    statusUpdated: 'EditBeneficiary.statusUpdated',
    beneficiaryUpdatesLoaded: 'EditBeneficiary.beneficiaryUpdatesLoaded',
    beneficiaryUpdateRejected: 'EditBeneficiary.beneficiaryUpdateRejected',
    beneficiaryUpdatePosted: 'EditBeneficiary.beneficiaryUpdatePosted',
    beneficiaryActivityLogsLoaded: 'EditBeneficiary.beneficiaryActivityLogsLoaded',
};
