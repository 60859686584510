import { DateSelectFilter, SelectOption, Filter, MultiSelectFilter } from '../src/utils/FilterTypes';
import {
    PaymantStatuses,
    PaymentStatus,
    TransactionStatus,
    TransactionStatuses,
    TransactionType,
    TransactionTypes,
} from '../src/transactions/transactions';
import { CustomDateFilters, PredefinedDateFilters, stringForDateFilter } from '../src/utils/DateFilter';

export function createTransactionTypeFilter(transactionTypes: TransactionType[] = TransactionTypes.all()): Filter {
    const transactionTypeSelectOptionsMap = new Map<TransactionType, SelectOption>();
    transactionTypes.forEach((transactionType) => {
        transactionTypeSelectOptionsMap.set(
            transactionType,
            new SelectOption(TransactionTypes.displayName(transactionType), transactionType)
        );
    });

    return new MultiSelectFilter(
        'Transaction Type',
        'type',
        [],
        Array.from(transactionTypeSelectOptionsMap.values()).sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
        )
    );
}

export const PredefinedDateRanges = [
    new SelectOption('All dates', undefined),
    new SelectOption(stringForDateFilter(PredefinedDateFilters.today), PredefinedDateFilters.today),
    new SelectOption(stringForDateFilter(PredefinedDateFilters.yesterday), PredefinedDateFilters.yesterday),
    new SelectOption(stringForDateFilter(PredefinedDateFilters.thisWeek), PredefinedDateFilters.thisWeek),
    new SelectOption(stringForDateFilter(PredefinedDateFilters.lastWeek), PredefinedDateFilters.lastWeek),
    new SelectOption(stringForDateFilter(PredefinedDateFilters.thisMonth), PredefinedDateFilters.thisMonth),
    new SelectOption(stringForDateFilter(PredefinedDateFilters.lastMonth), PredefinedDateFilters.lastMonth),
    new SelectOption(stringForDateFilter(PredefinedDateFilters.thisYear), PredefinedDateFilters.thisYear),
    new SelectOption(stringForDateFilter(PredefinedDateFilters.lastYear), PredefinedDateFilters.lastYear),
    new SelectOption(stringForDateFilter(CustomDateFilters.custom), CustomDateFilters.custom),
];

export function createDateFilter(): Filter {
    return new DateSelectFilter('Date', 'createdAt', '', PredefinedDateRanges);
}

export function createTransactionStatusFilter(
    transactionStatuses: TransactionStatus[] = TransactionStatuses.allStatuses()
): Filter {
    const transactionStatusSelectOptionsMap = new Map<TransactionStatus, SelectOption>();

    transactionStatuses.forEach((transactionStatus) => {
        transactionStatusSelectOptionsMap.set(
            transactionStatus,
            new SelectOption(TransactionStatuses.displayNameForStatus(transactionStatus), transactionStatus)
        );
    });

    return new MultiSelectFilter(
        'Transaction Status',
        'status',
        [],
        Array.from(transactionStatusSelectOptionsMap.values()).sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
        )
    );
}

export function createBranchCodeFilter(): Filter {
    return new MultiSelectFilter('Outlet', 'branchCode', [], []);
}

export function createCategoryFilter(): Filter {
    return new MultiSelectFilter('Category', 'category', [], []);
}

export function createPaymentStatusFilter(paymentStatuses: PaymentStatus[] = PaymantStatuses.all()): Filter {
    const paymentStatusSelectOptionsMap = new Map<PaymentStatus, SelectOption>();
    const filterableStatuses = paymentStatuses.filter((status) => status !== PaymentStatus.Paid);
    filterableStatuses.forEach((paymentStatus) => {
        paymentStatusSelectOptionsMap.set(paymentStatus, new SelectOption(paymentStatus, paymentStatus));
    });

    return new MultiSelectFilter(
        'Payment Status',
        'payment',
        [],
        Array.from(paymentStatusSelectOptionsMap.values()).sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
        )
    );
}
