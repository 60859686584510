import React from 'react';
import { Modal } from 'react-bootstrap';
import { Form } from '../utils/inputs';

interface Props {
    onSubmit: () => Promise<any>;
    onClose: () => void;

    title: string;
    confirmTitle: string;
}

export default class ManagerDisableOtpConfirmationDialog extends React.Component<Props, null> {
    renderBody() {
        return (
            <div>
                <div className="form-group">
                    <p>Are you sure you want to deactivate your one time password ?</p>
                </div>
            </div>
        );
    }

    async _onSubmit(event) {
        event.preventDefault();
        await this.props.onSubmit();
    }

    render() {
        const { onClose, title, confirmTitle } = this.props;
        return (
            <div>
                <div className="fade modal-backdrop in" />
                <Modal.Dialog>
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Form className="mv1 wfp-form" onSubmit={this._onSubmit.bind(this)}>
                        <Modal.Body>{this.renderBody()}</Modal.Body>
                        <Modal.Footer>
                            <button className="wfp-btn" onClick={onClose} style={{ marginRight: 10 }} type="button">
                                No
                            </button>
                            <button className="wfp-btn--primary" type="submit">
                                {confirmTitle}
                            </button>
                        </Modal.Footer>
                    </Form>
                </Modal.Dialog>
            </div>
        );
    }
}
