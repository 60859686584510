import React from 'react';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import ManagerEditPersonalPage from '../ManagerEditPersonalPage';
import SecurityPage from '../../security/SecurityPage';
import ActivityLogView from '../ActivityLogView';
import { Navigate } from 'react-router-dom';
import { ManagerTabType } from '../ManagersMainPage';

export const ManagerPersonalTabRenderer: FC = () => {
    const params = useParams();
    const personalTab = params.personalTab as ManagerTabType;

    switch (personalTab) {
        case ManagerTabType.DETAILS:
            return <ManagerEditPersonalPage />;
        case ManagerTabType.SECURITY:
            return <SecurityPage />;
        case ManagerTabType.ACTIVITY_LOG:
            return <ActivityLogView />;
        default:
            return <Navigate to={'/personal/details'} />;
    }
};
