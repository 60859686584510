import React from 'react';
import { SelectFilter, Filter } from '../FilterTypes';

interface Props {
    filter: SelectFilter;
    filterChanged: (filter: Filter, event: any) => void;
    withIsSelected: boolean;
}

export default class SelectFilterField extends React.Component<Props, any> {
    checkbox(filter: SelectFilter) {
        return (
            <input
                checked={filter.isSelected}
                name={filter.name + 'Selected'}
                onChange={(e) => this.props.filterChanged(filter, e)}
                type="checkbox"
            />
        );
    }

    render() {
        const filter = this.props.filter;
        return (
            <div className="wfp-form--group" key={filter.name}>
                <label htmlFor="selection">{filter.label}:</label>
                {this.props.withIsSelected && this.checkbox(filter)}
                <select id={filter.name} onChange={(e) => this.props.filterChanged(filter, e)} value={filter.value}>
                    {filter.options.map((o) => (
                        <option key={o.label} value={o.value}>
                            {o.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
}
