import React from 'react';
import { SecurityProps } from '../security/security';
import { Input } from '../utils/inputs';
import { ProjectType } from '../app/appConfig';
import { countries } from '../utils/countries';

interface Props {
    projectType: ProjectType;
    country: countries;
    error?: any;
    otpReset?: boolean;
    security?: SecurityProps;
    onSubmit: (email: string, password: string, otp?: string, secret?: string) => any;
    onEmailRequest?: (email: string, password: string) => any;
    checkToken?: () => any;
    changeProjectType: (projectType: ProjectType) => void;
}

enum PresentedView {
    Login,
    Otp,
}

class State {
    email = '';
    password = '';
    otp = '';
    view: PresentedView = PresentedView.Login;
    emailSent = false;
}

interface FormStatus {
    allInputsClass: string;
    error: JSX.Element;
    otpError: JSX.Element;
    originalError: any;
}

export default class LoginForm extends React.Component<Props, State> {
    formSendCount = 0;

    constructor(props: Props) {
        super(props);
        this.state = new State();

        this._onSubmit = this._onSubmit.bind(this);
        this._onEmailChange = this._onEmailChange.bind(this);
        this._onPasswordChange = this._onPasswordChange.bind(this);
    }

    _onEmailChange(event) {
        this.setState({ email: event.target.value });
    }

    _onPasswordChange(event) {
        this.setState({ password: event.target.value });
    }

    _onOtpChange = (event) => {
        this.setState({ otp: event.target.value });
    };

    _onSubmit(event) {
        event.preventDefault();
        const tempOtp = this.state.view === PresentedView.Otp ? this.state.otp : undefined;
        const secret = this.props.security ? this.props.security.otpSecret : null;
        this.props.onSubmit(this.state.email, this.state.password, tempOtp, tempOtp ? secret : null).then(() => {
            if (
                (this.props.security && this.props.security.otpSecret) ||
                (this.props.error && this.props.error.status === 403)
            ) {
                this.setState({
                    view: PresentedView.Otp,
                });
                this.formSendCount++;
                this.forceUpdate();
            } else if (this.props.security && this.props.security.otpSecret) {
                this.setState({
                    view: PresentedView.Otp,
                });
                this.formSendCount++;
                this.forceUpdate();
            }
        });
    }

    _onEmailRequest(event) {
        event.preventDefault();
        this.props.onEmailRequest(this.state.email, this.state.password);
        this.setState({ emailSent: true });
    }

    formStatus(): FormStatus {
        let allInputsClass = 'form-control';
        let error: JSX.Element;
        let otpError: JSX.Element;
        const loginAttemptFailedSpan = <span className="error">Login attempt failed</span>;
        if (this.props.error) {
            if (
                this.props.error.status === 401 &&
                this.state.view === PresentedView.Login &&
                this.props.error.bodyParsed &&
                this.props.error.bodyParsed.type === 'password_reset'
            ) {
                allInputsClass += ' invalid';
                error = (
                    <span className="error">
                        Your password has been reset. Please use link from email to set new one.
                    </span>
                );
            } else if (this.props.error.status === 401 && this.state.view === PresentedView.Login) {
                allInputsClass += ' invalid';
                error = <span className="error">Login attempt failed.</span>;
            } else if ([410, 400, 404].includes(this.props.error.status)) {
                allInputsClass += ' invalid';
                error = null;
            } else if (this.props.error.status === 423) {
                allInputsClass += ' invalid';
                error = loginAttemptFailedSpan;
                otpError = loginAttemptFailedSpan;
            } else if (this.state.view === PresentedView.Otp) {
                if (this.formSendCount > 1) {
                    allInputsClass += ' invalid';
                    otpError = loginAttemptFailedSpan;
                }
            } else {
                allInputsClass += ' invalid';
                error = (
                    <span className="error">
                        {this.props.error.status}: {this.props.error.statusText}
                    </span>
                );
            }
        }
        return {
            allInputsClass,
            error,
            otpError,
            originalError: this.props.error || {},
        };
    }

    UNSAFE_componentWillReceiveProps(newProps: Props) {
        if (newProps.error && newProps.error.status === 403) {
            this.setState({ otp: '' });
        }
    }

    renderLoginForm() {
        const { allInputsClass, error } = this.formStatus();
        return (
            <div>
                <div className="form-group">
                    <label htmlFor="login_email">User ID</label>
                    <Input
                        className={allInputsClass}
                        isFocused={true}
                        name={'login_email'}
                        onChange={this._onEmailChange}
                        type="email"
                        value={this.state.email}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="login_password">Password</label>
                    <Input
                        className={allInputsClass}
                        name="login_password"
                        onChange={this._onPasswordChange}
                        type="password"
                        value={this.state.password}
                    />
                    {error}
                </div>
            </div>
        );
    }

    renderOtpForm() {
        const { allInputsClass, otpError, originalError } = this.formStatus();
        const wasEmailSent = this.props.error?.bodyParsed?.emailSent || this.state.emailSent;
        return (
            <div>
                {this.props.security.otpSecret ? (
                    <div>
                        <div className="row">
                            <div>
                                <img
                                    alt="QR Code"
                                    src={this.props.security.otpQRDataUrl}
                                    style={{ margin: '5px auto 10px' }}
                                />
                                <span style={{ textAlign: 'center' }}>
                                    <p>Secret key: {this.props.security.otpSecret}</p>
                                </span>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                ) : null}
                <div className="form-group">
                    <label htmlFor="login_otp">One Time Password</label>
                    <Input
                        className={allInputsClass}
                        isFocused={true}
                        name="login_otp"
                        onChange={this._onOtpChange}
                        type="text"
                        value={this.state.otp}
                    />
                    {otpError}
                    <br />
                    {originalError.bodyParsed && originalError.bodyParsed.otpEmail && this.props.onEmailRequest ? (
                        <a className="cursor-pointer" onClick={this._onEmailRequest.bind(this)}>
                            <small>{wasEmailSent ? 'Resend' : 'Send'} One Time Password by email</small>
                        </a>
                    ) : null}
                </div>
            </div>
        );
    }

    render() {
        const isLoginView = this.state.view === PresentedView.Login;
        const isOtpResetWithLogin = this.props.otpReset && this.props.security && !this.props.security.otpEnabled;
        const shouldShowProjectSelection = isLoginView || isOtpResetWithLogin;
        return (
            <div>
                <div>
                    <form className="mv1 wfp-form" onSubmit={this._onSubmit}>
                        {isLoginView ? this.renderLoginForm() : this.renderOtpForm()}
                        <div className="form-group pv2">
                            <Input className="wfp-btn--primary" name={'submit_button'} type="submit" value="Submit" />
                        </div>
                    </form>
                </div>
                {shouldShowProjectSelection && <div>{this.renderOtherProjectLink()}</div>}
            </div>
        );
    }

    private renderOtherProjectLink() {
        if (this.props.country === countries.BANGLADESH) {
            if (this.props.projectType === ProjectType.default) {
                return (
                    <div>
                        <span>You are logging in to the CXB environment. </span>
                        <a
                            onClick={() => {
                                this.props.changeProjectType(ProjectType.dhaka);
                            }}
                        >
                            For DHK click here
                        </a>
                    </div>
                );
            }
            if (this.props.projectType === ProjectType.dhaka) {
                return (
                    <div>
                        <span>You are logging in to the DHK environment. </span>
                        <a
                            onClick={() => {
                                this.props.changeProjectType(ProjectType.default);
                            }}
                        >
                            For CXB click here
                        </a>
                    </div>
                );
            }
        }
    }
}
