import { useCallback, useEffect, useState, MouseEvent } from 'react';
import { useToggle } from '../../utils/useToggle';
import { useDispatch, useSelector } from 'react-redux';
import { documentViewed, loadDocuments, removeDocument, updateDocumentSectionName } from '../../apiClient';
import { ActionCreators, AuthState, hasFeatureAccess } from '../../login/auth';
import { errorAction } from '../../utils/notifications';
import { FileDocument } from './types';
import { useDocumentSectionReorder } from './useDocumentSectionReorder';
import { ManagerPermission } from '../../permission-profiles/permission';
import State from '@wfp-root-app/store/state';

interface ReturnObject {
    documents: [string, FileDocument[]][];
    isModalOpen: boolean;
    selectedDocument: FileDocument;
    hasPermission: boolean;
    auth: AuthState;

    handleSave(newValue: string, oldValue: string): Promise<void>;

    handleClickCapture(document: FileDocument): (event: MouseEvent<HTMLButtonElement>) => void;

    handleDelete(document: FileDocument): Promise<void>;

    handleDownloadSuccess(documentId: number, isNew: boolean): (res: boolean) => Promise<void>;

    toggleIsModalOpen(): void;

    reorderSections(movedSection: string, targetSection: string): void;

    saveSectionOrder(): void;

    getDocuments(): Promise<void>;
}

export function useDocuments(): ReturnObject {
    const [documents, setDocuments] = useState<[string, FileDocument[]][]>([]);
    const [isModalOpen, toggleIsModalOpen] = useToggle();
    const [selectedDocument, setSelectedDocument] = useState<FileDocument>(null);
    const auth = useSelector((state: State) => state.auth);
    const getState = useSelector((state: State) => () => state);
    const dispatch = useDispatch();
    const getDocuments = useCallback(async () => {
        try {
            const documents = await loadDocuments();
            setDocuments(Object.entries(documents));
        } catch (error) {
            dispatch(errorAction('Could not load documents'));
            throw new Error(error);
        }
    }, []);

    useEffect(() => {
        getDocuments().catch((v) => v);
    }, []);
    const hasPermission = hasFeatureAccess(auth, ManagerPermission.documentManagement);

    const handleClickCapture = useCallback(
        (document: FileDocument) => (event: MouseEvent) => {
            event.stopPropagation();
            setSelectedDocument(document);
            toggleIsModalOpen();
        },
        []
    );

    const handleDelete = useCallback(async (document) => {
        try {
            await removeDocument(document.id);
            await getDocuments();
            toggleIsModalOpen();
            setSelectedDocument(null);
        } catch (error) {
            dispatch(errorAction('Could not remove document'));
        }
    }, []);

    const handleDownloadSuccess = useCallback(
        (documentId, isNew) => async (res) => {
            if (res && isNew) {
                try {
                    await documentViewed(documentId);
                    await ActionCreators.getPendingActions()(dispatch, getState);
                    await getDocuments();
                } catch (err) {}
            }
        },
        []
    );

    const handleSave = useCallback(async (value: string, oldValue: string) => {
        await updateDocumentSectionName(oldValue, value);
        await getDocuments();
    }, []);

    const sectionOrderUtils = useDocumentSectionReorder({ documents, setDocuments, getDocuments });

    return {
        documents,
        isModalOpen,
        selectedDocument,
        hasPermission,
        handleClickCapture,
        handleDelete,
        handleSave,
        toggleIsModalOpen,
        auth,
        handleDownloadSuccess,
        getDocuments,
        ...sectionOrderUtils,
    };
}
