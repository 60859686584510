import React from 'react';
import { useEffect } from 'react';
import { UploadPermissionsButton } from './UploadPermissionsButton';
import moment from 'moment';
import { DownloadPermissionsButton } from './DownloadPermissionsButton';
import NamedId from '../utils/NamedId';
import { hasOneOfPermissions } from '../login/auth';
import { useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';
import { AsyncTask } from '../utils/asyncTasks';
import { ManagerPermission } from '../permission-profiles/permission';

const columns: string[] = ['File Name', 'Date Added', 'Created by', 'Download'];

interface Props {
    userId: string | number;
    permissionsFiles: AsyncTask[];
    uploadPermissionsFile: (id: string | number, file: File) => void;
    loadUsers?: () => void;
    requiredPermission: ManagerPermission[];
}

export default function PermissionsFiles({
    userId,
    permissionsFiles,
    uploadPermissionsFile,
    loadUsers,
    requiredPermission,
}: Props) {
    const auth = useSelector((state: State) => state.auth);
    const canUploadPermissions = hasOneOfPermissions(auth, requiredPermission) && Boolean(auth.manager.id);

    useEffect(() => {
        loadUsers && loadUsers();
    }, []);

    return (
        <>
            <div className="row">
                <h6 className="col-sm-8">Permissions Files</h6>
                {canUploadPermissions && (
                    <UploadPermissionsButton userId={userId} uploadPermissionsFile={uploadPermissionsFile} />
                )}
            </div>
            <section>
                <table className="wfp-table mt4 table-hover">
                    <thead>
                        <tr>
                            {columns.map((column) => (
                                <td key={column}>
                                    <strong>{column}</strong>
                                </td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {permissionsFiles &&
                            permissionsFiles.length > 0 &&
                            permissionsFiles.map((file) => (
                                <Row
                                    createdAt={file.createdAt}
                                    createdByManager={file.createdByManager}
                                    fileName={file.fileName}
                                    hasAccess={file.hasAccess}
                                    id={file.id}
                                    key={file.id}
                                />
                            ))}
                    </tbody>
                </table>
            </section>
        </>
    );
}

interface RowProps {
    fileName: string;
    createdAt: Date;
    createdByManager: NamedId;
    id: string;
    hasAccess?: boolean;
}

function Row({ fileName, createdAt, createdByManager, id, hasAccess }: RowProps) {
    return (
        <tr>
            <td>{fileName}</td>
            <td>{moment(createdAt).format('YYYY-MM-DD')}</td>
            <td>{createdByManager && createdByManager.name}</td>
            <td>{hasAccess && <DownloadPermissionsButton asyncTaskId={id} />}</td>
        </tr>
    );
}
