import React from 'react';
import { Modal } from 'react-bootstrap';

interface TextProps {
    title: string;
    message: string | JSX.Element;
}

interface ConfirmationDialogProps extends TextProps {
    onClose: () => void;
    onConfirm: () => void;
}

const ModalMessageStyle: React.CSSProperties = {
    display: 'block',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    overflow: 'hidden',
};

function renderDialog(props: TextProps, renderButtons) {
    const { title, message } = props;
    return (
        <div>
            <div className="fade modal-backdrop in" />
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={ModalMessageStyle}>{message}</p>
                </Modal.Body>
                <Modal.Footer>{renderButtons()}</Modal.Footer>
            </Modal.Dialog>
        </div>
    );
}

export function ConfirmationDialog(props: ConfirmationDialogProps) {
    const { onClose, onConfirm } = props;
    return renderDialog(props, () => (
        <div>
            <button className="wfp-btn" onClick={onClose} style={{ marginRight: 10 }} type="button">
                Close
            </button>
            <button className="wfp-btn--primary" onClick={onConfirm} type="submit">
                Confirm
            </button>
        </div>
    ));
}

interface InformationDialogProps extends TextProps {
    title: string;
    message: string | JSX.Element;
    onClose: () => void;
}

export function InformationDialog(props: InformationDialogProps) {
    const { onClose } = props;
    return renderDialog(props, () => (
        <button className="wfp-btn" onClick={onClose} style={{ marginRight: 10 }} type="button">
            Close
        </button>
    ));
}
