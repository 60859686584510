import Action from '../../action';

export enum FileUploadListType {
    FileUpload = 'Upload Assistance',
    FamilyTaxIdMapping = 'HF to HM Tax ID Mapping',
    RemoveEntitlements = 'Remove Assistance',
    RemoveDeduplications = 'Remove UAOP',
}

export class FileUploadState {
    activeTab: FileUploadListType;

    constructor(tab: FileUploadListType) {
        this.activeTab = tab;
    }
}

export const ActionTypes = {
    changeTab: 'FileUploadPage.changeTab',
};

export const ActionCreators = {
    changeTab(tab: FileUploadListType) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.changeTab,
                payload: tab,
            });
    },
};

export function fileUploadReducer(
    state: FileUploadState = new FileUploadState(FileUploadListType.FileUpload),
    action: Action
) {
    switch (action.type) {
        case ActionTypes.changeTab:
            return new FileUploadState(action.payload);
    }
    return state;
}
