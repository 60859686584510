import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import State from '@wfp-root-app/store/state';
import { ActionCreators, ManagersProps, ExtendedManager } from './managers';
import ManagersList from './ManagersList';
import FeatureButton from '../utils/FeatureButton';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { ManagerFilters } from './ManagerFilters';
import { ManagerPermission } from '../permission-profiles/permission';

interface Props {
    auth: AuthState;
    managers: ManagersProps;
    updateOtpSecret: (string) => any;
    getManagers: (sortingColumn, sortingOrder) => void;
    authorizeManager: (managerId: string) => void;
    cancelPark: (id: string) => void;
    permissionProfilesPendingActions: number;

    sortManagers(options): void;
}

export enum sortingOrder {
    ASC = 'ASC',
    DESC = 'DESC',
}

export const sortableColumnNames = {
    organization: 'agency',
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    expirationDate: 'expirationDate',
    status: 'status',
    otp: 'otpEnabled',
    profile: 'profile',
    startDate: 'startDate',
};

class ManagersPage extends React.Component<Props, any> {
    constructor(props, context) {
        super(props, context);

        this.state = {
            otpSecret: null,
            showStartDateColumn: false,
        };
    }

    authorizeManager(manager: ExtendedManager) {
        this.props.authorizeManager(manager.id);
    }

    getSortingOrder(order) {
        return order === sortingOrder.ASC ? sortingOrder.DESC : sortingOrder.ASC;
    }

    changeSorting(column: string) {
        let order = 'ASC';
        const { sortingOptions } = this.props.managers;
        if (sortingOptions.sortingColumn === column) {
            order = this.getSortingOrder(sortingOptions.sortingOrder);
            this.props.sortManagers({ ...sortingOptions, sortingOrder: order });
        } else {
            this.props.sortManagers({
                sortingColumn: column,
                sortingOrder: sortingOrder.ASC,
            });
        }
    }

    render() {
        const { auth, managers } = this.props;
        const postEnabled = hasFeatureAccess(auth, ManagerPermission.managersManagementPost);
        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">Admin</span>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <h3 className="col-sm-4">Users</h3>
                    <div className="col-sm-8 tr">
                        <FeatureButton
                            linkTo={'/admin/new'}
                            manager={auth.manager}
                            restrictedToFeatures={[ManagerPermission.managersManagementPark]}
                            title={'Add User'}
                        />
                        <FeatureButton
                            linkTo={'/admin/download'}
                            manager={auth.manager}
                            restrictedToFeatures={[ManagerPermission.userDownload]}
                            title={'Download Users'}
                        />
                        {/* <FeatureButton
                            linkTo={'/admin/permission-profiles'}
                            manager={auth.manager}
                            restrictedToFeatures={[ManagerPermission.profilesRead]}
                            title={'Permission Profiles'}
                            counter={
                                this.props.permissionProfilesPendingActions !== 0 &&
                                this.props.permissionProfilesPendingActions.toString()
                            }
                        />*/}
                    </div>
                </div>
                <ManagerFilters
                    setShowStartDateColumn={(showColumn: boolean) =>
                        this.setState({ ...this.state, showStartDateColumn: showColumn })
                    }
                />
                {auth.manager && (
                    <ManagersList
                        authorizeManager={this.authorizeManager.bind(this)}
                        authorizedManagerId={auth.manager.id}
                        cancelPark={this.props.cancelPark.bind(this)}
                        changeSorting={this.changeSorting.bind(this)}
                        managers={managers.items}
                        postEnabled={postEnabled}
                        showStartDateColumn={this.state.showStartDateColumn}
                        sortingData={{
                            column: this.props.managers.sortingOptions.sortingColumn,
                            order: this.props.managers.sortingOptions.sortingOrder,
                        }}
                    />
                )}
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        managers: state.managers,
        auth: state.auth,
        permissionProfilesPendingActions: state.pendingActions.permissionProfilePost,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getManagers: bindActionCreators(ActionCreators.getManagers, dispatch),
        sortManagers: bindActionCreators(ActionCreators.sortManagers, dispatch),
        authorizeManager: bindActionCreators(ActionCreators.authorizeManager, dispatch),
        cancelPark: bindActionCreators(ActionCreators.cancelPark, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagersPage as any);
