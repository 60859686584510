import React, { useEffect, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import State from '@wfp-root-app/store/state';
import { NavigateHook, useNavigate } from '@wfp-common/hooks/useNavigate';

import { ActionCreators, ActionCreators as ManagerActionCreators, ExtendedManager, ManagerNickName } from './managers';
import { ExternalValidationError } from '../utils/inputs';
import { AuthState, hasFeatureAccess } from '../login/auth';
import Manager from '../managers/manager';
import { SideMenu, SideMenuProps } from '../utils/SideMenu';
import { ActionCreators as VendorActionCreators, VendorNickName, VendorUserName } from '../vendors/vendors';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { ManagerPermission } from '../permission-profiles/permission';
import { ActionCreators as PartnerActionCreators } from '../partners/partnersActions';
import { BeneficiarySupportOrganizationName, BeneficiarySupportUserName } from '../partners/partners';
import { MANAGER_URL_TABS_MAP, ManagerTab } from './models/manager-tabs.model';

interface OwnProps {
    params: { managerId: string };
    navigate: NavigateHook;
    activeTab: ManagerTab;
}

function EditManagerSideMenu(props: SideMenuProps<ManagerTab>) {
    return SideMenu(props);
}

interface Props extends OwnProps {
    getManagers: () => void;
    editManager: (prevVersion: Manager, manager: ExtendedManager) => void;
    createManager: (manager: any) => void;
    resetPasswordRequest: (manager: ExtendedManager) => void;
    resetOtpRequest: (manager: ExtendedManager) => void;
    resetOtpPasswordRequest: (manager: ExtendedManager) => void;
    getBasicActivityLog: (managerId: string) => void;
    authorizeManager: (managerId: string) => void;
    cancelPark: (id: string) => void;
    loadManagersNickNames: () => void;
    loadVendorsNickNames: (sortOptions: SORT_OPTIONS) => void;
    loadVendorUsersNames: (sortOptions: SORT_OPTIONS) => void;
    validationError?: ExternalValidationError;
    manager: ExtendedManager;
    auth: AuthState;
    managerNickNameList: Array<ManagerNickName>;
    vendorsNickNames: Array<VendorNickName>;
    vendorUsersNames: Array<VendorUserName>;
    partnersNickNames: Array<BeneficiarySupportOrganizationName>;
    partnerUsersNickNames: Array<BeneficiarySupportUserName>;
    loadPartnersNames: (sortOptions: SORT_OPTIONS) => void;
    loadPartnerUserNames: (sortOptions: SORT_OPTIONS) => void;
}

class ManagerEditPage extends React.Component<Props> {
    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        this.props.getManagers();
    }

    renderSidemenu() {
        const subtabs = [ManagerTab.GeneralInformation];
        if (hasFeatureAccess(this.props.auth, ManagerPermission.userActivityView)) {
            subtabs.push(ManagerTab.ActivityLogs);
        }
        if (hasFeatureAccess(this.props.auth, ManagerPermission.userDownload)) {
            subtabs.push(ManagerTab.DownloadUser);
        }
        if (hasFeatureAccess(this.props.auth, ManagerPermission.userDownload)) {
            subtabs.push(ManagerTab.PermissionsFiles);
        }
        return (
            <div>
                <EditManagerSideMenu
                    activeTab={this.props.activeTab}
                    onSelectTab={(activeTab) => {
                        const tab = Object.entries(MANAGER_URL_TABS_MAP).find(([, v]) => v === activeTab);

                        if (tab) {
                            this.props.navigate(`${tab[0]}`);
                        }
                    }}
                    tabs={subtabs}
                />
            </div>
        );
    }

    render() {
        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/admin">
                                <span>Admin</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">
                                {this.props.manager.id &&
                                    `${this.props.manager.firstName} ${this.props.manager.lastName}`}
                            </span>
                        </li>
                    </ol>
                </nav>

                <div className={'row'}>
                    {this.props.manager.id && (
                        <div className="col-sm-3">
                            <h5 style={{ marginTop: 0, marginBottom: 0 }}>
                                {this.props.manager.firstName} {this.props.manager.lastName}
                            </h5>
                            {this.renderSidemenu()}
                        </div>
                    )}
                    <div className="col-sm-9" style={{ marginBottom: 150 }}>
                        <h3>{!this.props.manager.id ? 'Add User' : ''}</h3>

                        <Outlet />
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        validationError: state.managers.validationError,
        auth: state.auth,
        managerNickNameList: state.managers.managerNickNameList,
        vendorsNickNames: state.vendors.nickNameList,
        vendorUsersNames: state.vendors.usersNames,
        partnersNickNames: state.partners.nameList,
        partnerUsersNickNames: state.partners.partnerUsersNamesList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getManagers: bindActionCreators(ActionCreators.getManagers, dispatch),
        editManager: bindActionCreators(ActionCreators.editManager, dispatch),
        createManager: bindActionCreators(ActionCreators.createManager, dispatch),
        resetPasswordRequest: bindActionCreators(ActionCreators.resetPasswordRequest, dispatch),
        resetOtpRequest: bindActionCreators(ActionCreators.resetOtpRequest, dispatch),
        loadManagersNickNames: bindActionCreators(ManagerActionCreators.loadManagersNickNames, dispatch),
        loadVendorsNickNames: bindActionCreators(VendorActionCreators.loadVendorsNickNames, dispatch),
        loadVendorUsersNames: bindActionCreators(VendorActionCreators.loadVendorUsersNames, dispatch),
        loadPartnersNames: bindActionCreators(PartnerActionCreators.loadPartnersNames, dispatch),
        loadPartnerUserNames: bindActionCreators(PartnerActionCreators.loadPartnerUsersName, dispatch),
        authorizeManager: bindActionCreators(ActionCreators.authorizeManager, dispatch),
        cancelPark: bindActionCreators(ActionCreators.cancelPark, dispatch),
        resetOtpPasswordRequest: bindActionCreators(ActionCreators.resetOtpPasswordRequest, dispatch),
    };
}

const withHooks = (Component: any) => {
    return (props: any) => {
        const navigate = useNavigate();
        const { managerId } = useParams();
        const manager = useSelector(
            (state: State) =>
                state.managers.items.find((manager) => manager.id.toString() === managerId) || new ExtendedManager()
        );
        const { managerTab } = useParams();
        const [activeTab, setActiveTab] = useState(MANAGER_URL_TABS_MAP.information);

        useEffect(() => {
            const tab = MANAGER_URL_TABS_MAP[managerTab];
            if (!tab) return;

            setActiveTab(tab);
        }, [managerTab]);

        return <Component {...props} activeTab={activeTab} manager={manager} navigate={navigate} params={managerId} />;
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ManagerEditPage));
