import { ReactElement, Children } from 'react';

export function recursiveChildrenPropCheck(parent: ReactElement): string {
    const isEmptyColumn = !parent;
    if (isEmptyColumn) return ' ';

    const child = parent?.props?.children;
    if (Array.isArray(child) && !parent.props.columnName) {
        throw new Error('This element should only have one child or a data-columnName attribute');
    }
    if (parent.props['columnName']) return parent.props['columnName'];

    return typeof child === 'string' ? child : recursiveChildrenPropCheck(child);
}

export function propsAreEqual(prevProps, nextProps) {
    const previousChildren = Children.map(prevProps.children, (child) => recursiveChildrenPropCheck(child));
    const futureChildren = Children.map(nextProps.children, (child) => recursiveChildrenPropCheck(child));
    let shouldNotRerender = true;
    const longerArray = previousChildren.length > futureChildren.length ? previousChildren : futureChildren;
    for (const index in longerArray) {
        if (previousChildren[index] !== futureChildren[index]) {
            shouldNotRerender = false;
            break;
        }
    }
    return shouldNotRerender;
}
