import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadDocument } from '../../apiClient';
import { errorAction } from '../../utils/notifications';
import { useToggle } from '../../utils/useToggle';
import { useNavigate } from '@wfp-common/hooks/useNavigate';

interface ReturnObject {
    values: {
        section: string;
        file: File;
        fileName: string;
    };
    uploading: boolean;

    handleChange(event: ChangeEvent<HTMLInputElement>): void;

    handleSubmit(event: FormEvent<HTMLFormElement>): void;
}

export function useDocumentUpload(): ReturnObject {
    const navigate = useNavigate();
    const [values, setValues] = useState({
        section: '',
        file: null,
        fileName: '',
    });
    const [uploading, toggleUploading] = useToggle();
    const dispatch = useDispatch();
    const handleChange = useCallback((event) => {
        event.persist();
        if (event.target.type === 'file') {
            return setValues((state) => {
                const isChanged = Boolean(state.fileName && state.fileName !== state.file?.name);
                const fileName = isChanged ? state.fileName : event.target.files[0].name;
                return {
                    ...state,
                    file: event.target.files[0],
                    fileName,
                };
            });
        }
        setValues((state) => ({ ...state, [event.target.name]: event.target.value }));
    }, []);
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!values.fileName) {
            dispatch(errorAction('File name can not be empty'));
            return;
        }

        if (!values.file) {
            dispatch(errorAction('There is no file to upload'));
            return;
        }

        try {
            toggleUploading(true);
            await uploadDocument(values);
            toggleUploading();
            navigate('/documents');
        } catch (error) {
            toggleUploading(false);
            dispatch(errorAction('Could not upload file'));
        }
    };
    return {
        values,
        handleChange,
        handleSubmit,
        uploading,
    };
}
