import React from 'react';
import moment from 'moment-timezone';

import { currencyFormatter, displayMomentAsDay } from '../utils/utils';
import { EntitlementCurrencyConfig } from '../app/appConfig';
import { DeduplicatedEntitlement } from './deduplicatedEntitlements';
import { NavigateHook, useNavigate } from '@wfp-common/hooks/useNavigate';

interface Props {
    entitlements: DeduplicatedEntitlement[];
    currency: EntitlementCurrencyConfig;
    timezone: string;
    navigate: NavigateHook;
}

class DeduplicatedEntitlementsList extends React.Component<Props, any> {
    render() {
        const formatter = currencyFormatter(this.props.currency).format;
        return (
            <table className="wfp-table--striped mt4 table-hover">
                <thead>
                    <tr>
                        <th>BB Account ID</th>
                        <th>Organization</th>
                        <th>Category</th>
                        <th>Amount</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Created</th>
                        <th>Results</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.entitlements.map((entitlement) => (
                        <tr
                            className={'cursor-pointer ' + (entitlement.removedByAsyncTaskId ? 'failed' : '')}
                            key={entitlement.id}
                            onClick={() => this._onEntitlementChosen.bind(this)(entitlement)}
                        >
                            <td>{entitlement.beneficiaryId}</td>
                            <td>{entitlement.agency}</td>
                            <td>{entitlement.category}</td>
                            <td>{entitlement.amount ? formatter(entitlement.amount) : '-'}</td>
                            <td>{displayMomentAsDay(moment(entitlement.validFrom).tz(this.props.timezone))}</td>
                            <td>{displayMomentAsDay(moment(entitlement.expiry).tz(this.props.timezone))}</td>
                            <td>{displayMomentAsDay(moment(entitlement.createdAt).tz(this.props.timezone))}</td>
                            <td>{entitlement.message}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    _onEntitlementChosen(entitlement: DeduplicatedEntitlement) {
        this.props.navigate('/entitlements/download/beneficiary/deduplicated-entitlements', {
            beneficiaryId: entitlement.beneficiaryId,
        });
    }
}

const withHooks = (Component: any) => {
    return (props: any) => {
        const navigate = useNavigate();

        return <Component navigate={navigate} {...props} />;
    };
};

export default withHooks(DeduplicatedEntitlementsList);
