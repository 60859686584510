import React from 'react';
import { ManagerInformationTab } from './tabs';
import BreadCrumbs from '../utils/BreadCrumbs';

export const AddManagerPage = () => {
    return (
        <div>
            <div>
                <BreadCrumbs path={`/admin/add-user`} />
                <h3>Add Users</h3>
            </div>

            <ManagerInformationTab />
        </div>
    );
};
