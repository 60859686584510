import React from 'react';

interface Props {
    onProlongAlert: () => void;
    onClose: () => void;
}

export default class SessionProlongAlert extends React.Component<Props, any> {
    onClose(event) {
        event.preventDefault();
        this.props.onClose();
    }

    onProlongAlert(event) {
        event.preventDefault();
        this.props.onProlongAlert();
    }

    render() {
        const style = {
            textDecoration: 'none',
            color: '#000',
        };

        return (
            <div className={'alert alert-warning'} style={{zIndex: 99999, width: '100%', marginBottom: '0'}}>
                Your session is about to expire. Do you want to prolong the session?
                <a className="alert-button" onClick={this.onClose.bind(this)} style={style}>
                    Logout
                </a>
                <a className="alert-button" onClick={this.onProlongAlert.bind(this)} style={style}>
                    Prolong
                </a>
            </div>
        );
    }
}
