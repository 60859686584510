import Action from '../action';
import {
    getManager,
    getMyself,
    getPendingActions,
    login,
    logout,
    newAuthToken,
    resetPassword,
    sendOtpCode,
} from '../apiClient';
import Manager from '../managers/manager';
import {
    ActionTypes as SessionProlongActionTypes,
    closeSessionProlongAlert,
    resetSessionProlongAlertState,
} from '../utils/sessionExpiration/sessionExpiration';
import { closeNotificationAction, errorAction, successAction } from '../utils/notifications';
import _ from 'lodash';
import { ActionTypes as SecurityActionTypes } from '../security/security';
import { AppConfigActionCreators } from '../app/appConfig';
import { removeInactivityAlertListeners } from '../utils/inactivityDetection';
import { stopHealthCheckRefresher } from '../utils/refresher';
import { ManagerPermission } from '../permission-profiles/permission';
import { windowStorage, WindowStorageToken } from '../windowStorage';
import State from '@wfp-root-app/store/state';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

export class AuthState {
    apiToken?: string;
    manager?: Manager;
    error?: any;
    resetLinkIsSent?: boolean;
    resetOtpSuccessful?: boolean;

    constructor(
        apiToken: string = null,
        manager: Manager = null,
        error: any = null,
        resetLinkIsSent?: boolean,
        resetOtpSuccessful?: boolean
    ) {
        this.apiToken = apiToken || windowStorage.getItem(WindowStorageToken.managerApiToken);
        this.resetLinkIsSent = resetLinkIsSent;
        this.resetOtpSuccessful = resetOtpSuccessful;
        this.manager = manager;
        this.error = error;
    }
}

export function hasFeatureAccess(auth: AuthState, feature: ManagerPermission) {
    if (auth.manager?.profile?.permissions) {
        return auth.manager.profile.permissions.includes(feature);
    }
    return false;
}

export function hasOneOfPermissions(auth: AuthState, features: ManagerPermission[]) {
    if (auth.manager?.profile?.permissions) {
        const commonFeatures = _.intersection(auth.manager.profile.permissions, features);
        return commonFeatures.length > 0;
    }
    return false;
}

export function doesManagerPermissionsIncludeGivenFeatures(features: ManagerPermission[], manager: Manager) {
    return manager && _.intersection(manager.profile?.permissions, features).length === features.length;
}

export const ActionTypes = {
    loginSuccessful: 'LoginPage.login',
    loginFailed: 'LoginPage.loginFailed',
    logoutSuccessful: 'LoginPage.logout',
    selfEdit: 'LoginPage.selfEdit',
    reloadUserSuccess: 'LoginPage.reloadUserSuccess',
    resetOtpRequested: 'ResetOtpPage.request',
    resetOtpSuccessful: 'ResetOtpPage.success',
    resetOtpFailed: 'ResetOtpPage.error',
    resetOtpNotification: 'ResetOtpPage.resetOtpNotification',
    loadedPendingActions: 'LoginPage.loadedPendingActions',
    loadedSelf: 'LoginPage.loadedSelf',
};

export const ActionCreators = {
    getMyself() {
        return (dispatch) => {
            return getMyself()
                .then((res) => {
                    dispatch({
                        type: ActionTypes.loadedSelf,
                        payload: res.manager,
                    });
                    dispatch(routerHelperActions.makeRedirect('/home'));
                })
                .catch(() => {
                    dispatch(logout());
                });
        };
    },
    login(
        redirectUrl: string | null,
        email: string,
        password: string,
        resetOtp: boolean,
        otp?: string,
        secret?: string
    ) {
        return async (dispatch) => {
            try {
                dispatch(closeNotificationAction);
                const response = await login(email, password, resetOtp, otp, secret);
                dispatch({
                    type: SecurityActionTypes.updateOtpData,
                    payload: response,
                });

                if (response.apiToken) {
                    windowStorage.setItem(WindowStorageToken.managerApiToken, response.apiToken);
                    dispatch({
                        type: ActionTypes.loginSuccessful,
                        payload: {
                            apiToken: windowStorage.getItem(WindowStorageToken.managerApiToken),
                            manager: response.user,
                        },
                    });
                    dispatch({
                        type: SecurityActionTypes.updateOtpData,
                        payload: response,
                    });
                    dispatch(resetSessionProlongAlertState);
                    dispatch(ActionCreators.getPendingActions());

                    if (response.user.askForNewPassword) {
                        dispatch(routerHelperActions.makeRedirect('/change-password'));
                    } else {
                        if (redirectUrl && !resetOtp) {
                            dispatch(routerHelperActions.makeRedirect(redirectUrl));
                        } else {
                            dispatch(routerHelperActions.makeRedirect('/home'));
                        }
                    }
                    dispatch(AppConfigActionCreators.loadApplicationConfig());
                } else if (response.status === 204) {
                    if (resetOtp) {
                        dispatch(successAction('OTP has been changed successfully'));
                        dispatch({
                            type: ActionTypes.resetOtpSuccessful,
                            payload: true,
                        });
                        setTimeout(function () {
                            window.location.replace(window.location.origin);
                        }, 2000);
                    } else {
                        dispatch({
                            type: ActionTypes.resetOtpNotification,
                            payload: true,
                        });
                    }
                } else if (resetOtp) {
                    dispatch({
                        type: SecurityActionTypes.updateOtpData,
                        payload: response,
                    });
                }
            } catch (error) {
                if (!!error.body) {
                    try {
                        error.bodyParsed = await error.json();
                    } catch (e) {}
                }
                if (resetOtp) {
                    if (error.status === 410) {
                        dispatch(errorAction('OTP set link expired'));
                    } else if (error.status === 404) {
                        dispatch(errorAction('Invalid manager id in token!'));
                    } else if (error.status === 400) {
                        dispatch(errorAction('Invalid token!'));
                    }
                }
                dispatch({
                    type: ActionTypes.loginFailed,
                    payload: { error },
                });
            }
        };
    },
    checkToken() {
        return (dispatch: any) => {
            dispatch({ type: ActionTypes.resetOtpRequested });
            return resetPassword('').catch(async (error) => {
                if (error.status === 404) {
                    dispatch({
                        type: ActionTypes.resetOtpFailed,
                        payload: { error: 'invalidToken' },
                    });
                }
                if (error.status === 410) {
                    dispatch({
                        type: ActionTypes.resetOtpFailed,
                        payload: { error: 'expired' },
                    });
                }
            });
        };
    },

    sendOtpCode(email: string, password: string) {
        return async (dispatch: any) => {
            try {
                await sendOtpCode(email, password);
                dispatch(successAction('Email with OTP code sent.'));
            } catch (err) {
                dispatch(errorAction('Failed to sent OTP code.'));
            }
        };
    },

    prolongSession() {
        return async (dispatch) => {
            return newAuthToken().then((response) => {
                windowStorage.setItem(WindowStorageToken.managerApiToken, response.apiToken);
                dispatch({
                    type: SessionProlongActionTypes.newToken,
                    payload: {
                        apiToken: windowStorage.getItem(WindowStorageToken.managerApiToken),
                    },
                });
                dispatch(resetSessionProlongAlertState);
                dispatch(successAction('The session has been successfully prolonged.'));
            });
        };
    },

    logout(url?: string) {
        return async (dispatch: any) => {
            try {
                await logout();
            } catch (e) {}
            removeInactivityAlertListeners();
            stopHealthCheckRefresher();

            windowStorage.removeItem(WindowStorageToken.managerApiToken);
            dispatch({ type: ActionTypes.logoutSuccessful });
            dispatch({
                type: SecurityActionTypes.updateOtpData,
                payload: {},
            });
            dispatch(resetSessionProlongAlertState);
            dispatch(routerHelperActions.makeRedirect(typeof url === 'string' ? url : '/login'));
        };
    },
    reloadUserData() {
        return (dispatch, getState: () => State) => {
            const manager = getState().auth.manager;
            if (manager) {
                return getManager(manager.id).then((manager) => {
                    dispatch({
                        type: ActionTypes.reloadUserSuccess,
                        payload: { manager },
                    });
                });
            }
        };
    },
    getPendingActions() {
        return (dispatch, getState: () => State) =>
            getPendingActions().then((pendingActions) => {
                if (getState().auth.manager) {
                    const permissions = getState().auth.manager.profile?.permissions;
                    dispatch({
                        type: ActionTypes.loadedPendingActions,
                        payload: Object.assign({}, { pendingActions }, { permissions }),
                    });
                }
            });
    },
    endSession() {
        return (dispatch) => {
            dispatch(closeSessionProlongAlert);
            dispatch(ActionCreators.logout());
        };
    },
};

export function authReducer(state: AuthState = new AuthState(), action: Action) {
    switch (action.type) {
        case ActionTypes.loginSuccessful:
            return new AuthState(action.payload.apiToken, action.payload.manager);
        case ActionTypes.loginFailed:
            return new AuthState(null, null, action.payload.error);
        case ActionTypes.logoutSuccessful:
            return new AuthState();
        case SessionProlongActionTypes.newToken:
            return new AuthState(action.payload.apiToken, state.manager);
        case ActionTypes.selfEdit:
            return new AuthState(state.apiToken, action.payload.manager);
        case ActionTypes.reloadUserSuccess:
            return new AuthState(state.apiToken, action.payload.manager);
        case ActionTypes.resetOtpRequested:
            return new AuthState();
        case ActionTypes.resetOtpFailed:
            return new AuthState();
        case ActionTypes.resetOtpNotification:
            return new AuthState(null, null, null, action.payload);
        case ActionTypes.resetOtpSuccessful:
            return new AuthState(null, null, null, null, true);
        case ActionTypes.loadedSelf:
            return new AuthState(state.apiToken, action.payload);
    }

    return state;
}
