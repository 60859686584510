import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';

import ErrorPage from '../../app/ErrorPage';
import PartnerDetails from '../PartnerDetails';
import { hasFeatureAccess } from '../../login/auth';
import {
    ActionCreators as PartnerActionCreators,
    BeneficiarySupportOrganization,
    BeneficiarySupportOrganizationGeneralInfo,
    updatePartner,
} from '../partners';
import { ExternalValidationError } from '../../utils/inputs';
import { ManagerPermission } from '../../permission-profiles/permission';
import { ActionCreators } from '../partnersActions';
import { PartnerUsersList } from '../PartnerUsersList';
import { PartnerUserAddPage } from '../PartnerUserAddPage';
import { PartnerUserDetails } from '../PartnerUserDetails';
import { PartnerPermissionsFiles } from '../PartnerPermissionsFiles';

export enum BeneficiarySupportPanelTab {
    GENERAL = 'general',
    SUPPORT_USERS = 'panel',
    PERMISSION_FILES = 'permission-files',
}

export const BeneficiarySupportPanelTabRenderer = () => {
    const { partners } = useSelector((state: State) => state);
    const params = useParams();
    const location = useLocation();
    const partnerId = params.partnerId;
    const partner =
        partners.list.find((v) => v.id.toString() === params.partnerId) || new BeneficiarySupportOrganization();

    const { auth, vendors } = useSelector((state: State) => state);
    const state = useSelector((state: State) => state);
    const partnerGeneralInfo = new BeneficiarySupportOrganizationGeneralInfo(partner);
    const validationError: ExternalValidationError = vendors.validationError;
    const parkEnabled = hasFeatureAccess(auth, ManagerPermission.partnerPark);
    const postEnabled = hasFeatureAccess(auth, ManagerPermission.partnerPost);
    const managerId = auth && auth.manager ? auth.manager.id : null;
    const isNewPartner = partnerId === 'new';
    const dispatch = useDispatch();

    const savePartnerData = (data: BeneficiarySupportOrganization) => {
        const oldPartner = partner;
        const updatedPartner = updatePartner(oldPartner, data);

        const actionRef = PartnerActionCreators.savePartner(updatedPartner);

        actionRef(dispatch, () => state);
    };

    const authorizePartner = (id: string) => {
        const action = ActionCreators.authorizePartner(id);
        action(dispatch);
    };

    const cancelPark = (id: string) => {};

    switch (params.baneSupportTab as BeneficiarySupportPanelTab) {
        case BeneficiarySupportPanelTab.GENERAL:
            return (
                <PartnerDetails
                    authState={auth}
                    authorize={authorizePartner}
                    cancelPark={cancelPark}
                    isNewPartner={isNewPartner}
                    managerId={managerId}
                    onSaved={savePartnerData}
                    params={{ partnerId: params.partnerId }}
                    parkEnabled={parkEnabled}
                    partnerGeneralInfo={partnerGeneralInfo}
                    postEnabled={postEnabled}
                    validationError={validationError}
                />
            );
        case BeneficiarySupportPanelTab.SUPPORT_USERS:
            // Due to routing refactor the possible urls are as follows: /panel or /panel/add-user or panel/[id]
            switch (params.supportTabSubView) {
                case 'add-user':
                    return (
                        <PartnerUserAddPage
                            params={{
                                partnerId: params.partnerId,
                            }}
                        />
                    );
                case undefined:
                    return (
                        <PartnerUsersList
                            location={location}
                            params={{
                                partnerId: params.partnerId,
                                supportTabSubView: params.supportTabSubView,
                            }}
                        />
                    );
                default:
                    return (
                        <PartnerUserDetails
                            authState={auth}
                            params={{
                                partnerId: params.partnerId,
                                partnerUserId: params.supportTabSubView,
                            }}
                        />
                    );
            }
        case BeneficiarySupportPanelTab.PERMISSION_FILES:
            return <PartnerPermissionsFiles partner={partnerGeneralInfo} />;
        default:
            return <ErrorPage />;
    }
};
