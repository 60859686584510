import React from 'react';
import { Input } from '../utils/inputs';
import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

export type ResetPasswordFormProps = {
    onSubmit: (oldPassword: string, newPassword: string) => void;
    ownPassword: boolean;
    onClose: () => void;
};

const ErrorMessages = {
    usedPassword: 'This password was used before. Please use different password.',
    unknown: 'Unknown error',
    tooShort: 'Password should have minimum 12 characters',
    tooLong: 'Password should have maximum 72 characters',
    shouldHaveUppercase: 'Password should have at least one uppercase letter',
    shouldHaveLowercase: 'Password should have at least one lowercase letter',
    shouldHaveNumber: 'Password should have at least one number',
    shouldHaveSpecialCharacter:
        'Password should have at least one special character (~!@#$%^&*_-+=`|(){}[]:;"\'<>,.?/)',
    shouldNotHaveWFP: 'Password should not have any variation of WFP acronym',
    shouldNotHaveBB: 'Password should not have any variation of Building Blocks',
    passwordsDoNotMatch: 'Passwords do not match',
    invalidOtp: 'Invalid one time password.',
    incorrectOldPassword: 'Old password is incorrect',
};

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onClose, onSubmit, ownPassword }) => {
    const [newPassword, setNewPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

    const [oldPasswordError, setOldPasswordError] = useState<string | null>(null);
    const [newPasswordError, setNewPasswordError] = useState<string | null>(null);
    const [newPasswordRepeatError, setNewPasswordRepeatError] = useState<string | null>(null);

    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    useEffect(() => {
        let isOldPasswordValid = false;
        if (oldPassword.length) {
            const validateResult = validateOldPassword();
            isOldPasswordValid = !validateResult;
            setOldPasswordError(validateResult);
        }

        let isNewPasswordValid = false;
        if (newPassword.length) {
            const validateResult = validate();
            isNewPasswordValid = !validateResult;
            setNewPasswordError(validateResult);
        }

        let isNewPasswordRepeatValid = false;
        if (newPasswordRepeat.length) {
            if (newPassword != newPasswordRepeat) {
                const validateResult = ErrorMessages['passwordsDoNotMatch'];
                isNewPasswordRepeatValid = false;
                setNewPasswordRepeatError(validateResult);
            } else {
                isNewPasswordRepeatValid = true;
                setNewPasswordRepeatError(null);
            }
        }

        setIsFormValid(isOldPasswordValid && isNewPasswordValid && isNewPasswordRepeatValid);
    }, [oldPassword, newPassword, newPasswordRepeat]);

    const validate = (): string | null => {
        if (newPassword.length < 12) {
            return ErrorMessages['tooShort'];
        } else if (newPassword.length > 72) {
            //bcrypt restrictions
            return ErrorMessages['tooLong'];
        } else if (!/[A-Z]/.test(newPassword)) {
            return ErrorMessages['shouldHaveUppercase'];
        } else if (!/[a-z]/.test(newPassword)) {
            return ErrorMessages['shouldHaveLowercase'];
        } else if (!/[0-9]/.test(newPassword)) {
            return ErrorMessages['shouldHaveNumber'];
        } else if (!/[\~\!\@\#\$\%\^\&\*\_\-\+\=\`\|\(\)\{\}\[\]\:\;\"\'\<\>\,\.\?\/]/.test(newPassword)) {
            return ErrorMessages['shouldHaveSpecialCharacter'];
        } else if (/W(orld)?([ ]?)F(ood)?([ ]?)P(rogramme)?/i.test(newPassword)) {
            return ErrorMessages['shouldNotHaveWFP'];
        } else if (/B(uilding)?([ ]?)B(locks)?/i.test(newPassword)) {
            return ErrorMessages['shouldNotHaveBB'];
        }

        return null;
    };

    const validateOldPassword = (): string | null => {
        if (oldPassword.length < 12) {
            return ErrorMessages['tooShort'];
        } else if (newPassword.length > 72) {
            return ErrorMessages['tooLong'];
        } else {
            return null;
        }
    };

    const renderResetPasswordForm = (oldPasswordRequired: boolean) => {
        return (
            <div>
                {oldPasswordRequired && (
                    <div className="form-group">
                        <label htmlFor="password">Old password</label>
                        <Input
                            className="form-control"
                            isFocused={true}
                            name="old_password"
                            onChange={(event) => setOldPassword(event.target.value.trim())}
                            type="password"
                            value={oldPassword}
                        />
                        {oldPasswordError && <span className="error">{oldPasswordError}</span>}
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="password">New password</label>
                    <Input
                        className="form-control"
                        isFocused={!oldPasswordRequired}
                        name="password"
                        onChange={(event) => setNewPassword(event.target.value.trim())}
                        type="password"
                        value={newPassword}
                    />
                    {newPasswordError && <span className="error">{newPasswordError}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="password_repeat">Repeat new password</label>
                    <Input
                        className={classNames('form-control', { invalid: newPasswordRepeatError })}
                        name="password_repeat"
                        onChange={(event) => setNewPasswordRepeat(event.target.value.trim())}
                        type="password"
                        value={newPasswordRepeat}
                    />
                    {newPasswordRepeatError && <span className="error">{newPasswordRepeatError}</span>}
                </div>
            </div>
        );
    };

    const handleSubmit = () => {
        onSubmit(oldPassword, newPassword);
    };

    return (
        <div>
            <Modal.Body>{renderResetPasswordForm(ownPassword)}</Modal.Body>
            <Modal.Footer>
                <button className="wfp-btn--primary" disabled={!isFormValid} onClick={handleSubmit} type="submit">
                    Submit
                </button>
                <button className="wfp-btn" onClick={onClose} style={{ marginRight: 10 }} type="button">
                    Close
                </button>
            </Modal.Footer>
        </div>
    );
};

export default ResetPasswordForm;
