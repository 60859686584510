import React from 'react';
import { Calendar } from 'react-date-range';
import { Input } from './inputs';
import { CSSProperties, useCallback, useState } from 'react';
import moment from 'moment';
import { useUpdateEffect } from './useUpdateEffect';

interface Props {
    initialValue?: Date;
    onChange?: (data: { name: string; value: string }) => void;
    name?: string;
    label?: string;
    minDate?: Date;
    maxDate?: Date;
    disabled?: boolean;
    value?: Date;
}

const style: CSSProperties = {
    position: 'absolute',
    border: '1px solid rgba(0,0,0,0.125)',
    top: '100%',
    left: '50%',
    background: '#fff',
    boxSizing: 'border-box',
    zIndex: 1,
    transform: 'translate3d(-50%, 0, 0)',
};
const defaultValue = formatDate(new Date());
export const DatePicker: React.FC<Props> = function DatePicker({
    initialValue,
    onChange,
    name,
    label,
    minDate,
    maxDate,
    disabled,
    value: _value,
}) {
    const [value, setValue] = useState<string | Date>(formatDate(initialValue) || defaultValue);
    const [isVisible, setIsVisible] = useState(false);
    const handleChange = useCallback(
        (date) => {
            if (disabled) return;
            const formattedDate = moment(date).format('YYYY-MM-DD');
            if (onChange) {
                onChange({ value: formattedDate, name: name || 'datePicker' });
            }
            setValue(formattedDate);
            toggleCalendar();
        },
        [disabled]
    );
    useUpdateEffect(() => {
        if (_value) {
            setValue(formatDate(_value));
        }
    }, [_value]);
    const toggleCalendar = useCallback(() => {
        if (disabled) return;
        setIsVisible((state) => !state);
    }, [disabled]);
    return (
        <div style={{ position: 'relative' }}>
            <InputWrapper
                disabled={disabled ?? false}
                label={label}
                name={name || 'datePicker'}
                onClick={toggleCalendar}
                value={value}
            />
            {isVisible && (
                <div style={style}>
                    <Calendar
                        className="rdrDateRangePickerWrapper"
                        maxDate={new Date(maxDate)}
                        minDate={minDate ? new Date(minDate) : new Date()}
                        onChange={handleChange}
                    />
                    <div
                        className="wfp-form--actions text-center"
                        style={{ borderTop: '1px solid rgba(0,0,0,0.125)', padding: '5px 0' }}
                    >
                        <button className="wfp-btn--primary" onClick={toggleCalendar} type="button">
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

function InputWrapper({ disabled, value, name, label, onClick }) {
    return (
        <div className="wfp-form--group row" onClick={onClick}>
            {label && (
                <label className="col-sm-2 ta-right" htmlFor={name}>
                    {label}
                </label>
            )}
            <div className="col-sm-6">
                <Input
                    className="form-control"
                    disabled={disabled}
                    inputStyle={{
                        cursor: disabled ? 'not-allowed' : 'default',
                        textShadow: '0 0 0 black',
                        color: 'transparent',
                    }}
                    name={name}
                    value={value}
                />
            </div>
        </div>
    );
}

function formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
}
