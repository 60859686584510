import clonedeep from 'lodash/cloneDeep';
import State from '@wfp-root-app/store/state';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

import { savePartner, loadBeneficiarySupportOrganizations } from '../apiClient';
import { successAction, errorAction } from '../utils/notifications';
import NamedId from '../utils/NamedId';
import { EntityUpdateReference } from '../authorization/taskAuthorizations';
import hasNewFieldsChanges from '../utils/hasNewFieldsChanges';
import { ActionTypes } from './partnersActions';
import { ExternalValidationError } from '../utils/inputs';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { AsyncTask } from '../utils/asyncTasks';
import { BENEFICIARY_SUPPORT_PANEL_URL } from './utils/constants';

export class BeneficiarySupportOrganization {
    id = '';
    name = '';
    sublocation = '';
    city = '';
    country = '';
    phoneNumber = '';
    email?: string;
    authorizedAt?: Date;
    createdAt?: Date;
    cancelledAt?: Date;
    createdByManager: NamedId;
    cancelledByManager: NamedId;
    authorizedByManager?: NamedId;
    pendingEntityUpdates?: EntityUpdateReference[];
    status: string;
    requestCount = '';
    isPasswordBlocked?: boolean;
    permissionFiles: Array<AsyncTask> = [];
}

export class BeneficiarySupportUser {
    id = '';
    name = '';
    firstName = '';
    lastName = '';
    sublocation = '';
    city = '';
    country = '';
    phoneNumber = '';
    email = '';
    blockchainAddress = '';
    authorizedAt?: Date;
    createdByManagerId: string;
    cancelledByManagerId: string;
    authorizedByManager?: NamedId;
    pendingEntityUpdates?: EntityUpdateReference[];
    panelAccessStatus: string;
    status: string;
    isPasswordBlocked?: boolean;
}

export class BeneficiarySupportOrganizationGeneralInfo {
    id: string;
    name: string;
    email: string;
    sublocation: string;
    city: string;
    country: string;
    authorizedAt: Date;
    createdAt?: Date;
    cancelledAt?: Date;
    createdByManager: NamedId;
    cancelledByManager: NamedId;
    authorizedByManager: NamedId;
    status: string;
    pendingEntityUpdates?: EntityUpdateReference[];
    phoneNumber: string;
    requestCount: string;
    permissionFiles: Array<AsyncTask> = [];

    constructor(partner: BeneficiarySupportOrganization = new BeneficiarySupportOrganization()) {
        this.id = partner.id || '';
        this.name = partner.name || '';
        this.sublocation = partner.sublocation || '';
        this.city = partner.city || '';
        this.country = partner.country || '';
        this.createdByManager = partner.createdByManager;
        this.authorizedAt = partner.authorizedAt;
        this.authorizedByManager = partner.authorizedByManager;
        this.cancelledByManager = partner.cancelledByManager;
        this.pendingEntityUpdates = partner.pendingEntityUpdates;
        this.status = partner.status;
        this.phoneNumber = partner.phoneNumber;
        this.createdAt = partner.createdAt;
        this.email = partner.email || '';
        this.permissionFiles = partner.permissionFiles;
    }
}

export class BeneficiarySupportOrganizationName {
    id = '';
    name = '';
    authorizedAt: Date;
}

export class BeneficiarySupportUserName {
    id: string;
    firstName: string;
    lastName: string;
    authorizedAt: Date;
}

export class BeneficiarySupportOrganizationsState {
    list: Array<BeneficiarySupportOrganization> = [];
    parkedUsers = [];
    partnerUser: BeneficiarySupportUser;
    validationError?: ExternalValidationError = null;
    nameList: Array<BeneficiarySupportOrganizationName> = [];
    partnerUsersNamesList: Array<BeneficiarySupportUserName> = [];

    constructor(
        list: Array<BeneficiarySupportOrganization> = [],
        parkedUsers = [],
        partnerUser: BeneficiarySupportUser = null,
        nameList: Array<BeneficiarySupportOrganizationName> = []
    ) {
        this.list = list;
        this.parkedUsers = parkedUsers;
        this.partnerUser = partnerUser;
        this.nameList = nameList || [];
    }
}

export const PartnerStatuses = {
    parked: 'parked',
    active: 'active',
    cancelled: 'cancelled',
    blocked: 'blocked',
    expired: 'expired',
};

export const defaultPartnerSortOptions: SORT_OPTIONS = {
    'orderBy:column': 'name',
    'orderBy:direction': 'ASC',
};

export const defaultPartnerUserSortOptions: SORT_OPTIONS = {
    'orderBy:column': 'firstName',
    'orderBy:direction': 'ASC',
};

export function updatePartner(oldPartner: BeneficiarySupportOrganization, data: any): BeneficiarySupportOrganization {
    return {
        id: data.id || oldPartner.id,
        name: data.name || oldPartner.name,
        sublocation: data.sublocation || oldPartner.sublocation,
        city: data.city || oldPartner.city,
        country: data.country || oldPartner.country,
        phoneNumber: data.phoneNumber || oldPartner.phoneNumber,
        email: data.email || oldPartner.email,
        createdByManager: oldPartner.cancelledByManager,
        cancelledByManager: oldPartner.cancelledByManager,
        authorizedAt: oldPartner.authorizedAt,
        authorizedByManager: oldPartner.authorizedByManager,
        pendingEntityUpdates: oldPartner.pendingEntityUpdates,
        status: data.status || oldPartner.status,
        requestCount: data.requestCount || oldPartner.requestCount,
        permissionFiles: data.permissionFiles || oldPartner.permissionFiles,
    };
}

function createPartnerWithoutForbiddenEditedKeys(
    partner: BeneficiarySupportOrganization
): BeneficiarySupportOrganization {
    const validPartner = clonedeep(partner);
    delete validPartner.authorizedAt;
    delete validPartner.authorizedByManager;
    delete validPartner.createdByManager;
    return validPartner;
}

export const ActionCreators = {
    loadPartners(sortOptions: SORT_OPTIONS) {
        return (dispatch) =>
            loadBeneficiarySupportOrganizations(sortOptions).then((partners) => {
                dispatch({
                    type: ActionTypes.dataLoaded,
                    payload: partners,
                });
            });
    },
    savePartner(partner: BeneficiarySupportOrganization) {
        const validPartner = createPartnerWithoutForbiddenEditedKeys(partner);
        return (dispatch, getState: () => State) => {
            const oldPartnerData = getState().partners.list.find((v) => v.id === partner.id);
            const noChangesToUpdate = oldPartnerData && !hasNewFieldsChanges(oldPartnerData, partner);
            if (noChangesToUpdate) {
                dispatch(errorAction('There are no changes to be updated'));
                return;
            }
            return savePartner(validPartner).then((res) => {
                if (res === 409) {
                    dispatch(errorAction('A partner with the same name already exists.'));
                } else if (res === 400) {
                    dispatch(errorAction('Data Validation Error, please check if you you send proper data'));
                } else {
                    dispatch(routerHelperActions.makeRedirect(BENEFICIARY_SUPPORT_PANEL_URL));
                    dispatch(successAction('Beneficiary Support saved.'));
                }
            });
        };
    },
};
