import React from 'react';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ManagerTabUrl } from '../models/manager-tabs.model';
import { ManagerActivityTab, ManagerDownloadUserTab, ManagerInformationTab, ManagerPermissionsTab } from './index';
import ErrorPage from '../../app/ErrorPage';

export const ManagerTabRenderer: FC = () => {
    const { managerTab } = useParams();
    const activeTab = managerTab as ManagerTabUrl;

    switch (activeTab) {
        case 'information':
            return <ManagerInformationTab />;
        case 'activity':
            return <ManagerActivityTab />;
        case 'download':
            return <ManagerDownloadUserTab />;
        case 'permissions':
            return <ManagerPermissionsTab />;
        default:
            return <ErrorPage />;
    }
};
