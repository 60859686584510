import React from 'react';
import { FC } from 'react';

export const ErrorPage: FC = () => (
    <main>
        <h3>Error</h3>
        <p>Page not found</p>
    </main>
);

export default ErrorPage;
