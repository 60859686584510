import React from 'react';
import { Filter } from './FilterTypes';

export class PagingResponse {
    offset: number;
    limit: number;
    totalAtLeast: number;
    total?: number;

    constructor(offset: number, limit: number, totalAtLeast: number, total?: number) {
        this.offset = offset;
        this.limit = limit;
        this.totalAtLeast = totalAtLeast;
        this.total = total;
    }
}

export class PagedState<T> {
    paging: PagingResponse;
    items: Array<T>;
    filters: Array<Filter>;

    constructor() {
        this.items = [];
        this.paging = null;
        this.filters = [];
    }

    configureFor204Response(limit, filters: Array<Filter>) {
        this.paging = new PagingResponse(0, limit, 0, 0);
        this.filters = filters;
        return this;
    }
}

interface Props {
    onPageChanged: (page) => void;
    paging: PagingResponse;
}

export default class PagingComponent extends React.Component<Props, any> {
    constructor(props) {
        super(props);
    }

    /***
     *
     * @returns number of all pages if total provided, otherwise -1
     */
    numberOfPages(): number {
        if (!this.props.paging.total) {
            return -1;
        }
        return Math.ceil(this.props.paging.total / this.props.paging.limit);
    }

    /***
     *
     * @returns number of all known pages
     */
    numberOfKnownPages(): number {
        return Math.ceil(this.props.paging.totalAtLeast / this.props.paging.limit);
    }

    currentPage(): number {
        return this.props.paging.offset / this.props.paging.limit + 1;
    }

    previousClicked() {
        this.props.onPageChanged(this.currentPage() - 1);
    }

    nextClicked() {
        this.props.onPageChanged(this.currentPage() + 1);
    }

    setPageClicked(page) {
        this.props.onPageChanged(page);
    }

    render() {
        if (!this.props.paging) {
            return <div />;
        }

        const pages = [];
        let iPage = 1;
        const currentPage = this.currentPage();
        const isFirstPage = this.props.paging.offset === 0;
        const isLastPage = this.props.paging.offset + this.props.paging.limit >= this.props.paging.totalAtLeast;
        const numberOfAllPages = this.numberOfPages();
        const numberOfPages = numberOfAllPages === -1 ? this.numberOfKnownPages() : numberOfAllPages;

        if (numberOfPages === 1) {
            return <div />;
        }

        while (iPage <= numberOfPages) {
            const isActive = iPage === currentPage;
            pages.push({
                pageNumber: iPage,
                isActive: isActive,
            });
            if (iPage > currentPage + 1) {
                break;
            }
            iPage++;
        }
        const lastIPage = pages.length;

        return (
            <div className="wfp-pagination">
                <ol className="pagination--wrapper">
                    {!isFirstPage && (
                        <li className="pagination--item">
                            <a className="pagination--btn" onClick={this.previousClicked.bind(this)}>
                                Previous
                            </a>
                        </li>
                    )}
                    {pages.map((page) => {
                        let buttonClass = 'pagination--item';
                        if (page.isActive) {
                            buttonClass += ' active';
                        }
                        return (
                            <li className={buttonClass} key={'page' + page.pageNumber}>
                                <a
                                    className="pagination--btn"
                                    onClick={() => this.setPageClicked.bind(this)(page.pageNumber)}
                                >
                                    {page.pageNumber}
                                </a>
                            </li>
                        );
                    })}
                    {lastIPage < numberOfPages && (
                        <li className="pagination--item ellipsis">
                            <span className="pagination--btn">&hellip;</span>
                        </li>
                    )}
                    {!isLastPage && (
                        <li className="pagination--item">
                            <a className="pagination--btn" onClick={this.nextClicked.bind(this)}>
                                Next
                            </a>
                        </li>
                    )}
                </ol>
            </div>
        );
    }
}
