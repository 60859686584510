import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PagedState } from '../../utils/paging';
import { RefreshSignaler } from '../../utils/refresher';
import AsyncTasksListExportView from '../../utils/AsyncTasksListExportView';
import { ActionCreators as AsyncActionCreators, AsyncTasksTypes, AsyncTask } from '../../utils/asyncTasks';
import State from '@wfp-root-app/store/state';

interface Props {
    exports: PagedState<AsyncTask>;
    entitlementTotalCount?: number;
    downloadFile: (type: string, id: string) => string;
    loadAsyncTasksList: (page: number, limit: number, type: string) => Promise<RefreshSignaler>;
    exportEntitlements: (timezone: string, mapping: Array<{ key; header }>) => Promise<any>;
    authorizeTask: (type: string, id: string) => Promise<any>;
    appendAsyncTasksList: (limit: number, type: string) => void;
    managerId: string;
    timezone: string;
}

interface LocalState {
    selectedColumns: any;
    selectAll: boolean;
}

class ExportedFromStatistics extends React.Component<Props, LocalState> {
    private defaultPageSize = 10;
    private refresher?: RefreshSignaler;

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedColumns: {
                id: true,
                householdId: true,
            },
            selectAll: false,
        };
    }

    async changePageRequested() {
        this.props.appendAsyncTasksList(this.defaultPageSize, AsyncTasksTypes.EXPORT_BENEFICIARIES_FROM_STATS);
    }

    async UNSAFE_componentWillMount() {
        this.refresher = await this.props.loadAsyncTasksList(
            1,
            this.defaultPageSize,
            AsyncTasksTypes.EXPORT_BENEFICIARIES_FROM_STATS
        );
    }

    componentWillUnmount() {
        if (this.refresher) {
            this.refresher.stop();
        }
    }

    renderExportFeatureDescription() {
        return (
            <div>
                <p>
                    This page contains historical exports. To trigger new statistics export, click on specific columns.
                </p>
            </div>
        );
    }

    render() {
        const title = 'Download exported statistics';

        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home-basic">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">{title}</span>
                        </li>
                    </ol>
                </nav>
                <h3>{title}</h3>
                {this.renderExportFeatureDescription()}
                {this.props.exports && this.props.exports.items.length > 0 && (
                    <div>
                        <AsyncTasksListExportView
                            authorize={this.props.authorizeTask.bind(this)}
                            authorizedManagerId={this.props.managerId}
                            canDownload={true}
                            data={this.props.exports.items}
                            downloadFile={this.props.downloadFile.bind(this)}
                        />
                        <div className="wfp-form--actions">
                            <button
                                className="wfp-btn--primary"
                                disabled={this.props.exports.items.length === this.props.exports.paging.total}
                                onClick={this.changePageRequested.bind(this)}
                                type="button"
                            >
                                More
                            </button>
                        </div>
                    </div>
                )}
            </main>
        );
    }

    _handleCheck(event) {
        const key = event.target.name;
        const value = event.target.checked;

        this.setState((prevState) => {
            const selectedColumns = prevState.selectedColumns;
            selectedColumns[key] = value;
            return { selectedColumns };
        });
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        loadAsyncTasksList: bindActionCreators(AsyncActionCreators.loadAsyncTasksList, dispatch),
        appendAsyncTasksList: bindActionCreators(AsyncActionCreators.appendAsyncTasksList, dispatch),
        authorizeTask: bindActionCreators(AsyncActionCreators.authorizeAsyncTask, dispatch),
        downloadFile: bindActionCreators(AsyncActionCreators.downloadFile, dispatch),
    };
}

function mapStateToProps(state: State) {
    return {
        exports: state.asyncTasks.asyncTasks[AsyncTasksTypes.EXPORT_BENEFICIARIES_FROM_STATS] || new PagedState(),
        managerId: state.auth.manager ? state.auth.manager.id : null,
        timezone: state.appConfig.timeZone,
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(ExportedFromStatistics as any);
