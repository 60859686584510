import React, { FC, useState } from 'react';
import { ManagerPermission } from '../../permission-profiles/permission';
import { ActivityLogsList } from '../../activityLogs/ActivityLogsList';
import { PagedState } from '../../utils/paging';
import { useDispatch, useSelector } from 'react-redux';
import { useManagerParams } from '../hooks/useManagerParams';
import { SORT_OPTIONS } from '../../utils/hooks/useSort';
import { getManagerActivityLogs } from '../../apiClient';
import { ActionCreators as ManagerActionCreators } from '../managers';
import { ActionCreators as VendorActionCreators } from '../../vendors/vendors';
import { ActionCreators as PartnerActionCreators } from '../../partners/partnersActions';
import State from '@wfp-root-app/store/state';

export const ActivityLogs: FC = () => {
    const {
        auth,
        vendors,
        partners,
        managers: { managerNickNameList },
    } = useSelector((state: State) => state);
    const [activityLogs, setActivityLogs] = useState(new PagedState<any>());
    const managerParams = useManagerParams();
    const dispatch = useDispatch();

    const loadActivityLogs = (page: number, limit: number, managerId?: string) => {
        return getManagerActivityLogs(managerId, page, limit).then((result) => {
            setActivityLogs(result);
        });
    };

    const loadManagersNickNames = () => {
        const actionRef = ManagerActionCreators.loadManagersNickNames();
        actionRef(dispatch);
    };

    const loadVendorUsersNames = (sortOptions: SORT_OPTIONS) => {
        const actionRef = VendorActionCreators.loadVendorUsersNames(sortOptions);
        actionRef(dispatch);
    };

    const loadVendorsNickNames = (sortOptions: SORT_OPTIONS) => {
        const actionRef = VendorActionCreators.loadVendorsNickNames(sortOptions);
        actionRef(dispatch);
    };

    const loadPartnersNames = (sortOptions: SORT_OPTIONS) => {
        const actionRef = PartnerActionCreators.loadPartnersNames(sortOptions);
        actionRef(dispatch);
    };

    const loadPartnerUserNames = (sortOptions: SORT_OPTIONS) => {
        const actionRef = PartnerActionCreators.loadPartnerUsersName(sortOptions);
        actionRef(dispatch);
    };

    const renderActivityLogs = () => {
        return (
            <div>
                <ActivityLogsList
                    activityLogs={activityLogs}
                    auth={auth}
                    downloadRestriction={[ManagerPermission.userActivityDownload]}
                    entityId={managerParams.manager.id}
                    isManager={true}
                    loadActivityLogs={loadActivityLogs}
                    loadManagersNickNames={loadManagersNickNames}
                    loadVendorUsersNames={loadVendorUsersNames}
                    loadVendorsNickNames={loadVendorsNickNames}
                    managersNickNames={managerNickNameList}
                    url={`/admin/${managerParams.manager.id}/activity/export-activity-log`}
                    vendorUsersNames={vendors.usersNames}
                    vendorsNickNames={vendors.nickNameList}
                    partnersNickNames={partners.nameList}
                    partnerUsersNickNames={partners.partnerUsersNamesList}
                    loadPartnersNames={loadPartnersNames}
                    loadPartnerUserNames={loadPartnerUserNames}
                />
            </div>
        );
    };

    return auth.manager ? renderActivityLogs() : <>loading</>;
};
