import React from 'react';
import { ManagerStatus } from './manager';
import { ExtendedManager } from './managers';
import { stopPropagation } from '../utils/events';
import { displayAsDay } from '../utils/utils';
import { sortableColumnNames, sortingOrder } from './ManagersPage';
import { ManagerTabUrl } from './models/manager-tabs.model';
import { NavigateHook, useNavigate } from '@wfp-common/hooks/useNavigate';

interface Props {
    managers: Array<ExtendedManager>;
    postEnabled?: boolean;
    authorizedManagerId?: string;
    authorizeManager?: (manager: ExtendedManager) => void;
    cancelPark?: (id: string) => void;
    changeSorting: (column: string) => void;
    showStartDateColumn: boolean;
    sortingData: { column: string; order: string };
    navigate: NavigateHook;
}

const UNICODE_UP_ARROW = '\u2191';
const UNICODE_DOWN_ARROW = '\u2193';

class ManagersList extends React.Component<Props, any> {
    thisManagerParked(manager: ExtendedManager) {
        return manager && manager.createdByManager && manager.createdByManager.id === this.props.authorizedManagerId;
    }

    render() {
        const showStartDateColumn = this.props.showStartDateColumn;
        const trClassName = (manager) =>
            'cursor-pointer ' +
            (manager.status === ManagerStatus.blocked ? 'failed' : '') +
            (manager.pendingEntityUpdates.length > 0 || manager.isPasswordBlocked ? 'attention-color' : '');

        const sortingOrderMark = (
            <label style={{ marginLeft: 10 }}>
                {this.props.sortingData.order === sortingOrder.ASC ? UNICODE_UP_ARROW : UNICODE_DOWN_ARROW}
            </label>
        );
        return (
            <main>
                <table className="wfp-table mt4 table-hover">
                    <thead>
                        <tr>
                            <th
                                className="cursor-pointer"
                                onClick={() => this.props.changeSorting(sortableColumnNames.organization)}
                            >
                                Organization
                                {this.props.sortingData.column === sortableColumnNames.organization
                                    ? sortingOrderMark
                                    : null}
                            </th>
                            <th
                                className="cursor-pointer"
                                onClick={() => this.props.changeSorting(sortableColumnNames.firstName)}
                            >
                                First Name
                                {this.props.sortingData.column === sortableColumnNames.firstName
                                    ? sortingOrderMark
                                    : null}
                            </th>
                            <th
                                className="cursor-pointer"
                                onClick={() => this.props.changeSorting(sortableColumnNames.lastName)}
                            >
                                Last Name
                                {this.props.sortingData.column === sortableColumnNames.lastName
                                    ? sortingOrderMark
                                    : null}
                            </th>
                            <th
                                className="cursor-pointer"
                                onClick={() => this.props.changeSorting(sortableColumnNames.email)}
                            >
                                Email
                                {this.props.sortingData.column === sortableColumnNames.email ? sortingOrderMark : null}
                            </th>
                            <th>Phone Number</th>
                            {/*<th
                                className="cursor-pointer"
                                onClick={() => this.props.changeSorting(sortableColumnNames.profile)}
                            >
                                Permission Profile
                                {this.props.sortingData.column === sortableColumnNames.profile
                                    ? sortingOrderMark
                                    : null}
                            </th>*/}
                            <th
                                className="cursor-pointer"
                                onClick={() => this.props.changeSorting(sortableColumnNames.status)}
                            >
                                Status
                                {this.props.sortingData.column === sortableColumnNames.status ? sortingOrderMark : null}
                            </th>
                            {/*<th
                                className="cursor-pointer"
                                onClick={() => this.props.changeSorting(sortableColumnNames.otp)}
                            >
                                OTP
                                {this.props.sortingData.column === sortableColumnNames.otp ? sortingOrderMark : null}
                            </th>*/}
                            <th
                                className="cursor-pointer"
                                onClick={() => this.props.changeSorting(sortableColumnNames.expirationDate)}
                            >
                                Expiration Date
                                {this.props.sortingData.column === sortableColumnNames.expirationDate
                                    ? sortingOrderMark
                                    : null}
                            </th>
                            {showStartDateColumn && (
                                <th
                                    className="cursor-pointer"
                                    onClick={() => this.props.changeSorting(sortableColumnNames.startDate)}
                                >
                                    Start Date
                                    {this.props.sortingData.column === sortableColumnNames.startDate
                                        ? sortingOrderMark
                                        : null}
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.managers.map((manager) => (
                            <tr
                                className={trClassName(manager)}
                                key={manager.id}
                                onClick={() => this.onAdminChosen(manager)}
                            >
                                <td>{manager.agency}</td>
                                <td>{manager.firstName}</td>
                                <td>{manager.lastName}</td>
                                <td>{manager.email}</td>
                                <td>{manager.phoneNumber}</td>
                                {/*<td>{manager.profile?.name}</td>*/}
                                <td>
                                    {manager.status.slice(0, 1).toUpperCase() + manager.status.slice(1)}{' '}
                                    {manager.status === ManagerStatus.parked && this.authorizationDataCell(manager)}
                                </td>
                                {/*<td>{manager.otpEnabled ? 'Enabled' : 'Disabled'}</td>*/}
                                <td>{displayAsDay(manager.expirationDate)}</td>
                                {showStartDateColumn && <td>{displayAsDay(manager.startDate)}</td>}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {this.shouldDisplayAnnotation(this.props.managers) && (
                    <div>
                        <label style={{ color: 'red' }}>*</label>
                        <label>You cannot post this User, because you parked him/her.</label>
                    </div>
                )}
            </main>
        );
    }

    authorizationDataCell(manager: ExtendedManager) {
        return (
            <span>
                {this.thisManagerParked(manager) ? (
                    <div>
                        <label style={{ color: 'red' }}>*</label>
                    </div>
                ) : (
                    this.props.postEnabled && (
                        <span>
                            <br />
                            <a onClick={stopPropagation(() => this.props.authorizeManager(manager))}>Post</a>
                            <br />
                        </span>
                    )
                )}
                <a onClick={stopPropagation(() => this.props.cancelPark(manager.id))}>Cancel</a>
            </span>
        );
    }

    private onAdminChosen(manager: ExtendedManager) {
        const defaultTab: ManagerTabUrl = 'information';
        this.props.navigate(`/admin/${manager.id}/${defaultTab}`);
    }

    private shouldDisplayAnnotation(managers: ExtendedManager[]) {
        return managers.some((manager) => !manager.authorizedAt && this.thisManagerParked(manager));
    }
}

const withHooks = (Component: any) => {
    return (props: any) => {
        const navigate = useNavigate();

        return <Component navigate={navigate} {...props} />;
    };
};

export default withHooks(ManagersList);
