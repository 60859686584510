import 'whatwg-fetch';
import querystring from 'querystring';
import moment from 'moment-timezone';

import { Entitlement } from './beneficiaries/model';
import { PaymentRequest, PaymentRequestStatus, SapPaymentRequest } from './paymentRequests/paymentRequests';
import { DateSelectFilter, Filter, FilterTypes } from './utils/FilterTypes';
import { PagedState } from './utils/paging';
import { Transaction, TransactionChain, TransactionType } from './transactions/transactions';
import { Beneficiary, BeneficiaryStatuses } from './beneficiaries/beneficiaries';
import {
    AsyncTask,
    AsyncTaskScheduler,
    AsyncTasksTypes,
    SapPoToAmountEntry,
    SapPoToAmountRequestAction,
} from './utils/asyncTasks';
import {
    CustomEntityUpdateListType,
    EntityType,
    EntityUpdate,
    EntityUpdateRequest,
    ListedEntitiesType,
    ProductUpdateRequest,
    UpdateStateListType,
} from './authorization/taskAuthorizations';
import { CreateTransactionRequestBody } from './transactions/adjustments/adjustments';
import { EntityBalanceType } from './entityBalance/entityBalance';
import { ActivityLogPageType } from './activityLogs/ActivityLogExport';
import { VendorUserDraft } from './vendors/vendors';
import { ZeroingOption } from './beneficiaries/BeneficiaryActivitiesPage';
import { changeTimezone, transformDateFilters } from '../utils/transformDateFilters';
import { BeneficiarySupportOrganization } from './partners/partners';
import { SORT_OPTIONS } from './utils/hooks/useSort';
import { UserEntityType } from './utils/users';
import { getProjectUrl } from './app/appConfig';
import { windowStorage, WindowStorageToken } from './windowStorage';
import { PermissionProfileStatus } from './permission-profiles/use-permission-profiles';
import {
    DuplicationPreventedByAgencyResponse,
    DuplicationPreventedThanksToAgencyDataResponse,
    TaxIdsOverlapStatsResponse,
    UniqueTaxIdsStatsResponse,
} from './statistics/deduplicationStatistics/deduplication-statistics.model';
import BigNumber from './utils/bigNumber';

export const tooLongValueError = new Error('Given value is too long');

export const BaseUrl = () => {
    return `${getProjectUrl()}api/manager`;
};

interface HasId {
    id: any;
}

function authHeaders() {
    return {
        Authorization: windowStorage.getItem(WindowStorageToken.managerApiToken) || '',
    };
}

function jsonHeaders() {
    return new Headers(
        Object.assign(
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            authHeaders()
        )
    );
}

function fetch(url, requestInit) {
    const invalid = false;

    const params = url.split('?')[0].split('/');
    params.forEach((param) => {
        if (param && param.length > 255) {
            throw tooLongValueError;
        }
    });

    return invalid
        ? null
        : window.fetch(url, requestInit).then((response) => {
              if (response.status < 200 || response.status >= 300) {
                  throw response;
              }
              if (response.status !== 204) {
                  return response.json();
              }
              return response;
          });
}

export function get(url): Promise<any> {
    return fetch(url, {
        method: 'GET',
        headers: jsonHeaders(),
    });
}

export function deleteRequest(url, body = {}): Promise<any> {
    return fetch(url, {
        method: 'DELETE',
        headers: jsonHeaders(),
        body: JSON.stringify(body),
    });
}

export function post(url, body): Promise<any> {
    return fetch(url, {
        method: 'POST',
        headers: jsonHeaders(),
        body: JSON.stringify(body),
    });
}

export function patch(url, body): Promise<any> {
    const jsonBody = JSON.stringify(body);
    return fetch(url, {
        method: 'PATCH',
        headers: jsonHeaders(),
        body: jsonBody,
    });
}

// function download(url, body): Promise<any> {
//     const requestInit = {
//         method: 'POST',
//         headers: jsonHeaders(),
//         body: JSON.stringify(body),
//     };
//
//     return window
//         .fetch(url, requestInit)
//         .then((response) => {
//             if (response.status !== 201) {
//                 throw response;
//             }
//
//             const location = response.headers.get('location');
//             if (!location) {
//                 throw new Error('File Location header missing.');
//             }
//             window.location.href = location;
//         })
//         .catch((e) => {
//             if (e instanceof TypeError && e.message === 'Failed to fetch') {
//                 throw new Error('Failed to fetch, request timeout');
//             }
//             throw e;
//         });
// }

export interface TransactionRequestParams {
    'createdAt:lte'?: string;
    'createdAt:gte'?: string;
    category?: string;
    vendorId?: string;
    status?: string;
    beneficiaryId?: string;
    paymentRequestId?: string;
    tab?: 'expenditures' | 'adjustments';
}

export function upload(url, data): Promise<any> {
    const formData = new FormData();

    for (const key of Object.keys(data)) {
        let value = data[key];
        if (value && value.constructor && value.constructor.name === 'Object') {
            value = JSON.stringify(value);
        }

        formData.append(key, value);
    }
    const requestInit = {
        method: 'POST',
        headers: new Headers(
            Object.assign(
                {
                    Accept: 'application/json',
                },
                authHeaders()
            )
        ),
        body: formData,
    };

    return fetch(url, requestInit);
}

function assignFiltersToParams(filters: Array<Filter>, params: { _offset: number; _limit: number }) {
    filters.forEach((f) => {
        if (f.isSelected) {
            if (f instanceof DateSelectFilter) {
                const dateFilter = f as DateSelectFilter;
                const startDateString = moment(dateFilter.dateRange.startDate).tz('utc').format();
                const endDateString = moment(dateFilter.dateRange.endDate).tz('utc').format();
                params[f.name + ':gte'] = startDateString;
                params[f.name + ':lte'] = endDateString;
            } else if (f.type === FilterTypes.multiselect) {
                const values = f.value ? f.value.map((selectOption) => selectOption.value) : [];
                params[f.name] = values.toString();
            } else {
                params[f.name] = f.value;
            }
        }
    });
}

async function getWithOffset<T>(
    url: string,
    filters: Array<Filter>,
    offset: number,
    limit: number,
    customParams: any = {}
): Promise<PagedState<T>> {
    const params = Object.assign({}, { _offset: offset, _limit: limit });
    assignFiltersToParams(filters, params);
    Object.assign(params, customParams);
    const queryString = querystring.stringify(params);

    const res = await get(`${url}?${queryString}`);
    if (res.status === 204) {
        return new PagedState<T>().configureFor204Response(limit, filters);
    }
    return res;
}

export function getWithPaging<T>(
    url: string,
    filters: Array<Filter>,
    page: number,
    limit: number,
    customParams: any = {}
): Promise<PagedState<T>> {
    const offset = (page - 1) * limit;
    return getWithOffset(url, filters, offset, limit, customParams);
}

async function postWithOffset<T>(
    url: string,
    filters: Array<Filter>,
    offset: number,
    limit: number,
    customBody: any = {}
): Promise<PagedState<T>> {
    const body = Object.assign({}, { _offset: offset, _limit: limit });
    assignFiltersToParams(filters, body);
    Object.assign(body, customBody);

    const res = await post(url, body);
    if (res.status === 204) {
        return new PagedState<T>().configureFor204Response(limit, filters);
    }
    return res;
}

export function postWithPaging<T>(
    url: string,
    filters: Array<Filter>,
    page: number,
    limit: number,
    customParams: any = {}
): Promise<PagedState<T>> {
    const offset = (page - 1) * limit;
    return postWithOffset(url, filters, offset, limit, customParams);
}

/////// exported functions

export function healthCheck() {
    return get(`${BaseUrl()}/health-check`);
}

export function login(email: string, password: string, resetOtp: boolean, otp?: string, secret?: string) {
    const search = new URLSearchParams(window.location.search);
    const entityType = search.get('e');
    let params;
    if (entityType === UserEntityType.manager) {
        params = {
            managerId: atob(search.get('u')),
            token: search.get('o'),
        };
    }
    return post(`${BaseUrl()}/auth`, {
        email,
        password,
        resetOtp,
        otp,
        secret,
        ...params,
    });
}

export function getMyself() {
    return get(`${BaseUrl()}/myself`);
}

export function logout() {
    return post(`${BaseUrl()}/logout`, {});
}

export function newAuthToken() {
    return post(`${BaseUrl()}/new-auth-token`, {});
}

export function getPendingActions() {
    return get(`${BaseUrl()}/pending-requests`);
}

enum Entities {
    'm' = 'manager',
    'v' = 'vendor',
    'bsp' = 'beneficiary-support-panel',
}

export async function resetPassword(password: string, oldPassword?: string) {
    const search = new URLSearchParams(window.location.search);
    const entityType = Entities[search.get('e')];
    const params = {
        password,
        id: atob(search.get('u')),
        token: search.get('t'),
        otpToken: search.get('o'),
        oldPassword: oldPassword,
        oldPasswordRequired: search.get('op'),
    };
    return post(`${getProjectUrl()}api/${entityType}/reset-password`, params);
}

export function sessionInvalidation() {
    const search = new URLSearchParams(window.location.search);
    const entityType = search.get('e');
    if (entityType === UserEntityType.manager) {
        const params = {
            managerId: atob(search.get('u')),
            token: search.get('t'),
        };
        return post(`${BaseUrl()}/session-invalidation`, params);
    }
}

export function requestManagerPasswordReset(manager: HasId, transport: string) {
    return post(`${BaseUrl()}/managers/${manager.id}/resend-password`, {
        transport,
    }).then((data) => {
        return data.resetLink;
    });
}

export function changePassword(oldPassword: string, newPassword: string) {
    return post(`${BaseUrl()}/change-password`, { oldPassword, newPassword });
}

export function requestManagerOtpPasswordReset(manager: HasId, transport: string) {
    return post(`${BaseUrl()}/managers/${manager.id}/resend-otp-password`, {
        transport,
    }).then((data) => {
        return data.resetLink;
    });
}

export function requestManagerOtpReset(manager: HasId, transport: string) {
    return post(`${BaseUrl()}/managers/${manager.id}/resend-otp`, {
        transport,
    }).then((data) => {
        return data.resetLink;
    });
}

export function loadVendors(sortOptions: SORT_OPTIONS, filters?: Filter[]) {
    const _filters =
        filters &&
        filters
            .map((filter) => ({ [filter.name]: filter.value.map((val) => val.value) }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const queryString = new URLSearchParams({ ...sortOptions, ..._filters }).toString();

    return get(`${BaseUrl()}/vendors?${queryString}`);
}

export function getAgencies() {
    return get(`${BaseUrl()}/managers/agencies`);
}

export function loadBeneficiarySupportOrganization(beneficiarySupportOrganizationid: string) {
    return get(`${BaseUrl()}/beneficiary-support-organization/${beneficiarySupportOrganizationid}`);
}

export function loadBeneficiarySupportOrganizations(sortOptions: SORT_OPTIONS, filters?: Filter[]) {
    const _filters =
        filters &&
        filters
            .map((filter) => ({ [filter.name]: filter.value.map((val) => val.value) }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const queryString = new URLSearchParams({ ...sortOptions, ..._filters }).toString();

    return get(`${BaseUrl()}/beneficiary-support-organization?${queryString}`);
}

export function loadParkedBeneficiarySupportUsers() {
    return get(`${BaseUrl()}/beneficiary-support-user/parked`);
}

export function createBeneficiarySupportOrganization(partner: BeneficiarySupportOrganization) {
    return post(`${BaseUrl()}/beneficiary-support-organization`, partner);
}

export function cancelBeneficiarySupportOrganization(beneficiarySupportOrganizationId: string) {
    return post(`${BaseUrl()}/beneficiary-support-organization/${beneficiarySupportOrganizationId}/cancel-park`, {});
}

export function loadBeneficiarySupportOrganizationNames(sortOptions: SORT_OPTIONS) {
    const query = sortOptions && Object.values(sortOptions).every(Boolean) ? new URLSearchParams(sortOptions) : '';

    return get(`${BaseUrl()}/beneficiary-support-organization/names?${query}`);
}

export function loadBeneficiarySupportUserNames(sortOptions: SORT_OPTIONS) {
    const query = new URLSearchParams(sortOptions);
    return get(`${BaseUrl()}/beneficiary-support-user/names?${query}`);
}

export function createBeneficiarySupportUser(beneficiarySupportUser) {
    beneficiarySupportUser.validUntil = new Date(beneficiarySupportUser.validUntil);
    return post(`${BaseUrl()}/beneficiary-support-user`, beneficiarySupportUser);
}

export function cancelBeneficiarySupportUser(beneficiarySupportUserId) {
    return post(`${BaseUrl()}/beneficiary-support-user/${beneficiarySupportUserId}/cancel-park`, {});
}

export function loadBeneficiarySupportUsersForOrganization(
    organizationId,
    sortOptions: SORT_OPTIONS,
    filters?: Filter[]
) {
    const _filters =
        filters &&
        filters
            .map((filter) => ({ [filter.name]: filter.value.map((val) => val.value) }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const queryString = new URLSearchParams({ ...sortOptions, ..._filters }).toString();

    return get(`${BaseUrl()}/beneficiary-support-user/${organizationId}?${queryString}`);
}

export function authorizeBeneficiarySupportUser(beneficiarySupportUserId) {
    return post(`${BaseUrl()}/beneficiary-support-user/${beneficiarySupportUserId}/authorize`, {});
}

export function resetBeneficiarySupportUserPassword(beneficiarySupportUserId) {
    return post(`${BaseUrl()}/beneficiary-support-user/${beneficiarySupportUserId}/resend-password`, {
        transport: 'email',
    });
}

export function loadSingleBeneficiarySupportUser(partnerUserId) {
    return get(`${BaseUrl()}/beneficiary-support-user/${partnerUserId}/findOne`);
}

export function authorizeBeneficiarySupportOrganization(id) {
    return post(`${BaseUrl()}/beneficiary-support-organization/${id}/authorize`, {});
}

export function loadVendorsNickNames(query: SORT_OPTIONS) {
    const searchparams = Object.entries(query).reduce(
        (object, [key, value]) =>
            value
                ? {
                      ...object,
                      [key]: value,
                  }
                : object,
        {}
    );
    const querystring = new URLSearchParams(searchparams);
    return get(`${BaseUrl()}/vendors/nick-names?${querystring}`);
}

export function loadVendorUsersNames(sortOptions: SORT_OPTIONS) {
    const query = sortOptions && Object.values(sortOptions).every(Boolean) ? new URLSearchParams(sortOptions) : '';
    return get(`${BaseUrl()}/vendor-users/names?${query}`);
}

export function loadVendor(vendorId: string) {
    return get(`${BaseUrl()}/vendors/${vendorId}`);
}

export function loadBranchCodes() {
    return get(`${BaseUrl()}/branch-codes`);
}

export function savePartner(partner: HasId) {
    if (partner.id) {
        return post(`${BaseUrl()}/entity-update-requests/partner-details`, partner)
            .then((res) => {
                return res.partner;
            })
            .catch(() => {
                return 400;
            });
    } else {
        return post(`${BaseUrl()}/partners`, partner)
            .then((res) => {
                return res.partner;
            })
            .catch((err) => {
                return err.status;
            });
    }
}

export function savePartnerUser(partnerUser) {
    return post(`${BaseUrl()}/entity-update-requests/partner-user-details`, partnerUser);
}

export function requestVendorUserPasswordReset(vendorUser: HasId, transport: string) {
    return post(`${BaseUrl()}/vendor-users/${vendorUser.id}/resend-password`, {
        transport,
    });
}

export function requestPartnerPasswordReset(partnerId: string, transport: string) {
    return post(`${BaseUrl()}/partners/${partnerId}/resend-password`, {
        transport,
    });
}

export function cancelVendorPark(id: string) {
    return post(`${BaseUrl()}/vendors/${id}/cancel-park`, {});
}

export function cancelManagerPark(id: string) {
    return post(`${BaseUrl()}/managers/${id}/cancel-park`, {});
}

export function uploadPermissionsFile(id: number | string, file: File) {
    return upload(`${BaseUrl()}/managers/${id}/permissions`, { file });
}

export function beneficiarySupportUploadPermissionsFile(id: number | string, file: File) {
    return upload(`${BaseUrl()}/beneficiary-support-organization/${id}/permissions`, { file });
}

export function uploadVendorPermissionsFile(id: string, file: File) {
    return upload(`${BaseUrl()}/vendors/${id}/permissions`, { file });
}

export function uploadPartnerPermissionsFile(id: string, file: File) {
    return upload(`${BaseUrl()}/partners/${id}/permissions`, { file });
}

export function createPermissionProfile(newProfile: { name: string; permissions: string[] }) {
    return post(`${BaseUrl()}/permission-profiles`, newProfile);
}

export function rejectPermissionProfile(id: number) {
    return patch(`${BaseUrl()}/permission-profiles/${id}/reject`, {});
}

export function cancelPermissionProfile(id: number) {
    return deleteRequest(`${BaseUrl()}/permission-profiles/${id}`);
}

export function authorizePermissionProfile(id: number) {
    return patch(`${BaseUrl()}/permission-profiles/${id}/authorize`, {});
}

export function createPermissionProfileUpdate(profile) {
    return post(`${BaseUrl()}/entity-update-requests/permission-profile-details`, profile);
}

export function loadPermissionProfiles(sortOptions: SORT_OPTIONS, permissionProfilesStatus: PermissionProfileStatus) {
    const queryString = new URLSearchParams({ ...sortOptions, status: permissionProfilesStatus }).toString();

    return get(`${BaseUrl()}/permission-profiles?${queryString}`);
}

export function exportBeneficiaries(timezone: string, columns, filters) {
    const params = { _offset: 0, _limit: 0 };
    const _filters = filters.map(transformDateFilters(timezone));
    assignFiltersToParams(_filters, params);
    const queryString = querystring.stringify(params);

    return post(`${BaseUrl()}/beneficiaries/export?${queryString}`, {
        fileName: 'Beneficiaries.xlsx',
        columns,
    });
}

export function exportEntitlements(timezone, columns, filters) {
    const params = { _offset: 0, _limit: 0 };
    const _filters = filters.map(transformDateFilters(timezone));
    assignFiltersToParams(_filters, params);

    const queryString = querystring.stringify(params);

    return post(`${BaseUrl()}/entitlements/export-entitlements?${queryString}`, {
        fileName: 'BeneficiariesEntitlements.xlsx',
        columns,
    });
}

export function exportDeduplicatedEntitlements(timezone, columns, filters) {
    const params = { _offset: 0, _limit: 0 };
    const _filters = filters.map(transformDateFilters(timezone));
    assignFiltersToParams(_filters, params);

    const queryString = querystring.stringify(params);

    return post(`${BaseUrl()}/deduplicated-entitlements/export-deduplicated-entitlements?${queryString}`, {
        fileName: 'BeneficiariesDeduplicatedEntitlements.xlsx',
        columns,
    });
}

export function exportTaxIdMapping(timezone, columns, filters) {
    const params = { _offset: 0, _limit: 0 };
    const _filters = filters.map(transformDateFilters(timezone));
    assignFiltersToParams(_filters, params);

    const queryString = querystring.stringify(params);

    return post(`${BaseUrl()}/beneficiaries/export-tax-id-mapping?${queryString}`, {
        fileName: 'BeneficiariesTaxIdMappingExport.xlsx',
        columns,
    });
}

export function loadEntitlements(timezone: string, filters: Array<Filter>, page: number, limit: number) {
    const _filters = filters.map(transformDateFilters(timezone));
    return postWithPaging<Entitlement>(`${BaseUrl()}/entitlements/list`, _filters, page, limit);
}

export function loadDeduplicatedEntitlements(timezone: string, filters: Array<Filter>, page: number, limit: number) {
    const _filters = filters.map(transformDateFilters(timezone));
    return postWithPaging<Entitlement>(`${BaseUrl()}/deduplicated-entitlements/list`, _filters, page, limit);
}

export function importBeneficiaries(data): Promise<AsyncTask> {
    return upload(`${BaseUrl()}/beneficiaries/import`, data);
}

export function importTaxIdFamilyTaxIdMapping(data): Promise<AsyncTask> {
    return upload(`${BaseUrl()}/family-tax-id-mapping/upload-family-tax-id-mapping`, data);
}

export function importRemoveEntitlements(data): Promise<AsyncTask> {
    return upload(`${BaseUrl()}/entitlements/upload-remove-entitlements`, data);
}

export function importRemoveDeduplications(data): Promise<AsyncTask> {
    return upload(`${BaseUrl()}/deduplicated-entitlements/upload-remove-deduplications`, data);
}

export function addAdditionalFile(data, taskType): Promise<AsyncTask> {
    return upload(`${BaseUrl()}/async-task-additional-file/upload/${taskType}`, data);
}

export function loadBeneficiaries(
    timezone: string,
    filters: Array<Filter>,
    page: number,
    limit: number,
    sortOptions: SORT_OPTIONS
) {
    const _filters = filters.map(transformDateFilters(timezone));
    const sort = sortOptions && Object.values(sortOptions).every(Boolean) ? new URLSearchParams(sortOptions) : '';
    return postWithPaging<Beneficiary>(`${BaseUrl()}/beneficiaries/list?${sort}`, _filters, page, limit);
}

export function getAsyncTasksList(filters: Array<Filter>, page: number, limit: number, type = '') {
    return getWithPaging<AsyncTask>(`${BaseUrl()}/tasks/${type}`, filters, page, limit);
}

export function appendAsyncTasksList(filters: Array<Filter>, offset: number, limit: number, type = '') {
    return getWithOffset<AsyncTask>(`${BaseUrl()}/tasks/${type}`, filters, offset, limit);
}

export function getAsyncTask(type: string, id: string): Promise<{ task: AsyncTask; scheduler: AsyncTaskScheduler }> {
    return get(`${BaseUrl()}/tasks/${type}/${id}`);
}

export function getAsyncTaskSubtasksArray(type: string, id: string): Promise<AsyncTask[]> {
    return get(`${BaseUrl()}/tasks/subtasks/${id}/${type}`);
}

export function authorizeAsyncTask(type: string, id: string) {
    return post(`${BaseUrl()}/tasks/${type}/${id}/authorize`, {});
}

export function openFile(taskType: string, id: string) {
    return get(`${BaseUrl()}/tasks/${taskType}/${id}/open`);
}

export function downloadFile(taskType: string, id: string) {
    return get(`${BaseUrl()}/tasks/${taskType}/${id}/download`)
        .then((data) => data.url)
        .catch((err) => (err.status ? err.status : 500));
}

export function downloadSpecificFile(taskType: string, id: string, index: number) {
    return get(`${BaseUrl()}/tasks/${taskType}/${id}/${index}/download`)
        .then((data) => data.url)
        .catch((err) => (err.status ? err.status : 500));
}

export function downloadStatic(fileName: string) {
    return get(`${BaseUrl()}/static/${fileName}/download`)
        .then((data) => data.url)
        .catch((err) => (err.status ? err.status : 500));
}

export function downloadAdditionalFile(fileKey: string) {
    return post(`${BaseUrl()}/async-task-additional-file/url`, { fileKey })
        .then((data) => data.url)
        .catch((err) => (err.status ? err.status : 500));
}

export function cancelTask(type: string, id: string) {
    return post(`${BaseUrl()}/tasks/${type}/${id}/cancel`, {});
}

export function cancelWholeImport(type: string, id: string) {
    return post(`${BaseUrl()}/tasks/${type}/${id}/cancel-import`, {});
}

export function cancelSubTask(type: string, id: string) {
    return post(`${BaseUrl()}/tasks/${AsyncTasksTypes.IMPORT_BENEFICIARIES}/${id}/cancel-scheduled`, {});
}

export function cancelRunningExport(type: string, id: string) {
    return post(`${BaseUrl()}/tasks/${type}/${id}/cancel-running-export`, {});
}

export function getImportTaskPoDetails() {
    return get(`${BaseUrl()}/tasks/beneficiary-import-requests/po-details`);
}

export function manualSapPoToAmountPost(
    asyncTaskId: string,
    sapPoToAmountEntries: Array<SapPoToAmountEntry>,
    action: SapPoToAmountRequestAction
) {
    if (action === SapPoToAmountRequestAction.park) {
        return post(`${BaseUrl()}/tasks/${AsyncTasksTypes.IMPORT_BENEFICIARIES}/${asyncTaskId}/manual-po`, {
            sapPoToAmountEntries,
        });
    } else if (action === SapPoToAmountRequestAction.post) {
        return post(`${BaseUrl()}/tasks/${AsyncTasksTypes.IMPORT_BENEFICIARIES}/${asyncTaskId}/manual-po/authorize`, {
            sapPoToAmountEntries,
        });
    } else if (action === SapPoToAmountRequestAction.cancel) {
        return post(`${BaseUrl()}/tasks/${AsyncTasksTypes.IMPORT_BENEFICIARIES}/${asyncTaskId}/manual-po/cancel`, {
            sapPoToAmountEntries,
        });
    }
}

export function createBeneficiary(beneficiary: HasId) {
    return post(`${BaseUrl()}/beneficiaries`, beneficiary);
}

export function editBeneficiary(beneficiary) {
    return post(`${BaseUrl()}/entity-update-requests/beneficiary-details`, beneficiary);
}

export async function getEntitlements(beneficiaryId: string, pageNumber: number, pageSize: number) {
    const url = `${BaseUrl()}/beneficiaries/beneficiary/entitlements`;
    return await postWithPaging<Entitlement>(url, [], pageNumber, pageSize, { beneficiaryId });
}

export async function getDeduplicatedEntitlements(beneficiaryId: string, pageNumber: number, pageSize: number) {
    const url = `${BaseUrl()}/beneficiaries/beneficiary/deduplicated-entitlements`;
    return await postWithPaging<Entitlement>(url, [], pageNumber, pageSize, { beneficiaryId });
}

export async function getClustersInfo(beneficiaryId: string) {
    const url = `${BaseUrl()}/beneficiaries/beneficiary/token-clusters`;
    return post(url, { beneficiaryId });
}

export async function loadBeneficiary(beneficiaryId: string) {
    const response = await post(`${BaseUrl()}/beneficiaries/beneficiary`, {
        beneficiaryId,
    });
    return response.beneficiary;
}

export function loadTransactions(
    timezone: string,
    filters: Array<Filter> = [],
    page: number,
    limit: number,
    customParams: TransactionRequestParams = {},
    sortOptions: SORT_OPTIONS
): Promise<PagedState<Transaction>> {
    const query = sortOptions?.['orderBy:column'] ? new URLSearchParams(sortOptions) : '';
    const _filters = filters.map(transformDateFilters(timezone));
    const paged = postWithPaging<Transaction>(
        `${BaseUrl()}/transactions/list?${query}`,
        _filters,
        page,
        limit,
        customParams
    );
    return paged.then((result) => {
        result.items.forEach((transaction) => (transaction.amount = new BigNumber(transaction.amount)));
        return result;
    });
}

export function loadTransactionChain(transactionId: string): Promise<TransactionChain> {
    return get(`${BaseUrl()}/transactions/${transactionId}`).then((result) => {
        const chain: TransactionChain = result;
        chain.amount = new BigNumber(chain.amount);
        chain.items.forEach((transaction) => {
            transaction.amount = new BigNumber(transaction.amount);
        });
        return result;
    });
}

export function loadTransactionDetails(transactionId: string) {
    return get(`${BaseUrl()}/transactions/${transactionId}/details`);
}

export function getTransactionTypeTotals(
    timezone: string,
    startDate?: string,
    endDate?: string,
    types?: Array<TransactionType>
) {
    let customParam = {
        startDate: startDate ? changeTimezone(new Date(startDate.slice(0, 19)), timezone).toISOString() : undefined,
        endDate: endDate ? changeTimezone(new Date(endDate.slice(0, 19)), timezone).toISOString() : undefined,
    };

    if (types) {
        customParam = Object.assign({}, customParam, { type: types });
    }
    const queryString = querystring.stringify(customParam);
    return get(`${BaseUrl()}/transactions/type-totals/?${queryString}`);
}

export function getTokensStatistics() {
    return get(`${BaseUrl()}/transactions/tokens-statistics`);
}

export function getUniqueTaxIdsStatistics(categories): Promise<UniqueTaxIdsStatsResponse> {
    return get(`${BaseUrl()}/statistics/deduplication-statistics/unique-tax-id/${categories}`);
}

export function getTaxIdsOverlapStats(categories): Promise<TaxIdsOverlapStatsResponse> {
    return get(`${BaseUrl()}/statistics/deduplication-statistics/tax-ids-overlap-stats/${categories}`);
}

export function getDeduplicationPreventedByAgencyStatistics(
    categories: string
): Promise<DuplicationPreventedByAgencyResponse> {
    return get(`${BaseUrl()}/statistics/deduplication-statistics/duplication-prevented-by-agency/${categories}`);
}

export function getDuplicationPreventedThanksToAgencyDataStatistics(
    categories: string
): Promise<DuplicationPreventedThanksToAgencyDataResponse> {
    return get(
        `${BaseUrl()}/statistics/deduplication-statistics/duplication-prevented-thanks-to-agency-data/${categories}`
    );
}

export function triggerBeneficiariesIdsDownloadFromStats(overlapRecord: string, categories: string) {
    return post(
        `${BaseUrl()}/statistics/deduplication-statistics/beneficiaries-file-from-overlap-stats/${categories}`,
        {
            overlapRecord,
        }
    );
}

export function exportTransactions(timezone: string, columns, filters, customParams: TransactionRequestParams = {}) {
    const params = { _offset: 0, _limit: 0 };
    const _filters = filters.map(transformDateFilters(timezone));
    assignFiltersToParams(_filters, params);
    Object.assign(params, customParams);
    const queryString = querystring.stringify(params);

    return post(`${BaseUrl()}/transactions/export?${queryString}`, {
        fileName: 'Transactions.xlsx',
        columns,
    });
}

export function loadPaymentRequests(
    periodEnd: Date,
    category: string,
    filters: Array<Filter>,
    page: number,
    limit: number
) {
    return getWithPaging<PaymentRequest>(`${BaseUrl()}/payment-requests`, filters, page, limit, {
        periodEnd: periodEnd.toISOString(),
        category: category,
    });
}

export function loadPaymentRequest(paymentRequestId: string) {
    return get(`${BaseUrl()}/payment-requests/${paymentRequestId}`);
}

export function loadPaymentPeriods(page, limit) {
    return getWithPaging(`${BaseUrl()}/payment-requests/periods`, [], page, limit);
}

export function getPossibleTargetStatuses(paymentRequestId: string) {
    return get(`${BaseUrl()}/payment-requests/${paymentRequestId}/target-status`);
}

export function getPaymentSapPoDetails(paymentRequestId: string) {
    return get(`${BaseUrl()}/payment-requests/${paymentRequestId}/po-details`);
}

export function changePaymentRequestStatus(
    paymentRequestId: string,
    targetStatus: PaymentRequestStatus,
    routeOverride?: string,
    paymentSapDetails?: Array<SapPaymentRequest>
) {
    return post(`${BaseUrl()}/payment-requests/${paymentRequestId}/change-status/${targetStatus}`, {
        paymentSapDetails,
    });
}

export function getSecurityData() {
    return get(`${BaseUrl()}/security`);
}

export function postOtpSecret(otp: string, otpSecret: string) {
    return post(`${BaseUrl()}/security/otp`, { otp, otpSecret });
}

export function createOtpSecret() {
    return get(`${BaseUrl()}/security/otp-request`);
}

export function deleteOtpSecret() {
    return deleteRequest(`${BaseUrl()}/security/otp`);
}

export function sendOtpCode(email: string, password: string) {
    return post(`${BaseUrl()}/send-otp-email`, { email, password });
}

export function updateBeneficiaryStatus(
    beneficiaryId: string,
    status: BeneficiaryStatuses,
    reason: string,
    source: string
) {
    const action = status === BeneficiaryStatuses.active ? 'unblock' : 'block';
    return post(`${BaseUrl()}/entity-update-requests/beneficiary-${action}`, {
        id: beneficiaryId,
        reason,
        source,
    });
}

export function zeroBeneficiary(beneficiaryId: string, reason: string, source: string, zeroingOption: ZeroingOption) {
    return post(`${BaseUrl()}/entity-update-requests/beneficiary-zeroing`, {
        id: beneficiaryId,
        zeroingReason: reason,
        zeroingSource: source,
        categories: zeroingOption.category,
        availabilityType: zeroingOption.availabilityType,
    });
}

export function acStatusChange(id: string, reason: string, source: string, newStatus: string) {
    return post(`${BaseUrl()}/entity-update-requests/alternative-collector-status-change`, {
        id: id,
        reason: reason,
        source: source,
        newStatus: newStatus,
    });
}

export function getManagers(sortingColumn = 'firstName', sortingOrder = 'ASC', filters = []) {
    const _filters = filters
        .map((filter) => ({ [filter.name]: filter.value.map((val) => val.value) }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {});
    const queryString = new URLSearchParams({ sortingColumn, sortingOrder, ..._filters }).toString();
    return get(`${BaseUrl()}/managers?${queryString}`);
}

export function getManagersNickNames() {
    return get(`${BaseUrl()}/managers/nick-names`);
}

export function getManager(id: string) {
    return get(`${BaseUrl()}/managers/${id}`);
}

export async function editManager(manager: any) {
    return await post(`${BaseUrl()}/entity-update-requests/manager-details`, manager);
}

export function managerOtpChangeStatus(manager: any) {
    return post(`${BaseUrl()}/entity-update-requests/manager-details-otp`, manager);
}

export function editPersonalData(manager: any) {
    return patch(`${BaseUrl()}/managers/${manager.id}`, manager);
}

export function createManager(manager: any) {
    return post(`${BaseUrl()}/managers`, manager);
}

export function authorizeManager(id: string) {
    return post(`${BaseUrl()}/managers/${id}/authorize`, {});
}

export function exportUsers(columns, id) {
    const params = { _offset: 0, _limit: 0 };
    if (id) {
        const idFilter = {
            name: 'id',
            type: FilterTypes.text,
            isSelected: true,
            value: id,
        };
        assignFiltersToParams([idFilter], params);
    }

    const queryString = querystring.stringify(params);

    return post(`${BaseUrl()}/managers/export?${queryString}`, {
        columns,
    });
}

function getParamsForUpdateList(listType: UpdateStateListType) {
    const params = {
        type: listType.toLowerCase(),
    };
    switch (listType) {
        case UpdateStateListType.Requested:
            params['authorizedAt:eq'] = 'null';
            params['deletedAt:eq'] = 'null';
            return params;
        case UpdateStateListType.Posted:
            params['authorizedAt:ne'] = 'null';
            return params;
        case UpdateStateListType.Rejected:
            params['deletedAt:ne'] = 'null';
            return params;
    }

    return params;
}

export function createTransactionRequest(transactionRequest: CreateTransactionRequestBody) {
    return post(`${BaseUrl()}/transaction-requests`, transactionRequest);
}

export function getLastEntityBalance(entityId: string, entityType: EntityBalanceType) {
    return post(`${BaseUrl()}/entity-balance/last-balance/${entityType}`, {
        entityId,
    });
}

export function getEntityBalanceComparison(entityId: string, entityType: EntityBalanceType) {
    return post(`${BaseUrl()}/entity-balance/balance-comparison/${entityType}`, {
        entityId,
    });
}

export function editEntityUpdateRequest(id: string, newUpdates: EntityUpdate[]) {
    return patch(`${BaseUrl()}/entity-update-requests/pre-parked-beneficiaries/${id}`, newUpdates);
}

function requestPrefixForEntityUpdateRequestGET(listedEntitiesType: ListedEntitiesType) {
    switch (listedEntitiesType) {
        case CustomEntityUpdateListType.beneficiaryAllUpdates:
            return 'beneficiaries';
        case EntityType.vendorDetails:
            return 'vendor';
        case EntityType.managerDetails:
            return 'manager';
        case EntityType.preParkedBeneficiary:
            return 'pre-parked-beneficiary';
        case EntityType.vendorUserDetails:
            return 'vendor-user';
        case EntityType.partnerDetails:
            return 'partner';
        case EntityType.partnerUserDetails:
            return 'partner-user';
        case EntityType.locationDetails:
            return 'locations/get';
        case EntityType.productManagement:
            return 'product-management';
        case EntityType.permissionProfileDetails:
            return 'permission-profile';
    }
    return '';
}

export async function getEntityUpdateRequests(
    listedEntitiesType: ListedEntitiesType,
    requestsType: UpdateStateListType,
    page: number,
    limit: number,
    filters?: Filter[]
) {
    if (listedEntitiesType === CustomEntityUpdateListType.beneficiaryAllUpdates) {
        const url = `${BaseUrl()}/entity-update-requests/beneficiaries`;
        const params = getParamsForUpdateList(requestsType);
        return postWithPaging<EntityUpdateRequest>(url, filters || [], page, limit, params);
    } else {
        const prefix = requestPrefixForEntityUpdateRequestGET(listedEntitiesType);
        const url = `${BaseUrl()}/entity-update-requests/${prefix}`;
        const params = getParamsForUpdateList(requestsType);
        return postWithPaging<EntityUpdateRequest | ProductUpdateRequest>(url, filters || [], page, limit, params);
    }
}

function requestPrefixForEntityUpdateRequestChange(entityUpdateRequest: EntityUpdateRequest) {
    switch (entityUpdateRequest.entityType) {
        case EntityType.beneficiaryDetails:
            return 'beneficiary-details';
        case EntityType.beneficiarySingleBlock:
            return 'beneficiary-block';
        case EntityType.beneficiarySingleUnblock:
            return 'beneficiary-unblock';
        case EntityType.beneficiaryZeroing:
            return 'beneficiary-zeroing';
        case EntityType.vendorDetails:
            return 'vendor-details';
        case EntityType.managerDetails:
            return 'manager-details';
        case EntityType.preParkedBeneficiary:
            return 'pre-parked-beneficiaries';
        case EntityType.alternativeCollectorStatusChange:
            return 'alternative-collector-status-change';
        case EntityType.vendorUserDetails:
            return 'vendor-user-details';
        case EntityType.partnerDetails:
            return 'partner-details';
        case EntityType.partnerUserDetails:
            return 'partner-user-details';
        case EntityType.locationDetails:
            return 'locations';
        case EntityType.permissionProfileDetails:
            return 'permission-profile-details';
        case EntityType.productManagement:
            return 'product-management-details';
    }
    return '';
}

export function parkEntityUpdateRequest(entityUpdateRequest: EntityUpdateRequest) {
    const prefix = requestPrefixForEntityUpdateRequestChange(entityUpdateRequest);
    return post(`${BaseUrl()}/entity-update-requests/${prefix}/${entityUpdateRequest.id}/park`, {});
}

export function authorizeEntityUpdateRequest(entityUpdateRequest: EntityUpdateRequest) {
    const prefix = requestPrefixForEntityUpdateRequestChange(entityUpdateRequest);
    return post(`${BaseUrl()}/entity-update-requests/${prefix}/${entityUpdateRequest.id}/authorize`, {});
}

export function authorizeEntityUpdateRequestWithOtp(entityUpdateRequest: EntityUpdateRequest) {
    return post(`${BaseUrl()}/entity-update-requests/manager-details-otp/${entityUpdateRequest.id}/authorize`, {});
}

export function rejectEntityUpdateRequest(entityUpdateRequest: EntityUpdateRequest, comment: string) {
    const prefix = requestPrefixForEntityUpdateRequestChange(entityUpdateRequest);
    return deleteRequest(`${BaseUrl()}/entity-update-requests/${prefix}/${entityUpdateRequest.id}`, { comment });
}

export function exportActivityLog(entityId: string, entityType: ActivityLogPageType, options: any, filter) {
    const params = { _offset: 0, _limit: 0 };
    assignFiltersToParams([filter], params);
    const queryString = querystring.stringify(params);
    return post(`${BaseUrl()}/activity-logs/${entityType}/export?${queryString}`, { options, entityId });
}

export function getActivityLog(id: string) {
    return get(`${BaseUrl()}/activity-logs/${id}`);
}

export function loadAllActivityLogs(timezone: string, page: number, limit: number, filters: Filter[] = []) {
    const _filters = filters.map(transformDateFilters(timezone));
    const url = `${BaseUrl()}/activity-logs/list`;
    return postWithPaging(url, _filters, page, limit);
}

export function exportOversights(columns, filters) {
    const params = { _offset: 0, _limit: 0 };
    assignFiltersToParams(filters, params);
    const queryString = querystring.stringify(params);
    return post(`${BaseUrl()}/activity-logs/export?${queryString}`, {
        fileName: 'Oversight.xlsx',
        columns,
    });
}

export function loadActivityLogsChild(parentId: string, page: number, limit: number, filters: Filter[] = []) {
    const url = `${BaseUrl()}/activity-logs/list/${parentId}`;
    return getWithPaging(url, filters, page, limit);
}

export function getManagerActivityLogs(managerId: string, page: number, limit: number, filters: Filter[] = []) {
    const url = `${BaseUrl()}/activity-logs/manager/${managerId}`;
    return getWithPaging(url, filters, page, limit);
}

export function sign(signatureId: string, managerId: string) {
    return post(`${BaseUrl()}/signatures/${signatureId}/sign`, {
        managerId: Number(managerId),
    });
}

export function getAppConfig() {
    return get(`${BaseUrl()}/app-config`);
}

export function loadLocations() {
    return get(`${BaseUrl()}/locations`);
}

export function loadLocationById(locationId) {
    return get(`${BaseUrl()}/locations/${locationId}`);
}

export function createLocation(data: { location1: string; location2: string }) {
    return post(`${BaseUrl()}/locations`, data);
}

export function authorizeLocation(locationId) {
    return patch(`${BaseUrl()}/locations/${locationId}/authorize`, {});
}

export function cancelLocation(locationId) {
    return patch(`${BaseUrl()}/locations/${locationId}/cancel`, {});
}

export function getPermissionsList() {
    return get(`${BaseUrl()}/managers/permissions-list`);
}

export function getMobilizationStatistics(filters) {
    const query = new URLSearchParams(filters).toString();
    return get(`${BaseUrl()}/statistics/mobilization-statistics?${query}`);
}

export function getTodayMobilizationStatistics(filters) {
    const query = new URLSearchParams(filters).toString();
    return get(`${BaseUrl()}/statistics/mobilization-statistics/today?${query}`);
}

export function getMobilizationStatisticsSyncDate() {
    return get(`${BaseUrl()}/statistics/mobilization-statistics/sync-date`);
}

export async function loadDocuments() {
    return get(`${BaseUrl()}/documents`);
}

export async function uploadDocument(document) {
    return upload(`${BaseUrl()}/documents`, { ...document });
}

export async function updateDocumentSectionName(oldSection, newSection) {
    return patch(`${BaseUrl()}/documents/section`, {
        oldSection,
        newSection,
    });
}

export async function updateDocumentFileName(documentId, oldName, newName) {
    return patch(`${BaseUrl()}/documents/${documentId}`, { oldName, newName });
}

export async function removeDocument(documentId) {
    return deleteRequest(`${BaseUrl()}/documents/${documentId}`);
}

export async function documentViewed(documentId) {
    return patch(`${BaseUrl()}/documents/${documentId}/mark-as-viewed`, {});
}

export async function updateSectionOrder(newOrder) {
    return patch(`${BaseUrl()}/documents/sections`, newOrder);
}

export async function checkTokens() {
    const search = new URLSearchParams(window.location.search);
    const entityType = Entities[search.get('e')];
    const token = search.get('t');
    const otpToken = search.get('o');

    const params = {
        id: atob(search.get('u')),
        token: token ? token : '',
        otpToken: otpToken ? otpToken : '',
    };
    return post(`${getProjectUrl()}api/${entityType}/check-url-tokens`, params);
}
