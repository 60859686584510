import React from 'react';
import { ExtendedManager } from './managers';
import { Form, Input, ExternalValidationError, ValidationContext } from '../utils/inputs';
import PermissionsList from '../permission-profiles/permissions-list';
import { DisplayPublicGpgKey } from './helpers/DisplayPublicGpgKey';

interface Props {
    manager: ExtendedManager;
    validationError?: ExternalValidationError;

    onSaved: (params: any) => void;
    editDisabled: boolean;
}

type SupportedFields = 'phoneNumber';

export default class ManagerPersonalDetails extends React.Component<Props, ExtendedManager> {
    _validationContext = new ValidationContext();

    constructor(props: Props) {
        super(props);
        this.state = new ExtendedManager();
    }

    UNSAFE_componentWillReceiveProps(props: Props) {
        if (props.manager && props.manager.id) {
            this.setState(props.manager);
        }
        if (props.validationError) {
            this._validationContext.pushError(props.validationError);
        }
    }

    UNSAFE_componentWillMount() {
        this.setState(this.props.manager);
    }

    renderInputField(options: {
        readonly: boolean;
        value: string;
        name: string;
        required: boolean;
        type: string;
        isFocused?: boolean;
    }) {
        if (options.readonly) {
            return <div className="col-sm-8">{options.value}</div>;
        } else {
            return (
                <div className="row col-sm-4">
                    <Input
                        className="form-control"
                        context={this._validationContext}
                        isFocused={options.isFocused}
                        name={options.name}
                        onChange={this._handleInputChange.bind(this)}
                        required={options.required}
                        type={options.type}
                        value={options.value}
                    />
                </div>
            );
        }
    }

    readonlyTitleWithValue(title: string, value: string) {
        return (
            <div className="wfp-form--group row">
                <label className="col-sm-4 ta-right">{title}</label>
                <div className="col-sm-8">{value}</div>
            </div>
        );
    }

    render() {
        const manager = this.props.manager;
        return (
            <main>
                <Form className="wfp-form" context={this._validationContext} onSubmit={this._saveChanges.bind(this)}>
                    {this.readonlyTitleWithValue('First Name', this.state.firstName)}
                    {this.readonlyTitleWithValue('Last Name', this.state.lastName)}
                    {this.readonlyTitleWithValue('Email', this.state.email)}
                    {this.readonlyTitleWithValue('Agency', this.state.agency)}
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Public Key</label>
                        <div className="col-sm-8">
                            <DisplayPublicGpgKey publicGpgKey={this.state.publicKey} />
                        </div>
                    </div>{' '}
                    {/*{this.readonlyTitleWithValue('Profile', this.state.profile.name)}*/}
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Phone Number</label>
                        {this.renderInputField({
                            readonly: this.props.editDisabled,
                            value: this.state.phoneNumber,
                            name: 'phoneNumber',
                            required: true,
                            type: 'text',
                            isFocused: true,
                        })}
                    </div>
                    {!this.props.editDisabled && (
                        <div className="wfp-form--actions text-center">
                            <div className="col-sm-8 col-sm-offset-4">
                                <button className="wfp-btn--primary" type="submit">
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    )}
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Permissions</label>
                        <div className="col-sm-8">
                            <PermissionsList editDisabled={true} permissions={manager?.profile?.permissions} />
                        </div>
                    </div>
                </Form>
            </main>
        );
    }

    private _handleInputChange({ target }) {
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name: SupportedFields = target.name;
        this.setState({ [name]: value });
    }

    private _saveChanges(event) {
        const params = {
            id: this.props.manager.id,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
        };
        this.props.onSaved(params);
        event.preventDefault();
    }
}
