import React from 'react';
import { FC } from 'react';
import { ManagerPermission } from '../../permission-profiles/permission';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '@wfp-common/auth/AuthContext';

interface PermissionCheckerProps {
    requiredPermissions: ManagerPermission[];
}

export const PermissionChecker: FC<PermissionCheckerProps> = (props) => {
    const { loggedUser, appHomePath } = useAuthContext();

    if (loggedUser && !loggedUser.hasPermissions(props.requiredPermissions)) {
        return <Navigate to={appHomePath} />;
    }

    return <>{props.children}</>;
};
