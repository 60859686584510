import { ProjectType } from '../../../app/appConfig';
import { windowStorage, WindowStorageToken } from '../../../windowStorage';

export function getProjectUrl(): ProjectType {
    return (windowStorage.getItem('projectType') as ProjectType) || ProjectType.default;
}

export const BaseUrl = () => {
    return `${getProjectUrl()}api/beneficiary-support-panel`;
};

export function get(url): Promise<any> {
    return fetch(url, {
        method: 'GET',
        headers: jsonHeaders(),
    });
}

export function post(url, body): Promise<any> {
    return fetch(url, {
        method: 'POST',
        headers: jsonHeaders(),
        body: JSON.stringify(body),
    });
}

export function fetch(url, requestInit) {
    return window.fetch(url, requestInit).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            throw response;
        }
        if (response.status !== 204) {
            return response.json();
        }
        return response;
    });
}

function jsonHeaders() {
    return new Headers(
        Object.assign(
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            authHeaders()
        )
    );
}

function authHeaders() {
    return {
        Authorization: windowStorage.getItem(WindowStorageToken.partnerUserApiToken) || '',
    };
}
