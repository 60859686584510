import { errorAction, warningAction } from './notifications';
import { ActionCreators } from '../login/auth';
import { ActionTypes } from './notifications';
import Manager from '../managers/manager';

let mouseMoveFunction, mouseMoveEndFunction, inactivityLogoutFunction;
let timeout, moveEndTimeout, inactivityLogoutTimeout;
let mouseMoveFunctionListener, mouseMoveEndFunctionListener, inactivityLogoutFunctionListener;

const MINUTES_TO_INACTIVITY_POPUP_FOR_PARK_POSTERS = 12;
const MINUTES_TO_INACTIVITY_POPUP_FOR_NON_PARK_POSTERS = 27;
const ONE_MINUTE = 60 * 1000;
const MINUTES_TO_INACTIVITY_LOGOUT = 3;
const MOUSE_MOVE_EVENT = 'mousemove';
const MOUSE_MOVE_END_EVENT = 'MOUSE_MOVE_END_EVENT';
const INACTIVITY_LOGOUT_EVENT = 'INACTIVITY_LOGOUT_EVENT';

function clearInactiveLogoutTimer() {
    clearTimeout(timeout);
    clearTimeout(moveEndTimeout);
    clearTimeout(inactivityLogoutTimeout);
}

export function removeInactivityAlertListeners() {
    clearInactiveLogoutTimer();
    window.removeEventListener(MOUSE_MOVE_EVENT, mouseMoveFunctionListener, false);
    window.removeEventListener(MOUSE_MOVE_END_EVENT, mouseMoveEndFunctionListener, false);
    window.removeEventListener(INACTIVITY_LOGOUT_EVENT, inactivityLogoutFunctionListener, false);
}

export function addInactivityListeners(store, manager: Manager) {
    const hasParkPost = manager
        ? manager.profile?.permissions.filter(
              (permission) => permission.includes('Park') || permission.includes('Post')
          ).length
        : false;

    const MINUTES_TO_INACTIVITY_POPUP = hasParkPost
        ? MINUTES_TO_INACTIVITY_POPUP_FOR_PARK_POSTERS
        : MINUTES_TO_INACTIVITY_POPUP_FOR_NON_PARK_POSTERS;
    const INACTIVITY_POPUP_MESSAGE = `You have been inactive for ${MINUTES_TO_INACTIVITY_POPUP} minutes, you will be logged out in ${MINUTES_TO_INACTIVITY_LOGOUT} minutes. Move your mouse to prevent it.`;

    removeInactivityAlertListeners();

    window.addEventListener('unload', (ev) => {
        ev.preventDefault();
        clearInactiveLogoutTimer();
        removeInactivityAlertListeners();
        return (ev.returnValue = true);
    });

    mouseMoveFunction = () => {
        if (timeout !== undefined) {
            clearInactiveLogoutTimer();
        }
        const state = store.getState();
        if (
            state.notification &&
            state.notification.type === 'warning' &&
            state.notification.message === INACTIVITY_POPUP_MESSAGE
        ) {
            store.dispatch({ type: ActionTypes.clear });
        }
        timeout = setTimeout(function () {
            window.dispatchEvent(new Event(MOUSE_MOVE_END_EVENT));
        }, 100);
    };

    mouseMoveEndFunction = () => {
        moveEndTimeout = setTimeout(function () {
            store.dispatch(warningAction(INACTIVITY_POPUP_MESSAGE));
            inactivityLogoutTimeout = setTimeout(function () {
                window.dispatchEvent(new Event(INACTIVITY_LOGOUT_EVENT));
            }, ONE_MINUTE * MINUTES_TO_INACTIVITY_LOGOUT);
        }, ONE_MINUTE * MINUTES_TO_INACTIVITY_POPUP);
    };

    inactivityLogoutFunction = () => {
        store.dispatch(ActionCreators.logout()).then(() => {
            store.dispatch(errorAction('You have been logged out due to inactivity'));
        });
    };

    mouseMoveFunctionListener = mouseMoveFunction.bind(this);
    mouseMoveEndFunctionListener = mouseMoveEndFunction.bind(this);
    inactivityLogoutFunctionListener = inactivityLogoutFunction.bind(this);

    window.addEventListener(MOUSE_MOVE_EVENT, mouseMoveFunctionListener, false);
    window.addEventListener(MOUSE_MOVE_END_EVENT, mouseMoveEndFunctionListener, false);
    window.addEventListener(INACTIVITY_LOGOUT_EVENT, inactivityLogoutFunctionListener, false);

    window.dispatchEvent(new Event(MOUSE_MOVE_END_EVENT));
}
