import React from 'react';
import { useEffect, useState } from 'react';
import { useManagerFilters } from './helpers/useManagerFilters';
import { useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';
import { Filter } from '../utils/FilterTypes';

interface Props {
    setShowStartDateColumn: (showStartDateColumn: boolean) => void;
}

export function ManagerFilters({ setShowStartDateColumn }: Props) {
    const [activeFilters, setActiveFilters] = useState<Filter[]>([]);
    const { filters, handleApplyFilters } = useManagerFilters();
    const sortingOrder = useSelector((state: State) => state.managers.sortingOptions);

    useEffect(() => {
        handleApplyFilters(activeFilters);
    }, [sortingOrder]);

    const handleChangeFilters = (filters: Filter[]) => {
        const statusFilter = filters.find((filter) => filter.name === 'status');
        const isFutureUsersOption = !!statusFilter?.value.find((option) => option.value === 'future');

        setShowStartDateColumn(isFutureUsersOption);
        setActiveFilters(filters);
        handleApplyFilters(filters);
    };

    return null;
    /*    return (
        <FiltersComponent
            defaultFilters={filters}
            filters={filters}
            onApplyFilters={handleChangeFilters}
            withApply
            withIsSelected
        />
    );*/
}
