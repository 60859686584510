import React from 'react';
import { FC, useState } from 'react';
import { hasFeatureAccess } from '../../login/auth';
import { ManagerPermission } from '../../permission-profiles/permission';
import ManagerDetails from '../ManagerDetails';
import Switch from 'rc-switch';
import { useDispatch, useSelector } from 'react-redux';
import { InformationDialog } from '../../utils/Dialogs';
import { ActionCreators, ExtendedManager } from '../managers';
import { useManagerParams } from '../hooks/useManagerParams';
import { EntityType } from '../../authorization/taskAuthorizations';
import State from '@wfp-root-app/store/state';

export const Information: FC = () => {
    const {
        auth,
        managers: { validationError },
    } = useSelector((state: State) => state);
    const dispatch = useDispatch();
    const managerParams = useManagerParams();

    const [cannotParkedThemSelf, setCannotParkedThemSelf] = useState(false);
    const [hasPendingDetailsUpdate, setHasPendingDetailsUpdate] = useState(false);

    const isCreateManagerPage = () => {
        return !managerParams.managerId;
    };

    const [isEditEnabled, setIsEditEnabled] = useState(isCreateManagerPage());

    const hasWritePermission = () => {
        return hasFeatureAccess(auth, ManagerPermission.managersManagementPark);
    };

    const isThisManagerParked = (parkedByManagerId) => {
        return (
            managerParams.manager &&
            managerParams.manager.createdByManager &&
            parkedByManagerId === managerParams.manager.createdByManager.id
        );
    };

    const renderPendingUpdatesDialog = () => {
        return (
            <InformationDialog
                message="User details cannot be currently edited because another edit is waiting to be posted"
                onClose={() => setHasPendingDetailsUpdate(false)}
                title="Pending Updates"
            />
        );
    };

    const renderCannotParkedDialog = () => {
        return (
            <InformationDialog
                message="User details cannot be edited by yourself"
                onClose={() => setCannotParkedThemSelf(false)}
                title="Forbidden Access"
            />
        );
    };

    const authorizeManager = (managerId: string) => {
        const actionRef = ActionCreators.authorizeManager(managerId);
        actionRef(dispatch);
    };

    const cancelPark = (managerId: string) => {
        const actionRef = ActionCreators.cancelPark(managerId);
        actionRef(dispatch);
    };

    const saveManager = (manager: ExtendedManager) => {
        if (isCreateManagerPage()) {
            const actionRef = ActionCreators.createManager(manager);
            actionRef(dispatch);
        } else {
            const actionRef = ActionCreators.editManager(managerParams.manager, manager);
            actionRef(dispatch);
        }
    };

    const resetOtpPasswordRequest = (manager: ExtendedManager) => {
        const actionRef = ActionCreators.resetOtpPasswordRequest(manager);
        actionRef(dispatch);
    };

    const resetOtpRequest = (manager: ExtendedManager) => {
        const actionRef = ActionCreators.resetOtpRequest(manager);
        actionRef(dispatch);
    };

    const resetPasswordRequest = (manager: ExtendedManager) => {
        const actionRef = ActionCreators.resetPasswordRequest(manager);
        actionRef(dispatch);
    };

    const toggleEdit = (checked: boolean) => {
        let hasPendingDetailsUpdate = managerParams.manager.authorizedAt ? false : checked;
        const cannotParkedThemSelf =
            managerParams.manager.id !== auth.manager.id || auth.manager.id === '1' ? false : checked;

        if (managerParams.manager) {
            const hasManagerDetailsEntity = managerParams.manager.pendingEntityUpdates.filter(
                (update) => update.entityType === EntityType.managerDetails
            );
            hasPendingDetailsUpdate = hasManagerDetailsEntity.length !== 0 || hasPendingDetailsUpdate;
        }
        if (cannotParkedThemSelf) {
            setCannotParkedThemSelf(cannotParkedThemSelf);
        } else if (hasPendingDetailsUpdate) {
            setHasPendingDetailsUpdate(hasPendingDetailsUpdate);
        } else {
            setIsEditEnabled(checked);
        }
    };

    const renderGeneralInformation = () => {
        const isEditSwitchVisible = !isCreateManagerPage() && hasWritePermission();
        const thisManagerParked = auth.manager ? isThisManagerParked(auth.manager.id) : true;
        const postEnabled = hasFeatureAccess(auth, ManagerPermission.managersManagementPost);

        return (
            <div>
                <h6>General Information</h6>
                <div className={'row'}>
                    {isEditSwitchVisible && <label className="col-sm-4 ta-right">Toggle Edit</label>}
                    {isEditSwitchVisible && (
                        <div className="col-sm-8 pv1">
                            {hasPendingDetailsUpdate && renderPendingUpdatesDialog()}
                            {cannotParkedThemSelf && renderCannotParkedDialog()}
                            {
                                <Switch
                                    checked={isEditEnabled}
                                    disabled={!managerParams.manager.authorizedAt}
                                    onClick={toggleEdit}
                                />
                            }
                        </div>
                    )}
                </div>
                {auth && auth.manager && (
                    <ManagerDetails
                        authState={auth}
                        authorizeManager={authorizeManager}
                        cancelPark={cancelPark}
                        editDisabled={!isEditEnabled}
                        manager={managerParams.manager}
                        managerId={auth.manager.id}
                        onSaved={saveManager}
                        postEnabled={postEnabled}
                        resetOtpPasswordRequest={resetOtpPasswordRequest}
                        resetOtpRequest={resetOtpRequest}
                        resetPasswordRequest={resetPasswordRequest}
                        thisManagerParked={thisManagerParked}
                        validationError={validationError}
                    />
                )}
            </div>
        );
    };

    return renderGeneralInformation();
};
