import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { currencyFormatter, displayTime } from './utils';
import {
    ActionCreators,
    AsyncTask,
    AsyncTaskRawStatus,
    AsyncTaskScheduler,
    AsyncTaskStatus,
    AsyncTasksTypes,
    mapAsyncTaskTypeToRequiredPostingAccessFeature,
    SapPoToAmountDialogType,
    SapPoToAmountEntry,
    SapPoToAmountRequestAction,
    Signature,
} from './asyncTasks';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { hasDownloadFeature } from '../managers/manager';
import { RefreshSignaler } from './refresher';
import _ from 'lodash';
import { isEmpty } from 'lodash';
import { ActionCreators as ManagerActionCreators, ManagerNickName } from '../managers/managers';
import { canCancelSubtask, canCancelWholeImport } from './validations';
import { scaleFloatToInteger } from './customMath';
import { stopPropagation } from './events';
import SapPoToAmountEntryStaticDialog from './SapPoToAmountEntryStaticDialog';
import { SESRequestsDialog } from './SESRequestsDialog';
import SapPoToAmountEntryEditableDialog from './SapPoToAmountEntryEditableDialog';
import { PredefinedCurrency } from '../app/appConfig';
import { DownloadAdditionalFileButton } from './DownloadStaticFileButton';
import { ConfirmationDialog } from './Dialogs';
import { ManagerPermission } from '../permission-profiles/permission';
import State from '@wfp-root-app/store/state';

const rawStatusesToStopRefresher = [AsyncTaskRawStatus.failed, AsyncTaskRawStatus.finished, AsyncTaskRawStatus.parked];

interface NextAction {
    style: any;
    text: string;
}

enum ImportActionTypes {
    SignLevel1 = 'Sign Level 1',
    SignLevel2 = 'Sign Level 2',
    PostCancellation = 'Post cancellation',
    ParkCancellation = 'Park cancellation',
    Post = 'Post',
    Cancel = 'Cancel',
    Reject = 'Reject',
}

export function getNextAction(row, auth, permission): NextAction[] {
    const canAuthorizeTask = hasFeatureAccess(auth, permission);

    const signatureOne = row.signatures.find((s) => s.requiredRole === ManagerPermission.beneficiariesSignLevelOne);
    const signatureTwo = row.signatures.find((s) => s.requiredRole === ManagerPermission.beneficiariesSignLevelTwo);

    const currentSignatureAction =
        signatureOne && !signatureOne.signedByManagerId
            ? {
                  signature: signatureOne,
                  permission: ManagerPermission.beneficiariesSignLevelOne,
                  text: ImportActionTypes.SignLevel1,
              }
            : signatureTwo && !signatureTwo.signedByManagerId
            ? {
                  signature: signatureTwo,
                  permission: ManagerPermission.beneficiariesSignLevelTwo,
                  text: ImportActionTypes.SignLevel2,
              }
            : null;

    const thisManagerParked = auth && auth.manager && row.createdByManagerId === auth.manager.id;

    const shouldDisplayPostForRow = (row) => {
        return row.status === AsyncTaskStatus.parked && canAuthorizeTask && !thisManagerParked;
    };
    const parkedImportCancellationText = thisManagerParked ? ImportActionTypes.Cancel : ImportActionTypes.Reject;

    const cancelSubtask = () =>
        !!row.authorizedAt &&
        !row.cancelledByManagerId &&
        !row.startedAt &&
        canCancelSubtask(auth, row) &&
        row.parentId &&
        new Date(row.scheduledFor) > new Date();
    const parkPostCancellationText = row.preCancelledAt
        ? ImportActionTypes.PostCancellation
        : ImportActionTypes.ParkCancellation;
    const signTask = () =>
        row.status === AsyncTaskStatus.toBeSigned &&
        currentSignatureAction &&
        validateSignaturesVisibility(row, row.signatures, auth, currentSignatureAction.permission);
    const nextAction: NextAction[] = [];
    if (shouldDisplayPostForRow(row)) {
        nextAction.push({
            style: { color: 'green', margin: 10 },
            text: ImportActionTypes.Post,
        });
    }
    if (
        ((thisManagerParked && row.status === AsyncTaskStatus.parked) || shouldDisplayPostForRow(row)) &&
        !row.parentId
    ) {
        nextAction.push({
            style: { color: 'red', margin: 10 },
            text: parkedImportCancellationText,
        });
    }
    if (
        canCancelWholeImport(auth, row) &&
        (row.type === AsyncTasksTypes.SCOPE_ANNOUNCEMENTS || row.type === AsyncTasksTypes.IMPORT_BENEFICIARIES) &&
        !row.parentId &&
        signatureTwo &&
        !signatureTwo.signedByManagerId
    ) {
        nextAction.push({
            style: { color: 'red', margin: 10 },
            text: parkPostCancellationText,
        });
    }
    if (cancelSubtask()) {
        nextAction.push({
            style: {},
            text: parkPostCancellationText,
        });
    }
    if (signTask() && !row.preCancelledAt) {
        nextAction.push({
            style: { color: 'green', margin: 10 },
            text: currentSignatureAction.text,
        });
    }

    return nextAction;
}

interface DispatchProps {
    getAsyncTask: (type: string, id: string) => Promise<RefreshSignaler>;
    sapPoToAmountEntriesAction: (
        asyncTaskId: string,
        sapPoToAmountEntries: Array<SapPoToAmountEntry>,
        action: SapPoToAmountRequestAction
    ) => void;
    authorizeAsyncTask: (type: string, id: string) => void;
    downloadFile: (type: string, id: string, index?: number) => string;
    sign: (signatureId: string, managerId: string) => void;
    cancelTask: (type: string, id: string, isSubtask: boolean, parkPostAction: boolean) => void;
    loadManagersNickNames: () => void;
    clearDetails: () => void;
    handlePoDetailsFetchingError: (err) => void;
    cancelRunningTask: (type: string, id: string) => void;
    uploadAdditionalFile: (file: File, id: string, type: string, managerId: string) => any;
}

interface StateProps {
    auth: AuthState;
    data: AsyncTask;
    scheduler: AsyncTaskScheduler;
    entityName: string;
    entityUrl: string;
    taskKind: string;
    subTaskKind: string;
    error: string;
    managerNickNames: ManagerNickName[];
    currency: PredefinedCurrency;
    confirmNextAction: boolean;
}

interface OwnProps {
    params: { type: string; taskId: string };
}

type Props = OwnProps & DispatchProps & StateProps;

interface AsyncTaskDetailsViewState {
    actionExecuted: boolean;
    showSapPoEntryDialog: boolean;
    showSesRequestsDialog: boolean;
    task: AsyncTask;
    confirmDialogOptions: {
        showDialog: boolean;
        confirmAction: () => void;
    };
}

export class AsyncTaskDetailsView extends React.Component<Props, AsyncTaskDetailsViewState> {
    row: AsyncTask;
    private refresher?: RefreshSignaler;
    private featureAccess: ManagerPermission;

    constructor(props) {
        super(props);
        this.props.clearDetails();
        this.state = {
            actionExecuted: false,
            showSapPoEntryDialog: false,
            showSesRequestsDialog: false,
            task: null,
            confirmDialogOptions: {
                showDialog: false,
                confirmAction: () => {
                    return;
                },
            },
        };
    }

    markComponentAsActionExecuted() {
        this.setState({ actionExecuted: true });
    }

    resetComponentActionExecutionFlag() {
        this.setState({ actionExecuted: false });
    }

    async UNSAFE_componentWillMount() {
        await this.props.loadManagersNickNames();
        this.refresher = await this.props.getAsyncTask(this.props.params.type, this.props.params.taskId);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.refresher && this.props.data && !rawStatusesToStopRefresher.includes(this.props.data.rawStatus)) {
            this.refresher.stop();
        }
        if (this.props.data !== nextProps.data) {
            this.resetComponentActionExecutionFlag();
        }
    }

    componentWillUnmount() {
        if (this.refresher) {
            this.refresher.stop();
        }
    }

    async sign(id: string) {
        await this.props.sign(id, this.props.auth.manager.id);
        this.refresher.refresh();
    }

    renderWarningsErrorRow(rowName: string, warningsErrorArrayToMap: Array<string>) {
        return (
            <tr>
                <th>{rowName}</th>
                <td>
                    {warningsErrorArrayToMap.map((element, index) => {
                        return !isEmpty(element) ? <p key={index}>{element}</p> : null;
                    })}
                    {rowName === 'Warnings' ? <p>Check report for details</p> : null}
                </td>
            </tr>
        );
    }

    renderDeduplicationWarningsRow(deduplicationCount: number) {
        return (
            <tr>
                <th>UAOP Rows Count</th>
                <td>{deduplicationCount}</td>
            </tr>
        );
    }

    renderStaticPoDetailsDialog(dialogType: SapPoToAmountDialogType, currency: PredefinedCurrency) {
        if (this.state.showSapPoEntryDialog) {
            return (
                <SapPoToAmountEntryStaticDialog
                    onClose={this.onClosePoDetailsDialog.bind(this)}
                    existingSapPoToAmountEntries={this.props.data.sapPoToAmountEntries}
                    onSubmit={this.onSapPoToAmountEntrySubmit.bind(this)}
                    dialogType={dialogType}
                    currency={currency}
                />
            );
        }
    }

    renderEditablePoDetailsDialog() {
        if (this.state.showSapPoEntryDialog) {
            return (
                <SapPoToAmountEntryEditableDialog
                    onClose={this.onClosePoDetailsDialog.bind(this)}
                    onSubmit={this.onSapPoToAmountEntrySubmit.bind(this)}
                    errorHandler={this.props.handlePoDetailsFetchingError}
                    currency={this.props.currency}
                    fileAmount={this.props.data.additionalInfo.rowSum}
                />
            );
        }
    }

    renderSesRequestsDialog() {
        if (this.state.showSesRequestsDialog) {
            return (
                <SESRequestsDialog
                    onClose={this.onCloseSesRequestsDialog.bind(this)}
                    sesRequests={this.props.data.sesRequests}
                    currency={this.props.currency}
                />
            );
        }
    }

    onClosePoDetailsDialog() {
        this.setState({ showSapPoEntryDialog: false });
    }

    onCloseSesRequestsDialog() {
        this.setState({ showSesRequestsDialog: false });
    }

    onSapPoToAmountEntrySubmit(sapPoToAmountEntries: Array<SapPoToAmountEntry>, action: SapPoToAmountRequestAction) {
        this.props.sapPoToAmountEntriesAction(this.props.data.id, sapPoToAmountEntries, action);
        this.onClosePoDetailsDialog();
    }

    renderManualPoParkedAtInfo = (managerNickNames: ManagerNickName[]) => {
        const { sapPoToAmount } = this.props.data;
        if (sapPoToAmount && sapPoToAmount.parkedAt && sapPoToAmount.parkedByManagerId) {
            return (
                <div>
                    Parked at {displayTime(sapPoToAmount.parkedAt)} by{' '}
                    {getManagerEmail(sapPoToAmount.parkedByManagerId, managerNickNames)}
                </div>
            );
        }
    };

    renderManualPoPostedAtInfo = (managerNickNames: ManagerNickName[]) => {
        const { sapPoToAmount } = this.props.data;
        if (sapPoToAmount && sapPoToAmount.authorizedAt && sapPoToAmount.authorizedByManagerId) {
            return (
                <div>
                    Posted at {displayTime(sapPoToAmount.authorizedAt)} by{' '}
                    {getManagerEmail(sapPoToAmount.authorizedByManagerId, managerNickNames)}
                </div>
            );
        }
    };

    renderManualPoCancelledAtInfo = (managerNickNames: ManagerNickName[]) => {
        const { sapPoToAmount } = this.props.data;
        if (sapPoToAmount && sapPoToAmount.cancelledAt && sapPoToAmount.cancelledByManagerId) {
            return (
                <div>
                    Rejected at {displayTime(sapPoToAmount.cancelledAt)} by{' '}
                    {getManagerEmail(sapPoToAmount.cancelledByManagerId, managerNickNames)}
                </div>
            );
        }
    };

    renderShowPoDialogActionButton = () => {
        const { sapPoToAmount, rawStatus } = this.props.data;

        if (
            rawStatus === AsyncTaskRawStatus.pendingManualPoEntryPark &&
            hasFeatureAccess(this.props.auth, ManagerPermission.beneficiariesEntitlementPark)
        ) {
            return this.renderPoDialogLink(SapPoToAmountDialogType.editable);
        } else if (
            rawStatus === AsyncTaskRawStatus.pendingManualPoEntryPost &&
            this.props.auth.manager &&
            sapPoToAmount.parkedByManagerId.toString() !== this.props.auth.manager.id.toString() &&
            hasFeatureAccess(this.props.auth, ManagerPermission.beneficiariesEntitlementVerify)
        ) {
            return this.renderPoDialogLink(SapPoToAmountDialogType.authorizable);
        } else if (sapPoToAmount && sapPoToAmount.parkedAt && !sapPoToAmount.cancelledAt) {
            return this.renderPoDialogLink(SapPoToAmountDialogType.display);
        } else {
            return '-';
        }
    };

    onCloseDialog = () => {
        this.setState({
            ...this.state,
            confirmDialogOptions: {
                showDialog: false,
                confirmAction: () => {
                    return;
                },
            },
        });
    };

    renderConfirmDialog = () => {
        const { confirmAction } = this.state.confirmDialogOptions;
        const message = `Have you read additional files ?`;

        const onConfirmHandler = () => {
            confirmAction();
            this.onCloseDialog();
        };

        return (
            <ConfirmationDialog
                message={message}
                title={`Confirm`}
                onConfirm={onConfirmHandler}
                onClose={this.onCloseDialog}
            />
        );
    };

    goNextActionHandler = (actionName: string, currentSignatureAction) => {
        const isAdditionalFile = this.props.data.additionalFiles.length > 0;

        const post = actionName === ImportActionTypes.Post;
        const cancelOrReject = actionName === ImportActionTypes.Cancel || actionName === ImportActionTypes.Reject;
        const parkOrPostCancellation =
            actionName === ImportActionTypes.ParkCancellation || actionName === ImportActionTypes.PostCancellation;
        const sign = actionName === ImportActionTypes.SignLevel1 || actionName === ImportActionTypes.SignLevel2;

        switch (true) {
            case post:
                if (this.props.confirmNextAction && isAdditionalFile) {
                    this.setState({
                        ...this.state,
                        confirmDialogOptions: {
                            showDialog: true,
                            confirmAction: this._authorizeTask.bind(this, this.props.data),
                        },
                    });
                } else {
                    this._authorizeTask(this.props.data);
                }
                break;
            case cancelOrReject:
                this.cancelTask(this.props.data.type, this.props.data.id, false, false);
                break;
            case parkOrPostCancellation:
                this.cancelTask(this.props.data.type, this.props.data.id, false, true);
                break;
            case sign:
                if (this.props.confirmNextAction && isAdditionalFile) {
                    this.setState({
                        ...this.state,
                        confirmDialogOptions: {
                            showDialog: true,
                            confirmAction: this.sign.bind(this, currentSignatureAction.signature.id),
                        },
                    });
                } else {
                    this.sign(currentSignatureAction.signature.id);
                }
        }
    };

    renderPoDialogLink(sapPoDialogType: SapPoToAmountDialogType) {
        return (
            <div>
                {
                    <span onClick={stopPropagation(() => {})}>
                        {sapPoDialogType === SapPoToAmountDialogType.editable
                            ? this.renderEditablePoDetailsDialog()
                            : this.renderStaticPoDetailsDialog(sapPoDialogType, this.props.currency)}
                        <a onClick={() => this.setState({ showSapPoEntryDialog: true })}>{sapPoDialogType}</a>
                    </span>
                }
            </div>
        );
    }

    renderSapPoToAmountElements = (managerNickNames: Array<ManagerNickName>) => {
        return (
            <div>
                {this.renderManualPoParkedAtInfo(managerNickNames)}
                {this.renderManualPoPostedAtInfo(managerNickNames)}
                {this.renderManualPoCancelledAtInfo(managerNickNames)}
                {this.renderShowPoDialogActionButton()}
            </div>
        );
    };

    renderSESDetails = () => {
        return (
            <div>
                {
                    <span onClick={stopPropagation(() => {})}>
                        {this.renderSesRequestsDialog()}
                        <a onClick={() => this.setState({ showSesRequestsDialog: true })}>Show SES requests</a>
                    </span>
                }
            </div>
        );
    };

    renderDownloadFile() {
        const { batchFileNames, errors, type } = this.props.data;
        if (this.props.data.fileExpired) {
            return <td>File Expired</td>;
        }
        if (errors && errors.length && type.includes('-export')) {
            return <td>Can not download file - export task finished with errors.</td>;
        }
        if (!batchFileNames) {
            return (
                <td>
                    <a onClick={() => this.props.downloadFile(this.props.params.type, this.props.params.taskId)}>
                        {this.props.data.fileName}
                    </a>
                    {this.props.error && <label>{this.props.error}</label>}
                    {/* TODO check why refresher doesn't work identically every time on details page */}
                </td>
            );
        } else {
            return (
                <td>
                    {batchFileNames.map((fileName, idx) => {
                        return (
                            <div>
                                <a
                                    onClick={() =>
                                        this.props.downloadFile(this.props.params.type, this.props.params.taskId, idx)
                                    }
                                >
                                    {fileName}
                                </a>
                                {this.props.error && <label>{this.props.error}</label>}
                            </div>
                        );
                    })}
                </td>
            );
        }
    }

    renderAdditionalFileUpload() {
        return (
            <tr>
                <th>Add Files</th>
                <td>
                    <button className="wfp-btn replaced-input-file" type="button">
                        <span>Add</span>
                        <input
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx, text/csv, .csv, image/jpeg, .jpg, image/png, .png, application/pdf, .pdf, .docx"
                            name="data"
                            onChange={this.onAdditionalFilesUploadChange.bind(this)}
                            type="file"
                        />
                    </button>
                </td>
            </tr>
        );
    }

    renderAdditionalFilesTable() {
        const managerNickNames = this.props.managerNickNames;
        if (this.props.data.additionalFiles && this.props.data.additionalFiles.length > 0) {
            return (
                <tr>
                    <th>Added Files</th>
                    <td>
                        <table>
                            <thead>
                                <tr>
                                    <th>File</th>
                                    <th>Added at</th>
                                    <th>Added by</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.data.additionalFiles.map((file) => (
                                    <tr key={file.key}>
                                        <td>
                                            <DownloadAdditionalFileButton fileKey={file.key}>
                                                {file.fileName}
                                            </DownloadAdditionalFileButton>
                                        </td>
                                        <td>{displayTime(file.createdAt)}</td>
                                        <td>{getManagerEmail(file.addedByManagerId, managerNickNames)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </td>
                </tr>
            );
        } else {
            return null;
        }
    }

    onAdditionalFilesUploadChange(event) {
        const file = event.target.files[0];
        if (file) {
            this.props
                .uploadAdditionalFile(
                    file,
                    this.props.params.taskId,
                    this.props.params.type,
                    this.props.auth.manager.id
                )
                .then(() => {
                    console.log('refreshing');
                    this.refresher.refresh();
                });
        }
    }

    render() {
        let cancellationText = '';
        let preCancellationText = '';
        const titleForExport = this.props.data && this.props.data.type.includes('export') ? 'Export' : 'Import';
        let canDownload = false;
        if (this.props.data) {
            this.featureAccess = mapAsyncTaskTypeToRequiredPostingAccessFeature(this.props.data.type);
            preCancellationText = this.props.data.preCancelledByManager ? 'Park cancellation' : '';
            cancellationText =
                this.props.data.cancelledByManagerId === this.props.data.createdByManagerId ||
                this.props.data.preCancelledByManager
                    ? 'Cancelled'
                    : 'Rejected';
            if (this.props.auth && this.props.auth.manager) {
                canDownload = hasDownloadFeature(this.props.auth.manager, this.props.data.type);
            }
        }

        const managerNickNames = this.props.managerNickNames;
        const intl = new Intl.NumberFormat(navigator.language);

        const isExport = this.props.data && this.props.data.type.includes('-export');
        const uploadReportChildrenWithoutDuplicates =
            this.props.data &&
            _.uniqBy(
                this.props.data.children.filter((child) =>
                    [
                        AsyncTasksTypes.UPLOAD_REPORT,
                        AsyncTasksTypes.SCOPE_UPLOAD_REPORT,
                        AsyncTasksTypes.EXPORT_REPORTS_SES_PARKING,
                    ].includes(child.type)
                ),
                (child) => child.fileName
            );

        const unfinishedChildTask =
            this.props.data &&
            this.props.data.children.find(
                (child) => !child.cancelledAt && !child.finishedAt && child.type === this.props.data.type
            );

        return (
            <div>
                {this.state.confirmDialogOptions.showDialog && this.renderConfirmDialog()}
                {this.props.entityName !== 'ActivityLogs' && this.props.entityName !== 'ReportsResult' && (
                    <nav className="wfp-breadcrumbs">
                        <ol className="breadcrumbs--wrapper">
                            <li className="breadcrumbs--item">
                                <Link to="/home-basic" className="breadcrumbs--link">
                                    <span>Home</span>
                                </Link>
                            </li>
                            <li className="breadcrumbs--item">
                                <Link
                                    to={
                                        this.props.entityUrl +
                                        '/' +
                                        (this.props.taskKind || '').toLowerCase() +
                                        '/' +
                                        (this.props.subTaskKind || '').toLowerCase()
                                    }
                                    className="breadcrumbs--link"
                                >
                                    <span>
                                        {this.props.taskKind.substring(0, 1).toUpperCase() +
                                            this.props.taskKind.substring(1) +
                                            ' ' +
                                            this.props.subTaskKind.substring(0, 1).toUpperCase() +
                                            this.props.subTaskKind.substring(1)}
                                    </span>
                                </Link>
                            </li>
                            {this.props.data && (
                                <li className="breadcrumbs--item">
                                    <span className="breadcrumbs--last">{`Show Details of ${this.props.taskKind} "${this.props.data.id}"`}</span>
                                </li>
                            )}
                        </ol>
                    </nav>
                )}
                <table className="wfp-table mt4 table-hover">
                    {this.props.data && (
                        <tbody>
                            <tr>
                                <th>ID</th>
                                <td>{this.props.data.id}</td>
                            </tr>
                            {this.props.data.description && (
                                <tr>
                                    <th>Description</th>
                                    <td>{this.props.data.description}</td>
                                </tr>
                            )}
                            <tr>
                                <th>{titleForExport} Status</th>
                                <td>{!this.state.actionExecuted && <div>{this.props.data.status}</div>}</td>
                            </tr>

                            {this.props.data.progress &&
                                this.props.data.rawStatus === AsyncTaskRawStatus.inProgress &&
                                this.props.data.progress.toString() !== '1' && (
                                    <tr>
                                        <th>{titleForExport}ing Progress</th>
                                        <td>
                                            {!this.state.actionExecuted && (
                                                <div>
                                                    {` (${scaleFloatToInteger(
                                                        parseFloat(this.props.data.progress),
                                                        2
                                                    )}% – ${this.props.data.additionalInfo.lastBatchIndex}/${
                                                        this.props.data.additionalInfo.numberOfBatches
                                                    })`}
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                )}

                            {this.props.data.fileName && canDownload && (
                                <tr>
                                    <th>Download File</th>
                                    {this.renderDownloadFile()}
                                </tr>
                            )}

                            {!this.props.data.isAsyncTaskErroneous() && this.props.data.additionalInfo && (
                                <tr>
                                    <th>
                                        {this.props.data.parentId && !this.props.data.payload
                                            ? 'Info after processing'
                                            : 'File info'}
                                    </th>
                                    <td>
                                        <div>
                                            {this.props.data.additionalInfo.lastBatchIndex !== 0 &&
                                                this.props.data.additionalInfo.lastBatchIndex &&
                                                `Batch: ${this.props.data.additionalInfo.lastBatchIndex}/${this.props.data.additionalInfo.numberOfBatches}`}
                                        </div>
                                        <div>
                                            {this.props.data.additionalInfo.rowCount &&
                                                `Total file row count: ${intl.format(
                                                    this.props.data.additionalInfo.rowCount
                                                )}`}{' '}
                                            <br />
                                            {this.props.data.additionalInfo.rowSum > 0 &&
                                                `Total file amount: ${currencyFormatter(this.props.currency).format(
                                                    this.props.data.additionalInfo.rowSum
                                                )}`}
                                        </div>
                                    </td>
                                </tr>
                            )}

                            {isExport || (
                                <tr>
                                    <th>{isExport ? 'Requested' : 'Created'}</th>
                                    <td>
                                        {displayTime(this.props.data.createdAt)}
                                        {this.props.data.type === AsyncTasksTypes.SCOPE_ANNOUNCEMENTS ? (
                                            <div>by SCOPE</div>
                                        ) : (
                                            this.props.data.createdByManagerId && (
                                                <div>by {this.props.data.createdByManager.name}</div>
                                            )
                                        )}
                                    </td>
                                </tr>
                            )}

                            {this.props.data.preCancelledAt && (
                                <tr>
                                    <th>{preCancellationText}</th>
                                    <td>
                                        {displayTime(this.props.data.preCancelledAt)}
                                        <div>by {this.props.data.preCancelledByManager.name}</div>
                                    </td>
                                </tr>
                            )}

                            {this.props.data.cancelledAt && (
                                <tr>
                                    <th>{cancellationText}</th>
                                    <td>
                                        {displayTime(this.props.data.cancelledAt)}
                                        <div>by {this.props.data.cancelledByManager.name}</div>
                                    </td>
                                </tr>
                            )}
                            {!this.props.data.parentId && this.props.data.getFinancialProcessor() && (
                                <tr>
                                    <th>SAP PO Details</th>
                                    <td>{this.renderSapPoToAmountElements(managerNickNames)}</td>
                                </tr>
                            )}
                            {!this.props.data.parentId &&
                                this.props.data.sesRequests &&
                                this.props.data.sesRequests.length > 0 && (
                                    <tr>
                                        <th>SAP SES Details</th>
                                        <td>{this.renderSESDetails()}</td>
                                    </tr>
                                )}

                            <tr>
                                <th>Started</th>
                                <td>{displayTime(this.props.data.startedAt)}</td>
                            </tr>
                            <tr>
                                <th>Finished</th>
                                <td>{displayTime(this.props.data.finishedAt)}</td>
                            </tr>
                            <tr>
                                <th>Organization</th>
                                <td>{this.props.data.createdByManager.agency || 'None'}</td>
                            </tr>
                            {!this.props.data.parentId &&
                                this.props.data.type === AsyncTasksTypes.IMPORT_BENEFICIARIES &&
                                this.renderDeduplicationInfo()}

                            {this.props.data.payload && this.props.data.payload.balancesCalculationTime && (
                                <tr>
                                    <th>Data as of</th>
                                    <td>{displayTime(this.props.data.payload.balancesCalculationTime)}</td>
                                </tr>
                            )}
                            {canDownload &&
                                uploadReportChildrenWithoutDuplicates &&
                                uploadReportChildrenWithoutDuplicates.length > 0 &&
                                !unfinishedChildTask && (
                                    <tr>
                                        <th>Upload Reports</th>
                                        <td>
                                            {uploadReportChildrenWithoutDuplicates.map((uploadReportChild, index) => {
                                                return (
                                                    <p key={uploadReportChild.toString() + index}>
                                                        {uploadReportChild.fileExpired &&
                                                            `Upload Report ${uploadReportChild.fileName} is expired`}
                                                        {uploadReportChild.errors &&
                                                            'Errors in upload report creation occurred'}
                                                        {!uploadReportChild.errors && (
                                                            <a
                                                                onClick={() =>
                                                                    this.props.downloadFile(
                                                                        uploadReportChild.type,
                                                                        uploadReportChild.id
                                                                    )
                                                                }
                                                            >
                                                                {uploadReportChild.fileName}
                                                            </a>
                                                        )}
                                                    </p>
                                                );
                                            })}
                                        </td>
                                    </tr>
                                )}
                            {this.props.data.isAsyncTaskErroneous() &&
                                this.renderWarningsErrorRow('Errors', this.props.data.errors)}
                            {this.props.data.warnings &&
                                this.props.data.warnings.length > 0 &&
                                this.renderWarningsErrorRow('Warnings', this.props.data.warnings)}
                            {this.props.data.dryRunErrors &&
                                this.props.data.dryRunErrors.length > 0 &&
                                this.renderWarningsErrorRow('Pre validation warnings', this.props.data.dryRunErrors)}
                            {this.props.data.type === AsyncTasksTypes.IMPORT_BENEFICIARIES &&
                                this.props.data.deduplicationCount &&
                                this.renderDeduplicationWarningsRow(this.props.data.deduplicationCount)}
                        </tbody>
                    )}
                </table>
            </div>
        );
    }

    private async _authorizeTask(task: AsyncTask) {
        this.markComponentAsActionExecuted();
        await this.props.authorizeAsyncTask(task.type, task.id);
        this.refresher.refresh();
    }

    private async cancelTask(type: string, id: string, isSubtask: boolean, parkPostAction: boolean) {
        this.markComponentAsActionExecuted();
        await this.props.cancelTask(type, id, isSubtask, parkPostAction);
        this.refresher.refresh();
    }

    private renderDeduplicationInfo() {
        const deduplicationInfoText = getDeduplicationInfoText(this.props.data);
        const deduplicationCategories = this.props.data.deduplicationOptions?.categories;
        const prededuplicationOverlapPeriod = this.props.data.deduplicationOptions?.overlapPeriod || 'None';
        let prededuplicationOverlapPeriodText;

        switch (prededuplicationOverlapPeriod) {
            case 'none':
                prededuplicationOverlapPeriodText = 'None';
                break;
            case '1':
                prededuplicationOverlapPeriodText = '1 month';
                break;
            case '2':
            case '3':
                prededuplicationOverlapPeriodText = `${prededuplicationOverlapPeriod} months`;
        }

        return (
            <>
                {deduplicationCategories && (
                    <tr>
                        <th>Selected UAOP Categories</th>
                        <td>{deduplicationCategories.join(', ')}</td>
                    </tr>
                )}
                <tr>
                    <th>Selected UAOP Parameters</th>
                    <td>{deduplicationInfoText}</td>
                </tr>
                <tr>
                    <th>Excluded Assisted Households Period</th>
                    <td>{prededuplicationOverlapPeriodText}</td>
                </tr>
            </>
        );
    }
}

type PickedAsyncTaskProps = Pick<AsyncTask, 'deduplicationOptions'>;
const getDeduplicationInfoText = (pickedProps: PickedAsyncTaskProps) => {
    const { uaopFlag } = pickedProps.deduplicationOptions;
    if (uaopFlag) {
        return uaopFlag.replace(/[A-Z]/g, ' $&').replace(' No ', ' Keep ').replace(' Yes ', ' Skip ').trim();
    }

    return 'None';
};

function validateSignaturesVisibility(
    asyncTask: AsyncTask,
    signatures: Signature[],
    authState: AuthState,
    requiredFeatureAccess: ManagerPermission
) {
    if (
        !hasFeatureAccess(authState, requiredFeatureAccess) ||
        asyncTask.isAsyncTaskErroneous() ||
        asyncTask.finishedAt
    ) {
        return false;
    }

    if (requiredFeatureAccess === ManagerPermission.beneficiariesSignLevelTwo) {
        const firstSign = signatures.find((s) => s.requiredRole === ManagerPermission.beneficiariesSignLevelOne);
        if (firstSign.signedByManagerId.toString() === authState.manager.id) {
            return false;
        }
    }
    if (asyncTask.type === AsyncTasksTypes.IMPORT_BENEFICIARIES) {
        return asyncTask.authorizedByManager;
    }

    if (asyncTask.type === AsyncTasksTypes.SCOPE_ANNOUNCEMENTS) {
        return asyncTask.parkedAt;
    } else {
        return false;
    }
}

function getManagerEmail(managerId: number, managersNickName: ManagerNickName[]) {
    const manager = managerId ? managersNickName.find((name) => name.id === managerId.toString()) : null;
    if (manager) {
        return manager.email;
    }
    return managerId;
}

function determineEntityData(entity: string): { entityName: string; entityUrl: string } {
    switch (entity) {
        case 'beneficiary':
            return { entityName: 'Entitlements', entityUrl: '/entitlements' };
        case 'activity':
            return { entityName: 'ActivityLogs', entityUrl: null };
        case 'users':
            return { entityName: 'Admins', entityUrl: '/admin' };
        case 'download-mapping':
            return { entityName: 'TaxIdMapping', entityUrl: '/entitlements' };
        case 'upload-family-mapping':
        case 'upload-remove-entitlements':
        case 'upload-remove-deduplications':
            return { entityName: 'Entitlements', entityUrl: '/entitlements' };
        case 'entitlements':
            return {
                entityName: 'Entitlements',
                entityUrl: '/entitlements/download/entitlements',
            };
        case 'deduplicated-entitlements':
            return {
                entityName: 'DeduplicatedEntitlements',
                entityUrl: '/entitlements/download/deduplicated-entitlements',
            };
        case 'exported-from-statistics':
            return {
                entityName: 'Exported from statistics',
                entityUrl: '',
            };
    }
}

function mapStateToProps(state: State): StateProps {
    let subTaskKind = '';
    const splitTypeString = ((state.asyncTasks.details || { type: '' }).type || '').split('-', 3);
    let [entity, taskKind = ''] = splitTypeString;

    if (state.asyncTasks.details && state.asyncTasks.details.type.includes('export')) {
        taskKind = 'Download';
    }

    switch (state.asyncTasks.details?.type) {
        case AsyncTasksTypes.EXPORT_BENEFICIARIES_TAX_ID_MAPPING:
            entity = 'download-mapping';
            taskKind = 'download';
            break;
        case AsyncTasksTypes.EXPORT_BENEFICIARIES_FROM_STATS:
            entity = 'exported-from-statistics';
            taskKind = 'exported-from-statistics';
            break;
        case AsyncTasksTypes.EXPORT_BENEFICIARIES_ENTITLEMENTS:
            entity = 'entitlements';
            taskKind = 'file';
            break;
        case AsyncTasksTypes.EXPORT_BENEFICIARIES_DEDUPLICATED_ENTITLEMENTS:
            entity = 'deduplicated-entitlements';
            taskKind = 'file';
            break;
        case AsyncTasksTypes.UPLOAD_TAX_ID_FAMILY_TAX_ID_MAPPING:
            entity = 'upload-family-mapping';
            taskKind = 'import';
            break;
        case AsyncTasksTypes.UPLOAD_REMOVE_ENTITLEMENTS:
            entity = 'upload-remove-entitlements';
            taskKind = 'import';
            break;
        case AsyncTasksTypes.UPLOAD_REMOVE_DEDUPLICATIONS:
            entity = 'upload-remove-deduplications';
            taskKind = 'import';
            break;
    }

    const { entityName = null, entityUrl = null } = determineEntityData(entity) || {};

    return {
        data: state.asyncTasks.details,
        scheduler: state.asyncTasks.schedulerDetails,
        entityName,
        entityUrl,
        taskKind,
        subTaskKind,
        error: state.asyncTasks.error,
        auth: state.auth,
        managerNickNames: state.managers.managerNickNameList,
        currency: state.appConfig.entitlementCurrencyConfig,
        confirmNextAction: true,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAsyncTask: bindActionCreators(ActionCreators.getAsyncTask, dispatch),
        sapPoToAmountEntriesAction: bindActionCreators(ActionCreators.sapPoToAmountEntriesAction, dispatch),
        authorizeAsyncTask: bindActionCreators(ActionCreators.authorizeAsyncTask, dispatch),
        downloadFile: bindActionCreators(ActionCreators.downloadFile, dispatch),
        sign: bindActionCreators(ActionCreators.sign, dispatch),
        cancelTask: bindActionCreators(ActionCreators.cancelTask, dispatch),
        loadManagersNickNames: bindActionCreators(ManagerActionCreators.loadManagersNickNames, dispatch),
        clearDetails: bindActionCreators(ActionCreators.clearDetails, dispatch),
        handlePoDetailsFetchingError: bindActionCreators(ActionCreators.handlePoDetailsFetchingError, dispatch),
        cancelRunningTask: bindActionCreators(ActionCreators.cancelRunningTask, dispatch),
        uploadAdditionalFile: bindActionCreators(ActionCreators.uploadAdditionalFile, dispatch),
    };
}

const withHooks = (Component: any) => {
    return (props: any) => {
        const params = useParams();
        return <Component {...props} params={{ type: params.type, taskId: params.taskId }} />;
    };
};

export default connect<StateProps, DispatchProps, any>(
    mapStateToProps,
    mapDispatchToProps as any
)(withHooks(AsyncTaskDetailsView));
