import moment from 'moment';
import { Moment } from 'moment';

export type PredefinedDateFilter =
    | 'THIS_WEEK'
    | 'LAST_WEEK'
    | 'THIS_MONTH'
    | 'LAST_MONTH'
    | 'THIS_YEAR'
    | 'LAST_YEAR'
    | 'TODAY'
    | 'YESTERDAY';

export class PredefinedDateFilters {
    static today: PredefinedDateFilter = 'TODAY';
    static yesterday: PredefinedDateFilter = 'YESTERDAY';
    static thisWeek: PredefinedDateFilter = 'THIS_WEEK';
    static lastWeek: PredefinedDateFilter = 'LAST_WEEK';
    static thisMonth: PredefinedDateFilter = 'THIS_MONTH';
    static lastMonth: PredefinedDateFilter = 'LAST_MONTH';
    static thisYear: PredefinedDateFilter = 'THIS_YEAR';
    static lastYear: PredefinedDateFilter = 'LAST_YEAR';
}

export const defaultDateLimit: DateFilterLimit = {
    minDate: new Date(2017, 1, 1),
    maxDate: new Date(),
};

export type CustomDateFilter = 'CUSTOM';

export class CustomDateFilters {
    static custom: CustomDateFilter = 'CUSTOM';
}

export interface DateFilterRange {
    startDate: Date;
    endDate: Date;
}

export interface DateFilterLimit {
    maxDate: Date;
    minDate: Date;
}

export type DateFilter = PredefinedDateFilter | CustomDateFilter;

export function stringForDateFilter(filter: DateFilter) {
    switch (filter) {
        case PredefinedDateFilters.today:
            return 'Today';
        case PredefinedDateFilters.yesterday:
            return 'Yesterday';
        case PredefinedDateFilters.thisWeek:
            return 'This week';
        case PredefinedDateFilters.lastWeek:
            return 'Last week';
        case PredefinedDateFilters.thisMonth:
            return 'This month';
        case PredefinedDateFilters.lastMonth:
            return 'Last month';
        case PredefinedDateFilters.thisYear:
            return 'This year';
        case PredefinedDateFilters.lastYear:
            return 'Last year';
        case CustomDateFilters.custom:
            return 'Custom';
    }
}

export function stringForDateRange(dateRange: DateFilterRange): string {
    const start = moment(dateRange.startDate).format('DD/MM/YYYY');
    const end = moment(dateRange.endDate).format('DD/MM/YYYY');
    return start + ' - ' + end;
}

export function dateFilterRangeForDateFilter(dateFilter: PredefinedDateFilter): DateFilterRange {
    switch (dateFilter) {
        case PredefinedDateFilters.today: {
            const start = moment().startOf('day');
            const end = moment().endOf('day');
            return { startDate: start.toDate(), endDate: end.toDate() };
        }
        case PredefinedDateFilters.yesterday: {
            const start = moment().subtract(1, 'day').startOf('day');
            const end = moment().subtract(1, 'day').endOf('day');
            return { startDate: start.toDate(), endDate: end.toDate() };
        }
        case PredefinedDateFilters.thisWeek: {
            const start = moment().startOf('week');
            const end = moment().endOf('week');
            return { startDate: start.toDate(), endDate: end.toDate() };
        }
        case PredefinedDateFilters.thisMonth: {
            const start = moment().startOf('month');
            const end = moment().endOf('month');
            return { startDate: start.toDate(), endDate: end.toDate() };
        }
        case PredefinedDateFilters.lastWeek: {
            const start = moment().subtract(1, 'week').startOf('week');
            const end = moment().subtract(1, 'week').endOf('week');
            return { startDate: start.toDate(), endDate: end.toDate() };
        }
        case PredefinedDateFilters.lastMonth: {
            const start = moment().subtract(1, 'month').startOf('month');
            const end = moment().subtract(1, 'month').endOf('month');
            return { startDate: start.toDate(), endDate: end.toDate() };
        }
        case PredefinedDateFilters.thisYear: {
            const start = moment().startOf('year');
            const end = moment().endOf('year');
            return { startDate: start.toDate(), endDate: end.toDate() };
        }
        case PredefinedDateFilters.lastYear: {
            const start = moment().subtract(1, 'year').startOf('year');
            const end = moment().subtract(1, 'year').endOf('year');
            return { startDate: start.toDate(), endDate: end.toDate() };
        }
    }
}

export const alignDateToUtcMoment = (date: Date): Moment => {
    return moment.utc(date).add(moment(date).utcOffset(), 'm');
};
