export function handleZeroCategoryChange(value) {
    if (!Array.isArray(value)) return value;

    const isAllSelected = value.map(({ label }) => label).includes('ALL');
    const shouldRemoveAllFromSelection = value[value.length - 1]?.label !== 'ALL';

    if (shouldRemoveAllFromSelection) {
        return value.filter(({ label }) => label !== 'ALL');
    }

    return isAllSelected ? [{ label: 'ALL', value: 'ALL' }] : value;
}
