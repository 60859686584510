import styled from 'styled-components';

export const TableRow = styled.tr`
    user-select: none;
    cursor: pointer;
    .disabled-column {
        cursor: default;
    }
    .arrow {
        span {
            display: inline-block;
            visibility: hidden;
            transition: 100ms ease-in;
        }
        padding: 0 0 0 10px;
    }
    .sort-order {
        visibility: hidden;
    }
    .active {
        &.desc {
            .arrow {
                span {
                    visibility: visible;
                }
            }
            .sort-order {
                visibility: visible;
            }
        }
        &.asc {
            .arrow {
                span {
                    visibility: visible;
                    transform: rotate3d(0, 0, 1, 180deg);
                }
            }

            .sort-order {
                visibility: visible;
            }
        }
    }
`;
