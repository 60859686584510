import React from 'react';
import { Modal } from 'react-bootstrap';
import { Input, ValidationContext, Form } from './inputs';

interface Props {
    title: string;
    message: string | JSX.Element;
    onClose: () => void;
    onConfirm: (comment: string) => void;
}

interface State {
    comment?: string;
}

export default class RejectionDialog extends React.Component<Props, State> {
    validationContext = new ValidationContext();

    constructor(props) {
        super(props);
        this.state = {
            comment: '',
        };
    }

    handleInputChange({ target }) {
        const { name, value } = target;
        this.setState({ [name]: value });
    }

    onConfirm() {
        this.props.onConfirm(this.state.comment);
    }

    renderInputField(options: { value: string; name: string; required: boolean; type: string; isFocused?: boolean }) {
        return (
            <div className="col-sm-8">
                <Input
                    className="form-control"
                    context={this.validationContext}
                    isFocused={options.isFocused}
                    name={options.name}
                    onChange={this.handleInputChange.bind(this)}
                    required={options.required}
                    type={options.type}
                    value={options.value}
                />
            </div>
        );
    }

    render() {
        const { title, message, onClose } = this.props;
        return (
            <div>
                <div className="fade modal-backdrop in" />
                <Modal.Dialog>
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Form context={this.validationContext} onSubmit={this.onConfirm.bind(this)}>
                        <Modal.Body>
                            {message}
                            <div className="wfp-form--group row">
                                <label className="col-sm-4 ta-right">Comment: </label>
                                {this.renderInputField({
                                    value: this.state.comment,
                                    name: 'comment',
                                    required: true,
                                    type: 'text',
                                    isFocused: true,
                                })}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="wfp-btn" onClick={onClose} style={{ marginRight: 10 }} type="button">
                                Close
                            </button>
                            <button className="wfp-btn--primary" type="submit">
                                Confirm
                            </button>
                        </Modal.Footer>
                    </Form>
                </Modal.Dialog>
            </div>
        );
    }
}
