import { Beneficiary } from '../beneficiaries';
import Action from '../../action';
import { EditActionTypes } from './editBeneficiaryActionTypes';
import { PagedState } from '../../utils/paging';
import { Transaction, TransactionsActionTypes } from '../../transactions/transactions';
import clonedeep from 'lodash/cloneDeep';
import { EntityUpdateRequest } from '../../authorization/taskAuthorizations';

export interface EditBeneficiaryState {
    beneficiary?: Beneficiary;
    expenditureTransactions: PagedState<Transaction>;
    adjustmentTransactions: PagedState<Transaction>;
    entityUpdateRequests: PagedState<EntityUpdateRequest>;
    activityLogs: PagedState<any>;
}

const createDefaultState = () => {
    return {
        expenditureTransactions: new PagedState<Transaction>(),
        adjustmentTransactions: new PagedState<Transaction>(),
        entityUpdateRequests: new PagedState<EntityUpdateRequest>(),
        activityLogs: new PagedState<any>(),
    };
};

function updateTransactions(
    transactions: PagedState<Transaction>,
    transactionToUpdateId: string,
    transactionRequestId: string
) {
    const newTransactions = clonedeep(transactions);
    newTransactions.items
        .filter((element) => element.id.toString() === transactionToUpdateId)
        .forEach((element) => {
            element.pendingTransactionRequestId = transactionRequestId;
        });
    return newTransactions;
}

function pagedStateWithoutEntityUpdateRequestWithId(
    pagedState: PagedState<EntityUpdateRequest>,
    entityUpdateRequestId: string
) {
    const newState = clonedeep(pagedState) as PagedState<EntityUpdateRequest>;
    newState.items = newState.items.filter((request) => request.id !== entityUpdateRequestId);
    return newState;
}

export function editBeneficiaryReducer(state: EditBeneficiaryState = createDefaultState(), action: Action) {
    switch (action.type) {
        case EditActionTypes.statusUpdated:
        case EditActionTypes.editBeneficiaryLoadSuccess:
            return {
                ...state,
                beneficiary: action.payload,
            };
        case EditActionTypes.cleanBeneficiaryData:
            return {
                ...state,
                beneficiary: null,
            };
        case EditActionTypes.addComment: {
            const { comment } = action.payload;
            const beneficiaryWithComments = clonedeep(state.beneficiary);
            beneficiaryWithComments.comments.push(comment);
            return {
                ...state,
                beneficiary: beneficiaryWithComments,
            };
        }
        case TransactionsActionTypes.editBeneficiaryAdjustmentTransactionsLoaded:
            return {
                ...state,
                adjustmentTransactions: action.payload,
            };
        case TransactionsActionTypes.editBeneficiaryExpendituresTransactionsLoaded:
            return {
                ...state,
                expenditureTransactions: action.payload,
            };
        case TransactionsActionTypes.transactionAdjusted:
            const { causedByTransactionId, id } = action.payload;
            return {
                ...state,
                expenditureTransactions: updateTransactions(state.expenditureTransactions, causedByTransactionId, id),
                adjustmentTransactions: updateTransactions(state.adjustmentTransactions, causedByTransactionId, id),
            };
        case EditActionTypes.beneficiaryUpdatesLoaded:
            return {
                ...state,
                entityUpdateRequests: action.payload,
            };
        case EditActionTypes.beneficiaryUpdatePosted:
        case EditActionTypes.beneficiaryUpdateRejected:
            const stateWithoutEntityUpdateRequest = pagedStateWithoutEntityUpdateRequestWithId(
                state.entityUpdateRequests,
                action.payload.id
            );
            return {
                ...state,
                entityUpdateRequests: stateWithoutEntityUpdateRequest,
            };
        case EditActionTypes.beneficiaryActivityLogsLoaded:
            return {
                ...state,
                activityLogs: action.payload,
            };
    }

    return state;
}
