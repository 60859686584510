import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';

import { useStepperContext } from '../StepperContext';
import { CheckBoxContainer } from '../utils/utils';
import { isBoolean } from "lodash";

interface Props {
    setSelectedCategory: (category: string) => void;
    selectedCategory: string;
    resetUaopFlag: () => void;
}

const CategoryAssistanceSelectionStep: React.FC<Props> = ({ setSelectedCategory, selectedCategory, resetUaopFlag }) => {
    const { disableNextStep, enableNextStep } = useStepperContext();
    const { categories, categoriesToCategoriesFullNames, categoryConfig } = useSelector(
        (state: State) => state.appConfig.entitlementsConfig
    );

    useEffect(() => resetUaopFlag(), []);

    useEffect(() => {
        selectedCategory ? enableNextStep() : disableNextStep();
    }, [selectedCategory]);

    const categoriesToShow = categories
        .filter((category) => {
            const cc = categoryConfig.find((cc) => cc.category === category);
            if (!cc) return false;
            if (!isBoolean(cc.readonly)) return false;

            return !cc.readonly;
        })
        .sort()
        .sort((a, b) => {
            if (a === 'CASH-MPA') return -1;
            if (b === 'CASH-MPA') return 1;
        });

    return (
        <>
            <h6
                style={{
                    marginTop: '32px',
                }}
            >
                Assistance Category
            </h6>
            <CheckBoxContainer marginTop="0px">
                {categoriesToShow.map((category) => (
                    <React.Fragment key={category}>
                        <label>
                            <input
                                checked={selectedCategory === category}
                                onChange={() => setSelectedCategory(category)}
                                type="radio"
                            />{' '}
                            {categoriesToCategoriesFullNames[category]} ({category})
                        </label>
                    </React.Fragment>
                ))}
            </CheckBoxContainer>
        </>
    );
};

CategoryAssistanceSelectionStep.displayName = 'CategoryAssistanceSelectionStep';

export { CategoryAssistanceSelectionStep };
