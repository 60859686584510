import { useState, useEffect } from 'react';
import { hasFeatureAccess } from '../login/auth';
import { useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';

const initialState = Object.freeze({
    selected: null,
    hovered: null,
});

interface Args {
    onDrop?: (event: DragEvent, grabbedElement?: HTMLElement) => void;
    onDragEnter: (event: any) => void;
}

interface DragAndDropReturnObject {
    componentProps: {
        draggable: boolean;
        onDragStart(event: any): void;
        onDragLeave(event: any): void;
        onDrop(event: any): void;
        onDragEnter(event: any): void;
    };
    elements: typeof initialState;
}

export function useDragAndDrop({ onDrop, onDragEnter }: Args, permission): DragAndDropReturnObject {
    useWindowDragAndDrop();
    const [elements, setElements] = useState(initialState);
    const handleDragStart = (event) => {
        event.persist();
        event.dataTransfer.effectAllowed = 'all';
        setElements((state) => ({ ...state, selected: event.target }));
        event.dataTransfer.setData('text/plain', event.target.innerText);
    };

    const handleDrop = (event) => {
        event.persist();
        event.preventDefault();
        if (!event.target) return;
        const target = getDraggableElement(event.target);
        setElements((state) => {
            onDrop?.({ ...event, target }, state.selected);
            return initialState;
        });
    };

    const handleDragEnter = (event) => {
        event.persist();
        event.preventDefault();
        setElements((state) => ({
            ...state,
            hovered: event.target,
        }));
        onDragEnter(event);
    };
    const handleDragLeave = (event) => {
        event.persist();
        setElements((state) => ({
            ...state,
            hovered: null,
        }));
    };
    const auth = useSelector((state: State) => state.auth);
    const hasPermission = permission ? hasFeatureAccess(auth, permission) : true;
    return hasPermission
        ? {
              componentProps: {
                  draggable: true,
                  onDragStart: handleDragStart,
                  onDragLeave: handleDragLeave,
                  onDrop: handleDrop,
                  onDragEnter: handleDragEnter,
              },
              elements,
          }
        : {
              componentProps: {
                  draggable: false,
                  onDragStart: undefined,
                  onDragLeave: undefined,
                  onDrop: undefined,
                  onDragEnter: undefined,
              },
              elements,
          };
}

function useWindowDragAndDrop() {
    useEffect(() => {
        const ondragover = window.ondragover;
        const ondrop = window.ondrop;

        window.ondragover = function (e) {
            e.preventDefault();
            return false;
        };
        window.ondrop = function (e) {
            e.preventDefault();
            return false;
        };

        return () => {
            window.ondragover = ondragover;
            window.ondrop = ondrop;
        };
    }, []);
}

function getDraggableElement(element) {
    if (element.draggable) {
        return element;
    }
    return getDraggableElement(element.parentNode);
}
