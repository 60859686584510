import React from 'react';
import { Modal } from 'react-bootstrap';
import { Form, Input } from '../utils/inputs';
import { SecurityProps } from '../security/security';

interface Props {
    onSubmit: (otp?: string, secret?: string) => Promise<any>;
    onClose: () => void;

    security?: SecurityProps;
    title: string;
    confirmTitle: string;
    error: any;
    success: boolean;
}

class State {
    validateError: string;
    otp = '';
}

interface FormStatus {
    allInputsClass: string;
    error: JSX.Element;
    otpError: JSX.Element;
    originalError: any;
}

export default class ManagerOtpDialog extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            otp: '',
            validateError: '',
        };
    }

    formStatus(): FormStatus {
        let allInputsClass = 'form-control';
        let error: JSX.Element;
        let otpError: JSX.Element;
        if (this.props.error) {
            if (this.props.error.status === 403) {
                allInputsClass += ' invalid';
                otpError = <span className="error">Invalid one time password.</span>;
            } else {
                allInputsClass += ' invalid';
                error = (
                    <span className="error">
                        {this.props.error.status}: {this.props.error.statusText}
                    </span>
                );
            }
        }
        return {
            allInputsClass,
            error,
            otpError,
            originalError: this.props.error || {},
        };
    }

    UNSAFE_componentWillReceiveProps(newProps: Props) {
        if (newProps.error && newProps.error.status === 403) {
            this.setState({ otp: '' });
        }
    }

    _onOtpChange = (event) => {
        this.setState({ otp: event.target.value, validateError: null });
    };

    renderOtpForm() {
        const { allInputsClass, otpError } = this.formStatus();
        return (
            <div>
                {this.props.security.otpSecret ? (
                    <div>
                        <div className="row">
                            <img
                                alt="QR Code"
                                src={this.props.security.otpQRDataUrl}
                                style={{ margin: '5px auto 10px' }}
                            />
                            <span style={{ textAlign: 'center' }}>
                                <p>Secret key: {this.props.security.otpSecret}</p>
                            </span>
                            <br />
                        </div>
                    </div>
                ) : null}
                <div className="form-group">
                    <label htmlFor="login_otp">One Time Password</label>
                    <Input
                        className={allInputsClass}
                        isFocused={true}
                        name="login_otp"
                        onChange={this._onOtpChange}
                        type="text"
                        value={this.state.otp}
                    />
                    {otpError}
                    <br />
                </div>
            </div>
        );
    }

    async _onSubmit(event) {
        event.preventDefault();
        const { otp } = this.state;
        await this.props.onSubmit(otp, this.props.security.otpSecret);
    }

    render() {
        const { onClose, title, confirmTitle } = this.props;
        return (
            <div>
                <div className="fade modal-backdrop in" />
                <Modal.Dialog>
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Form className="mv1 wfp-form" onSubmit={this._onSubmit.bind(this)}>
                        <Modal.Body>{this.renderOtpForm()}</Modal.Body>
                        <Modal.Footer>
                            <button className="wfp-btn" onClick={onClose} style={{ marginRight: 10 }} type="button">
                                Close
                            </button>
                            <button className="wfp-btn--primary" type="submit">
                                {confirmTitle}
                            </button>
                        </Modal.Footer>
                    </Form>
                </Modal.Dialog>
            </div>
        );
    }
}
