import React from 'react';
import { NumberFilter } from '../FilterTypes';
import { Input } from '../inputs';

interface Props {
    filter: NumberFilter;
    filterChanged: (filter: NumberFilter, event: any) => void;
    withIsSelected: boolean;
}

export default class NumberFilterField extends React.Component<Props, any> {
    checkbox(filter: NumberFilter) {
        return (
            <input
                checked={filter.isSelected}
                name={filter.name + 'Selected'}
                onChange={(e) => this.props.filterChanged(filter, e)}
                type="checkbox"
            />
        );
    }

    render() {
        const filter = this.props.filter;
        return (
            <div className="" key={filter.name}>
                <label>{filter.label}:</label>
                {this.props.withIsSelected && this.checkbox(filter)}
                <Input
                    isFilter={true}
                    name={filter.name}
                    onChange={(e) => this.props.filterChanged(filter, e)}
                    style={{ display: 'inline' }}
                    type="number"
                    value={filter.value}
                />
            </div>
        );
    }
}
