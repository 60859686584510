import { errorAction } from './notifications';
import { throwErrorIfNotHandled } from './errorMiddleware';

export default function handleEntityUpdateConfirmationError(err, entityUpdateRequest, dispatch) {
    if (err.status === 409) {
        err.json().then(({ errors }) => {
            if (errors[0]) {
                dispatch(errorAction(`Failed to authorize entity update request - ${errors[0].message}`));
            } else {
                dispatch(errorAction(`Failed to authorize entity update request ${entityUpdateRequest.id}`));
            }
        });
    } else {
        throwErrorIfNotHandled(`Failed to authorize entity update request ${entityUpdateRequest.id}`)(err);
    }
}
