import { loadDeduplicatedEntitlements } from '../apiClient';
import Action from '../action';
import { Filter } from '../utils/FilterTypes';
import { PagedState } from '../utils/paging';

export class DeduplicatedEntitlement {
    id: number;
    beneficiaryId: string;
    agency: string;
    category: string;
    amount: number;
    currency: string;
    expiry: Date;
    validFrom: Date;
    createdAt: Date;
    message: string;
    removedByAsyncTaskId?: string;
}

export const ActionTypes = {
    dataLoaded: 'EntitlementsPage.dataLoaded',
};

export const ActionCreators = {
    loadDeduplicatedEntitlements(timezone: string, page, limit = 25, filters: Array<Filter> = []) {
        return (dispatch) =>
            loadDeduplicatedEntitlements(timezone, filters, page, limit).then((entitlements) =>
                dispatch({
                    type: ActionTypes.dataLoaded,
                    payload: Object.assign(entitlements, {
                        filters,
                    }),
                })
            );
    },
};

export function deduplicatedEntitlementsReducer(
    state: PagedState<DeduplicatedEntitlement> = new PagedState<DeduplicatedEntitlement>(),
    action: Action
) {
    switch (action.type) {
        case ActionTypes.dataLoaded:
            return action.payload;
    }
    return state;
}
