import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FiltersComponent from '../utils/filters';
import { DateSelectFilter, Filter, MultiSelectFilter } from '../utils/FilterTypes';
import PagingComponent, { PagedState } from '../utils/paging';
import FeatureButton from '../utils/FeatureButton';
import { AuthState } from '../login/auth';
import { PredefinedDateRanges } from '../../utils/createPredefinedFilters';
import { FiltersCreators, FiltersTypes } from '../utils/filtersStore';
import { PendingActionsState } from '../utils/pedingActionsReducer';
import { ActionCreators, Entitlement, EntitlementStatus } from './entitlements';
import State from '@wfp-root-app/store/state';
import EntitlementsList from './EntitlementsList';
import { EntitlementCurrencyConfig } from '../app/appConfig';
import {
    updateFiltersWithCategories,
    updateFiltersWithOrganizations,
    updateFiltersWithStatuses,
} from '../utils/createEntityMultiSelect';
import { EditBeneficiaryCreators } from '../beneficiaries/edit/editBeneficiaryActionCreator';
import BeneficiarySearch from '../beneficiaries/BeneficiarySearch';
import { ManagerPermission } from '../permission-profiles/permission';
import BreadCrumbs from '../utils/BreadCrumbs';

interface Props {
    auth: AuthState;
    entitlements: PagedState<Entitlement>;
    filters: Filter[];
    loadEntitlements: (timezone: string, page: number, limit: number, filters?: Array<Filter>) => void;
    searchBeneficiary: (beneficiaryId: string) => void;
    saveFilters: (filters: Filter[], relatedTo: string) => void;
    pendingActions: PendingActionsState;
    locationToggle: boolean;
    timezone: string;
    currency: EntitlementCurrencyConfig;
    organizations: string[];
    categories: string[];
}

interface LocalState {
    searchValue: string;
    defaultFilters: Filter[];
}

const initialDefaultFilters = [
    new MultiSelectFilter('Organization', 'organization', '', []),
    new MultiSelectFilter('Category', 'category', [], []),
    new DateSelectFilter('Assistance Start', 'start', '', PredefinedDateRanges),
    new DateSelectFilter('Assistance End', 'end', '', PredefinedDateRanges),
    new DateSelectFilter('Assistance creation date', 'createdAt', '', PredefinedDateRanges),
    new MultiSelectFilter('Status', 'status', [], []),
];

class EntitlementsPage extends React.Component<Props, LocalState> {
    defaultPageSize = 25;

    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            defaultFilters: initialDefaultFilters,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.loadEntitlements(this.props.timezone, 1, this.defaultPageSize, this.props.filters);
        let newFilters = updateFiltersWithCategories(this.props.categories, this.props.filters, 'category');
        newFilters = updateFiltersWithOrganizations(this.props.organizations, newFilters, 'organization');
        newFilters = updateFiltersWithStatuses(Object.values(EntitlementStatus), newFilters, 'status');
        this.props.saveFilters(newFilters, FiltersTypes.entitlements);

        let newDefaultFilters = updateFiltersWithCategories(
            this.props.categories,
            this.state.defaultFilters,
            'category'
        );

        newDefaultFilters = updateFiltersWithOrganizations(this.props.organizations, newDefaultFilters, 'organization');
        newDefaultFilters = updateFiltersWithStatuses(Object.values(EntitlementStatus), newDefaultFilters, 'status');
        this.setState({ defaultFilters: newDefaultFilters });
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
        if (nextProps.organizations.length > this.props.organizations.length) {
            const newFilters = updateFiltersWithOrganizations(
                this.props.organizations,
                this.props.filters,
                'organization'
            );
            this.props.saveFilters(newFilters, FiltersTypes.entitlements);
        }
        if (nextProps.categories.length > this.props.categories.length) {
            const newFilters = updateFiltersWithCategories(this.props.categories, this.props.filters, 'category');
            this.props.saveFilters(newFilters, FiltersTypes.entitlements);
        }
    }

    render() {
        const { auth, entitlements } = this.props;

        return (
            <main>
                {entitlements && (
                    <div>
                        <BreadCrumbs path={'/assistance-provided'} />
                        <div className="row">
                            <h3 className="col-sm-6">Assistance Provided</h3>
                            <div className="col-sm-6 tr">
                                {entitlements && entitlements.items.length > 0 && (
                                    <FeatureButton
                                        linkTo={'/entitlements/download/entitlements/file'}
                                        manager={auth.manager}
                                        restrictedToFeatures={[ManagerPermission.beneficiariesDownload]}
                                        title="Download"
                                    />
                                )}
                            </div>
                        </div>{' '}
                        <BeneficiarySearch search={this.props.searchBeneficiary.bind(this)} />
                        <FiltersComponent
                            defaultFilters={this.state.defaultFilters}
                            filters={this.props.filters}
                            onApplyFilters={this.applyFilters.bind(this)}
                            withApply={true}
                            withIsSelected={true}
                        />
                        <EntitlementsList
                            currency={this.props.currency}
                            entitlements={entitlements.items}
                            timezone={this.props.timezone}
                        />
                        <PagingComponent
                            onPageChanged={this.changePageRequested.bind(this)}
                            paging={this.props.entitlements.paging}
                        />
                    </div>
                )}
            </main>
        );
    }

    private changePageRequested(newPage: number) {
        this.props.loadEntitlements(this.props.timezone, newPage, this.defaultPageSize, this.props.filters);
    }

    private applyFilters(filters: Filter[]) {
        this.props.loadEntitlements(this.props.timezone, 1, this.defaultPageSize, filters);
        this.props.saveFilters(filters, FiltersTypes.entitlements);
    }
}

function mapStateToProps(state: State) {
    return {
        locationToggle: state.appConfig.location.isActive,
        entitlements: state.entitlements,
        auth: state.auth,
        pendingActions: state.pendingActions,
        filters: state.filters.find((x) => x.relatedTo === FiltersTypes.entitlements)
            ? state.filters.find((x) => x.relatedTo === FiltersTypes.entitlements).selectedFilters
            : initialDefaultFilters,
        timezone: state.appConfig.timeZone,
        currency: state.appConfig.entitlementCurrencyConfig,
        organizations: state.appConfig.organizations,
        categories: state.appConfig.entitlementsConfig.categories,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        loadEntitlements: bindActionCreators(ActionCreators.loadEntitlements, dispatch),
        searchBeneficiary: bindActionCreators(EditBeneficiaryCreators.searchBeneficiary, dispatch),
        saveFilters: bindActionCreators(FiltersCreators.saveFilters, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitlementsPage as any);
