import React, { useCallback, useEffect, useState } from 'react';
import { recursiveChildrenPropCheck } from '../table/table-head/table-head.utils';

const UNICODE_DOWN_ARROW = '\u2193';
const directions = ['ASC', 'DESC'] as const;

export type DIRECTION_OPTIONS = typeof directions[number];
export type SORT_OPTIONS<T = string> = {
    'orderBy:column': T;
    'orderBy:direction': DIRECTION_OPTIONS;
};

export class SortOptions<T = string> {
    'orderBy:column': T;
    'orderBy:direction': DIRECTION_OPTIONS;

    constructor({ column, direction }: { column: T; direction: DIRECTION_OPTIONS }) {
        this['orderBy:column'] = column;
        this['orderBy:direction'] = direction;
    }
}

export type ChangeSortOptions<T = string> = (
    options: [T, DIRECTION_OPTIONS]
) => void | [T, DIRECTION_OPTIONS] | Promise<[T, DIRECTION_OPTIONS]> | Promise<void>;

export function useSort(userOverrideSortOptions?: ChangeSortOptions) {
    const [column, setColumn] = useState('');
    const [direction, setDirection] = useState<DIRECTION_OPTIONS>('ASC');

    useEffect(() => {
        changeSortOptions([column, direction]);
    }, [column, direction]);
    const handleClick = useCallback((column) => {
        setColumn((state) => {
            if (state === column) {
                setDirection((current) => {
                    const newDirection = current === 'DESC' ? 'ASC' : 'DESC';
                    return newDirection;
                });
                return state;
            }
            setDirection(() => 'ASC');
            return column;
        });
    }, []);

    const changeSortOptions = useCallback(
        async (current: [string, DIRECTION_OPTIONS]) => {
            if (userOverrideSortOptions) {
                const options = await userOverrideSortOptions(current);
                if (Array.isArray(options)) {
                    const [newColumn, newDirection] = options;

                    setColumn((state) => newColumn ?? state);
                    setDirection((state) => newDirection ?? state);
                }
            }
        },
        [userOverrideSortOptions]
    );
    const renderChildren = useCallback(
        (children) =>
            React.Children.map(children, (child: any) => {
                const text = recursiveChildrenPropCheck(child);
                const isDisabled = child?.type?.displayName === 'DisabledColumn';
                return child
                    ? React.cloneElement(child, {
                          ...child?.props,
                          children: [
                              <div className="BB-column-wrapper" key={text}>
                                  {child.props.children}
                                  <span className="arrow" key="arrow">
                                      <span>{UNICODE_DOWN_ARROW}</span>
                                  </span>
                              </div>,
                          ],
                          onClick: isDisabled
                              ? undefined
                              : (event) => {
                                    handleClick(text);
                                    child?.props?.onClick?.(event);
                                },
                          className: isDisabled
                              ? 'disabled-column'
                              : column !== text
                              ? ''
                              : direction === 'ASC'
                              ? 'active asc'
                              : 'active desc',
                      })
                    : null;
            }),
        [column, direction]
    );
    return renderChildren;
}
