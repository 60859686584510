import React from 'react';
import { TextFilter } from '../FilterTypes';
import { Input } from '../inputs';

interface Props {
    filter: TextFilter;
    filterChanged: (filter: TextFilter, event: any) => void;
    withIsSelected: boolean;
}

export default class TextFilterField extends React.Component<Props, any> {
    checkbox(filter: TextFilter) {
        return (
            <input
                checked={filter.isSelected}
                name={filter.name + 'Selected'}
                onChange={(e) => this.props.filterChanged(filter, e)}
                type="checkbox"
            />
        );
    }

    render() {
        const filter = this.props.filter;
        return (
            <div className="" key={filter.name}>
                <label>{filter.label}:</label>
                {this.props.withIsSelected && this.checkbox(filter)}
                <div style={{ display: 'inline' }}>
                    <Input
                        isFilter={true}
                        maxLength={(filter as any).maxLength}
                        name={filter.name}
                        onChange={(e) => this.props.filterChanged(filter, e)}
                        style={{ display: 'inline' }}
                        type="text"
                        value={filter.value}
                    />
                </div>
            </div>
        );
    }
}
