import React, { useEffect } from 'react';
import getRoutes from '../Routes';
import scheduleManagerTokenExpirationChecks from '../utils/sessionExpiration/scheduleManagerTokenExpirationChecks';
import { useStore } from 'react-redux';
import { routerHelperActions, selectRouterHelper } from '@wfp-common/store/routerHelperSlice';
import { useNavigate } from '@wfp-common/hooks/useNavigate';
import { Routes } from 'react-router-dom';
import { dispatch, useStoreSelector } from '@wfp-root-app/store/store';

function scheduleTokenExpirationChecks() {
    if (document.visibilityState === 'visible') {
        dispatch(scheduleManagerTokenExpirationChecks());
    }
}

document.addEventListener('visibilitychange', scheduleTokenExpirationChecks);
scheduleTokenExpirationChecks();

const App = () => {
    const store = useStore();
    const routerHelper = useStoreSelector(selectRouterHelper);
    const navigate = useNavigate();

    useEffect(() => {
        if (!routerHelper.redirectUrl) return;

        const reg = /^\/\S*$/g.test(routerHelper.redirectUrl);
        navigate(reg ? routerHelper.redirectUrl : `/${routerHelper.redirectUrl}`, { ...routerHelper.navigateState });
        store.dispatch(routerHelperActions.clearRedirect());
    }, [routerHelper.redirectUrl]);

    return <Routes>{getRoutes()}</Routes>;
};

export default App;
