import React, { FC, memo } from 'react';

const HomePage: FC = () => {
    return (
        <main className={'home-page'}>
            <div>
                <h3>Home</h3>
            </div>

            <article className={'mt-2'}>
                <p>
                    Since May 2022, the Ukraine humanitarian response has selected "Building Blocks" (BB), a neutral
                    humanitarian/aid blockchain network, to deduplicate assistance among organizations. Ukrainian Tax
                    IDs are the common identifiers used for the inter-organizational deduplication of assistance on BB.
                    BB enables participating organizations to identify people served/intended to be served by other
                    participating organizations and to avoid double enrolling/potential duplicates. If duplicates are
                    encountered, BB provides the requisite data for adjudication, offline. BB's philosophy is to be a
                    neutral blockchain network and be 100% equally owned, operated, and governed by its members. Due to
                    the emergency nature of the Ukraine response, BB is being offered as a service to relevant sectors
                    with direct support from the World Food Programme (WFP). In the long run, if the participating
                    organizations see the value in BB, they can become full members, set up their own infrastructure,
                    and run their own operations on BB.
                </p>

                <p>
                    To delve deeper into the world of Building Blocks, please visit &nbsp;
                    <a href={'https://www.wfp.org/building-blocks'}>https://www.wfp.org/building-blocks</a>
                    &nbsp;or reach out to us at{' '}
                    <a href={'mailto:ukraine.buildingblocks@wfp.org'}>ukraine.buildingblocks@wfp.org.</a>
                </p>
            </article>
        </main>
    );
};

const Cmp = memo(HomePage);

export { Cmp as HomePage };
