import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AuthorizationTab, AuthorizationTaskType } from './TaskAuthorizationsPage';
import { EntityUpdateAuthorizationPage } from './EntityUpdateAuthorizationPage';
import { ManagerPermission } from '../permission-profiles/permission';
import { PermissionChecker } from '@wfp-common/auth/PermissionChecker';

export const AuthorizationTaskSubPage = () => {
    const params = useParams();
    const taskType = params.taskType as AuthorizationTaskType;
    let permissions = [];

    let tab: AuthorizationTab = null;
    switch (taskType) {
        case 'users':
            tab = AuthorizationTab.ManagerDetails;
            permissions = [ManagerPermission.managersManagement];
            break;
        case 'beneficiary-support':
            tab = AuthorizationTab.PartnersDetails;
            permissions = [ManagerPermission.partnersRead];
            break;
        case 'beneficiary-support-users':
            permissions = [ManagerPermission.partnersRead];
            tab = AuthorizationTab.PartnersUsersDetails;
            break;
        default:
            return <Navigate to={'/adjustments/users'} />;
    }

    return (
        <PermissionChecker requiredPermissions={permissions}>
            <EntityUpdateAuthorizationPage tab={tab} />
        </PermissionChecker>
    );
};
