import React from 'react';
import { AsyncTask, AsyncTasksTypes } from './asyncTasks';
import _ from 'lodash';

export interface UploadResult {
    totalRowsCount: number;
    rowsImportedCount: number;
    errors: Array<{ rowIdx?: number; sheetName?: string; error: any }>;
}

export type UploadStatus = null | 'pending' | 'finished';

export class UploadStatuses {
    static pending: UploadStatus = 'pending';
    static finished: UploadStatus = 'finished';
}

interface UploadStatusWidgetProps {
    importStatus: UploadStatus;
    importResult?: UploadResult;
    importTask?: AsyncTask;
    importError?: string;
    downloadFile?: (type: string, id: string, index?: number) => void;
}

export class UploadStatusWidget extends React.Component<UploadStatusWidgetProps> {
    _formatError(error: any) {
        const parts = [];

        if (error.message) {
            parts.push(error.message);
        }
        if (error.value) {
            parts.push(error.value);
        }

        if (error.errors) {
            error.errors.forEach((innerError) => parts.push(this._formatError(innerError)));
        }

        if (!parts.length) {
            parts.push(error.toString());
        }

        return parts.join(' - ');
    }

    render() {
        const { importStatus, importResult, importTask, importError } = this.props;
        const uploadReportChildrenWithoutDuplicates =
            this.props.importTask &&
            _.uniqBy(
                this.props.importTask.children.filter((child) => [AsyncTasksTypes.UPLOAD_REPORT].includes(child.type)),
                (child) => child.fileName
            );
        const progress = Number(importTask?.progress ? importTask.progress : 0) * 100;
        const progressString = `${progress}%`;

        return (
            <div className="mv3">
                {importStatus === UploadStatuses.pending && (
                    <div className="progress">
                        <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            style={{ width: progressString }}
                        />
                    </div>
                )}

                {!!importError && <div className="alert alert-danger">Upload error: {importError}</div>}
                {importStatus === UploadStatuses.finished && !!importResult && this.renderImportResult()}
                {importStatus === UploadStatuses.finished &&
                    !!importTask &&
                    this.renderImportTask(uploadReportChildrenWithoutDuplicates)}
            </div>
        );
    }

    private renderImportResult() {
        const { importResult } = this.props;
        const errors = (!!importResult && importResult.errors) || [];
        return (
            <div>
                {!!importResult.rowsImportedCount && !errors.length && (
                    <div className="alert alert-success">
                        Import finished. {importResult.rowsImportedCount} rows imported, no errors encountered.
                    </div>
                )}
                {!!importResult.rowsImportedCount && !!errors.length && (
                    <div className="alert alert-warning">
                        Import finished. {importResult.rowsImportedCount} rows imported, {errors.length} errors
                        encountered.
                    </div>
                )}
            </div>
        );
    }

    private renderImportTask(uploadReportChildrenWithoutDuplicates) {
        const { additionalInfo } = this.props.importTask;
        const errors = this.props.importTask.errors || [];
        const warnings = this.props.importTask.warnings || [];
        const informationMessages = this.props.importTask.informationMessages || [];
        const isImport =
            this.props.importTask.type === AsyncTasksTypes.IMPORT_BENEFICIARIES ||
            AsyncTasksTypes.IMPORT_ALTERNATIVE_COLLECTOR;
        const successCount = additionalInfo ? additionalInfo.successCount : 'All ';
        return (
            <div>
                {!errors.length && !warnings.length && (
                    <div className="alert alert-success">
                        Validation finished. {successCount} rows validated, no errors encountered.
                    </div>
                )}
                {!!errors.length && (
                    <div style={{ display: 'flex', flexDirection: 'column' }} className="alert alert-danger">
                        <span>Validation finished. {`Number of critical errors - ${errors.length}`} </span>
                        <small>
                            <i>You cannot continue until the noted issues are resolved.</i>
                        </small>
                    </div>
                )}
                {!!warnings.length && (
                    <div style={{ display: 'flex', flexDirection: 'column' }} className="alert alert-warning">
                        <span>
                            Validation finished. {isImport ? warnings : `Number of warnings - ${warnings.length}`}
                        </span>
                        <small>
                            <i>The noted entries will be skipped.</i>
                        </small>
                    </div>
                )}
                {!!informationMessages.length && (
                    <div
                        style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'silver' }}
                        className="alert"
                    >
                        <span>
                            Validation finished.{' '}
                            {isImport
                                ? informationMessages
                                : `Number of informational messages - ${informationMessages.length}.`}
                        </span>
                        <small>
                            <i>You can continue after you check to ensure the noted entries are not mistakes.</i>
                        </small>
                    </div>
                )}

                {!!(errors.length || warnings.length || informationMessages.length) &&
                    uploadReportChildrenWithoutDuplicates.map((uploadReportChild, index) => {
                        return (
                            <p key={uploadReportChild.toString() + index}>
                                {uploadReportChild.fileExpired &&
                                    `Upload Report ${uploadReportChild.fileName} is expired`}
                                {uploadReportChild.errors && 'Errors in upload report creation occurred'}
                                {!uploadReportChild.errors && (
                                    <button
                                        className="wfp-btn--primary"
                                        onClick={() =>
                                            this.props.downloadFile(uploadReportChild.type, uploadReportChild.id)
                                        }
                                    >
                                        Download validation result
                                    </button>
                                )}
                            </p>
                        );
                    })}
            </div>
        );
    }
}
