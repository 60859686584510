import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Switch from 'rc-switch';

import State from '@wfp-root-app/store/state';
import { ActionCreators, SecurityProps } from './security';
import Manager from '../managers/manager';
import { ActionCreators as ManagerActionCreators } from '../managers/managers';
import { AuthState, hasFeatureAccess } from '../login/auth';
import ManagerOtpDialog from '../managers/ManagerOtpDialog';
import ManagerDisableOtpConfirmationDialog from '../managers/ManagerDisableOtpConfirmationDialog';
import ChangePasswordModal from './ChangePasswordModal';
import { ManagerPermission } from '../permission-profiles/permission';

interface Props {
    security: SecurityProps;
    auth: AuthState;
    error: any;
    success: boolean;

    updateOtpSecret: (string) => any;
    getSecurityData: () => void;
    postOtpSecret: (otp: string, secret: string) => void;
    deleteOtpSecret: () => Promise<SecurityProps>;
    resetPasswordRequest: (manager: Manager) => void;
    createOtpSecret: () => Promise<SecurityProps>;
    removeSecretFromState: () => void;
    changePassword: (oldPass: string, newPass: string) => void;
}

class SecurityPage extends React.Component<Props, any> {
    constructor(props, context) {
        super(props, context);

        this.state = {
            otpSecret: null,
            showOtpSecret: false,
            isSetOtpDialogVisible: false,
            isSetOtpDeactivatingWarningDialog: false,
            changePassword: false,
        };
    }

    componentDidMount() {
        this.props.getSecurityData();
    }

    UNSAFE_componentWillReceiveProps(newProps: Props) {
        if (newProps.success !== this.props.success) {
            this.setState({
                isSetOtpDialogVisible: false,
            });
        }
    }

    componentWillUnmount() {
        this.props.removeSecretFromState();
    }

    generateNewSecret = async (event) => {
        await this.props.createOtpSecret();
        this.setState({ isSetOtpDialogVisible: true });
        event.preventDefault();
    };

    async _handleActiveChange(checked) {
        if (checked) {
            await this.props.createOtpSecret();
            this.setState({ isSetOtpDialogVisible: true });
        } else {
            this.setState({ isSetOtpDeactivatingWarningDialog: true });
        }
    }

    disableOtp = async () => {
        this.setState({ isSetOtpDeactivatingWarningDialog: false });
        await this.props.deleteOtpSecret();
    };

    renderSetOtpDailog() {
        return (
            <ManagerOtpDialog
                confirmTitle="Confirm"
                error={this.props.error}
                onClose={() => this.setState({ isSetOtpDialogVisible: false })}
                onSubmit={this.props.postOtpSecret.bind(this)}
                security={this.props.security}
                success={this.props.success}
                title="Generate new secret key for one time password"
            />
        );
    }

    renderOtpDisableDailog() {
        return (
            <ManagerDisableOtpConfirmationDialog
                confirmTitle="Yes"
                onClose={() => this.setState({ isSetOtpDeactivatingWarningDialog: false })}
                onSubmit={this.disableOtp}
                title="Deactivating one time password"
            />
        );
    }

    changePassword(oldPass, newPass) {
        this.props.changePassword(oldPass, newPass);
        this.setState({ changePassword: false });
    }

    render() {
        const canChangeOtpStatus = hasFeatureAccess(this.props.auth, ManagerPermission.changeOtpStatus);
        const { isSetOtpDialogVisible, isSetOtpDeactivatingWarningDialog } = this.state;

        return (
            <main>
                {/* <nav className="wfp-breadcrumbs">
                <ol className="breadcrumbs--wrapper">
                    <li className="breadcrumbs--item"><Link to="/home" className="breadcrumbs--link"><span>Home</span></Link></li>
                    <li className="breadcrumbs--item"><span className="breadcrumbs--last">Security</span></li>
                </ol>
            </nav>
            <h3>Security</h3> */}
                {isSetOtpDialogVisible && this.renderSetOtpDailog()}
                {isSetOtpDeactivatingWarningDialog && this.renderOtpDisableDailog()}
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h5>Password</h5>
                        </div>
                    </div>
                    <div>
                        <a onClick={() => this.setState({ changePassword: true })}>Change Password</a>
                    </div>
                    <div>
                        {canChangeOtpStatus && (
                            <div className="row">
                                <div className="col-md-6">
                                    <h5>One Time Password</h5>
                                </div>
                            </div>
                        )}
                        {canChangeOtpStatus && (
                            <div className="row">
                                <div className="col-sm-2">Active</div>
                                <div className="col-sm-10">
                                    {
                                        <Switch
                                            checked={!!this.props.security.otpEnabled}
                                            onChange={this._handleActiveChange.bind(this)}
                                        />
                                    }
                                </div>
                            </div>
                        )}
                        {this.state.changePassword && (
                            <ChangePasswordModal
                                onClose={() => this.setState({ changePassword: false })}
                                onConfirm={this.changePassword.bind(this)}
                            />
                        )}
                        {this.props.security.otpEnabled && this.props.security.otpSecret ? (
                            <div>
                                <div className="row">
                                    <div className="col-md-6">
                                        {this.state.showOtpSecret ? (
                                            <div>
                                                <img
                                                    alt="QR Code"
                                                    src={this.props.security.otpQRDataUrl}
                                                    style={{
                                                        margin: '5px 10px',
                                                    }}
                                                />
                                                <span>
                                                    Secret key: {this.props.security.otpSecret}
                                                    <br />
                                                    <a
                                                        className="cursor-pointer"
                                                        onClick={() =>
                                                            this.setState({
                                                                showOtpSecret: false,
                                                            })
                                                        }
                                                    >
                                                        <small>Hide OTP QR code</small>
                                                    </a>
                                                </span>
                                            </div>
                                        ) : (
                                            <a
                                                className="cursor-pointer"
                                                onClick={() =>
                                                    this.setState({
                                                        showOtpSecret: true,
                                                    })
                                                }
                                            >
                                                <small>Show OTP QR code</small>
                                            </a>
                                        )}
                                        <br />
                                        <br />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <button className="wfp-btn" onClick={this.generateNewSecret} type="button">
                                            Change OTP
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        security: state.security,
        auth: state.auth,
        error: state.security.error,
        success: state.security.success,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getSecurityData: bindActionCreators(ActionCreators.getSecurityData, dispatch),
        postOtpSecret: bindActionCreators(ActionCreators.postOtpSecret, dispatch),
        deleteOtpSecret: bindActionCreators(ActionCreators.deleteOtpSecret, dispatch),
        resetPasswordRequest: bindActionCreators(ManagerActionCreators.resetPasswordRequest, dispatch),
        createOtpSecret: bindActionCreators(ActionCreators.createOtpSecret, dispatch),
        removeSecretFromState: bindActionCreators(ActionCreators.removeSecretFromState, dispatch),
        changePassword: bindActionCreators(ManagerActionCreators.changePassword, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecurityPage as any);
