import React from 'react';
import ReactSelect, { Creatable } from 'react-select';
import { wfpFormat } from '../../utils';
import { ReactElement, useState } from 'react';
import { ConfirmationDialog } from '../../Dialogs';
import styled from 'styled-components';

interface Props {
    name: string;
    options: { value: string; label: string }[];
    value: { value: string; label: string; new?: boolean } | string | { value: string; label: string; new?: boolean }[];
    multi?: boolean;
    selectAll?: boolean;
    creatable?: boolean;
    confirmCreate?: boolean;
    disabled?: boolean;
    placeholder?: string;

    onChange(event: {
        target: {
            value: string;
            name: string;
            label: string;
        };
    }): void;

    optionRenderer?(arg): ReactElement;
}

export function Select({
    name,
    onChange,
    options,
    value,
    optionRenderer = null,
    multi,
    selectAll = false,
    creatable = false,
    confirmCreate = false,
    disabled = false,
    placeholder,
}: Props) {
    const [createdOptions, setCreatedOptions] = useState(() => {
        const isString = typeof value === 'string';
        if (!value || isString) return [];
        if (Array.isArray(value)) return value.filter((selected) => selected?.new);
        return isString || !(value as any)?.new ? [] : [value];
    });
    const [confirm, setConfirm] = useState(null);
    const handleChange = (option: { label: string; value: string }) => {
        onChange({ target: { ...option, name } });
    };
    const getValue = (val) => {
        return typeof val === 'string' ? { value: val, label: wfpFormat(val) } : val;
    };
    const isMulti = multi ?? false;
    const selectAllOption = { label: 'Select All', value: 'ALL' };
    const getOptions = (options: { label: string; value: string }[], value) => {
        // note: shadowing "value" to avoid type errors when "value" is an object and does not have length property
        if (!selectAll || !isMulti) return options;
        if (!options.length) return options;
        if (value === undefined || value === null) {
            return [selectAllOption, ...options];
        } else if (value?.length === options.length) {
            return options;
        } else {
            return [selectAllOption, ...options];
        }
    };

    const handleCreate = (option) => {
        setCreatedOptions((state) => [...state, option]);
        handleChange(option);
    };

    const handleCreateOption = async (newOption: string) => {
        const res = { value: newOption, label: newOption, new: true };
        if (confirmCreate) {
            setConfirm(res);
        } else {
            handleCreate(res);
        }
    };
    return (
        <>
            {creatable ? (
                <Creatable
                    className="react-select-container"
                    isDisabled={confirm ?? disabled}
                    isMulti={isMulti}
                    name={name}
                    onChange={handleChange}
                    onCreateOption={handleCreateOption}
                    {...(optionRenderer ? { optionRenderer } : null)}
                    options={[...createdOptions, ...getOptions(options, value)]}
                    showNewOptionAtTop={false}
                    styles={customStyles}
                    value={getValue(value)}
                />
            ) : (
                <ReactSelect
                    className="react-select-container"
                    isMulti={isMulti}
                    name={name}
                    onChange={handleChange}
                    {...(optionRenderer ? { optionRenderer } : null)}
                    {...(placeholder ? { placeholder } : null)}
                    options={getOptions(options, value)}
                    searchable={false}
                    styles={customStyles}
                    value={getValue(value)}
                />
            )}
            {confirm && (
                <Container>
                    <ConfirmationDialog
                        message={`Are you sure you want to create new product "${confirm?.label}"?`}
                        onClose={() => {
                            setConfirm(null);
                        }}
                        onConfirm={() => {
                            handleCreate({ ...confirm });
                            setConfirm(null);
                        }}
                        title="Confirm New Product"
                    />
                </Container>
            )}
        </>
    );
}

const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: grid;
    place-items: center;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.124);
    .modal[role='dialog'] {
        position: unset;
    }
    .fade.modal-backdrop.in {
        z-index: unset;
    }
`;

export const customStyles = {
    option(provided, state) {
        const isAllOptions = state?.options?.find((selectOption) => selectOption.value === 'ALL');
        if (isAllOptions) {
            return { ...provided, ':first-child': { 'font-weight': 'bold' } };
        }
        return provided;
    },
    multiValue(provided) {
        return {
            ...provided,
            color: '#007eff',
            background: '#007eff14',
            border: '1px solid #007eff3d',
        };
    },
    multiValueLabel(provided) {
        return {
            ...provided,
            color: 'inherit',
            padding: '3px 6px 3px 3px',
        };
    },
    multiValueRemove(provided) {
        return {
            ...provided,
            borderLeft: 'inherit',
        };
    },
};
