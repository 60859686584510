import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
/* Utility hook for using redux action creators;
 * hooks action creators use signature (data)=>(dispatch)=>{...}
 * hocs action creators use signature (dispatch)=>(data)=>{...}
 */

export function useActionCreator(actionCreator, hook = false) {
    const dispatch = useDispatch();
    const hocActionCreator = useCallback((...data) => {
        return actionCreator(...data)(dispatch);
    }, []);
    const hookActionCreator = useCallback((...data) => {
        return actionCreator(dispatch)(...data);
    }, []);
    return hook ? hookActionCreator : hocActionCreator;
}

export type ActionCreatorType<T> = (
    ...args: any[]
) => (dispatch: Dispatch<any>, getState: () => Record<string, any>) => Promise<T>;

export function useAsyncActionCreator<T>(actionCreator: ActionCreatorType<T>) {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    return (...args) => {
        return actionCreator(...args)(dispatch, () => selector);
    };
}
