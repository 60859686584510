import React from 'react';
import { usePartnerUserDetails } from './helpers/PartnerUserDetailsHelpers';
import { compose, separateCamelCase, wfpFormat } from '../utils/utils';
import { PartnerForm } from './PartnerForm';
import { PanelAccessSwitch, SwitchToggle } from './helpers/PartnerFormHelpers';
import { CSSProperties } from 'react';
import { hasFeatureAccess } from '../login/auth';
import { InformationDialog } from '../utils/Dialogs';
import { PartnerStatuses } from './partners';
import { ManagerPermission } from '../permission-profiles/permission';

interface Props {
    params: {
        partnerUserId: string;
        partnerId: string;
    };
    authState: any;
}

const styles: CSSProperties = {
    paddingTop: '1em',
};

export function PartnerUserDetails({ params }: Props) {
    const {
        partnerUser,
        isEditing,
        formFields,
        toggleEdit,
        handleSubmit,
        auth,
        hasPendingDetailsUpdate,
        setHasPendingDetailsUpdate,
    } = usePartnerUserDetails(params.partnerUserId, params.partnerId);

    const renderPendingUpdatesDialog = () => {
        return (
            <InformationDialog
                message="Beneficiary Support details cannot be currently edited because another edit is waiting to be posted"
                onClose={() => setHasPendingDetailsUpdate(false)}
                title="Pending updates"
            />
        );
    };

    const editEnabled = hasFeatureAccess(auth, ManagerPermission.partnerUserPark);
    return (
        <>
            {hasPendingDetailsUpdate && renderPendingUpdatesDialog()}
            <h6>Beneficiary Support User &quot;{partnerUser.id}&quot; Details</h6>
            <section style={styles}>
                {editEnabled ? (
                    <SwitchToggle checked={isEditing} disabled={partnerUser.status === 'parked'} onClick={toggleEdit} />
                ) : (
                    ''
                )}
                {isEditing ? (
                    <PartnerForm fields={formFields} onSubmit={handleSubmit} switchStatus={true} />
                ) : (
                    <>
                        {Object.entries(partnerUser)
                            .filter(([key]) => !['pendingEntityUpdates', 'isPasswordBlocked'].includes(key))
                            .map(([key, value]) => (
                                <div className="wfp-form--group row" key={key}>
                                    <strong className="col-sm-2 ta-right">
                                        {compose(wfpFormat, separateCamelCase)(key)}
                                    </strong>
                                    <div className="col-sm-6">{key === 'status' ? wfpFormat(value) : value}</div>
                                </div>
                            ))}
                        <div className="wfp-form--group row">
                            <strong className="col-sm-2 ta-right">Password Blocked</strong>
                            <div className="col-sm-6">{partnerUser.isPasswordBlocked ? 'Yes' : 'No'}</div>
                        </div>
                        <PanelAccessSwitch
                            checked={partnerUser.status === PartnerStatuses.active}
                            disabled={true}
                            name={'status'}
                            onClick={null}
                        />
                    </>
                )}
            </section>
        </>
    );
}
