import { AnyAction, applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import loadingMiddleware from '../../utils/loadingMiddleware';
import errorMiddleware from '../../utils/errorMiddleware';
import thunk, { ThunkDispatch } from 'redux-thunk';

import reducers from '@wfp-root-app/store/reducers';
import { BlockUiMiddleware } from 'react-block-ui/redux';
import { createSelectorHook, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import State from '@wfp-root-app/store/state';

const enhancer = composeWithDevTools(
    applyMiddleware(loadingMiddleware, errorMiddleware, thunkMiddleware, BlockUiMiddleware)
);
export const store = createStore<State>(combineReducers(reducers), enhancer);

export type RootAppState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootAppState> = useSelector;

export const dispatch = store.dispatch as ThunkDispatch<Store<RootAppState>, void, AnyAction>;

const authState = (state: RootAppState) => state.auth;
const appConfigState = (state: RootAppState) => state.appConfig;

export const selectAuth = createSelector([authState], (state) => state);
export const selectAppConfig = createSelector([appConfigState], (state) => state);
export const useStoreSelector: TypedUseSelectorHook<State> = createSelectorHook();
