export enum paymentFrequencyMap {
    'BOM' = 'First day of month',
    '15M' = '15th day of month',
    'EOM' = 'Last day of month',
    'MON' = 'Every Monday',
    'TUE' = 'Every Tuesday',
    'WED' = 'Every Wednesday',
    'THU' = 'Every Thursday',
    'FRI' = 'Every Friday',
    'SAT' = 'Every Saturday',
    'SUN' = 'Every Sunday',
}
