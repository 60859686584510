import Action from '../action';
import { ActionTypes } from '../login/auth';
import { ManagerPermission } from '../permission-profiles/permission';

export class BeneficiariesPendingActions {
    upload: number;
    block: number;
    unblock: number;
    zero: number;
    cancelImport: number;
    alternativeCollectorImport: number;
    removeAlternativeCollectorImport: number;

    constructor(
        upload = 0,
        sign = 0,
        block = 0,
        unblock = 0,
        zero = 0,
        cancelImport = 0,
        alternativeCollectorImport = 0,
        removeAlternativeCollectorImport = 0,
        permissions: Array<string> = []
    ) {
        this.upload =
            (permissions.includes(ManagerPermission.beneficiariesEntitlementVerify) ? upload : 0) +
            (permissions.includes(ManagerPermission.beneficiariesSignLevelOne) ||
            permissions.includes(ManagerPermission.beneficiariesSignLevelTwo)
                ? sign
                : 0);
        this.block = permissions.includes(ManagerPermission.beneficiariesMassBlockPost) ? block : 0;
        this.unblock = permissions.includes(ManagerPermission.beneficiariesMassUnblockPost) ? unblock : 0;
        this.zero = permissions.includes(ManagerPermission.beneficiariesMassZeroPost) ? zero : 0;
        this.cancelImport =
            permissions.includes(ManagerPermission.beneficiariesSignLevelOne) ||
            permissions.includes(ManagerPermission.beneficiariesSignLevelOne)
                ? cancelImport
                : 0;
        this.alternativeCollectorImport = permissions.includes(ManagerPermission.alternativeCollectorsImportPost)
            ? alternativeCollectorImport
            : 0;
        this.removeAlternativeCollectorImport = permissions.includes(
            ManagerPermission.removeAlternativeCollectorsImportPost
        )
            ? removeAlternativeCollectorImport
            : 0;
    }
}

export class AdjustmentsPendingActions {
    users: number;
    partners: number;
    partnerUsers: number;

    constructor(users = 0, permissions: Array<string> = [], partners: number, partnerUsers: number) {
        this.users = permissions.includes(ManagerPermission.managersManagementPost) ? users : 0;
        this.partners = permissions.includes(ManagerPermission.partnerUserPost) ? partners : 0;
        this.partnerUsers = permissions.includes(ManagerPermission.partnerUserPost) ? partnerUsers : 0;
    }
}

export class PendingActionsState {
    vendorsPost: number;
    vendorUsersPost: number;
    vendorBranchesPost: number;
    beneficiaries: BeneficiariesPendingActions;
    usersPost: number;
    adjustments: AdjustmentsPendingActions;
    partnersPost: number;
    partnerUsersPost: number;
    paymentsActions: number;
    locationsPost: number;
    documentsUpload: number;
    permissionProfilePost: number;

    constructor(payload: any = {}) {
        const pendingAction = payload.pendingActions ? payload.pendingActions : {};
        const permissions = payload.permissions ? payload.permissions : [];
        this.vendorsPost = permissions.includes(ManagerPermission.vendorsPost) ? pendingAction.vendorsPost : 0;
        this.vendorUsersPost = permissions.includes(ManagerPermission.vendorUserPost)
            ? pendingAction.vendorUsersPost
            : 0;
        this.vendorBranchesPost = permissions.includes(ManagerPermission.vendorsPost)
            ? pendingAction.vendorBranchesPost
            : 0;

        this.vendorsPost += this.vendorUsersPost;
        this.vendorsPost += this.vendorBranchesPost;

        this.beneficiaries = new BeneficiariesPendingActions(
            pendingAction.beneficiaryImportsPost,
            pendingAction.beneficiarySignatures,
            pendingAction.beneficiaryBlock,
            pendingAction.beneficiaryUnblock,
            pendingAction.beneficiaryZero,
            pendingAction.beneficiaryImportsCancel,
            pendingAction.alternativeCollectorImport,
            pendingAction.removeAlternativeCollectorImport,
            permissions
        );
        this.documentsUpload = pendingAction.documentsUpload;
        this.usersPost = permissions.includes(ManagerPermission.managersManagementPost) ? pendingAction.usersPost : 0;
        this.adjustments = new AdjustmentsPendingActions(
            pendingAction.adjustmentsUsers,
            permissions,
            pendingAction.adjustmentsPartners,
            pendingAction.adjustmentsPartnerUserDetails
        );
        this.partnersPost = permissions.includes(ManagerPermission.partnerPost) ? pendingAction.partnersPost : 0;
        this.partnersPost += permissions.includes(ManagerPermission.partnerUserPost)
            ? pendingAction.partnerUsersPost
            : 0;
        this.partnerUsersPost = permissions.includes(ManagerPermission.partnerUserPost)
            ? pendingAction.partnerUsersPost
            : 0;
        this.paymentsActions =
            (permissions.includes(ManagerPermission.paymentsGeneratePost) ? pendingAction.paymentGenerate || 0 : 0) +
            (permissions.includes(ManagerPermission.paymentsGeneratePost) ? pendingAction.paymentCancelPost || 0 : 0) +
            (permissions.includes(ManagerPermission.paymentIssuePost) ? pendingAction.paymentIssuePost || 0 : 0) +
            (permissions.includes(ManagerPermission.paymentIssuePark) ? pendingAction.paymentIssuePark || 0 : 0) +
            (permissions.includes(ManagerPermission.paymentsPaidPark) ? pendingAction.paymentParkPaid || 0 : 0) +
            (permissions.includes(ManagerPermission.paymentsPaidPost) ? pendingAction.paymentPostPaid || 0 : 0) +
            (permissions.includes(ManagerPermission.paymentsGeneratePark)
                ? pendingAction.paymentGenerationPark || 0
                : 0);
        this.locationsPost = permissions.includes(ManagerPermission.beneficiariesPark)
            ? pendingAction.locationsPost
            : 0;
        this.permissionProfilePost = permissions.includes(ManagerPermission.profilesPost)
            ? pendingAction.permissionProfilePost
            : 0;
    }
}

export const ActionCreators = {};

export function pendingActionsReducer(state: PendingActionsState = new PendingActionsState(), action: Action) {
    switch (action.type) {
        case ActionTypes.loadedPendingActions:
            // Until Ukraine branch still contains remained code after the check-out I'd like to limit
            // state props only to those which are required instead of putting everything here

            const { pendingActions, permissions } = action.payload;
            return new PendingActionsState({
                permissions,
                pendingActions: {
                    adjustmentsUsers: pendingActions.adjustmentsUsers,
                    adjustmentsPartners: pendingActions.adjustmentsPartners,
                    adjustmentsPartnerUserDetails: pendingActions.adjustmentsPartnerUserDetails,
                },
            });
    }

    return state;
}
