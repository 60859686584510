import { useLocation as useRouterLocation } from 'react-router-dom';
import { useMemo } from 'react';

export interface LocationHook {
    pathname: string;
    state: any;
}

export const useLocation = (): LocationHook => {
    const location = useRouterLocation();

    const result = useMemo(
        () => ({
            pathname: location.pathname,
            state: location.state,
        }),
        [location.pathname]
    );

    return result;
};
