import { displayTime } from '../../utils/utils';
import { RelatedModelResponse } from '../activityLogs';
import React from 'react';

export function PaymentTransitionView(payment: RelatedModelResponse) {
    return (payment.statusTransitions || []).map((transition) => (
        <tr key={`${transition.to}-${transition.at}`}>
            <th>{transition.to}</th>
            <td>
                {displayTime(transition.at)} by {transition.byManager ? transition.byManager.name : 'system'}
            </td>
        </tr>
    ));
}
