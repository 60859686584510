import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';
import clonedeep from 'lodash/cloneDeep';

import { SerializedBalanceEntry } from './model';
import Action from '../action';
import { ActivityLogPageType } from '../activityLogs/ActivityLogExport';
import {
    cancelVendorPark,
    exportActivityLog,
    loadVendors,
    loadVendorsNickNames,
    loadVendorUsersNames,
    requestVendorUserPasswordReset,
    uploadVendorPermissionsFile,
} from '../apiClient';
import { EntityUpdateReference } from '../authorization/taskAuthorizations';
import NamedId from '../utils/NamedId';
import { AsyncTask } from '../utils/asyncTasks';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { UploadResult, UploadStatuses } from '../utils/import';
import { ExternalValidationError, FieldValidationActionType } from '../utils/inputs';
import { errorAction, successAction } from '../utils/notifications';
import { compose } from '../utils/utils';

const messages = {
    permissionUploadSuccess: (fileName: string): string => `${fileName} was uploaded successfully`,
    permissionUploadError: (fileName): string => `${fileName} could not be uploaded`,
    permissionProfilesLoadError: `Permission profiles could not be loaded`,
};

export const vendorUserColumnToAttributeMapping = {
    'First Name': 'firstName',
    'Last Name': 'lastName',
    Email: 'email',
    Status: 'status',
} as const;

export const defaultVendorSortingOrder: SORT_OPTIONS = {
    'orderBy:column': 'nickName',
    'orderBy:direction': 'ASC',
};

export const defaultVendorUsersSortingOrder: SORT_OPTIONS<VendorUserSortAttributes> = {
    'orderBy:column': 'firstName',
    'orderBy:direction': 'ASC',
};

export type VendorUserTableColumns = keyof typeof vendorUserColumnToAttributeMapping;
export type VendorUserSortAttributes = (typeof vendorUserColumnToAttributeMapping)[VendorUserTableColumns];

export class Vendor {
    id = '';
    name = '';
    nickName = '';
    sapId = '';
    sublocation = '';
    city = '';
    country = '';
    blockchainAddress = '';
    entitlements: Array<SerializedBalanceEntry> = [];
    authorizedAt?: Date;
    createdAt?: Date;
    createdByManager?: NamedId;
    createdByManagerId: string;
    cancelledByManagerId: string;
    authorizedByManager?: NamedId;
    wfpBankKey = '';
    vendorBankKey = '';
    paymentFrequency = '';
    paymentDiscount = '';
    pendingEntityUpdates?: EntityUpdateReference[];
    panelAccessStatus: string;
    transactionsExecutingStatus: string;
    parkedVendorUsers?: Array<ParkedVendorUsers>;
    vendorUsersRequestCount = 0;
    vendorBranchesRequestCount = 0;
    phoneNumber = '';
    email = '';
    permissionsFiles: Array<AsyncTask> = [];
}

export class ParkedVendorUsers {
    vendorId?: string;
    createdByManagerId?: number;
}

export class VendorNickName {
    id = '';
    name = '';
    nickName = '';
    authorizedAt: Date;
}

export class VendorUserName {
    id = '';
    firstName = '';
    lastName = '';
    authorizedAt: Date;
}

export class VendorUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    vendorId: string;
    createdByManager: NamedId;
    createdAt: string;
    authorizedByManager: NamedId;
    authorizedAt: string;
    status: string;
    pendingEntityUpdates?: EntityUpdateReference[];
    isPasswordBlocked: boolean;

    constructor() {
        this.id = '';
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.phoneNumber = '';
        this.vendorId = '';
        this.createdByManager = null;
        this.createdAt = '';
        this.authorizedByManager = null;
        this.authorizedAt = '';
        this.status = '';
        this.pendingEntityUpdates = [];
        this.isPasswordBlocked = false;
    }
}

export class VendorUserDraft {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    vendorId: string;

    constructor() {
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.phoneNumber = '';
        this.vendorId = '';
    }
}

export class VendorsState {
    list: Array<Vendor> = [];
    nickNameList: Array<VendorNickName> = [];
    validationError?: ExternalValidationError = null;
    importStatus?: string;
    importResult?: UploadResult;
    usersList: Array<VendorUser> = [];
    vendorUser: VendorUser;
    branchCodes: Array<string>;
    usersNames: Array<VendorUserName> = null;

    constructor(
        list: Array<Vendor> = [],
        usersList = [],
        vendorUser = new VendorUser(),
        validationError: ExternalValidationError = null,
        importStatus: string = null,
        importResult: UploadResult = null,
        nickNameList: Array<VendorNickName> = [],
        vendorUsersNames: Array<VendorUserName> = [],
        branchCodes = []
    ) {
        this.list = list || [];
        this.nickNameList = nickNameList || [];
        this.validationError = validationError;
        this.importStatus = importStatus;
        this.importResult = importResult;
        this.usersList = usersList;
        this.vendorUser = vendorUser;
        this.usersNames = vendorUsersNames;
        this.branchCodes = branchCodes;
    }
}

const ActionTypes = {
    dataLoaded: 'VendorsPage.dataLoaded',
    nickNameLoaded: 'VendorsPage.nickNameLoaded',
    singleVendorLoaded: 'VendorsPage.singleVendorLoaded',
    importStarted: 'VendorsPage.importStarted',
    importFinished: 'VendorsPage.importFinished',
    vendorCancelled: 'VendorsPage.vendorCancelled',
    cancelledVendorUser: 'VendorsPage.cancelledVendorUser',
    vendorUsersLoaded: 'VendorsPage.vendorUsersLoaded',
    authorizedVendorUser: 'VendorsPage.authorizedVendorUser',
    vendorUserLoaded: 'VendorsPage.vendorUserLoaded',
    vendorUsersNamesLoaded: 'VendorsPage.vendorUsersNamesLoaded',
    branchCodesLoaded: 'VendorsPage.branchCodesLoaded',
    permissionFileUploaded: 'VendorsPage.permissionFileUploaded',
};

function getVendorUsersWithAuthorized(vendorUsers: Array<VendorUser>, vendorUser: VendorUser) {
    const oldUsers = clonedeep(vendorUsers);

    const index = oldUsers.findIndex((user) => user.id === vendorUser.id);
    if (index >= 0) {
        oldUsers[index] = vendorUser;
    }

    return oldUsers;
}

function vendorsWithCancelledManager(vendors: Array<Vendor>, cancelledVendor: Vendor): Array<Vendor> {
    const newVendors: Array<Vendor> = clonedeep(vendors);
    const index = newVendors.findIndex((vendor) => vendor.id === cancelledVendor.id.toString());
    if (index >= 0) {
        newVendors[index] = cancelledVendor;
    }
    return newVendors;
}

export const ActionCreators = {
    loadVendorsNickNames(query: SORT_OPTIONS) {
        return (dispatch) =>
            loadVendorsNickNames(query).then((vendors) => {
                dispatch({
                    type: ActionTypes.nickNameLoaded,
                    payload: vendors,
                });
            });
    },
    loadVendorUsersNames(sortOptions: SORT_OPTIONS) {
        return (dispatch) =>
            loadVendorUsersNames(sortOptions).then((vendorUsers) => {
                dispatch({
                    type: ActionTypes.vendorUsersNamesLoaded,
                    payload: vendorUsers,
                });
            });
    },

    requestPasswordReset(vendorUser: VendorUser, transport: string) {
        return (dispatch) =>
            requestVendorUserPasswordReset(vendorUser, transport).then(() =>
                dispatch(successAction('Reset password link sent.'))
            );
    },

    cancelPark(id: string) {
        return (dispatch) =>
            cancelVendorPark(id).then((vendor) => {
                dispatch({
                    type: ActionTypes.vendorCancelled,
                    payload: vendor,
                });
                dispatch(routerHelperActions.makeRedirect('/vendors'));
                dispatch(successAction('Vendor cancelled successfully.'));
            });
    },
    exportActivityLog(vendorId: string, options: any, filter: any) {
        return (dispatch) =>
            exportActivityLog(vendorId, ActivityLogPageType.Vendor, options, filter).catch((err) => {
                if (err.status === 423) {
                    dispatch(
                        errorAction(
                            `Export task limit exceeded. Please wait until one of the scheduled exports finished`
                        )
                    );
                }
            });
    },
    uploadPermissionsFile(id: string, file: File) {
        return async (dispatch) => {
            try {
                await uploadVendorPermissionsFile(id, file);
                compose<string, void>(dispatch, successAction, messages.permissionUploadSuccess)(file.name);

                const vendors = await loadVendors(defaultVendorSortingOrder);
                dispatch({
                    type: ActionTypes.dataLoaded,
                    payload: vendors,
                });
            } catch (err) {
                compose<string, void>(dispatch, errorAction, messages.permissionUploadError)(file.name);
            }
        };
    },
};

export function vendorsReducer(state: VendorsState = new VendorsState(), action: Action) {
    switch (action.type) {
        case ActionTypes.dataLoaded:
            return new VendorsState(action.payload, state.usersList);
        case ActionTypes.nickNameLoaded:
            return new VendorsState(
                state.list,
                state.usersList,
                state.vendorUser,
                state.validationError,
                state.importStatus,
                state.importResult,
                action.payload,
                state.usersNames,
                state.branchCodes
            );
        case ActionTypes.singleVendorLoaded:
            const existingVendor = state.list.find((v) => v.id === action.payload.id);
            if (existingVendor) {
                Object.assign(existingVendor, action.payload);
                return state;
            } else {
                return new VendorsState(
                    state.list.concat([action.payload]),
                    state.usersList,
                    state.vendorUser,
                    null,
                    null,
                    null,
                    state.nickNameList,
                    state.usersNames
                );
            }
        case ActionTypes.vendorCancelled:
            const newVendors = vendorsWithCancelledManager(state.list, action.payload.vendor);
            return new VendorsState(newVendors, state.usersList, state.vendorUser);
        case ActionTypes.importStarted:
            return new VendorsState(state.list, state.usersList, state.vendorUser, null, UploadStatuses.pending);
        case ActionTypes.importFinished:
            return new VendorsState(
                [],
                state.usersList,
                state.vendorUser,
                null,
                UploadStatuses.finished,
                action.payload
            );
        case FieldValidationActionType:
            return new VendorsState(state.list, state.usersList, state.vendorUser, action.payload);
        case ActionTypes.vendorUsersLoaded:
            return new VendorsState(
                state.list,
                action.payload.vendorUsers,
                state.vendorUser,
                state.validationError,
                state.importStatus,
                state.importResult,
                state.nickNameList,
                state.usersNames,
                state.branchCodes
            );
        case ActionTypes.cancelledVendorUser:
        case ActionTypes.authorizedVendorUser:
            const newVendorUsers = getVendorUsersWithAuthorized(state.usersList, action.payload);
            return new VendorsState(state.list, newVendorUsers, state.vendorUser);
        case ActionTypes.vendorUserLoaded:
            return new VendorsState(state.list, state.usersList, action.payload);
        case ActionTypes.vendorUsersNamesLoaded:
            return new VendorsState(
                state.list,
                state.usersList,
                state.vendorUser,
                null,
                null,
                null,
                state.nickNameList,
                action.payload
            );
        case ActionTypes.branchCodesLoaded:
            return new VendorsState(
                state.list,
                state.usersList,
                state.vendorUser,
                state.validationError,
                state.importStatus,
                state.importResult,
                state.nickNameList,
                state.usersNames,
                action.payload
            );
    }

    return state;
}
