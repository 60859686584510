export enum WindowStorageToken {
    managerApiToken = 'apiToken',
    partnerUserApiToken = 'partnerPanelApiToken',
    vendorUserApiToken = 'vendorPanelApiToken',
}

export enum WindowStorageUser {
    manager = 'manager',
    vendorUser = 'vendorPanelUser',
    partnerUser = 'partnerPanelUser',
}

export class WindowStorage implements Storage {
    clear(): void {
        return window.sessionStorage.clear();
    }

    key(index: number): string | null {
        return window.sessionStorage.key(index);
    }

    removeItem(key: string): void {
        return window.sessionStorage.removeItem(key);
    }

    getItem(key: string): string | null {
        return window.sessionStorage.getItem(key);
    }

    setItem(key: string, value: string): void {
        return window.sessionStorage.setItem(key, value);
    }

    readonly length: number = window.sessionStorage.length;
}

export const windowStorage = new WindowStorage();
