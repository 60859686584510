import React from 'react';
import moment from 'moment-timezone';

import { TokenContractWithBalance } from './model';

import { Input, ValidationContext } from '../utils/inputs';
import { currencyFormatter, displayMomentAsDay } from '../utils/utils';
import { PredefinedCurrency } from '../app/appConfig';

const CardImage: React.CSSProperties = {
    alignSelf: 'center',
};

const DataContainer: React.CSSProperties = {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    flexDirection: 'column',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
};

const CardTitle: React.CSSProperties = {
    display: 'flex',
};

const CardValue: React.CSSProperties = {
    display: 'flex',
    fontSize: 25,
};

const CardInput: React.CSSProperties = {
    display: 'flex',
    alignSelf: 'stretch',
};

const CardContainer: React.CSSProperties = {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: 'auto',
    paddingLeft: 20,
    paddingRight: 40,
    margin: 10,
};

const TokenCardContainer: React.CSSProperties = { width: '100%' };

const TokenCardData: React.CSSProperties = {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 10,
};

const TokenCardDataValues: React.CSSProperties = {
    textAlign: 'end',
    fontWeight: 'bold',
};

const CardValues: React.CSSProperties = {
    display: 'flex',
    gap: '40px',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
};

interface CardProps {
    title: string;
    style?: React.CSSProperties;
    image?: string;
}

interface CardInputOptions {
    context: ValidationContext;
    name: string;
    inputRequired: boolean;
    onInputChange: (event: React.ChangeEvent<any>) => void;
    type?: string;
}

interface ReadonlyProps extends CardProps {
    value: string;
}

interface ReadonlyLinkProps extends ReadonlyProps {
    href?: string;

    onClick?(): void;
}

interface ReadonlyLinkMultipleProps extends CardProps {
    values: string[];
    href?: string;

    onClick?(id): any;
}

interface TokenProps extends CardProps {
    currency: PredefinedCurrency;
    data: Array<TokenContractWithBalance>;
    timezone: string;
}

interface InputCardProps extends CardProps {
    value: string;
    inputOptions: CardInputOptions;
}

function CardTemplate(props: CardProps, child: React.ReactChild) {
    const { title, image } = props;
    return (
        <div className="shadowed-container" style={CardContainer}>
            {image && <span className={image} style={CardImage} />}
            <div style={DataContainer}>
                <p style={CardTitle}>{title}</p>
                {child}
            </div>
        </div>
    );
}

export function ReadonlyCard(props: ReadonlyProps) {
    const { value } = props;
    const cardValue = <label style={CardValue}>{value || '-'}</label>;
    return CardTemplate(props, cardValue);
}

export function ReadonlyCardLink(props: ReadonlyLinkProps) {
    const { value, image, title, onClick, href } = props;
    const cursor = onClick ? 'pointer' : 'default';
    const Wrapper = ({ children }) =>
        onClick ? <div onClick={onClick}>{children}</div> : <a href={href}>{children}</a>;
    return (
        <div className="shadowed-container" style={CardContainer}>
            {image && <span className={image} style={CardImage} />}
            <div style={DataContainer}>
                <p style={CardTitle}>{title}</p>
                <Wrapper>
                    <label style={{ ...CardValue, cursor }}>{value || '-'}</label>
                </Wrapper>
            </div>
        </div>
    );
}

export function ReadonlyCardMultipleLink(props: ReadonlyLinkMultipleProps) {
    const { values, image, title, onClick, href } = props;
    const cursor = onClick ? 'pointer' : 'default';
    const Wrapper = ({ children, id }) =>
        onClick ? <div onClick={onClick(id)}>{children}</div> : <a href={href}>{children}</a>;
    return (
        <div className="shadowed-container" style={CardContainer}>
            {image && <span className={image} style={CardImage} />}
            <div style={DataContainer}>
                <p style={CardTitle}>{title}</p>
                <div style={CardValues}>
                    {values &&
                        values.map((link) => {
                            return (
                                <Wrapper key={link} id={link}>
                                    <label style={{ ...CardValue, cursor }}>{link || '-'}</label>
                                </Wrapper>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}

export function TokenCard(props: TokenProps) {
    const { currency, data, timezone } = props;
    const cardValue = (
        <div style={TokenCardContainer}>
            {data.map((el: TokenContractWithBalance) => (
                <div key={el.address} style={TokenCardData}>
                    <div>
                        <div>From</div>
                        <div>Until</div>
                        <div>Balance</div>
                    </div>
                    <div style={TokenCardDataValues}>
                        <div>{displayMomentAsDay(moment(el.validFrom).tz(timezone))}</div>
                        <div>{displayMomentAsDay(moment(el.validUntil).tz(timezone))}</div>
                        <div>{currencyFormatter(currency).format(Number(el.balance))}</div>
                    </div>
                </div>
            ))}
        </div>
    );
    return data && data.length > 0 ? CardTemplate(props, cardValue) : null;
}

export function InputCard(props: InputCardProps) {
    const { value, inputOptions } = props;
    const cardValue = (
        <Input
            className="form-control"
            context={inputOptions.context}
            name={inputOptions.name}
            onChange={inputOptions.onInputChange}
            required={inputOptions.inputRequired}
            style={CardInput}
            type={inputOptions.type || 'text'}
            value={value}
        />
    );
    return CardTemplate(props, cardValue);
}
