import { SerializedBalanceEntry } from '../vendors/model';
import { getEntityBalanceComparison, getLastEntityBalance } from '../apiClient';
import Action from '../action';
import { TransactionType } from '../transactions/transactions';
import BigNumber from '../utils/bigNumber';

export enum EntityBalanceType {
    Beneficiary = 'beneficiary',
}

export const ActionTypes = {
    loadLastEntityBalance: 'EntityBalance.loadLastEntityBalance',
    loadEntityBalanceComparison: 'EntityBalance.loadEntityBalanceComparison',
};

export const ActionCreators = {
    loadLastEntityBalance(entityId: string, entityType: EntityBalanceType) {
        return (dispatch) => {
            return getLastEntityBalance(entityId, entityType).then((entityBalance) => {
                const obj = {
                    type: ActionTypes.loadLastEntityBalance,
                    payload: entityBalance,
                };
                dispatch(obj);
            });
        };
    },
    loadEntityBalanceComparison(entityId: string, entityType: EntityBalanceType) {
        return (dispatch) => {
            return getEntityBalanceComparison(entityId, entityType).then((entityBalance) => {
                const obj = {
                    type: ActionTypes.loadEntityBalanceComparison,
                    payload: entityBalance,
                };
                dispatch(obj);
            });
        };
    },
};

export interface MappedTransactionSums {
    entitlements: BigNumber;
    adjustments: BigNumber;
    expenditures: BigNumber;
    unloads: BigNumber;
}

export interface TypeTotals {
    transactionsSum: number;
    transactionsType: TransactionType;
    isAdjustment: boolean;
}

export interface TypeSumBalanceEntry {
    dbBalance: number;
    category: string;
    currency: string;
    typeBasedSum: Array<TypeTotals>;
}

export class EntityBalanceResponse {
    entityId = '';
    databaseBalances: Array<TypeSumBalanceEntry> = [];
    blockchainBalance?: Array<SerializedBalanceEntry> = [];
    doBalancesMatch?: boolean = false;
    lastTransactionAt = '';
}

export class EntityBalanceState {
    lastEntityBalance: EntityBalanceResponse = new EntityBalanceResponse();
    lastEntityBalanceComparison: EntityBalanceResponse = new EntityBalanceResponse();

    constructor(lastEntityBalance: EntityBalanceResponse, lastEntityBalanceComparison: EntityBalanceResponse) {
        this.lastEntityBalance = lastEntityBalance && lastEntityBalance;
        this.lastEntityBalanceComparison = lastEntityBalanceComparison && lastEntityBalanceComparison;
    }
}

export function entityBalanceReducer(state: EntityBalanceState = new EntityBalanceState(null, null), action: Action) {
    {
        switch (action.type) {
            case ActionTypes.loadLastEntityBalance:
                return new EntityBalanceState(action.payload.entityBalance, state.lastEntityBalanceComparison);
            case ActionTypes.loadEntityBalanceComparison:
                return new EntityBalanceState(state.lastEntityBalance, action.payload.entityBalance);
            default:
                return state;
        }
    }
}

export function mapTypeSumBalanceEntryToAdjustmentsExpenditures(
    typeSumBalance: TypeSumBalanceEntry
): MappedTransactionSums {
    const transactionSums = typeSumBalance.typeBasedSum.reduce(
        (prevTotals, totals) => {
            const bigNumberTransactionsSum = new BigNumber(totals.transactionsSum);

            if (totals.transactionsType === TransactionType.topup) {
                prevTotals.entitlements = prevTotals.entitlements.plus(bigNumberTransactionsSum);
                return prevTotals;
            } else if (totals.transactionsType === TransactionType.spendBalance) {
                if (totals.isAdjustment) {
                    prevTotals.adjustments = prevTotals.adjustments.minus(bigNumberTransactionsSum);
                    prevTotals.expenditures = prevTotals.expenditures.minus(bigNumberTransactionsSum);
                } else {
                    prevTotals.expenditures = prevTotals.expenditures.plus(bigNumberTransactionsSum);
                }
                return prevTotals;
            } else if (totals.transactionsType === TransactionType.reverse && totals.isAdjustment) {
                prevTotals.adjustments = prevTotals.adjustments.plus(bigNumberTransactionsSum);
                return prevTotals;
            } else if (totals.transactionsType === TransactionType.unload) {
                prevTotals.unloads = prevTotals.unloads.plus(bigNumberTransactionsSum);
                return prevTotals;
            } else {
                return prevTotals;
            }
        },
        {
            entitlements: new BigNumber(0),
            adjustments: new BigNumber(0),
            expenditures: new BigNumber(0),
            unloads: new BigNumber(0),
        }
    );
    return transactionSums;
}
