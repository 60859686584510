import React from 'react';
import FiltersComponent from '../utils/filters';
import { useEffect, useState } from 'react';
import { Filter, MultiSelectFilter } from '../utils/FilterTypes';
import { useDispatch, useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';
import { UserStatuses } from '../utils/users';
import { startCase } from 'lodash';
import { ActionCreators } from './partnersActions';
import _ from 'lodash';
import { SORT_OPTIONS } from '../utils/hooks/useSort';

interface Props {
    sortOptions: SORT_OPTIONS;
}

const PartnerFilters: React.FC<Props> = ({ sortOptions }) => {
    const [activeFilters, setActiveFilters] = useState<Filter[]>([]);
    const dispatch = useDispatch();
    const partnerStatuses = useSelector((state: State) => state.appConfig.userStatuses.partnerStatus);

    useEffect(() => {
        const statusesOptions = Object.values(partnerStatuses).map((status: UserStatuses) => ({
            label: startCase(status),
            value: status,
        }));

        setActiveFilters([
            new MultiSelectFilter(
                'Status',
                'status',
                [
                    { label: startCase(UserStatuses.active), value: UserStatuses.active },
                    { label: startCase(UserStatuses.parked), value: UserStatuses.parked },
                ],
                statusesOptions,
                null,
                true
            ),
        ]);
    }, []);

    useEffect(() => {
        if (activeFilters.length > 0) {
            fetchPartners();
        }
    }, [activeFilters, sortOptions]);

    const fetchPartners = () => {
        dispatch(
            ActionCreators.loadPartners(
                sortOptions,
                activeFilters.filter((input) => input.isSelected)
            )
        );
    };

    const filterChangedHandler = (filter: Filter) => {
        const activeFiltersCopy = _.clone(activeFilters);
        const index = activeFiltersCopy.findIndex((x) => x.name === filter.name);

        if (index === -1) {
            setActiveFilters(activeFiltersCopy.concat([filter]));
        } else {
            activeFiltersCopy.splice(index, 1, filter);
            setActiveFilters(activeFiltersCopy);
        }
    };

    return (
        <FiltersComponent
            onFilterChanged={filterChangedHandler}
            defaultFilters={activeFilters}
            filters={activeFilters}
            withApply={false}
            withIsSelected={false}
        />
    );
};

PartnerFilters.displayName = 'PartnerFilters';

export { PartnerFilters };
