import { loadEntitlements } from '../apiClient';
import Action from '../action';
import { Filter } from '../utils/FilterTypes';
import { PagedState } from '../utils/paging';

export class Entitlement {
    id: number;
    beneficiaryId: string;
    agency: string;
    category: string;
    amount: number;
    currency: string;
    expiry: Date;
    validFrom: Date;
    createdAt: Date;
    removedByAsyncTaskId: string;
}

export enum EntitlementStatus {
    Loaded = 'loaded',
    Removed = 'removed',
}

export const ActionTypes = {
    dataLoaded: 'EntitlementsPage.dataLoaded',
};

export const ActionCreators = {
    loadEntitlements(timezone: string, page, limit = 25, filters: Array<Filter> = []) {
        return (dispatch) =>
            loadEntitlements(timezone, filters, page, limit).then((entitlements) =>
                dispatch({
                    type: ActionTypes.dataLoaded,
                    payload: Object.assign(entitlements, {
                        filters,
                    }),
                })
            );
    },
};

export function entitlementsReducer(state: PagedState<Entitlement> = new PagedState<Entitlement>(), action: Action) {
    switch (action.type) {
        case ActionTypes.dataLoaded:
            return action.payload;
    }
    return state;
}
