import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActionCreators as ManagerActionCreators } from '../managers/managers';
import State from '@wfp-root-app/store/state';
import { ActionCreators, AsyncTask, AsyncTasksTypes } from '../utils/asyncTasks';
import AsyncTasksListExportView from '../utils/AsyncTasksListExportView';
import { PagedState } from '../utils/paging';
import { RefreshSignaler } from '../utils/refresher';
import { useManagerParams } from './hooks/useManagerParams';

interface Props {
    loadAsyncTasksList: (page: number, limit: number, type: string) => Promise<RefreshSignaler>;
    appendAsyncTasksList: (limit: number, type: string) => void;
    exports: PagedState<AsyncTask>;
    exportUsers: (mapping, id) => Promise<any>;
    downloadFile: (type: string, id: string) => string;
    managerId: string;
    urlId: string;
}

interface LocalState {
    selectedColumns: any;
    id: string | string[];
    selectAll: boolean;
}

class ManagerExportPage extends React.Component<Props, LocalState> {
    private availableColumns = [
        { key: 'id', header: 'User ID' },
        { key: 'firstName', header: 'First Name' },
        { key: 'lastName', header: 'Last Name' },
        { key: 'email', header: 'Email' },
        { key: 'createdAt', header: 'Creation Date' },
        { key: 'createdByManagerId', header: 'Created by' },
        { key: 'authorizedAt', header: 'Authorization Date' },
        { key: 'authorizedByManagerId', header: 'Authorized by' },
        { key: 'cancelledAt', header: 'Cancellation Date' },
        { key: 'cancelledByManagerId', header: 'Cancelled by' },
        { key: 'status', header: 'Status' },
        { key: 'phoneNumber', header: 'Phone Number' },
        { key: 'expirationDate', header: 'Expiration Date' },
        { key: 'otpStatus', header: 'OTP Status' },
        { key: 'permissions', header: 'Permissions' },
        { key: 'profile', header: 'Profile Name' },
        { key: 'agency', header: 'Organization' },
    ];

    private defaultPageSize = 10;
    private refresher?: RefreshSignaler;

    constructor(props) {
        super(props);
        this.state = {
            selectedColumns: {},
            id: null,
            selectAll: false,
        };
    }

    async changePageRequested() {
        this.props.appendAsyncTasksList(this.defaultPageSize, AsyncTasksTypes.EXPORT_USERS);
    }

    async UNSAFE_componentWillMount() {
        this.refresher = await this.props.loadAsyncTasksList(1, this.defaultPageSize, AsyncTasksTypes.EXPORT_USERS);

        if (this.props.urlId) {
            this.setState({ id: this.props.urlId });
        }
    }

    renderExportFeatureDescription() {
        return (
            <div>
                {this.state.id && `Downloading user with id="${this.state.id}"`}
                <p>Please select the information you would like to download per User:</p>
            </div>
        );
    }

    renderExportFeature() {
        return (
            <div>
                <div className="wfp-form--group">
                    <div className="checkbox" key="all" style={{ marginBottom: 25 }}>
                        <label>
                            <input
                                checked={this.state.selectAll}
                                name="all"
                                onChange={this._handleCheckAll.bind(this)}
                                type="checkbox"
                            />{' '}
                            Select All
                        </label>
                    </div>
                    {this.availableColumns.map((column) => (
                        <div className="checkbox" key={column.key}>
                            <label>
                                <input
                                    checked={this.state.selectedColumns[column.key] || false}
                                    name={column.key}
                                    onChange={this._handleCheck.bind(this)}
                                    type="checkbox"
                                />{' '}
                                {column.header}
                            </label>
                        </div>
                    ))}
                </div>

                <div className="wfp-form--actions">
                    <button className="wfp-btn" type="submit">
                        Generate
                    </button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/admin">
                                <span>Admin</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">Download Users</span>
                        </li>
                    </ol>
                </nav>
                <h3>Download Users</h3>

                {this.renderExportFeatureDescription()}

                <form onSubmit={this._exportUsers.bind(this)}>{this.renderExportFeature()}</form>
                {this.props.exports && this.props.exports.items.length > 0 && (
                    <div>
                        <AsyncTasksListExportView
                            authorize={() => null}
                            authorizedManagerId={this.props.managerId}
                            canDownload={true}
                            data={this.props.exports.items}
                            downloadFile={this.props.downloadFile.bind(this)}
                        />
                        <div className="wfp-form--actions">
                            <button
                                className="wfp-btn--primary"
                                disabled={this.props.exports.items.length === this.props.exports.paging.total}
                                onClick={this.changePageRequested.bind(this)}
                                type="button"
                            >
                                More
                            </button>
                        </div>
                    </div>
                )}
            </main>
        );
    }

    _handleCheck(event) {
        const key = event.target.name;
        const value = event.target.checked;

        this.setState((prevState) => {
            const selectedColumns = prevState.selectedColumns;
            selectedColumns[key] = value;
            return { selectedColumns };
        });
    }

    _handleCheckAll(event) {
        const value = event.target.checked;
        const selectedColumns = {};

        this.availableColumns.forEach((column) => {
            selectedColumns[column.key] = value;
        });

        this.setState(() => {
            return { selectedColumns, selectAll: value };
        });
    }

    _exportUsers(event) {
        event.preventDefault();

        const mapping = this.availableColumns.filter((column) => this.state.selectedColumns[column.key]);

        return this.props
            .exportUsers(mapping, this.state.id)
            .then(() => this.props.loadAsyncTasksList(1, this.defaultPageSize, AsyncTasksTypes.EXPORT_USERS));
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        exportUsers: bindActionCreators(ManagerActionCreators.exportUsers, dispatch),
        loadAsyncTasksList: bindActionCreators(ActionCreators.loadAsyncTasksList, dispatch),
        appendAsyncTasksList: bindActionCreators(ActionCreators.appendAsyncTasksList, dispatch),
        downloadFile: bindActionCreators(ActionCreators.downloadFile, dispatch),
    };
}

function mapStateToProps(state: State) {
    return {
        exports: state.asyncTasks.asyncTasks[AsyncTasksTypes.EXPORT_USERS] || new PagedState(),
        managerId: state.auth.manager ? state.auth.manager.id : null,
    };
}

const withHooks = (Component: any) => {
    return (props: any) => {
        const { managerId } = useManagerParams();
        return <Component {...props} urlId={managerId} />;
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ManagerExportPage));
