import React from 'react';
import { EntityType, ProductUpdate, EntityUpdate } from '../taskAuthorizations';
import { DefaultChangesList } from './default-change-list';
import { PropsWithChildren } from 'react';
import { ProductChangesList } from './product-change-list';

interface Props {
    entityType: EntityType;
    updates: EntityUpdate[] | ProductUpdate[];
}

export function ChangesListRenderer({ entityType, updates }: PropsWithChildren<Props>) {
    switch (entityType) {
        case EntityType.productManagement:
            return <ProductChangesList updates={updates} />;
        default:
            return <DefaultChangesList updates={updates} />;
    }
}
