import React from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';

import LoginForm from '../login/LoginForm';
import { ActionCreators } from './auth';
import State from '@wfp-root-app/store/state';
import { AppConfigActionCreators } from '../app/appConfig';
import { useSearchParams } from '../common/hooks/useSearchParams';

interface OwnProps {
    otpReset?: boolean;
}

class LoginPage extends React.Component<any, any> {
    renderOtpResetNotification() {
        return (
            <div>
                <h4>Email with link for setting new OTP has been sent.</h4>
                <p>Click on them and follow the instruction to set up new OTP.</p>
            </div>
        );
    }

    render() {
        return (
            <main>
                {!this.props.resetOtpSuccessful
                    ? this.props.resetLinkIsSent
                        ? this.renderOtpResetNotification()
                        : !this.props.token && (
                              <div>
                                  <div>
                                      <h3>
                                          {this.props.otpReset ? 'Building Blocks OTP Set' : 'Building Blocks Panel'}
                                      </h3>
                                      <LoginForm
                                          changeProjectType={this.props.changeProjectType}
                                          checkToken={this._checkToken.bind(this)}
                                          country={this.props.country}
                                          error={this.props.error}
                                          onEmailRequest={this._emailRequest.bind(this)}
                                          onSubmit={this._login.bind(this)}
                                          otpReset={this.props.otpReset}
                                          projectType={this.props.projectType}
                                          security={this.props.security}
                                      />
                                  </div>
                              </div>
                          )
                    : ''}
            </main>
        );
    }

    async _login(email: string, password: string, otp?: string, secret?: string) {
        return this.props.login(this.props.redirectUrl, email, password, this.props.otpReset, otp, secret);
    }

    _emailRequest(email: string, password: string) {
        return this.props.sendOtpCode(email, password);
    }

    _checkToken() {
        return this.props.checkToken();
    }
}

function mapStateToProps(state: State, ownProps: OwnProps) {
    return {
        token: state.auth.apiToken,
        country: state.appConfig.country,
        projectType: state.appConfig.project,
        error: state.auth.error,
        project: state.appConfig.project,
        otpReset: ownProps.otpReset ? ownProps.otpReset : false,
        security: state.security,
        resetLinkIsSent: state.auth.resetLinkIsSent,
        resetOtpSuccessful: state.auth.resetOtpSuccessful,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        login: bindActionCreators(ActionCreators.login, dispatch),
        changeProjectType: bindActionCreators(AppConfigActionCreators.changeProjectType, dispatch),
        sendOtpCode: bindActionCreators(ActionCreators.sendOtpCode, dispatch),
        checkToken: bindActionCreators(ActionCreators.checkToken, dispatch),
    };
}

const withHooks = (Component: any) => {
    return (props: any) => {
        const dispatch = useDispatch();
        const searchParams = useSearchParams();

        return <Component {...props} dispatch={dispatch} redirectUrl={searchParams.redirectUrl} />;
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null)(withHooks(LoginPage));
