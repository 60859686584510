import React from 'react';
import { Link } from 'react-router-dom';
import Manager from '../managers/manager';
import { supStyle } from './utils';
import { ManagerPermission } from '../permission-profiles/permission';

interface FeatureButtonProps {
    title: string;
    linkTo: string;
    manager: Manager;
    restrictedToFeatures: ManagerPermission[];
    counter?: string;
    state?: { [stateKey: string]: string | number };
    tooltip?: string;
}

export default function FeatureButton(props: FeatureButtonProps) {
    const { manager, restrictedToFeatures, tooltip } = props;
    let hasFeature = false;
    for (const feature of restrictedToFeatures) {
        if (manager?.profile?.permissions.includes(feature)) {
            hasFeature = true;
            break;
        }
    }
    const button = (
        <Link className="ml2" to={props.linkTo} state={props.state}>
            <button className="wfp-btn" style={{ margin: '2px' }} type="button" title={tooltip}>
                {props.title}
                {props.counter && <sup style={supStyle}>{props.counter}</sup>}
            </button>
        </Link>
    );
    return hasFeature ? button : null;
}
