// note: useEffect that does not run on first render

import { useEffect, useRef } from 'react';

export function useUpdateEffect(callback, deps) {
    const isFirst = useRef(true);
    useEffect(() => {
        if (!isFirst.current) {
            return callback();
        }
        isFirst.current = false;
    }, deps);
}
