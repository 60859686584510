/* eslint-disable */
import { EventHandler, SyntheticEvent } from 'react';

export function preventDefault<TH extends SyntheticEvent<TE>, TE>(
    handler: EventHandler<TH>
): EventHandler<TH> {
    return function () {
        if (arguments[0] && arguments[0].preventDefault) {
            arguments[0].preventDefault();
        }
        return handler.apply(this, arguments);
    };
}

export function stopPropagation<TH extends SyntheticEvent<TE>, TE>(
    handler: EventHandler<TH>
): EventHandler<TH> {
    return function () {
        if (arguments[0] && arguments[0].stopPropagation) {
            arguments[0].stopPropagation();
        }
        return handler.apply(this, arguments);
    };
}
