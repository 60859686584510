import { useCallback, useMemo, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDeduplicationPreventedByAgencyStatistics,
    getDuplicationPreventedThanksToAgencyDataStatistics,
    getTaxIdsOverlapStats,
    getUniqueTaxIdsStatistics,
} from '../../apiClient';
import {
    DuplicationPreventedByAgencyResponse,
    DuplicationPreventedThanksToAgencyDataResponse,
    TaxIdsOverlapStatsResponse,
    UniqueTaxIdsStatsResponse,
} from './deduplication-statistics.model';
import { errorAction } from '../../utils/notifications';
import State from '@wfp-root-app/store/state';

interface StatisticsState {
    isLoading: boolean;
    uniqueTaxIdsStatistics: UniqueTaxIdsStatsResponse;
    taxIdsOverlapStats: TaxIdsOverlapStatsResponse;
    duplicationPreventedByAgencyStatistics: DuplicationPreventedByAgencyResponse;
    duplicationPreventedThanksToAgencyDataStatistics: DuplicationPreventedThanksToAgencyDataResponse;
}

type Action =
    | { type: 'FETCH_INIT' }
    | {
          type: 'FETCH_SUCCESS';
          payload: {
              uniqueTaxIdsStatistics: StatisticsState['uniqueTaxIdsStatistics'];
              taxIdsOverlapStats: StatisticsState['taxIdsOverlapStats'];
              duplicationPreventedByAgencyStatistics: StatisticsState['duplicationPreventedByAgencyStatistics'];
              duplicationPreventedThanksToAgencyDataStatistics: StatisticsState['duplicationPreventedThanksToAgencyDataStatistics'];
          };
      }
    | { type: 'FETCH_FAILURE' };

const initialState: StatisticsState = {
    isLoading: false,
    uniqueTaxIdsStatistics: null,
    taxIdsOverlapStats: null,
    duplicationPreventedByAgencyStatistics: null,
    duplicationPreventedThanksToAgencyDataStatistics: null,
};

const reducer = (state: StatisticsState, action: Action) => {
    switch (action.type) {
        case 'FETCH_INIT':
            return { ...state, isLoading: true };
        case 'FETCH_SUCCESS':
            return { ...state, isLoading: false, ...action.payload };
        case 'FETCH_FAILURE':
            return { ...state };
        default:
            return state;
    }
};

export const useStatisticsHelper = () => {
    const storeDispatch = useDispatch();
    const { agency, entitlementsCategoryConfig } = useSelector((state: State) => ({
        agency: state.auth?.manager?.agency,
        entitlementsCategoryConfig: state.appConfig.entitlementsConfig,
    }));

    const [state, dispatch] = useReducer(reducer, initialState);

    const errorWhileDataLoading = useCallback(() => {
        storeDispatch(errorAction('Failed while loading deduplication statistics.'));
    }, []);

    const fetchStatisticsByCategory = (category: string) => {
        dispatch({ type: 'FETCH_INIT' });

        let categoriesToQuery = category;

        if (categoriesToQuery === 'ALL') {
            categoriesToQuery = entitlementsCategoryConfig?.categories.join(',');
        }

        if (agency === 'ALL') {
            Promise.all([
                getUniqueTaxIdsStatistics(categoriesToQuery),
                getTaxIdsOverlapStats(categoriesToQuery),
                getDeduplicationPreventedByAgencyStatistics(categoriesToQuery),
                getDuplicationPreventedThanksToAgencyDataStatistics(categoriesToQuery),
            ])
                .then(
                    ([
                        uniqueTaxIdsStatistics,
                        taxIdsOverlapStats,
                        duplicationPreventedByAgencyStatistics,
                        duplicationPreventedThanksToAgencyDataStatistics,
                    ]) => {
                        dispatch({
                            type: 'FETCH_SUCCESS',
                            payload: {
                                uniqueTaxIdsStatistics,
                                taxIdsOverlapStats,
                                duplicationPreventedByAgencyStatistics,
                                duplicationPreventedThanksToAgencyDataStatistics,
                            },
                        });
                    }
                )
                .catch(errorWhileDataLoading);
        } else {
            Promise.all([getUniqueTaxIdsStatistics(categoriesToQuery), getTaxIdsOverlapStats(categoriesToQuery)])
                .then(([uniqueTaxIdsStatistics, taxIdsOverlapStats]) => {
                    dispatch({
                        type: 'FETCH_SUCCESS',
                        payload: {
                            uniqueTaxIdsStatistics,
                            taxIdsOverlapStats,
                            duplicationPreventedByAgencyStatistics: null,
                            duplicationPreventedThanksToAgencyDataStatistics: null,
                        },
                    });
                })
                .catch(errorWhileDataLoading);
        }
    };

    const hasStatsToDisplay = useMemo(() => {
        if (agency !== 'ALL') {
            return state.taxIdsOverlapStats?.stats?.length > 0 && state.uniqueTaxIdsStatistics?.stats?.length > 0;
        }

        return (
            state.taxIdsOverlapStats?.stats?.length > 0 ||
            state.uniqueTaxIdsStatistics?.stats?.length > 0 ||
            state.duplicationPreventedByAgencyStatistics?.stats?.length > 0 ||
            state.duplicationPreventedThanksToAgencyDataStatistics?.stats?.length > 0
        );
    }, [
        state.taxIdsOverlapStats,
        state.uniqueTaxIdsStatistics,
        state.duplicationPreventedByAgencyStatistics,
        state.duplicationPreventedThanksToAgencyDataStatistics,
    ]);

    const areStatsExist = useMemo(() => {
        if (agency !== 'ALL') {
            return Boolean(state.taxIdsOverlapStats && state.uniqueTaxIdsStatistics);
        }

        return Boolean(
            state.taxIdsOverlapStats ||
                state.uniqueTaxIdsStatistics ||
                state.duplicationPreventedByAgencyStatistics ||
                state.duplicationPreventedThanksToAgencyDataStatistics
        );
    }, [
        state.taxIdsOverlapStats,
        state.uniqueTaxIdsStatistics,
        state.duplicationPreventedByAgencyStatistics,
        state.duplicationPreventedThanksToAgencyDataStatistics,
    ]);

    return {
        statsState: { ...state, hasStatsToDisplay, areStatsExist },
        fetchStatisticsByCategory,
    };
};
