import Action from '../action';
import { getSecurityData, postOtpSecret, deleteOtpSecret, createOtpSecret } from '../apiClient';
import { errorAction, successAction } from '../utils/notifications';

export interface SecurityProps {
    otpSecret?: string;
    otpQRDataUrl?: string;
    otpEnabled?: boolean;
}

export class SecurityState implements SecurityProps {
    otpSecret: string;
    otpQRDataUrl: string;
    error: any;
    success: boolean;
    otpEnabled?: boolean;

    constructor(security: SecurityProps = {}, error?: any, success?: boolean) {
        this.otpSecret = security.otpSecret;
        this.otpQRDataUrl = security.otpQRDataUrl;
        this.error = error;
        this.success = success;
        this.otpEnabled = security.otpEnabled;
    }
}

export const ActionTypes = {
    updateOtpData: 'Security.updateOtpData',
    successOtpEnable: 'Security.successOtpEnable',
    invalidOtp: 'Security.invalidOtp',
};

export function securityReducer(state: SecurityState = new SecurityState(), action: Action) {
    switch (action.type) {
        case ActionTypes.updateOtpData:
            return new SecurityState(action.payload);
        case ActionTypes.invalidOtp:
            return new SecurityState(state, action.payload);
        case ActionTypes.successOtpEnable:
            return new SecurityState(action.payload, null, true);
        default:
            return state;
    }
}

function handleSecurityResponse(response, dispatch) {
    dispatch({
        type: ActionTypes.updateOtpData,
        payload: response,
    });
}

export const ActionCreators = {
    getSecurityData: () => {
        return (dispatch) => getSecurityData().then((response) => handleSecurityResponse(response, dispatch));
    },
    postOtpSecret: (otp: string, secret: string) => {
        return (dispatch) =>
            postOtpSecret(otp, secret)
                .then((response) => {
                    dispatch(successAction('One time password has been set successfully.'));
                    dispatch({
                        type: ActionTypes.successOtpEnable,
                        payload: response,
                    });
                })
                .catch((error) => {
                    if (error.status === 403) {
                        dispatch({
                            type: ActionTypes.invalidOtp,
                            payload: error,
                        });
                    } else {
                        dispatch(errorAction(error));
                    }
                });
    },
    deleteOtpSecret: () => {
        return (dispatch) => deleteOtpSecret().then((response) => handleSecurityResponse(response, dispatch));
    },
    createOtpSecret: () => {
        return (dispatch) => createOtpSecret().then((response) => handleSecurityResponse(response, dispatch));
    },

    removeSecretFromState: () => {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.updateOtpData,
                payload: {},
            });
    },
};
