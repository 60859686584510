import { hasFeatureAccess } from '../login/auth';
import { AsyncTaskStatus } from './asyncTasks';
import { ManagerPermission } from '../permission-profiles/permission';

export const canCancelSubtask = (auth, row) => {
    return (
        (!row.preCancelledAt && hasFeatureAccess(auth, ManagerPermission.beneficiariesSignLevelOne)) ||
        (row.preCancelledAt &&
            auth.manager &&
            row.preCancelledByManager.id !== auth.manager.id &&
            hasFeatureAccess(auth, ManagerPermission.beneficiariesSignLevelTwo))
    );
};

export const canCancelWholeImport = (auth, row) => {
    return (
        !row.cancelledAt &&
        ((!row.preCancelledAt &&
            row.status === AsyncTaskStatus.toBeSigned &&
            hasFeatureAccess(auth, ManagerPermission.beneficiariesEntitlementPark)) ||
            (row.preCancelledAt &&
                auth.manager &&
                row.preCancelledByManager.id !== auth.manager.id &&
                hasFeatureAccess(auth, ManagerPermission.beneficiariesEntitlementVerify)))
    );
};
