import React, { createContext, FC, useContext, useMemo, useState } from 'react';

interface StepperState {
    activeStep: boolean;
    stepsCount: number;
    steps: any;
    currentStep: number;
    isNextStepDisable: boolean;
}

const initialState: StepperState = {
    activeStep: true,
    stepsCount: 0,
    steps: [],
    currentStep: 0,
    isNextStepDisable: true,
};

type StepperContextType = StepperState & {
    nextStep: () => void;
    prevStep: () => void;
    setSteps: (steps: any[]) => void;
    enableNextStep: () => void;
    disableNextStep: () => void;
};

export const StepperProvider: FC = ({ children }) => {
    const [isNextStepDisable, setIsNextStepDisable] = useState<boolean>(true);

    const disableNextStep = () => {
        setIsNextStepDisable(true);
    };

    const enableNextStep = () => {
        setIsNextStepDisable(false);
    };

    const nextStep = () => {};

    const prevStep = () => {};

    const value = useMemo(
        () => ({
            nextStep,
            prevStep,
            currentStep: 0,
            steps: [],
            isNextStepDisable,
            disableNextStep,
            enableNextStep,
        }),
        [isNextStepDisable]
    );

    return <StepperContext.Provider value={value}>{children}</StepperContext.Provider>;
};

const StepperContext = createContext<StepperState | any>(initialState);

export const useStepperContext = () => {
    const context = useContext<StepperContextType>(StepperContext);

    if (!context) throw new Error('StepperContextProvider should be wrapped.');

    return context;
};
