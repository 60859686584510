import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import State from '@wfp-root-app/store/state';

import { PredefinedDateRanges } from '../../utils/createPredefinedFilters';
import { EntitlementCurrencyConfig } from '../app/appConfig';
import BeneficiarySearch from '../beneficiaries/BeneficiarySearch';
import { EditBeneficiaryCreators } from '../beneficiaries/edit/editBeneficiaryActionCreator';
import { AuthState } from '../login/auth';
import { ManagerPermission } from '../permission-profiles/permission';
import FeatureButton from '../utils/FeatureButton';
import { DateSelectFilter, Filter, MultiSelectFilter } from '../utils/FilterTypes';
import {
    updateFiltersWithCategories,
    updateFiltersWithOrganizations,
    updateFiltersWithStatuses,
} from '../utils/createEntityMultiSelect';
import FiltersComponent from '../utils/filters';
import PagingComponent, { PagedState } from '../utils/paging';
import { FiltersCreators, FiltersTypes } from '../utils/filtersStore';
import { PendingActionsState } from '../utils/pedingActionsReducer';
import DeduplicatedEntitlementsList from './DeduplicatedEntitlementsList';
import { ActionCreators, DeduplicatedEntitlement } from './deduplicatedEntitlements';
import { EntitlementStatus } from './entitlements';

interface Props {
    auth: AuthState;
    entitlements: PagedState<DeduplicatedEntitlement>;
    filters: Filter[];
    loadEntitlements: (timezone: string, page: number, limit: number, filters?: Array<Filter>) => void;
    searchBeneficiary: (beneficiaryId: string) => void;
    saveFilters: (filters: Filter[], relatedTo: string) => void;
    pendingActions: PendingActionsState;
    locationToggle: boolean;
    timezone: string;
    currency: EntitlementCurrencyConfig;
    organizations: string[];
    categories: string[];
}

interface LocalState {
    searchValue: string;
    defaultFilters: Filter[];
}

const initialDefaultFilters = [
    new MultiSelectFilter('Prevented Organization', 'organization', '', []),
    new MultiSelectFilter('Category', 'category', [], []),
    new DateSelectFilter('Assistance start', 'start', '', PredefinedDateRanges),
    new DateSelectFilter('Assistance end', 'end', '', PredefinedDateRanges),
    new DateSelectFilter('Attempted assistance creation date', 'createdAt', '', PredefinedDateRanges),
    // new MultiSelectFilter('Entitlement Status', 'status', [], []), TODO - first discuss logic of removal only deduplications with Houman
    new MultiSelectFilter('Original organization', 'refEntitlementOrganization', '', []),
    new MultiSelectFilter('Assistance status', 'refEntitlementStatus', [], []),
];

class DeduplicatedEntitlementsPage extends React.Component<Props, LocalState> {
    defaultPageSize = 25;

    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            defaultFilters: initialDefaultFilters,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.loadEntitlements(this.props.timezone, 1, this.defaultPageSize, this.props.filters);
        let newFilters = updateFiltersWithCategories(this.props.categories, this.props.filters, 'category');
        newFilters = updateFiltersWithOrganizations(this.props.organizations, newFilters, 'organization');
        newFilters = updateFiltersWithOrganizations(this.props.organizations, newFilters, 'refEntitlementOrganization');
        newFilters = updateFiltersWithStatuses(Object.values(EntitlementStatus), newFilters, 'status');
        newFilters = updateFiltersWithStatuses(Object.values(EntitlementStatus), newFilters, 'refEntitlementStatus');
        this.props.saveFilters(newFilters, FiltersTypes.deduplicatedEntitlements);

        let newDefaultFilters = updateFiltersWithCategories(
            this.props.categories,
            this.state.defaultFilters,
            'category'
        );
        newDefaultFilters = updateFiltersWithOrganizations(this.props.organizations, newDefaultFilters, 'organization');
        newDefaultFilters = updateFiltersWithOrganizations(
            this.props.organizations,
            newFilters,
            'refEntitlementOrganization'
        );
        newDefaultFilters = updateFiltersWithStatuses(Object.values(EntitlementStatus), newDefaultFilters, 'status');
        newDefaultFilters = updateFiltersWithStatuses(
            Object.values(EntitlementStatus),
            newDefaultFilters,
            'refEntitlementStatus'
        );
        this.setState({ defaultFilters: newDefaultFilters });
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
        if (nextProps.organizations.length > this.props.organizations.length) {
            let newFilters = updateFiltersWithOrganizations(
                this.props.organizations,
                this.props.filters,
                'organization'
            );
            newFilters = updateFiltersWithOrganizations(
                this.props.organizations,
                this.props.filters,
                'refEntitlementOrganization'
            );
            this.props.saveFilters(newFilters, FiltersTypes.deduplicatedEntitlements);
        }
        if (nextProps.categories.length > this.props.categories.length) {
            const newFilters = updateFiltersWithCategories(this.props.categories, this.props.filters, 'category');
            this.props.saveFilters(newFilters, FiltersTypes.deduplicatedEntitlements);
        }
    }

    render() {
        const { auth, entitlements } = this.props;

        return (
            <main>
                {entitlements && (
                    <div>
                        <nav className="wfp-breadcrumbs">
                            <ol className="breadcrumbs--wrapper">
                                <li className="breadcrumbs--item">
                                    <Link className="breadcrumbs--link" to="/home-basic">
                                        <span>Home</span>
                                    </Link>
                                </li>
                                <li className="breadcrumbs--item">
                                    <span className="breadcrumbs--last">Assistance Prevented</span>
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <h3 className="col-sm-6">Assistance Prevented</h3>
                            <div className="col-sm-6 tr">
                                {entitlements && entitlements.items.length > 0 && (
                                    <FeatureButton
                                        linkTo={'/entitlements/download/deduplicated-entitlements/file'}
                                        manager={auth.manager}
                                        restrictedToFeatures={[ManagerPermission.beneficiariesDownload]}
                                        title="Download"
                                    />
                                )}
                            </div>
                        </div>
                        <BeneficiarySearch search={this.props.searchBeneficiary.bind(this)} />
                        <FiltersComponent
                            defaultFilters={this.state.defaultFilters}
                            filters={this.props.filters}
                            onApplyFilters={this.applyFilters.bind(this)}
                            withApply={true}
                            withIsSelected={true}
                        />
                        <DeduplicatedEntitlementsList
                            currency={this.props.currency}
                            entitlements={entitlements.items}
                            timezone={this.props.timezone}
                        />
                        <PagingComponent
                            onPageChanged={this.changePageRequested.bind(this)}
                            paging={this.props.entitlements.paging}
                        />
                    </div>
                )}
            </main>
        );
    }

    private changePageRequested(newPage: number) {
        this.props.loadEntitlements(this.props.timezone, newPage, this.defaultPageSize, this.props.filters);
    }

    private applyFilters(filters: Filter[]) {
        this.props.loadEntitlements(this.props.timezone, 1, this.defaultPageSize, filters);
        this.props.saveFilters(filters, FiltersTypes.deduplicatedEntitlements);
    }
}

function mapStateToProps(state: State) {
    return {
        locationToggle: state.appConfig.location.isActive,
        entitlements: state.deduplicatedEntitlements,
        auth: state.auth,
        pendingActions: state.pendingActions,
        filters: state.filters.find((x) => x.relatedTo === FiltersTypes.deduplicatedEntitlements)
            ? state.filters.find((x) => x.relatedTo === FiltersTypes.deduplicatedEntitlements).selectedFilters
            : initialDefaultFilters,
        timezone: state.appConfig.timeZone,
        currency: state.appConfig.entitlementCurrencyConfig,
        organizations: state.appConfig.organizations,
        categories: state.appConfig.entitlementsConfig.categories,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        loadEntitlements: bindActionCreators(ActionCreators.loadDeduplicatedEntitlements, dispatch),
        searchBeneficiary: bindActionCreators(EditBeneficiaryCreators.searchBeneficiary, dispatch),
        saveFilters: bindActionCreators(FiltersCreators.saveFilters, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeduplicatedEntitlementsPage as any);
