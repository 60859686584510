import { addInactivityListeners, removeInactivityAlertListeners } from '../../utils/inactivityDetection';
import { errorAction } from '../../utils/notifications';
import { windowStorage, WindowStorageToken } from '../../windowStorage';
import { ActionTypes } from '../../login/auth';
import { Store } from '@reduxjs/toolkit';
import { getMyself, getPendingActions } from '../../apiClient';
import { AppConfigActionCreators } from '../appConfig';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AuthConfig } from '@wfp-common/models';
import { useMatchPath } from '@wfp-common/hooks/useMatchPath';
import { useRedirectPath } from '@wfp-common/hooks/useRedirectPath';
import { store, useStoreSelector } from '@wfp-root-app/store/store';
import State from '@wfp-root-app/store/state';

const useAuthConfig = (): AuthConfig => {
    const matchLogin = useMatchPath('/login');
    const matchSetOtp = useMatchPath('/set-otp');
    const isPathOnProtectedLocation = useMemo(() => !matchLogin && !matchSetOtp, [matchLogin, matchSetOtp]);
    const appBaseUrl = useMemo(() => '', []);

    const authState = useStoreSelector((state) => state.auth);
    const appConfigState = useStoreSelector((state) => state.appConfig);
    const redirectQueryParam = useRedirectPath();
    const dispatch = useDispatch();

    return {
        appBaseUrl,
        isUserLogged: Boolean(authState.manager) && Boolean(authState.apiToken),
        isAppReadyToUse: Boolean(appConfigState),
        isPathOnProtectedLocation,
        appHomePath: '/home',
        appEntryPath: '/login' + redirectQueryParam,
        getApiToken: () => authState.apiToken,
        getUser: () => {
            if (!Boolean(authState.manager)) {
                return null;
            }

            return {
                apiToken: authState.apiToken,
                askForNewPassword: authState.manager.askForNewPassword,
                hasPermissions: (requiredPermissions) => {
                    const permissions = authState?.manager?.profile?.permissions;

                    if (!permissions) {
                        return false;
                    }

                    for (const feature of requiredPermissions) {
                        if (!permissions.includes(feature)) {
                            return false;
                        }
                    }

                    return true;
                },
            };
        },
        initApp() {
            if (!authState.apiToken) return;

            loadUser(store as any)
                .then(() => dispatch(AppConfigActionCreators.loadApplicationConfig()))
                .catch((err) => {
                    removeInactivityAlertListeners();
                    store.dispatch(errorAction(err));
                    windowStorage.removeItem(WindowStorageToken.managerApiToken);
                    store.dispatch({
                        type: ActionTypes.loginFailed,
                        payload: {},
                    });
                });
        },
    };
};

export default useAuthConfig;

function loadUser(store: Store<State>) {
    return getMyself().then((res) => {
        store.dispatch({
            type: ActionTypes.loadedSelf,
            payload: res.manager,
        });

        addInactivityListeners(store, res.manager);

        getPendingActions().then((res) => {
            const permissions = store.getState().auth.manager.profile?.permissions;
            store.dispatch({
                type: ActionTypes.loadedPendingActions,
                payload: Object.assign({}, { pendingActions: res }, { permissions }),
            });
        });
    });
}
