import { useLocation } from './useLocation';

export const useRedirectPath = () => {
    const location = useLocation();

    const getRedirectQueryParam = () => {
        return `?redirectUrl=${location.pathname}`;
    };

    return getRedirectQueryParam();
};
