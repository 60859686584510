import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FileDocument } from './utils/types';

interface Props {
    document: FileDocument;

    onDelete(document: FileDocument): void;

    onCancel(): void;
}

export function RemoveDocumentConfirmationModal({ document, onDelete, onCancel }: Props) {
    return (
        <Modal.Dialog>
            <Modal.Header>
                <Modal.Title>Remove Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Are you sure you want to remove document <strong>{document.fileName}</strong>
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="wfp-btn" onClick={onCancel} variant="secondary">
                    Cancel
                </Button>
                <Button className="wfp-btn--primary" onClick={() => onDelete(document)} variant="primary">
                    Remove Document
                </Button>
            </Modal.Footer>
        </Modal.Dialog>
    );
}
