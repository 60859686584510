import BigNumber from './bigNumber';

function notValidNumber(numberToValidate: number): boolean {
    return (
        numberToValidate === null ||
        numberToValidate === undefined ||
        Number.isNaN(numberToValidate) ||
        !Number.isFinite(numberToValidate)
    );
}

export function roundTo(valueBeforeRounding: number, numberOfDecimalPlaces: number) {
    if (notValidNumber(valueBeforeRounding) || notValidNumber(numberOfDecimalPlaces)) {
        throw Error(`Value not supported ${valueBeforeRounding} ${numberOfDecimalPlaces}`);
    }
    return new BigNumber(valueBeforeRounding).decimalPlaces(numberOfDecimalPlaces).toNumber();
}

export function scaleFloatToInteger(floatValue: number, powerOfTen: number): number {
    if (notValidNumber(floatValue) || notValidNumber(powerOfTen)) {
        throw Error(`Value not supported ${floatValue} ${powerOfTen}`);
    }
    const scaleFactor = Math.pow(10, powerOfTen);
    const scaledValue = new BigNumber(floatValue).multipliedBy(scaleFactor);
    return Math.trunc(scaledValue.toNumber());
}
