import { errorAction } from './notifications';
import { throwErrorIfNotHandled } from './errorMiddleware';

export function handleImportFlowPoFetching(err, dispatch) {
    if (err.status === 422) {
        err.json().then(({ errors }) => {
            if (errors) {
                dispatch(errorAction(`Failed to fetch PO details - ${errors}`));
            } else {
                dispatch(errorAction(`Failed to fetch PO details`));
            }
        });
    } else {
        throwErrorIfNotHandled(`Failed to fetch PO details`)(err);
    }
}
