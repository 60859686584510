import React from 'react';
import { EntityUpdate } from '../taskAuthorizations';
import { compose, wfpFormat } from '../../utils/utils';
import { paymentFrequencyMap } from '../../utils/paymentFrequencyMap';
import { useSelector } from 'react-redux';
import { Permission } from '../../app/appConfig';
import { DisplayPublicGpgKey } from '../../managers/helpers/DisplayPublicGpgKey';
import State from '@wfp-root-app/store/state';

interface Props {
    updates: EntityUpdate[];
    isActivityLogsView?: boolean;
}

interface PermissionsWithSections {
    [key: string]: Permission[];
}

export function DefaultChangesList({ updates, isActivityLogsView }: Props) {
    const allPermissions = useSelector((state: State) => state.appConfig.permissions);

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <table className={`wfp-table--striped ${isActivityLogsView ? '' : 'mt4 table-hover'}`}>
                <thead>
                    <tr>
                        <th>Property</th>
                        <th>Old value</th>
                        <th>New value</th>
                    </tr>
                </thead>
                <tbody>
                    {updates
                        .filter(
                            (update) =>
                                JSON.stringify(update.newValue) !== JSON.stringify(update.oldValue) &&
                                update['path'] !== 'updatedAt' &&
                                update['path'] !== 'authorizedAt'
                        )
                        .map((update) => displayUpdate(update, allPermissions))}
                </tbody>
            </table>
        </div>
    );
}

function displayUpdate(update: EntityUpdate, permissions: PermissionsWithSections) {
    const { oldValue, newValue } = displayedValues(update, permissions);

    return (
        <tr key={update.path}>
            <td>{update.path ? wfpFormat(update.path) : '-'}</td>
            <td>{oldValue}</td>
            <td>{newValue}</td>
        </tr>
    );
}

function displayedValues(update: EntityUpdate, permissions: PermissionsWithSections): { oldValue; newValue } {
    if (update.path === 'featuresAccess' || update.path === 'permissions') {
        return displayedPermissions(update, permissions);
    } else if (update.path === 'paymentFrequency') {
        return displayedPaymentFrequency(update);
    } else if (update.path === 'expirationDate' || update.path === 'startDate') {
        return displayedDate(update);
    } else if (update.path === 'profile') {
        return displayedProfile(update);
    } else if (update.path === 'publicKey') {
        return displayedGpgPublicKey(update);
    } else {
        return {
            oldValue: compose(wfpFormat, displayValue)(update.oldValue),
            newValue: compose(wfpFormat, displayValue)(update.newValue),
        };
    }
}

function displayedGpgPublicKey(update: EntityUpdate) {
    return {
        oldValue: (
            <div style={{ maxWidth: '300px', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto' }}>
                <DisplayPublicGpgKey publicGpgKey={update.oldValue} />
            </div>
        ),
        newValue: (
            <div style={{ maxWidth: '300px', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto' }}>
                <DisplayPublicGpgKey publicGpgKey={update.newValue} />
            </div>
        ),
    };
}

function displayedPermissions(update: EntityUpdate, permissions: PermissionsWithSections): { oldValue; newValue } {
    const sections = {};

    Object.values(permissions).forEach((section) => {
        section.forEach((permission) => {
            sections[permission.permissionName] = permission.metadata;
        });
    });

    const addedValues =
        update.newValue && update.newValue.length > 0
            ? update.newValue.filter((permission) => !update.oldValue.includes(permission))
            : [];
    const removedValues =
        update.oldValue && update.oldValue.length > 0
            ? update.oldValue.filter((permission) => !update.newValue.includes(permission))
            : [];
    const unchangedValues =
        update.oldValue && update.oldValue.length > 0
            ? update.oldValue.filter(
                  (permission) => !addedValues.includes(permission) && !removedValues.includes(permission)
              )
            : [];

    const oldValues = [];
    unchangedValues.forEach((permission, index) =>
        sections[permission]
            ? oldValues.push(<p key={`old/unchanged/${index}/` + permission}>{sections[permission].displayText}</p>)
            : null
    );
    removedValues.forEach((permission, index) =>
        sections[permission]
            ? oldValues.push(
                  <p key={`old/removed/${index}/` + permission} style={{ color: 'red' }}>
                      {sections[permission].displayText}
                  </p>
              )
            : null
    );
    addedValues.forEach((permission, index) =>
        oldValues.push(
            <p key={`old/added/${index}/` + permission} style={{ color: 'green' }}>
                -
            </p>
        )
    );

    const newValues = [];
    unchangedValues.forEach((permission, index) =>
        sections[permission]
            ? newValues.push(<p key={`new/unchanged/${index}/` + permission}>{sections[permission].displayText}</p>)
            : null
    );
    removedValues.forEach((permission, index) =>
        newValues.push(
            <p key={`new/removed/${index}/` + permission} style={{ color: 'red' }}>
                -
            </p>
        )
    );
    addedValues.forEach((permission, index) =>
        sections[permission]
            ? newValues.push(
                  <p key={`new/added/${index}/` + permission} style={{ color: 'green' }}>
                      {sections[permission].displayText}
                  </p>
              )
            : null
    );

    return {
        oldValue: <div>{oldValues}</div>,
        newValue: <div>{newValues}</div>,
    };
}

function displayValue(value) {
    if (value instanceof Array) {
        return (
            <div>
                {value.map((text) => (
                    // eslint-disable-next-line react/jsx-key
                    <div>{text}</div>
                ))}
            </div>
        );
    }
    if (value === true) {
        return 'Yes';
    }
    if (value === false) {
        return 'No';
    }
    return value;
}

function displayedPaymentFrequency(update: EntityUpdate): { oldValue; newValue } {
    return {
        oldValue: paymentFrequencyMap[update.oldValue],
        newValue: paymentFrequencyMap[update.newValue],
    };
}

function displayedDate(update: EntityUpdate): { oldValue; newValue } {
    return {
        oldValue: update.oldValue ? update.oldValue.slice(0, 10) : '-',
        newValue: update.newValue ? update.newValue.slice(0, 10) : '-',
    };
}

function displayedProfile(update: EntityUpdate): { oldValue; newValue } {
    return {
        oldValue: update.oldValue ? `(ID: ${update.oldValue.id}) ${update.oldValue.name}` : '-',
        newValue: update.newValue ? `(ID: ${update.newValue.id}) ${update.newValue.name}` : '-',
    };
}
