import { EntityType } from '../authorization/taskAuthorizations';
import { Beneficiary } from '../beneficiaries/beneficiaries';

export default function hasConflictingBeneficiaryUpdates(beneficiary: Beneficiary, conflictingUpdates: EntityType[]) {
    let hasConflictingUpdate = false;
    if (beneficiary) {
        const conflictingEntityTypes = beneficiary.pendingEntityUpdates.filter((update) =>
            conflictingUpdates.includes(update.entityType)
        );
        hasConflictingUpdate = conflictingEntityTypes.length !== 0;
    }
    return hasConflictingUpdate;
}
