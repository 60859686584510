import { Entitlement } from '../beneficiaries/model';
import { DateSelectFilter, Filter, FilterTypes } from '../utils/FilterTypes';
import { PagedState } from '../utils/paging';
import moment from 'moment-timezone';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { BeneficiaryModel } from './beneficiaries/beneficiariesActions';
import { BaseUrl, get, post } from './api/configs/api-config';
import { DeduplicatedEntitlement } from '../entitlements/deduplicatedEntitlements';

export const tooLongValueError = new Error('Given value is too long');

function assignFiltersToParams(filters: Array<Filter>, params: { _offset: number; _limit: number }) {
    filters.forEach((f) => {
        if (f.isSelected) {
            if (f instanceof DateSelectFilter) {
                const dateFilter = f as DateSelectFilter;
                const startDateString = moment(dateFilter.dateRange.startDate).tz('utc').format();
                const endDateString = moment(dateFilter.dateRange.endDate).tz('utc').format();
                params[f.name + ':gte'] = startDateString;
                params[f.name + ':lte'] = endDateString;
            } else if (f.type === FilterTypes.multiselect) {
                const values = f.value ? f.value.map((selectOption) => selectOption.value) : [];
                params[f.name] = values.toString();
            } else {
                params[f.name] = f.value;
            }
        }
    });
}

async function postWithOffset<T>(
    url: string,
    filters: Array<Filter>,
    offset: number,
    limit: number,
    customBody: any = {}
): Promise<PagedState<T>> {
    const body = Object.assign({}, { _offset: offset, _limit: limit });
    assignFiltersToParams(filters, body);
    Object.assign(body, customBody);

    const res = await post(url, body);
    if (res.status === 204) {
        return new PagedState<T>().configureFor204Response(limit, filters);
    }
    return res;
}

function postWithPaging<T>(
    url: string,
    filters: Array<Filter>,
    page: number,
    limit: number,
    customParams: any = {}
): Promise<PagedState<T>> {
    const offset = (page - 1) * limit;
    return postWithOffset(url, filters, offset, limit, customParams);
}

export function getAppConfig() {
    return get(`${BaseUrl()}/app-config`);
}

export function loginPartner(email: string, password: string, otp?: string) {
    return post(`${BaseUrl()}/auth`, { email, password, otp });
}

export function logoutPartner() {
    return post(`${BaseUrl()}/logout`, {});
}

export function sendOtpCode(email: string, password: string) {
    return post(`${BaseUrl()}/send-otp-email`, { email, password });
}

export function loadBeneficiary(taxId: string): Promise<BeneficiaryModel> {
    return post(`${BaseUrl()}/beneficiaries/beneficiary`, { beneficiaryId: taxId });
}

export function getEntitlements(beneficiaryId: string, pageNumber: number, pageSize: number) {
    const url = `${BaseUrl()}/beneficiaries/beneficiary/entitlements`;
    return postWithPaging<Entitlement>(url, [], pageNumber, pageSize, { beneficiaryId });
}

export function getDeduplicatedEntitlements(beneficiaryId: string, pageNumber: number, pageSize: number) {
    const url = `${BaseUrl()}/beneficiaries/beneficiary/deduplicated-entitlements`;
    return postWithPaging<DeduplicatedEntitlement>(url, [], pageNumber, pageSize, { beneficiaryId });
}

export function loadVendorsNickNames(query: SORT_OPTIONS) {
    const searchparams = Object.entries(query).reduce(
        (object, [key, value]) =>
            value
                ? {
                      ...object,
                      [key]: value,
                  }
                : object,
        {}
    );
    const querystring = new URLSearchParams(searchparams);
    return get(`${BaseUrl()}/vendors/nick-names?${querystring}`);
}

export function loadTransactionDetails(transactionId: string) {
    return get(`${BaseUrl()}/transactions/${transactionId}/details`);
}

export function healthCheck() {
    return get(`${BaseUrl()}/health-check`);
}

export function getBeneficiariesTransactionStatistics(filters) {
    const query = new URLSearchParams(filters).toString();
    return get(`${BaseUrl()}/statistics/mobilization-statistics?${query}`);
}

export function getTodayBeneficiariesTransactionStatistics(filters) {
    const query = new URLSearchParams(filters).toString();
    return get(`${BaseUrl()}/statistics/mobilization-statistics/today?${query}`);
}

export function getMobilizationStatisticsSyncDate() {
    return get(`${BaseUrl()}/statistics/mobilization-statistics/sync-date`);
}

export function getBranchCodes() {
    return get(`${BaseUrl()}/branch-codes`);
}
