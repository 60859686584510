import { useToggle } from '../../utils/useToggle';
import { ChangeEvent, MouseEvent, useState } from 'react';
interface ReturnObject {
    edit: boolean;
    handleChange(event: ChangeEvent<HTMLInputElement>): void;
    handleEdit(event: MouseEvent<HTMLButtonElement>): Promise<void>;
    handleCancel(event: MouseEvent<HTMLButtonElement>): void;
    value: string;
    toggleEdit(event: any): void;
}
export function useDocumentSection(
    name: string,
    onSave: (newValue: string, oldValue: string) => Promise<void>
): ReturnObject {
    const [edit, toggleEdit] = useToggle();
    const [value, setValue] = useState(name);
    const handleChange = (event) => {
        event.persist();
        setValue(event.target.value);
    };
    const handleEdit = async () => {
        if (value.trim()) {
            if (value !== name) {
                await onSave(value.trim(), name);
            }
            toggleEdit();
        }
    };
    const handleCancel = () => {
        toggleEdit();
        setValue(name);
    };
    return {
        edit,
        value,
        handleChange,
        handleEdit,
        handleCancel,
        toggleEdit,
    };
}
