import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Beneficiary, BeneficiaryStatus, BeneficiaryStatuses } from '../beneficiaries';
import { EditBeneficiaryCreators } from './editBeneficiaryActionCreator';
import { AuthState } from '../../login/auth';
import { SideMenu, SideMenuProps } from '../../utils/SideMenu';
import BeneficiarySearch from '../BeneficiarySearch';
import State from '@wfp-root-app/store/state';
import { Outlet, useParams } from 'react-router-dom';
import { NavigateHook, useNavigate } from '@wfp-common/hooks/useNavigate';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';
import { useLocation } from '@wfp-common/hooks/useLocation';

export enum BeneficiaryTab {
    Main = 'Main',
    Entitlements = 'Entitlements',
    DeduplicatedEntitlements = 'Deduplicated Entitlements',
    Tokens = 'Tokens',
}

export const urlPathForTab = {
    [BeneficiaryTab.Main]: 'main',
    [BeneficiaryTab.Entitlements]: 'entitlements',
    [BeneficiaryTab.DeduplicatedEntitlements]: 'deduplicated-entitlements',
    [BeneficiaryTab.Tokens]: 'tokens',
};

interface Props {
    routes: { path: string }[];
    router: any;
    beneficiaryId: string;
    beneficiaryStatus?: BeneficiaryStatus;
    auth: AuthState;
    beneficiary: Beneficiary;
    searchBeneficiary: (beneficiaryId: string) => void;
    redirectToPage: (url: string) => void;
    loadBeneficiary: (string) => void;
    navigate: NavigateHook;
    beneficiaryTab: string;
}

interface DetailsState {
    activeTab: BeneficiaryTab;
    searchValue: string;
}

function EditBeneficiarySideMenu(props: SideMenuProps<BeneficiaryTab>) {
    return SideMenu(props);
}

class EditBeneficiaryPage extends React.Component<Props, DetailsState> {
    constructor(props) {
        super(props);
        this.state = this.createDefaultState();
    }

    componentDidMount() {
        const { loadBeneficiary, beneficiaryId } = this.props;
        loadBeneficiary(beneficiaryId);
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const { loadBeneficiary, beneficiaryId } = this.props;

        if (prevProps.beneficiaryId !== beneficiaryId) {
            loadBeneficiary(beneficiaryId);
        }
    }

    renderBreadcrumbs() {
        const { beneficiary } = this.props;

        return (
            <div className="col-sm-12 row">
                <nav className="wfp-breadcrumbs col-sm-6">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/entitlements/download">
                                <span>Assistance</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">{`Edit BB Account ${
                                beneficiary ? '"' + beneficiary.id + '"' : ''
                            }`}</span>
                        </li>
                    </ol>
                </nav>
                <BeneficiarySearch
                    search={(value) => this.props.searchBeneficiary(value)}
                    style={{ justifyContent: 'flex-end' }}
                />
            </div>
        );
    }

    activeMenuTab(): BeneficiaryTab {
        const tab = Object.entries(urlPathForTab).find(([, v]) => v === this.props.beneficiaryTab);

        if (!tab) return;

        return tab[0] as BeneficiaryTab;
    }

    renderSidemenu() {
        const { beneficiary } = this.props;
        const generalSubtabs = [
            BeneficiaryTab.Main,
            BeneficiaryTab.Entitlements,
            BeneficiaryTab.DeduplicatedEntitlements,
            BeneficiaryTab.Tokens,
        ];

        const activeTab = this.activeMenuTab();
        return (
            <div>
                <EditBeneficiarySideMenu
                    activeTab={activeTab}
                    onSelectTab={(activeTab) => {
                        const url = urlPathForTab[activeTab];
                        this.props.navigate(`/entitlements/download/beneficiary/${url}`, {
                            beneficiaryId: beneficiary.id,
                        });
                    }}
                    tabs={generalSubtabs}
                />
            </div>
        );
    }

    render() {
        const { beneficiary, beneficiaryStatus } = this.props;

        return (
            <main className={'row'}>
                {this.renderBreadcrumbs()}
                <div className="col-sm-12">
                    <h5 style={{ marginTop: 0, marginBottom: 0 }}>
                        {beneficiary ? beneficiary.id : ''}
                        {beneficiaryStatus === BeneficiaryStatuses.active && (
                            <span className={'active-indicator'}>Active</span>
                        )}
                        {beneficiaryStatus === BeneficiaryStatuses.blocked && (
                            <span className={'blocked-indicator'}>Blocked</span>
                        )}
                    </h5>
                </div>
                <div className="col-sm-3">{this.renderSidemenu()}</div>
                <div className="col-sm-9" style={{ marginBottom: 150 }}>
                    <Outlet />
                </div>
                <div className="clearfix" />
            </main>
        );
    }

    private createDefaultState() {
        return {
            searchValue: '',
            beneficiary: new Beneficiary(),
            activeTab: BeneficiaryTab.Entitlements,
        };
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        searchBeneficiary: bindActionCreators(EditBeneficiaryCreators.searchBeneficiary, dispatch),
        redirectToPage: (url) => dispatch(routerHelperActions.makeRedirect(url)),
        loadBeneficiary: bindActionCreators(EditBeneficiaryCreators.loadBeneficiary, dispatch),
    };
}

function mapStateToProps(state: State) {
    return {
        beneficiaryStatus: state.editBeneficiary.beneficiary ? state.editBeneficiary.beneficiary.status : null,
        beneficiary: state.editBeneficiary.beneficiary,
        auth: state.auth,
    };
}

const withHooks = (Component: any) => {
    return (props: any) => {
        const location = useLocation();
        const navigate = useNavigate();
        const { beneficiaryTab } = useParams();

        if (!location.state?.beneficiaryId) {
            return <Navigate to={'/entitlements/download'} />;
        }

        return (
            <Component
                {...props}
                beneficiaryId={location.state.beneficiaryId}
                navigate={navigate}
                beneficiaryTab={beneficiaryTab}
            />
        );
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(EditBeneficiaryPage));
