import { useParams } from 'react-router-dom';
import { ManagerTabUrl } from '../models/manager-tabs.model';
import { useSelector } from 'react-redux';
import { ExtendedManager } from '../managers';
import State from '@wfp-root-app/store/state';

interface ManagerParams {
    managerTab: ManagerTabUrl;
    managerId: string;
    manager: ExtendedManager;
}

export const useManagerParams = (): ManagerParams => {
    const { managerId, managerTab } = useParams();
    const manager = useSelector(
        (state: State) =>
            state.managers.items.find((manager) => manager.id.toString() === managerId) || new ExtendedManager()
    );

    return {
        managerId,
        managerTab: managerTab as ManagerTabUrl,
        manager,
    };
};
